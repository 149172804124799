import {graphql} from 'gatsby'
import * as IO from 'io-ts'
import {LinkedItems} from '~/kentico/util'
import {KenticoContentData} from '../content'
import {KenticoPageLinkData} from '../page-link'

/** Kentico Form Submission Redirect Data. */
export const KenticoFormSubmissionRedirectData = IO.intersection(
  [
    KenticoContentData,
    IO.type({
      elements: IO.type({
        redirectPage: LinkedItems(KenticoPageLinkData),
        responseStatus: IO.type({
          value: IO.array(
            IO.type({
              codename: IO.keyof({
                approved: undefined,
                declined: undefined,
                error: undefined,
                new_customer: undefined,
                temp_declined: undefined,
                multiple_error: undefined,
              }),
            }),
          ),
        }),
      }),
      system: IO.type({
        type: IO.literal('form_submission_redirect'),
      }),
    }),
  ],
  'KenticoFormSubmissionRedirect',
)

/** Form Submission Redirect type. */
export interface KenticoFormSubmissionRedirectData
  extends IO.TypeOf<typeof KenticoFormSubmissionRedirectData> {}

/** Fragment helper. */
export const fragment = graphql`
  fragment KenticoFormSubmissionRedirect on Node {
    ... on kontent_item_form_submission_redirect {
      elements {
        responseStatus: response_status {
          value {
            codename
          }
        }
        redirectPage: redirect_page {
          nodes: value {
            ...KenticoPageLink
          }
        }
      }
      system {
        id
        codename
        name
        type
      }
    }
  }
`
