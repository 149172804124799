"use strict";

exports.__esModule = true;
exports.resetImageCache = exports.activateCacheForMultipleImages = exports.activateCacheForImage = exports.allInImageCache = exports.inImageCache = void 0;

var _HelperUtils = require("./HelperUtils");

var _MediaUtils = require("./MediaUtils");

var _ImageUtils = require("./ImageUtils");

var _SimpleUtils = require("./SimpleUtils");

var imageCache = Object.create({});

var inImageCache = function inImageCache(props, index, isLoop) {
  if (index === void 0) {
    index = 0;
  }

  if (isLoop === void 0) {
    isLoop = false;
  }

  var convertedProps = (0, _HelperUtils.convertProps)(props);
  var isImageStack = (0, _ImageUtils.hasImageArray)(convertedProps) && !(0, _MediaUtils.hasArtDirectionArray)(convertedProps);

  if (isImageStack && !isLoop) {
    return allInImageCache(props);
  }

  var src = isImageStack ? (0, _ImageUtils.getSelectedImage)(convertedProps, index) : (0, _ImageUtils.getImageSrcKey)(convertedProps);

  if ((0, _SimpleUtils.isObject)(src)) {
    var objectSrc = (0, _ImageUtils.getImageSrcKey)({
      fluid: src,
      fixed: src
    });
    return imageCache[objectSrc] || false;
  }

  return imageCache[src] || false;
};

exports.inImageCache = inImageCache;

var allInImageCache = function allInImageCache(props) {
  var convertedProps = (0, _HelperUtils.convertProps)(props);
  var imageStack = convertedProps.fluid || convertedProps.fixed;
  return imageStack.every(function (imageData, index) {
    return inImageCache(convertedProps, index, true);
  });
};

exports.allInImageCache = allInImageCache;

var activateCacheForImage = function activateCacheForImage(props, index, isLoop) {
  if (index === void 0) {
    index = 0;
  }

  if (isLoop === void 0) {
    isLoop = false;
  }

  var convertedProps = (0, _HelperUtils.convertProps)(props);
  var isImageStack = (0, _ImageUtils.hasImageArray)(convertedProps) && !(0, _MediaUtils.hasArtDirectionArray)(convertedProps);

  if (isImageStack && !isLoop) {
    return activateCacheForMultipleImages(props);
  }

  var src = isImageStack ? (0, _ImageUtils.getSelectedImage)(convertedProps, index) : (0, _ImageUtils.getImageSrcKey)(convertedProps);

  if (src) {
    if ((0, _SimpleUtils.isObject)(src)) {
      var objectSrc = (0, _ImageUtils.getImageSrcKey)({
        fluid: src,
        fixed: src
      });
      imageCache[objectSrc] = true;
    } else {
      imageCache[src] = true;
    }
  }
};

exports.activateCacheForImage = activateCacheForImage;

var activateCacheForMultipleImages = function activateCacheForMultipleImages(props) {
  var convertedProps = (0, _HelperUtils.convertProps)(props);
  var imageStack = convertedProps.fluid || convertedProps.fixed;
  imageStack.forEach(function (imageData, index) {
    return activateCacheForImage(convertedProps, index, true);
  });
};

exports.activateCacheForMultipleImages = activateCacheForMultipleImages;

var resetImageCache = function resetImageCache() {
  for (var prop in imageCache) {
    delete imageCache[prop];
  }
};

exports.resetImageCache = resetImageCache;