import {lighten} from 'polished'
import React, {ReactNode, useCallback, useContext, useState} from 'react'
import {FormContext, useForm} from 'react-hook-form'
import styled from 'styled-components'
import {Button} from '~/bootstrap/components/button'
import {Form, FormGroup, FormItem} from '~/bootstrap/components/form'
import {
  Col as BootstrapCol,
  Container as BootstrapContainer,
  Row as BootstrapRow,
} from '~/bootstrap/components/layout'
import {CalculatorsContext} from '~/calculators/context'
import {Customize} from '~/common/components/customize'
import {Spinner} from '~/common/components/spinner'
import {StateOptions} from '~/common/components/state-options'
import {Color} from '~/common/types'
import {VehiclesMake} from '~/vehicles/components/make'
import {VehiclesModel} from '~/vehicles/components/model'
import {VehiclesSeries} from '~/vehicles/components/series'
import {VehiclesStyle} from '~/vehicles/components/style'
import {VehiclesYear} from '~/vehicles/components/year'
import {VehiclesContext} from '~/vehicles/context'
import {KenticoRichText, KenticoRichTextData} from '../rich-text'
import {
  FormData,
  Response,
  createOnSubmit,
} from '../title-loan-calculator/form'
import {FinalAmount} from './amount'
import {KenticoNeedMoreCashData} from './data'

/** Kentico type data. */
export {KenticoNeedMoreCashData}

interface Props {
  mainText: KenticoRichTextData
  subText: KenticoRichTextData
  editLink?: ReactNode
  backgroundColor: Color
  /** Reserved for testing. This should not be used normally. */
  response?: Response
}

const Container = styled(BootstrapContainer)`
  text-align: center;

  h1,
  h2,
  h3,
  h4 {
    line-height: ${({theme}) => theme.spacers[5]};
  }

  && {
    padding: ${({theme}) => theme.spacers[4]};
  }
`

const Row = styled(BootstrapRow)`
  @media (max-width: ${({theme}) => theme.breakpoints.max.sm}) {
    && {
      display: contents;
    }
  }
`

const Col = styled(BootstrapCol)`
  padding: ${({theme}) => theme.spacers[3]};
`

const ErrorMessage = styled.div`
  background-color: ${({theme}) => lighten(0.3, theme.colors.red)};
  color: ${({theme}) => theme.colors.red};
  padding: ${({theme}) => theme.spacers[2]};
  border-radius: 5px;
`

/**
 * Render yes more cash component from Kentico Data.
 * @return React component
 */
export const KenticoYesMoreCash = ({
  backgroundColor,
  editLink,
  mainText,
  subText,
  response: defaultResponse = {},
}: Props) => {
  const {title} = useContext(CalculatorsContext)
  const {selected} = useContext(VehiclesContext)
  const form = useForm<FormData>({mode: 'onChange'})
  const {
    handleSubmit,
    formState: {isValid},
  } = form
  const [response, setResponse] = useState<Response>(defaultResponse)

  const onSubmit = useCallback(
    createOnSubmit({
      selected,
      setResponse,
      title,
    }),
    [setResponse, title, selected],
  )

  let responseMessage
  if (response.success === false) {
    responseMessage = (
      <ErrorMessage>
        We are unable to submit your information at this time. Please try again
        later.
      </ErrorMessage>
    )
  } else if (response.success === true) {
    return (
      <FinalAmount
        backgroundColor={backgroundColor}
        editLink={editLink}
        finePrint={response.data.finePrint}
        loanAmount={response.data.amount}
        mainText={mainText}
        subText={subText}
      />
    )
  }

  const submitButtonText = title.ready ? 'Show Me How Much' : <Spinner />

  return (
    <FormContext {...form}>
      <Customize
        background={backgroundColor}
        component={Container}
        textColor="light"
      >
        {editLink}
        <KenticoRichText data={mainText} />
        <KenticoRichText data={subText} />
        <Form id="needMoreCashTitleForm" onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <Col>
              <VehiclesYear />
            </Col>
            <Col>
              <VehiclesMake />
            </Col>
            <Col>
              <VehiclesModel />
            </Col>
          </Row>
          <Row>
            <Col>
              <VehiclesSeries />
            </Col>
            <Col>
              <VehiclesStyle />
            </Col>
            <Col>
              <FormGroup>
                <FormItem
                  defaultValue=""
                  label="State"
                  name="state"
                  required="Please select a state"
                  type="select"
                >
                  <option disabled value="">
                    State
                  </option>
                  <StateOptions />
                </FormItem>
              </FormGroup>
            </Col>
          </Row>
          {responseMessage}
          <Row>
            <Col>
              <Button color="primary" disabled={!title.ready || !isValid}>
                {submitButtonText}
              </Button>
            </Col>
          </Row>
        </Form>
      </Customize>
    </FormContext>
  )
}
