import {graphql} from 'gatsby'
import * as IO from 'io-ts'
import {KenticoAccessCodeData} from '../access-code'
import {KenticoAccordionData} from '../accordion'
import {KenticoApprovedBannerData} from '../approved-banner'
import {KenticoBulletedListData} from '../bulleted-list'
import {KenticoButtonWithImageData} from '../button-with-image'
import {KenticoCampaignLandingData} from '../campaign-landing'
import {KenticoCardsData} from '../cards'
import {KenticoChecklistData} from '../checklist'
import {KenticoCityMapData} from '../city-map'
import {KenticoContactUsFormData} from '../contact-us-form'
import {KenticoErrorBodyData} from '../error-body'
import {KenticoFooterData} from '../footer'
import {KenticoFormData} from '../form'
import {KenticoFormDeclinedData} from '../form-declined'
import {KenticoFullSizeImageData} from '../full-size-image'
import {KenticoHeaderData} from '../header'
import {KenticoHeroBannerData} from '../hero-banner'
import {KenticoHowItWorksData} from '../how-it-works'
import {KenticoInstallmentLoanCalculatorData} from '../installment-loan-calculator'
import {KenticoLegalData} from '../legal'
import {KenticoLinkListData} from '../link-list'
import {KenticoLinksColumnData} from '../links-column'
import {KenticoListWithImagesData} from '../list-with-images'
import {KenticoListWithImagesHorizontalData} from '../list-with-images-horizontal'
import {KenticoLoanOptionsData} from '../loan-options'
import {KenticoLocationSearchData} from '../location-search'
import {KenticoNeedMoreCashData} from '../need-more-cash'
import {KenticoPageMenuSwitcherData} from '../page-menu-switcher'
import {KenticoPageSwitcherData} from '../page-switcher'
import {KenticoProductDetailsData} from '../product-details'
import {KenticoReferralFormData} from '../referral-form'
import {KenticoReviewsData} from '../reviews'
import {KenticoSecuritySealData} from '../security-seal'
import {KenticoSpecialOfferData} from '../special-offer'
import {KenticoSpecialOfferRebateData} from '../special-offer-rebate'
import {KenticoStaticChecklistData} from '../static-checklist'
import {KenticoStoreLocationData} from '../store-location'
import {KenticoStoreLocatorData} from '../store-locator'
import {KenticoTableData} from '../table'
import {KenticoTextData} from '../text'
import {KenticoTextWithButtonLinkData} from '../text-with-button-link'
import {KenticoTextWithImageData} from '../text-with-image'
import {KenticoTitleLoanCalculatorData} from '../title-loan-calculator'
import {KenticoListWithVideosData} from '../list-with-videos'

/** Base data type. */
export type KenticoBodyItemBaseData =
  | KenticoAccessCodeData
  | KenticoAccordionData
  | KenticoApprovedBannerData
  | KenticoBulletedListData
  | KenticoButtonWithImageData
  | KenticoCampaignLandingData
  | KenticoCardsData
  | KenticoChecklistData
  | KenticoCityMapData
  | KenticoContactUsFormData
  | KenticoErrorBodyData
  | KenticoFooterData
  | KenticoFormData
  | KenticoFormDeclinedData
  | KenticoFullSizeImageData
  | KenticoHeaderData
  | KenticoHeroBannerData
  | KenticoHowItWorksData
  | KenticoInstallmentLoanCalculatorData
  | KenticoLegalData
  | KenticoLinkListData
  | KenticoLinksColumnData
  | KenticoListWithImagesData
  | KenticoListWithImagesHorizontalData
  | KenticoListWithVideosData
  | KenticoLoanOptionsData
  | KenticoLocationSearchData
  | KenticoNeedMoreCashData
  | KenticoPageMenuSwitcherData
  | KenticoPageSwitcherData
  | KenticoProductDetailsData
  | KenticoReferralFormData
  | KenticoReviewsData
  | KenticoSecuritySealData
  | KenticoSpecialOfferData
  | KenticoSpecialOfferRebateData
  | KenticoStaticChecklistData
  | KenticoStoreLocationData
  | KenticoStoreLocatorData
  | KenticoTableData
  | KenticoTextData
  | KenticoTextWithButtonLinkData
  | KenticoTextWithImageData
  | KenticoTitleLoanCalculatorData

/** Kentico body item data base. */
export const KenticoBodyItemBaseData = IO.union(
  [
    KenticoAccessCodeData,
    KenticoAccordionData,
    KenticoApprovedBannerData,
    KenticoBulletedListData,
    KenticoButtonWithImageData,
    KenticoCampaignLandingData,
    KenticoCardsData,
    KenticoChecklistData,
    KenticoCityMapData,
    KenticoContactUsFormData,
    KenticoErrorBodyData,
    KenticoFooterData,
    KenticoFormData,
    KenticoFormDeclinedData,
    KenticoFullSizeImageData,
    KenticoHeaderData,
    KenticoHeroBannerData,
    KenticoHowItWorksData,
    KenticoInstallmentLoanCalculatorData,
    KenticoLegalData,
    KenticoLinkListData,
    KenticoLinksColumnData,
    KenticoListWithImagesData,
    KenticoListWithImagesHorizontalData,
    KenticoListWithVideosData,
    KenticoLoanOptionsData,
    KenticoLocationSearchData,
    KenticoNeedMoreCashData,
    KenticoPageMenuSwitcherData,
    KenticoPageSwitcherData,
    KenticoProductDetailsData,
    KenticoReferralFormData,
    KenticoReviewsData,
    KenticoSecuritySealData,
    KenticoSpecialOfferData,
    KenticoSpecialOfferRebateData,
    KenticoStaticChecklistData,
    KenticoStoreLocationData,
    KenticoStoreLocatorData,
    KenticoTableData,
    KenticoTextData,
    KenticoTextWithButtonLinkData,
    KenticoTextWithImageData,
    KenticoTitleLoanCalculatorData,
  ],
  'KenticoBodyItemBase',
)

/** Fragment helper. */
export const fragment = graphql`
  fragment KenticoBodyItemBase on Node {
    ...KenticoAccessCode
    ...KenticoAccordion
    ...KenticoApprovedBanner
    ...KenticoBulletedList
    ...KenticoButtonWithImage
    ...KenticoCampaignLanding
    ...KenticoCards
    ...KenticoChecklist
    ...KenticoCityMap
    ...KenticoContactUsForm
    ...KenticoErrorBody
    ...KenticoFooter
    ...KenticoForm
    ...KenticoFormDeclined
    ...KenticoFullSizeImage
    ...KenticoHeader
    ...KenticoHeroBanner
    ...KenticoHowItWorks
    ...KenticoInstallmentLoanCalculator
    ...KenticoLegal
    ...KenticoLinkList
    ...KenticoLinksColumn
    ...KenticoListWithImages
    ...KenticoListWithImagesHorizontal
    ...KenticoListWithVideos
    ...KenticoLoanOptions
    ...KenticoLocationSearch
    ...KenticoNeedMoreCash
    ...KenticoPageMenuSwitcher
    ...KenticoPageSwitcher
    ...KenticoProductDetails
    ...KenticoReferralForm
    ...KenticoReviews
    ...KenticoSecuritySeal
    ...KenticoSpecialOffer
    ...KenticoSpecialOfferRebate
    ...KenticoStaticChecklist
    ...KenticoStoreLocation
    ...KenticoStoreLocator
    ...KenticoTable
    ...KenticoText
    ...KenticoTextWithButtonLink
    ...KenticoTextWithImage
    ...KenticoTitleLoanCalculator
  }
`
