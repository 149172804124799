import {graphql} from 'gatsby'
import * as IO from 'io-ts'
import {LinkedItems} from '../../util'
import {KenticoBodyItemBaseData} from '../body-item-base'
import {KenticoContentData} from '../content'

/** Single column row. */
export const KenticoRowData = IO.intersection(
  [
    KenticoContentData,
    IO.type({
      elements: IO.type({
        columns: LinkedItems(KenticoBodyItemBaseData),
      }),
      system: IO.type({
        type: IO.literal('row'),
      }),
    }),
  ],
  'KenticoRow',
)

/** Row data type. */
export interface KenticoRowData extends IO.TypeOf<typeof KenticoRowData> {}

/** Fragment helper. */
export const fragment = graphql`
  fragment KenticoRow on Node {
    ... on kontent_item_row {
      system {
        codename
        id
        name
        type
      }
      elements {
        columns {
          nodes: value {
            ...KenticoBodyItemBase
          }
        }
      }
    }
  }
`
