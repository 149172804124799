import {CalculatorsContext} from '~/calculators/context'
import {TitleCalculatorData} from '~/calculators/context/title'
import {TitleCalculatorForm} from '~/calculators/types'
import {VehiclesContext} from '~/vehicles/context'

/** Form data. */
export interface FormData {
  state: string
}

interface Options {
  title: CalculatorsContext['title']
  selected: VehiclesContext['selected']
  setResponse(value: Response): void
}

/** Response data type. */
export type Response = ResponseDefault | ResponseSuccess | ResponseError

interface ResponseDefault {
  success?: undefined
}

interface ResponseSuccess {
  success: true
  data: TitleCalculatorData
}

interface ResponseError {
  success: false
}

/**
 * Create on submit handler.
 * @return Submit handler
 */
export const createOnSubmit = ({
  title,
  setResponse,
  selected,
}: Options) => async (formData: FormData) => {
  if (selected.style === undefined) {
    return
  }
  const data: TitleCalculatorForm = {
    make: selected.make,
    model: selected.model,
    series: selected.series,
    state: formData.state,
    style: selected.style,
    year: selected.year,
  }
  const wnd = window as any;
  if ('appInsights' in wnd) {
      wnd.appInsights.trackEvent('Cashstore:TitleCalculatorForm', data)
  }
  const result = await title.submit(data)
  if (result) {
    setResponse({data: result, success: true})
  } else {
    setResponse({success: false})
  }
}
