import React, {useCallback, useEffect} from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import {useFormContext} from 'react-hook-form'
import {ValidationOptions} from 'react-hook-form/dist/types'
import styled from 'styled-components'
import {FormGroup} from '~/bootstrap/components/form'

interface Props extends ValidationOptions {
  name: string
}

const GoogleRecaptcha = styled(ReCAPTCHA)`
  transform: scale(0.95);
  transform-origin: 0 0;
      @media (max-width: ${({theme}) => theme.breakpoints.max.xs}) {
      text-align: center;
    }
`

const StyledForm = styled(FormGroup)`
  && {
    @media (max-width: ${({theme}) => theme.breakpoints.max.xs}) {
      display: flex;
      place-content: center;
    }
  }
`

/**
 * Render Recaptcha V2.
 * @return React component
 */
export const Recaptcha = ({name, ...props}: Props) => {
  const {register, setValue} = useFormContext()
  // TODO Find way to code coverage callback
  // istanbul ignore next
  const onChange = useCallback(async (value: string | null) => {
    // eslint-disable-next-line @typescript-eslint/await-thenable
    await setValue(name, value, true)
  }, [name, setValue])
  useEffect(() => {
    register({name}, props)
    // We only want to register once (componentDidMount)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <StyledForm>
      <GoogleRecaptcha
        // Key has been checked in advance
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        sitekey={process.env.GOOGLE_RECAPTCHA_KEY!}
        onChange={onChange}
      />
    </StyledForm>
  )
}
