import {graphql} from 'gatsby'
import * as IO from 'io-ts'
import {LinkedItems} from '../../util'

/** Page Link. */
export const KenticoPageLinkData = IO.type(
  {
    elements: IO.intersection([
      IO.type({
        url: IO.type({
          value: IO.string,
        }),
      }),
      IO.partial({
        linkText: IO.type({
          value: IO.string,
        }),
        parent: LinkedItems(
          IO.type({
            elements: IO.type({
              url: IO.type({
                value: IO.string,
              }),
            }),
          }),
        ),
      }),
    ]),
    system: IO.type({
      id: IO.string,
      type: IO.keyof({
        city_page: undefined,
        external_link: undefined,
        form_page: undefined,
        page: undefined,
        page_collection: undefined,
        page_collection_item: undefined,
        sitemap: undefined,
        store_page: undefined,
      }),
    }),
  },
  'KenticoPageLink',
)

/** Data type. */
export interface KenticoPageLinkData
  extends IO.TypeOf<typeof KenticoPageLinkData> {}

/** Fragment helper. */
export const fragment = graphql`
  fragment KenticoPageLink on Node {
    ... on kontent_item_page {
      elements {
        url {
          value
        }
        linkText: metadata__link_text {
          value
        }
      }
      system {
        id
        type
      }
    }
    ... on kontent_item_city_page {
      elements {
        url {
          value
        }
        linkText: metadata__link_text {
          value
        }
      }
      system {
        id
        type
      }
    }
    ... on kontent_item_page_collection {
      elements {
        url {
          value
        }
        linkText: metadata__link_text {
          value
        }
      }
      system {
        id
        type
      }
    }
    ... on kontent_item_page_collection_item {
      elements {
        parent {
          nodes: value {
            ... on kontent_item_page_collection {
              elements {
                url {
                  value
                }
              }
            }
          }
        }
        url {
          value
        }
        linkText: metadata__link_text {
          value
        }
      }
      system {
        id
        type
      }
    }
    ... on kontent_item_store_page {
      elements {
        url {
          value
        }
        linkText: metadata__link_text {
          value
        }
      }
      system {
        id
        type
      }
    }
    ... on kontent_item_sitemap {
      elements {
        url {
          value
        }
        linkText: metadata__link_text {
          value
        }
      }
      system {
        id
        type
      }
    }
    ... on kontent_item_external_link {
      elements {
        linkText: link_text {
          value
        }
        url {
          value
        }
      }
      system {
        id
        type
      }
    }
    ... on kontent_item_form_page {
      elements {
        url {
          value
        }
        linkText: metadata__link_text {
          value
        }
      }
      system {
        id
        type
      }
    }
  }
`
