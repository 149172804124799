import {graphql} from 'gatsby'
import * as IO from 'io-ts'
import {KenticoContentData} from '../content'

/** Kentico Schema Loan Or Credit. */
export const KenticoSchemaLoanOrCreditData = IO.intersection(
  [
    KenticoContentData,
    IO.type({
      elements: IO.type({
        amount: IO.type({
          nodes: IO.array(
            IO.type({
              elements: IO.type({
                currency: IO.type({
                  value: IO.string,
                }),
                maxValue: IO.type({
                  value: IO.string,
                }),
                minValue: IO.type({
                  value: IO.string,
                }),
                name: IO.type({
                  value: IO.string,
                }),
                type: IO.type({
                  value: IO.string,
                }),
              }),
            }),
          ),
        }),
        areaServed: IO.type({
          nodes: IO.array(
            IO.type({
              elements: IO.type({
                id: IO.type({
                  value: IO.string,
                }),
                name: IO.type({
                  value: IO.string,
                }),
                type: IO.type({
                  value: IO.string,
                }),
              }),
            }),
          ),
        }),
        context: IO.type({
          value: IO.string,
        }),
        description: IO.type({
          value: IO.string,
        }),
        id: IO.type({
          value: IO.string,
        }),
        image: IO.type({
          value: IO.array(
            IO.type({
              file: IO.type({
                url: IO.string,
              }),
            }),
          ),
        }),
        logo: IO.type({
          value: IO.array(
            IO.type({
              file: IO.type({
                url: IO.string,
              }),
            }),
          ),
        }),
        name: IO.type({
          value: IO.string,
        }),
        offeredBy: IO.type({
          value: IO.string,
        }),
        offersType: IO.type({
          value: IO.string,
        }),
        type: IO.type({
          value: IO.string,
        }),
      }),
      system: IO.type({
        type: IO.literal('schema_loan_or_credit'),
      }),
    }),
  ],
  'KenticoSchemaLoanOrCredit',
)

/** Data Type. */
export interface KenticoSchemaLoanOrCreditData
  extends IO.TypeOf<typeof KenticoSchemaLoanOrCreditData> {}

/** Fragment Helper. */
export const fragment = graphql`
  fragment KenticoSchemaLoanOrCredit on Node {
    ... on kontent_item_schema_loan_or_credit {
      elements {
        amount {
          nodes: value {
            ... on kontent_item_schema_amount {
              elements {
                currency {
                  value
                }
                maxValue: max_value {
                  value
                }
                minValue: min_value {
                  value
                }
                name {
                  value
                }
                type {
                  value
                }
              }
            }
          }
        }
        areaServed: area_served {
          nodes: value {
            ... on kontent_item_schema_area_served {
              elements {
                id {
                  value
                }
                name {
                  value
                }
                type {
                  value
                }
              }
            }
          }
        }
        context {
          value
        }
        description {
          value
        }
        id {
          value
        }
        image {
          value {
            file {
              url: publicURL
            }
          }
        }
        logo {
          value {
            file {
              url: publicURL
            }
          }
        }
        name {
          value
        }
        offeredBy: offered_by {
          value
        }
        offersType: offers_type {
          value
        }
        type {
          value
        }
      }
      system {
        id
        codename
        name
        type
      }
    }
  }
`
