import {UUID} from 'io-ts-types/lib/UUID'
import React from 'react'
import styled from 'styled-components'
import {
  Col as BootstrapCol,
  Container as BootstrapContainer,
  Row,
} from '~/bootstrap/components/layout'
import {Customize} from '~/common/components/customize'
import {Link as CommonLink} from '~/common/components/link'
import {KenticoPageLinkData} from '../page-link'
import {KenticoPreviewBodyLink} from '../preview-link'
import {KenticoRichText} from '../rich-text'
import {createUrl, getLinkText, linkedItems} from '../../util'
import {KenticoLinkListData} from './data'

interface Props {
  data: KenticoLinkListData
}

/** Kentico type data. */
export {KenticoLinkListData}

const Container = styled(BootstrapContainer)`
  padding: ${({theme}) => theme.spacers[5]} 0;
`

const CenteredRow = styled(Row)`
  justify-content: center;
`

const Title = styled.div`
  padding-bottom: ${({theme}) => theme.spacers[5]};
`

const Col = styled(BootstrapCol)`
  @media (min-width: ${({theme}) => theme.breakpoints.min.md}) {
    &&:not(:last-child) {
      border-right: 1px solid transparent;
    }
  }
`

const Link = styled(CommonLink)`
  padding-bottom: ${({theme}) => theme.spacers[3]};
  text-decoration: underline;
`

/**
 * Render link list from Kentico data.
 * @return React component
 */
export const KenticoLinkList = ({
  data: {
    elements: {backgroundColor, links, textColor, title},
    system: {id, name},
  },
}: Props) => {
  const backgroundColorProvided = backgroundColor.value[0].codename
  const textColorProvided = textColor.value[0].codename

  // Render preview link
  let editLink
  if (!UUID.is(name)) {
    editLink = <KenticoPreviewBodyLink contentItem={id} />
  }

  const filledCols = Array.from({
    length: Math.ceil(linkedItems(links).length / 2),
  })
    .reduce(
      (arr: KenticoPageLinkData[][], _, index) => [
        ...arr,
        linkedItems(links).slice(index * 2, (index + 1) * 2),
      ],
      [] as KenticoPageLinkData[][],
    )
    .map((colLinks, i) => {
      const firstLink = (
        <Customize
          component={Link}
          textAlign="center"
          textColor={textColorProvided}
          to={createUrl(colLinks[0])}
        >
          {getLinkText([colLinks[0]])}
        </Customize>
      )
      let secondLink
      if (colLinks.length === 2) {
        secondLink = (
          <Customize
            component={Link}
            textAlign="center"
            textColor={textColorProvided}
            to={createUrl(colLinks[1])}
          >
            {getLinkText([colLinks[1]])}
          </Customize>
        )
      }
      return (
        <Customize
          // No better key
          // eslint-disable-next-line react/no-array-index-key
          key={i}
          borderColor={textColorProvided}
          component={Col}
          md="3"
          textAlign="center"
        >
          <CenteredRow>{firstLink}</CenteredRow>
          <CenteredRow>{secondLink}</CenteredRow>
        </Customize>
      )
    })

  return (
    <Customize
      background={backgroundColorProvided}
      textColor={textColorProvided}
    >
      <Container>
        {editLink}
        <Customize component={Title} textAlign="center">
          <KenticoRichText data={title} />
        </Customize>
        <CenteredRow>{filledCols}</CenteredRow>
      </Container>
    </Customize>
  )
}
