import React, {ReactNode, useMemo} from 'react'
import {CookiesProvider} from 'react-cookie'
import {ThemeProvider} from 'styled-components'
import {CalculatorsProvider} from '~/calculators/components/provider'
import {Language} from '~/common/context'
import {CommonProvider} from '~/common/context/gatsby'
import {theme} from '~/common/theme'
import {ContactProvider} from '~/contact/components/provider'
import {KenticoContext} from '~/kentico/context'
import {WebFormProvider} from '~/web-form/components/provider'
import {ReferralProvider} from '~/referral/components/provider'
import {ReviewsProvider} from '~/reviews/components/provider'
import {StoresProvider} from '~/stores/components/provider'
import {VehiclesProvider} from '~/vehicles/components/provider'

interface Props {
  children: ReactNode
  contentId?: string
  language?: Language
}

/**
 * Render Gatsby page layout.
 * @return React component
 */
export const GatsbyLayout = ({children, contentId, language}: Props) => {
  const kenticoValue = useMemo<KenticoContext>(
    () => ({
      contentId,
    }),
    [contentId],
  )

  return (
    <ThemeProvider theme={theme}>
      <CookiesProvider>
        <CommonProvider language={language}>
          <StoresProvider>
            <ReviewsProvider>
              <ContactProvider>
                <ReferralProvider>
                  <CalculatorsProvider>
                    <WebFormProvider>
                      <VehiclesProvider>
                        <KenticoContext.Provider value={kenticoValue}>
                          {children}
                        </KenticoContext.Provider>
                      </VehiclesProvider>
                    </WebFormProvider>
                  </CalculatorsProvider>
                </ReferralProvider>
              </ContactProvider>
            </ReviewsProvider>
          </StoresProvider>
        </CommonProvider>
      </CookiesProvider>
    </ThemeProvider>
  )
}
