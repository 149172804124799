import {noop} from 'lodash'
import {createContext} from 'react'
import {Store} from '../types'

/** Stores search data. */
export type SearchData = LatLng | State

/** LatLng data. */
export interface LatLng {
  lat: number
  lng: number
}

/** State data. */
export interface State {
  state: string
}

/** Stores search API. */
export interface StoresSearch {
  error?: Error
  ready: boolean
  stores?: Store[]
  /** Run store search using store data. */
  run(data: SearchData): void
}

/** Stores location API. */
export type StoresLocation = StoresLocationReady | StoresLocationNotReady

interface StoresLocationNotReady {
  ready: false
  position?: undefined
}

interface StoresLocationReady {
  ready: true
  city: string
  position: {
    lat: number
    lng: number
  }
  state: string
  stores: Store[]
}

/** Stores information. */
export interface StoresContext {
  search: StoresSearch
  location: StoresLocation
  stores?: Store[]
  store?: Store
}

/** Default context. */
export const DEFAULT_CONTEXT: StoresContext = {
  location: {
    ready: false,
  },
  search: {
    ready: false,
    run: noop,
  },
}

/** Context implementation. */
export const StoresContext = createContext(DEFAULT_CONTEXT)

// eslint-disable-next-line functional/immutable-data
StoresContext.displayName = 'Stores'
