import fingerprint2 from 'fingerprintjs2'

/**
 * Get fingerprint hash.
 * @return Fingerprint string hash
 */
export const makeFingerprint = async () => {
  const component = await fingerprint2.getPromise()
  const value = component.map(c => c.value).join('')
  return fingerprint2.x64hash128(value, 31)
}
