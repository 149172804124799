import {format} from 'date-fns'
import React, {useMemo} from 'react'
import {Helmet} from 'react-helmet'
import {useQuery} from '~/gatsby/util'

/** Kentico Schema Blog Data. */
export interface KenticoSchemaBlogData {
  authorName: string
  dateModified: Date
  datePublished: Date
  description: string
  headline: string
  imageUrl: string
  logoUrl: string
  system: {
    id: string
  }
  url: string
}

interface Props {
  data: KenticoSchemaBlogData
}

/**
 * Render Kentico Schema Blog script.
 * @return React component
 */
export const KenticoSchemaBlog = ({data}: Props) => {
  const {siteUrl} = useQuery()
  const schema = useMemo(() => {
    const imageUrl = new URL(data.imageUrl, siteUrl)
    const datePublished = `${format(data.datePublished, 'yyyy-MM-dd')}`
    const dateModified = `${format(data.dateModified, 'yyyy-MM-dd')}`
    const blogUrl = `${siteUrl}/blog/`
    const mainEntityId = String(new URL(data.url, blogUrl))

    return {
      '@context': 'https://schema.org',
      '@type': 'BlogPosting',
      author: {
        '@type': 'Person',
        name: data.authorName,
      },
      dateModified,
      datePublished,
      description: data.description,
      headline: data.headline,
      image: imageUrl,
      mainEntityOfPage: {
        '@id': mainEntityId,
        '@type': 'WebPage',
      },
      publisher: {
        '@type': 'Organization',
        logo: {
          '@type': 'ImageObject',
          url: data.logoUrl,
        },
        name: 'Cash Store',
      },
    }
  }, [
    data.authorName,
    data.dateModified,
    data.datePublished,
    data.description,
    data.headline,
    data.imageUrl,
    data.logoUrl,
    data.url,
    siteUrl,
  ])
  return (
    <Helmet>
      <script type="application/ld+json">{JSON.stringify(schema)}</script>
    </Helmet>
  )
}
