import {graphql} from 'gatsby'
import * as IO from 'io-ts'
import {Align, Color} from '~/common/types'
import {LinkedItems} from '../../util'
import {KenticoContentData} from '../content'
import {KenticoFluidImageData} from '../fluid-image'
import {KenticoLinkData} from '../link'
import {KenticoRichTextData} from '../rich-text'
import {KenticoFixedImageData} from '../fixed-image'

/** Hero banner. */
export const KenticoHeroBannerData = IO.intersection(
  [
    KenticoContentData,
    IO.type({
      elements: IO.type({
        body: KenticoRichTextData,
        buttonColor: IO.type({
          value: IO.array(
            IO.type({
              codename: Color,
            }),
          ),
        }),
        buttonText: IO.type({
          value: IO.string,
        }),
        image: IO.type({
          value: IO.array(KenticoFluidImageData),
        }),
        link: LinkedItems(KenticoLinkData),
        textColor: IO.type({
          value: IO.array(
            IO.type({
              codename: Color,
            }),
          ),
        }),
        textJustify: IO.type({
          value: IO.array(
            IO.type({
              codename: Align,
            }),
          ),
        }),
        columns: IO.type({
          value: IO.array(
            IO.type({
              elements: IO.type({
                image: IO.type({
                  value: IO.array(KenticoFixedImageData),
                }),
                text: IO.type({
                  value: IO.string,
                }),
              }),
              system: IO.type({
                codename: IO.string,
              }),
            }),
          ),
        }),
      }),
      system: IO.type({
        type: IO.literal('hero_banner'),
      }),
    }),
  ],
  'KenticoHeroBanner',
)

/** Hero Banner data type. */
export interface KenticoHeroBannerData
  extends IO.TypeOf<typeof KenticoHeroBannerData> {}

/** Fragment helper. */
export const fragment = graphql`
  fragment KenticoHeroBanner on Node {
    ... on kontent_item_hero_banner {
      elements {
        buttonColor: button_color {
          value {
            codename
          }
        }
        buttonText: button_text {
          value
        }
        image: background_image {
          value {
            ...KenticoFluidImage
          }
        }
        link: button_link {
          nodes: value {
            ...KenticoLink
          }
        }
        body {
          ...KenticoRichText
        }
        textColor: text_color {
          value {
            codename
          }
        }
        textJustify: text_justification {
          value {
            codename
          }
        }
        columns: untitled_linked_items {
          value {
            ... on kontent_item_list_with_images_item {
              elements {
                image {
                  value {
                    file {
                      childImageSharp {
                        gatsbyImageData
                      }
                    }
                  }
                }
                text {
                  value
                }
              }
              system {
                codename
              }
            }
          }
        }
      }
      system {
        id
        codename
        name
        type
      }
    }
  }
`
