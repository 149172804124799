import * as IO from 'io-ts'

/** Kentico content base. */
export const KenticoContentData = IO.type(
  {
    system: IO.type({
      // TODO perhaps check if in development to grab extra data so it is not
      // present in production builds. this means making these types optional.
      codename: IO.string,
      id: IO.string,
      name: IO.string,
      type: IO.string,
    }),
  },
  'KenticoContent',
)

/** Data type. */
export interface KenticoContentData
  extends IO.TypeOf<typeof KenticoContentData> {}
