import { ImageElement } from '@kentico/gatsby-kontent-components'
import {DomElement} from 'html-react-parser'
import * as IO from 'io-ts'
import {toString} from 'lodash'
import React from 'react'
import styled from 'styled-components'
import {Noop} from '~/common/components/noop'
import {KenticoFluidImageData, KenticoFluidImageFileData} from './data'

interface BaseProps {
  className?: string
}

interface LinkedItemProps {
  image: KenticoFluidImageData
  alt?: string
  id?: undefined
  images?: undefined
  scrub?: undefined
}

interface RichTextItemProps {
  id: string
  images: KenticoFluidImageFileData[]
  scrub: boolean
  element: DomElement
  image?: undefined
  alt?: undefined
}

type Props = BaseProps & (LinkedItemProps | RichTextItemProps)

const ElementWithAlt = IO.type({
  children: IO.tuple([
    IO.type({
      attribs: IO.type({
        alt: IO.string,
      }),
    }),
  ]),
})

const Image = styled.img`
  width: 100%;
`

/** Kentico type data. */
export {KenticoFluidImageData, KenticoFluidImageFileData}

/**
 * Render fluid image from Kentico data.
 * @param props Component properties
 * @return React component
 */
export const KenticoFluidImage = (props: Props) => {
  let image: KenticoFluidImageFileData | undefined
  let alt = ''
  if (props.image) {
    image = props.image as any
    alt = toString(props.alt ?? props.image.description)
  } else {
    //look by ID that is on the file. If this bombs out can make it optional.
    image = props.images.find(img => img.file.id === props.id)
    if (!image) {
      if (!props.scrub) {
        throw new Error('Unknown image')
      }
      return <Noop />
    }

    // Grab optional alt text for images
    if (ElementWithAlt.is(props.element)) {
      const altData = props.element.children[0].attribs.alt
      alt = altData
    }
  }
  if (image?.file?.childImageSharp === undefined && image?.url != "") {
    return (
      <Image
        alt={alt}
        className={props.className}
        src={encodeURI(image?.url ?? '')}
      />
    )
  }

  const imageData = {
    url: image.url ?? image.file.childImageSharp.gatsbyImageData.images.fallback.src,
    width: image.file.childImageSharp.gatsbyImageData.width,
    height: image.file.childImageSharp.gatsbyImageData.height
  }
  return (
    <ImageElement
      alt={alt}
      className={props.className}
      image={imageData}
      maxWidth={image.file.childImageSharp.gatsbyImageData.width}
    />
  )
}
