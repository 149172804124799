import {constant} from 'lodash'
import {createContext} from 'react'
import {InstallmentCalculatorAPI} from './installment'
import {TitleCalculatorAPI} from './title'

/** Expose data structure. */
export {InstallmentCalculatorData} from './installment'

/** Calculators information. */
export interface CalculatorsContext {
  installment: InstallmentCalculatorAPI
  title: TitleCalculatorAPI
}

/** Default context. */
export const DEFAULT_CONTEXT: CalculatorsContext = {
  installment: {
    ready: false,
    submit: constant(Promise.resolve(undefined)),
  },
  title: {
    ready: false,
    submit: constant(Promise.resolve(undefined)),
  },
}

/** Context implementation. */
export const CalculatorsContext = createContext(DEFAULT_CONTEXT)

// eslint-disable-next-line functional/immutable-data
CalculatorsContext.displayName = 'Calculators'
