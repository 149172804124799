import React, {ReactNode} from 'react'
import {FaEye} from 'react-icons/fa'
import styled from 'styled-components'
import {InputGroup as InputGroupBase, InputGroupAddon} from 'reactstrap'
import {Button} from '../button'

// Disable as the helper functions below are not actually components
/* eslint-disable react/no-multi-comp */

interface Props {
  hidden: boolean
  children: ReactNode
  errorMessage: ReactNode
  onClick(): void
}

interface InputGroupProps {
  children?: ReactNode[]
  hidden: boolean
}

const InputGroup = ({hidden: _, ...props}: InputGroupProps) => (
  <InputGroupBase {...props} />
)

const StyledInputGroup = styled(InputGroup)`
  input {
    ${({hidden}) => (hidden ? 'font-family: Secure Text;' : '')}
  }
`

/**
 * Render social input.
 * @return React component
 */
export const FormSocialInput = ({
  children,
  errorMessage,
  hidden,
  onClick,
}: Props) => (
  <StyledInputGroup hidden={hidden}>
    {children}
    <InputGroupAddon addonType="append">
      <Button block aria-label="Show/Hide SSN" color="input" onClick={onClick}>
        <FaEye />
      </Button>
    </InputGroupAddon>
    {errorMessage}
  </StyledInputGroup>
)
