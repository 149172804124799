import {DomElement, domToReact} from 'html-react-parser'
import React from 'react'
import {Noop} from '~/common/components/noop'
import {KenticoFileLinkData} from './data'

/** Kentico type data. */
export {KenticoFileLinkData}

interface Props {
  files: KenticoFileLinkData[]
  scrub: boolean
  id: string
  element: DomElement
}

/**
 * Render file link from Kentico data.
 * @return React component
 */
export const KenticoFileLink = ({
  id,
  files,
  scrub,
  element: {children = []},
}: Props) => {
  const file = files.find(f => f.id === id)
  if (!file) {
    if (!scrub) {
      throw new Error('Unknown file')
    }
    return <Noop />
  }
  return (
    <a href={encodeURI(file.url)} rel="noopener noreferrer" target="_blank">
      {domToReact(children)}
    </a>
  )
}
