import {graphql} from 'gatsby'
import * as IO from 'io-ts'
import {Align} from '~/common/types'
import {LinkedItems} from '../../util'
import {KenticoCardData} from '../card'
import {KenticoContentData} from '../content'
import {KenticoFluidImageData} from '../fluid-image'
import {KenticoRichTextData} from '../rich-text'

/** Cards row. */
export const KenticoCardsData = IO.intersection(
  [
    KenticoContentData,
    IO.type({
      elements: IO.type({
        background: IO.type({
          value: IO.array(KenticoFluidImageData),
        }),
        cards: LinkedItems(KenticoCardData),
        formatting: IO.type({
          value: IO.array(
            IO.type({
              codename: Align,
            }),
          ),
        }),
        title: KenticoRichTextData,
      }),
      system: IO.type({
        type: IO.literal('cards'),
      }),
    }),
  ],
  'KenticoCards',
)

/** Data type. */
export interface KenticoCardsData extends IO.TypeOf<typeof KenticoCardsData> {}

/** Fragment helper. */
export const fragment = graphql`
  fragment KenticoCards on Node {
    ... on kontent_item_cards {
      system {
        codename
        id
        name
        type
      }
      elements {
        background: background_image {
          value {
            ...KenticoFluidImage
          }
        }
        cards: body {
          nodes: value {
            ...KenticoCard
          }
        }
        formatting: formatting_options__formatting {
          value {
            codename
          }
        }
        title {
          ...KenticoRichText
        }
      }
    }
  }
`
