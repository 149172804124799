import React from 'react'
import {ImageElement} from '@kentico/gatsby-kontent-components'
import {KenticoFixedImageData} from './data'

interface Props {
  image: KenticoFixedImageData
  className?: string
  alt?: string
}

/** Kentico type data. */
export {KenticoFixedImageData}

/**
 * Render fixed image from Kentico data.
 * @return React component
 */
export const KenticoFixedImage = ({className, image, alt}: Props) => {
  // (image as any).images = {};
  // console.log(`className = ${className}`)
  // console.log(`image = ${image}`)
  // console.log(`image = ${JSON.stringify(image)}`)
  // console.log(`alt = ${alt}`)
  let myImage = {
    url: image.url ?? image.file.childImageSharp.gatsbyImageData.images.fallback.src,
    width: image.file.childImageSharp.gatsbyImageData.width,
    height: image.file.childImageSharp.gatsbyImageData.height,
  }
  return (
    <ImageElement alt={alt} className={className} image={myImage} />
  )
}
