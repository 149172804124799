import * as IO from 'io-ts'
import ky, {Options} from 'ky'
import {useCallback} from 'react'
import {decode} from '../util'

interface APIOptionsBase<T> {
  type?: IO.Decoder<unknown, T>
  url: string
}

interface NonGetMethod {
  method: 'POST'
  data?: Options['json']
}

interface GetMethod {
  method: 'GET'
  data?: Options['searchParams']
}

/** API options. */
export type APIOptions<T> = APIOptionsBase<T> & (NonGetMethod | GetMethod)

/** API call. */
export type APICall = <T = unknown>(options: APIOptions<T>) => Promise<T>

/**
 * Construct API instance.
 * @param prefixUrl Base URL for requests
 * @return API caller
 */
export const useAPI = (prefixUrl?: string): APICall =>
  useCallback(
    async <T>(options: APIOptions<T>): Promise<T> => {
      try {
        const response = await ky(options.url, {
          json: options.method === 'GET' ? undefined : options.data,
          method: options.method,
          prefixUrl,
          searchParams: options.method === 'GET' ? options.data : undefined,
          timeout: 45000,
          retry: {
            limit: process.env.NUMBER_OF_API_CALL_RETRIES === undefined
              ? 1 : parseInt(process.env.NUMBER_OF_API_CALL_RETRIES),
            methods: ['post'],
            statusCodes: [408, 413, 500, 502, 503, 504]
          }
        }).json()
        if (!options.type) {
          return response as T
        }
        return decode(options.type, response)
      } catch (e) {
        if (process.env.LOG_API_ERRORS === 'true') {
          console.error(e)
        }
        throw e
      }
    },
    [prefixUrl],
  )
