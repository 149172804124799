import {UUID} from 'io-ts-types/lib/UUID'
import React from 'react'
import styled from 'styled-components'
import {Container as BootstrapContainer} from '~/bootstrap/components/layout'
import {linkedItems, validImage} from '../../util'
import {KenticoFixedImage} from '../fixed-image'
import {KenticoPreviewBodyLink} from '../preview-link'
import {KenticoListWithImagesData} from './data'

interface Props {
  data: KenticoListWithImagesData
}

/** Kentico type data. */
export {KenticoListWithImagesData}

const Container = styled(BootstrapContainer)`
  padding-top: ${({theme}) => theme.spacers[4]};
  padding-bottom: ${({theme}) => theme.spacers[4]};
`

const Item = styled.div`
  display: flex;
  padding-bottom: ${({theme}) => theme.spacers[4]};

  @media (max-width: ${({theme}) => theme.breakpoints.max.md}) {
    flex-direction: column;
  }
`

const Title = styled.h3`
  padding-bottom: ${({theme}) => theme.spacers[4]};

  @media (max-width: ${({theme}) => theme.breakpoints.max.sm}) {
    text-align: center;
  }
`

const ImageContainer = styled.div`
  text-align: center;
  padding-right: ${({theme}) => theme.spacers[4]};
  padding-bottom: ${({theme}) => theme.spacers[4]};
`

const ItemText = styled.div`
  flex: 1;
`

/**
 * Render list with images from Kentico data.
 * @return React component
 */
export const KenticoListWithImages = ({
  data: {
    elements: {items, title},
    system: {id, name},
  },
}: Props) => {
  const listItems = linkedItems(items).map(item => {
    let imageElement
    if (validImage(item.elements.image)) {
      imageElement = <KenticoFixedImage image={item.elements.image.value[0]} />
    }
    return (
      <Item key={item.system.codename}>
        <ImageContainer>{imageElement}</ImageContainer>
        <ItemText>{item.elements.text.value}</ItemText>
      </Item>
    )
  })
  let editLink
  if (!UUID.is(name)) {
    editLink = <KenticoPreviewBodyLink contentItem={id} />
  }
  return (
    <Container>
      {editLink}
      <Title>{title.value}</Title>
      {listItems}
    </Container>
  )
}
