/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {globalHistory} from '@reach/router'
import {Link} from 'gatsby'
import idle from 'idle-promise'
import {noop} from 'lodash'
import React, {ReactNode, useMemo} from 'react'
import {useCookies} from 'react-cookie'
import {ThemeProvider} from 'styled-components'
import {theme} from '../theme'
import {useEffectAsync} from '../util'
import {useAPI} from './api'
import {makeFingerprint} from './fingerprint'
import {useStorage} from './storage'
import {CommonContext, DEFAULT_LANGUAGE, Language} from '.'

interface Props {
  language?: Language
  children: ReactNode
}

interface WebVisitorResponse{
  statusCode:string,
  reason?:string,
  clientVisitorSessionTrackingId:string
}

// Check for storage existance due to SSR
const localStorage =
  typeof window === 'object' && navigator.cookieEnabled
    ? window.localStorage
    : undefined!
const sessionStorage =
  typeof window === 'object' && navigator.cookieEnabled
    ? window.sessionStorage
    : undefined!

/**
 * Inject Gatsby specific information into common context.
 * @return React component
 */
export const CommonProvider = ({
  children,
  language = DEFAULT_LANGUAGE,
}: Props) => {
  const api = useAPI(process.env.CASH_STORE_API)
  const storage = useStorage(localStorage, 'cs')
  const session = useStorage(sessionStorage, 'cs')
  const [cookies, setCookie] = useCookies(['visitor', 'pixelSessionId'])

  useEffectAsync(async () => {
    if (cookies.visitor !== undefined) {
      if (!cookies.pixelSessionId) {
        try {
          let sessionId: string = await api({
          method: 'GET',
          url: 'v1/visitors/web-sessionid',
          })
          setCookie('pixelSessionId', sessionId)
        } catch (error) {
          console.log(error)
        }
      }

      return
    }
    // Establish web visitor and save response data
    const now = new Date()
    let response: WebVisitorResponse
    try {
      response = await api({
        data: {
          clientUTCOffset: now.getTimezoneOffset() / -60,
          clientUTCTime: now.toISOString(),
          entryPageRawUrl: globalHistory.location.href,
          referringRawURL: document.referrer,
        },
        method: 'POST',
        url: 'v1/visitors/web-visitor',
      })
      setCookie('visitor', true)
      setCookie('pixelSessionId', response.clientVisitorSessionTrackingId)
    } catch (error) {
      console.log(error)
    }

    // Construct browser fingerprint and send
    await idle()
    const fingerprint = await makeFingerprint()
    await api({
      data: {
        browserFingerprintHash: fingerprint,
      },
      method: 'POST',
      url: 'v1/visitors/web-browser-fingerprint',
    })
  }, [])

  const commonValue = useMemo<CommonContext>(
    () => ({
      api,
      history: globalHistory,
      language,
      link: Link,
      platform: 'web',
      session,
      setLanguage: noop,
      storage,
    }),
    [api, language, session, storage],
  )

  return (
    <ThemeProvider theme={theme}>
      <CommonContext.Provider value={commonValue}>
        {children}
      </CommonContext.Provider>
    </ThemeProvider>
  )
}
