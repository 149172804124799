import {graphql} from 'gatsby'
import * as IO from 'io-ts'
import {LinkedItems} from '../../util'
import {KenticoContentData} from '../content'
import {KenticoRichTextData} from '../rich-text'

/** Bulleted List Data. */
export const KenticoBulletedListData = IO.intersection(
  [
    KenticoContentData,
    IO.type({
      elements: IO.type({
        header: KenticoRichTextData,
        listBody: LinkedItems(
          IO.type({
            elements: IO.type({
              body: KenticoRichTextData,
            }),
          }),
        ),
      }),
      system: IO.type({
        type: IO.literal('bulleted_list'),
      }),
    }),
  ],
  'KenticoBulletedList',
)

/** Data Type. */
export interface KenticoBulletedListData
  extends IO.TypeOf<typeof KenticoBulletedListData> {}

/** Fragment Helper. */
export const fragment = graphql`
  fragment KenticoBulletedList on Node {
    ... on kontent_item_bulleted_list {
      elements {
        listBody: body {
          nodes: value {
            ... on kontent_item_bulleted_list_item {
              elements {
                body {
                  ...KenticoRichText
                }
              }
            }
          }
        }
        header {
          ...KenticoRichText
        }
      }
      system {
        id
        codename
        name
        type
      }
    }
  }
`
