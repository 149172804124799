import {UUID} from 'io-ts-types/lib/UUID'
import {darken} from 'polished'
import React from 'react'
import styled from 'styled-components'
import {Container as BootstrapContainer} from '~/bootstrap/components/layout'
import {Table} from '~/bootstrap/components/table'
import {linkedItems} from '../../util'
import {KenticoPreviewBodyLink} from '../preview-link'
import {KenticoTableRow} from '../table-row'
import {KenticoTableData} from './data'

interface Props {
  data: KenticoTableData
}

/** Kentico type data. */
export {KenticoTableData}

const Container = styled(BootstrapContainer)`
  padding-top: ${({theme}) => theme.spacers[4]};
  padding-bottom: ${({theme}) => theme.spacers[4]};
`

const StyledTableHead = styled.thead`
  background-color: ${({theme}) => theme.colors.tertiary};
  color: ${({theme}) => theme.colors.light};
  text-align: center;
`

const StyledTableBody = styled.tbody`
  text-align: center;
  border: 1px solid ${({theme}) => darken(0.1, theme.colors.light)};
`

/**
 * Render table from Kentico data.
 * @return React component
 */
export const KenticoTable = ({
  data: {
    elements: {rows, heading1, heading2},
    system: {id, name},
  },
}: Props) => {
  const tableRows = linkedItems(rows).map(row => (
    <tr key={row.system.id}>
      <KenticoTableRow data={row} />
    </tr>
  ))

  let editLink
  if (!UUID.is(name)) {
    editLink = <KenticoPreviewBodyLink contentItem={id} />
  }

  return (
    <Container>
      {editLink}
      <Table striped>
        <StyledTableHead>
          <tr>
            <th>
              <h4>{heading1.value}</h4>
            </th>
            <th>
              <h4>{heading2.value}</h4>
            </th>
            <th />
          </tr>
        </StyledTableHead>
        <StyledTableBody>{tableRows}</StyledTableBody>
      </Table>
    </Container>
  )
}
