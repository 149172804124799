import {UUID} from 'io-ts-types/lib/UUID'
import React, {useContext} from 'react'
import styled from 'styled-components'
import {Container as BootstrapContainer} from '~/bootstrap/components/layout'
import {
  ListGroup,
  ListGroupItem as BootstrapListGroupItem,
} from '~/bootstrap/components/list-group'
import {Link} from '~/common/components/link'
import {KenticoContext} from '../../context'
import {createUrl, getLinkText, linkedItems} from '../../util'
import {KenticoPreviewBodyLink} from '../preview-link'
import {KenticoRichText} from '../rich-text'
import {KenticoPageMenuSwitcherData} from './data'

interface Props {
  data: KenticoPageMenuSwitcherData
}

/** Kentico type data. */
export {KenticoPageMenuSwitcherData}

const Container = styled(BootstrapContainer)`
  padding: ${({theme}) => theme.spacers[4]} 0;
`

const MenuHeader = styled.div`
  padding: ${({theme}) => theme.spacers[1]} 0;
`

const ListGroupItem = styled(BootstrapListGroupItem)`
  &&.active {
    border-left: ${({theme}) => theme.spacers[2]} solid
      ${({theme}) => theme.colors.secondary};
  }
`

/**
 * Render Page Menu Switcher from Kentico data.
 * @return React component
 */
export const KenticoPageMenuSwitcher = ({
  data: {
    elements: {header, links},
    system: {id, name},
  },
}: Props) => {
  const {contentId} = useContext(KenticoContext)

  // Render preview link
  let editLink
  if (!UUID.is(name)) {
    editLink = <KenticoPreviewBodyLink contentItem={id} />
  }

  const listElements = linkedItems(links).map(link => (
    <ListGroupItem
      key={link.system.id}
      active={link.system.id === contentId}
      tag={Link}
      to={createUrl(link)}
    >
      {getLinkText(link)}
    </ListGroupItem>
  ))

  return (
    <Container>
      {editLink}
      <MenuHeader>
        <KenticoRichText data={header} />
      </MenuHeader>
      <ListGroup>{listElements}</ListGroup>
    </Container>
  )
}
