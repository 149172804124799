import {UUID} from 'io-ts-types/lib/UUID'
import React, {useCallback, useContext, useState} from 'react'
import {FormContext, useForm} from 'react-hook-form'
import styled from 'styled-components'
import {PATTERNS} from '~/common/util'
import {Button} from '~/bootstrap/components/button'
import {Form, FormGroup, FormItem, FormText} from '~/bootstrap/components/form'
import {
  Col,
  Container as BootstrapContainer,
  Row,
} from '~/bootstrap/components/layout'
import {Spinner} from '~/common/components/spinner'
import {ReferralContext} from '~/referral/context'
import {ReferralForm} from '~/referral/types'
import {KenticoPreviewBodyLink} from '../preview-link'
import {KenticoRichText} from '../rich-text'
import {KenticoReferralFormData} from './data'
import {createOnSubmit} from './form'

interface Props {
  data: KenticoReferralFormData
  /** Reserved for testing. This should not be used normally. */
  success?: boolean
}

/** Kentico type data. */
export {KenticoReferralFormData}

const MAX_LENGTH_MESSAGE = "You've exceeded the max length"

const errorValue = (errorMessage: string, errorNumber: number) => ({
  message: errorMessage,
  value: errorNumber,
})

const Container = styled(BootstrapContainer)`
  padding: ${({theme}) => theme.spacers[4]};
`

const ErrorMessage = styled.span`
  color: ${({theme}) => theme.colors.red};
`

const SuccessMessage = styled.span`
  color: ${({theme}) => theme.colors.tertiary};
`

/**
 * Render referral form.
 * @return React component
 */
export const KenticoReferralForm = ({
  data: {
    elements: {header},
    system: {id, name},
  },
  success: defaultSuccess,
}: Props) => {
  const referral = useContext(ReferralContext)
  const form = useForm<ReferralForm>({mode: 'onChange'})
  const {
    handleSubmit,
    formState: {isValid},
  } = form
  const [success, setSuccess] = useState<boolean | undefined>(defaultSuccess)
  const onSubmit = useCallback(
    createOnSubmit({
      referral,
      setSuccess,
    }),
    [referral, setSuccess],
  )

  const [ref1Required, setRef1Required] = useState<string | undefined>(undefined)
  const [ref2Required, setRef2Required] = useState<string | undefined>(undefined)
  const [areOptionalFieldsValid, setAreOptionalFieldsValid] = useState<boolean>(true)
  const [responseState, setResponseState] = useState<boolean | undefined>(undefined)

  function validateOptionalFields(formValues: any){
    const shouldTriggerValidation: boolean = (formValues["referrals[1].name"] !== '' &&
     formValues["referrals[1].email"] === '') || (formValues["referrals[2].name"] !== '' &&
     formValues["referrals[2].email"] === '')

    setAreOptionalFieldsValid(!shouldTriggerValidation)
  }

  function handleChange(){
    const e : Event | undefined = event
    const trgt: HTMLInputElement = e?.target as HTMLInputElement
    if(trgt.name === "referrals[1].name"){
      if(trgt.value){
        setRef1Required("Please enter an email")
      } else {
        setRef1Required(undefined)
      }
    }

    if(trgt.name === "referrals[2].name"){
      if(trgt.value){
        setRef2Required("Please enter an email")
      } else {
        setRef2Required(undefined)
      }
    }

    validateOptionalFields(form.getValues())
  }
  let response

  switch (responseState) {
    case true:
      response = (
        <SuccessMessage>
          Thank you for spreading the word! You will receive a $100 reward
          check in the mail once your referral comes in to a Cash Store
          location and takes out their first loan of $100 or more and is a new
          Cash Store customer.
        </SuccessMessage>
      )
      break
    case false:
      response = (
        <ErrorMessage>
          We are unable to submit your information at this time. Please try
          again later.
        </ErrorMessage>
      )
      break
    default:
      break
  }

  if(success !== undefined && referral.ready){
    setResponseState(success)
    setSuccess(undefined)
    form.reset()
  }

  let editLink
  if (!UUID.is(name)) {
    editLink = <KenticoPreviewBodyLink contentItem={id} />
  }
  return (
    <Container>
      {editLink}
      <KenticoRichText data={header} />
      <FormContext {...form}>
        <Form id="referralForm" onSubmit={handleSubmit(onSubmit)}>
          <FormGroup>
            <FormText color="secondary">*Required Fields</FormText>
          </FormGroup>
          <Row>
            <Col sm>
              <FormItem
                label="First Name"
                maxLength={errorValue(MAX_LENGTH_MESSAGE, 50)}
                name="name.first"
                required="Please enter a first name"
                type="text"
              />
            </Col>
            <Col sm>
              <FormItem
                label="Last Name"
                maxLength={errorValue(MAX_LENGTH_MESSAGE, 50)}
                name="name.last"
                required="Please enter a last name"
                type="text"
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <FormItem
                label="Email Address"
                maxLength={errorValue(MAX_LENGTH_MESSAGE, 254)}
                name="email"
                pattern={PATTERNS.EMAIL}
                required="Please enter an email"
                type="email"
              />
            </Col>
          </Row>
          <hr />
          <Row>
            <Col sm>
              <FormItem
                label="Referral Name 1"
                maxLength={errorValue(MAX_LENGTH_MESSAGE, 100)}
                name="referrals[0].name"
                required="Please enter a name"
                type="text"
              />
            </Col>
            <Col sm>
              <FormItem
                label="Referral Email 1"
                maxLength={errorValue(MAX_LENGTH_MESSAGE, 254)}
                name="referrals[0].email"
                pattern={PATTERNS.EMAIL}
                required="Please enter an email"
                type="email"
              />
            </Col>
          </Row>
          <Row>
            <Col sm>
              <FormItem
                label="Referral Name 2"
                maxLength={errorValue(MAX_LENGTH_MESSAGE, 100)}
                name="referrals[1].name"
                type="text"
                onChange={handleChange}
              />
            </Col>
            <Col sm>
              <FormItem
                label="Referral Email 2"
                maxLength={errorValue(MAX_LENGTH_MESSAGE, 254)}
                name="referrals[1].email"
                pattern={PATTERNS.EMAIL}
                required={ref1Required}
                type="email"
                onChange={handleChange}
              />
            </Col>
          </Row>
          <Row>
            <Col sm>
              <FormItem
                label="Referral Name 3"
                maxLength={errorValue(MAX_LENGTH_MESSAGE, 100)}
                name="referrals[2].name"
                type="text"
                onChange={handleChange}
              />
            </Col>
            <Col sm>
              <FormItem
                label="Referral Email 3"
                maxLength={errorValue(MAX_LENGTH_MESSAGE, 254)}
                name="referrals[2].email"
                pattern={PATTERNS.EMAIL}
                required={ref2Required}
                type="email"
                onChange={handleChange}
              />
            </Col>
          </Row>
          {response}
          <FormGroup>
            <Button color="tertiary" disabled={!referral.ready || !isValid || !areOptionalFieldsValid}>
              {referral.ready ? 'Submit Referrals' : <Spinner />}
            </Button>
          </FormGroup>
        </Form>
      </FormContext>
    </Container>
  )
}
