import {graphql} from 'gatsby'
import * as IO from 'io-ts'
import {Color} from '~/common/types'
import {LinkedItems} from '../../util'
import {KenticoContentData} from '../content'
import {KenticoPageLinkData} from '../page-link'

/** Store Locator. */
export const KenticoStoreLocatorData = IO.intersection(
  [
    KenticoContentData,
    IO.type({
      elements: IO.type({
        backgroundColor: IO.type({
          value: IO.array(
            IO.type({
              codename: Color,
            }),
          ),
        }),
        footerText: IO.type({
          value: IO.string,
        }),
        headerText: IO.type({
          value: IO.string,
        }),
        link: LinkedItems(KenticoPageLinkData),
        locations: LinkedItems(KenticoPageLinkData),
      }),
      system: IO.type({
        type: IO.literal('store_locator'),
      }),
    }),
  ],
  'KenticoStoreLocator',
)

/** Data Type. */
export interface KenticoStoreLocatorData
  extends IO.TypeOf<typeof KenticoStoreLocatorData> {}

/** Fragment helper. */
export const fragment = graphql`
  fragment KenticoStoreLocator on Node {
    ... on kontent_item_store_locator {
      system {
        id
        name
        codename
        type
      }
      elements {
        backgroundColor: background_color {
          value {
            codename
          }
        }
        footerText: footer_text {
          value
        }
        headerText: header_text {
          value
        }
        link {
          nodes: value {
            ...KenticoPageLink
          }
        }
        locations {
          nodes: value {
            ...KenticoPageLink
          }
        }
      }
    }
  }
`
