import * as IO from 'io-ts'
import {useCallback, useContext, useState} from 'react'
import {CommonContext, StorageAPI} from '~/common/context'
import {useEffectAsync} from '~/common/util'
import Geocode from 'react-geocode'
import {StoreResponse, parseStoreResponse} from '../types/api'
import {StoreStorage} from '../types/storage'
import {StoresLocation} from '.'

const LocationResponse = IO.type({
  city: IO.string,
  latitude: IO.number,
  longitude: IO.number,
  state: IO.string,
  stores: IO.array(StoreResponse),
})

const LocationStorage = IO.type({
  city: IO.string,
  position: IO.type({
    lat: IO.number,
    lng: IO.number,
  }),
  state: IO.string,
  stores: IO.array(StoreStorage),
})

/**
 * Construct location API.
 * @param session Session storage API
 * @return location API
 */
export const useStoresLocation = (session: StorageAPI) => {
  const [, setStatus] = useState('')
  const {api} = useContext(CommonContext)
  const [data, setDataBase] = useState<StoresLocation>({ready: false})
  const setData = useCallback(
    (newData: StoresLocation) => {
      setDataBase(newData)
      if (newData.ready) {
        const {ready: _, ...storage} = newData
        session?.write('location', storage)
      } else {
        session?.clear('location')
      }
    },
    [setDataBase, session],
  )

  // eslint-disable-next-line @typescript-eslint/require-await
  useEffectAsync(async () => {
    if (session?.has('location', LocationStorage)) {
      const cachedData = session.read('location', LocationStorage)
      setDataBase({
        ...cachedData,
        ready: true,
      })
      return
    }
    if (navigator.geolocation == undefined) {
      setStatus('Geolocation is not supported by your browser')
    } else {
      setStatus('Locating...')
      navigator.geolocation.getCurrentPosition(
        position => {
          setStatus('')
          Geocode?.fromLatLng(
            position.coords.latitude.toString(),
            position.coords.longitude.toString(),
            'AIzaSyBSr9kvY0zxHw7Pp-9hSJ6f_g7ICGi38YI',
            null,
            null,
            null,
          ).then(
            async response => {
              const address = response.results[0].formatted_address

              try {
                const addressLatitude = position.coords.latitude.toString()
                const addressLongitude = position.coords.longitude.toString()

                const addressSubstrings = address.split(',')
                const addressCity: string = addressSubstrings[1].trim()

                const addressStateAndZipCode = addressSubstrings[2]
                  .trim()
                  .split(' ')
                const adddresState: string = addressStateAndZipCode[0].trim()
                const adddresZipCode: string = addressStateAndZipCode[1].trim()

                const uriApend1 = `${addressLatitude}/${addressLongitude}`
                const uriApend2 = `/${addressCity}/${adddresState}/${adddresZipCode}`

                const resp = await api({
                  method: 'GET',
                  type: LocationResponse,
                  url: `v1/stores/closest-to-client/${uriApend1}${uriApend2}`,
                })
                setData({
                  city: resp.city,
                  position: {
                    lat: resp.latitude,
                    lng: resp.longitude,
                  },
                  ready: true,
                  state: resp.state,
                  stores: resp.stores.map(parseStoreResponse),
                })
              } catch {
                // Response failed, ignore
              }
            },
            error => {
              console.error(error)
            },
          )
        },
        () => {
          setStatus('Unable to retrieve your location')
        },
      )
    }

    // Establish web visitor and save response data
  }, [])

  return data
}
