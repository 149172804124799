"use strict";

exports.__esModule = true;
exports.imageLoaded = exports.createDummyImageArray = exports.imageArrayPropsChanged = exports.imagePropsChanged = exports.hasImageUrl = exports.isBase64 = exports.getUrlString = exports.getSelectedImage = exports.getCurrentSrcData = exports.getImageSrcKey = exports.getCurrentFromData = exports.hasImageArray = exports.hasPictureElement = void 0;

var _MediaUtils = require("./MediaUtils");

var _SimpleUtils = require("./SimpleUtils");

var hasPictureElement = function hasPictureElement() {
  return typeof HTMLPictureElement !== "undefined" || (0, _SimpleUtils.isBrowser)();
};

exports.hasPictureElement = hasPictureElement;

var hasImageArray = function hasImageArray(props) {
  return Boolean(props.fluid && Array.isArray(props.fluid) || props.fixed && Array.isArray(props.fixed));
};

exports.hasImageArray = hasImageArray;

var getCurrentFromData = function getCurrentFromData(_ref) {
  var data = _ref.data,
      propName = _ref.propName,
      _ref$addUrl = _ref.addUrl,
      addUrl = _ref$addUrl === void 0 ? true : _ref$addUrl,
      _ref$returnArray = _ref.returnArray,
      returnArray = _ref$returnArray === void 0 ? false : _ref$returnArray,
      _ref$checkLoaded = _ref.checkLoaded,
      checkLoaded = _ref$checkLoaded === void 0 ? true : _ref$checkLoaded;
  if (!data || !propName) return "";
  var tracedSVG = propName === "tracedSVG";

  if (Array.isArray(data) && !(0, _MediaUtils.hasArtDirectionArray)({
    fluid: data
  })) {
    var imageString = data.map(function (dataElement) {
      if (propName === "currentSrc" || propName === 'src') {
        return checkLoaded ? imageLoaded(dataElement) && dataElement[propName] || "" : dataElement[propName];
      }

      if (propName === "CSS_STRING" && (0, _SimpleUtils.isString)(dataElement)) {
        return dataElement;
      }

      return (0, _SimpleUtils.isString)(dataElement) ? dataElement : dataElement && propName in dataElement ? dataElement[propName] : "";
    });
    return getUrlString({
      imageString: imageString,
      tracedSVG: tracedSVG,
      addUrl: addUrl,
      returnArray: returnArray
    });
  }

  if ((0, _MediaUtils.hasArtDirectionArray)({
    fluid: data
  }) && (propName === "currentSrc" || propName === "src" || propName === "base64" || tracedSVG)) {
    var currentData = getCurrentSrcData({
      fluid: data
    });
    return propName in currentData ? getUrlString({
      imageString: currentData[propName],
      tracedSVG: tracedSVG,
      addUrl: addUrl
    }) : "";
  }

  if (typeof data !== 'object') {
    return '';
  }

  if ((propName === "currentSrc" || propName === 'src') && propName in data) {
    return getUrlString({
      imageString: checkLoaded ? imageLoaded(data) && data[propName] || "" : data[propName],
      addUrl: addUrl
    });
  }

  return propName in data ? getUrlString({
    imageString: data[propName],
    tracedSVG: tracedSVG,
    addUrl: addUrl
  }) : "";
};

exports.getCurrentFromData = getCurrentFromData;

var getImageSrcKey = function getImageSrcKey(_ref2) {
  var fluid = _ref2.fluid,
      fixed = _ref2.fixed;
  var data = getCurrentSrcData({
    fluid: fluid,
    fixed: fixed
  });
  return data ? data.src || null : null;
};

exports.getImageSrcKey = getImageSrcKey;

var getCurrentSrcData = function getCurrentSrcData(_ref3, index) {
  var fluid = _ref3.fluid,
      fixed = _ref3.fixed,
      returnArray = _ref3.returnArray;

  if (index === void 0) {
    index = 0;
  }

  var currentData = fluid || fixed;

  if (hasImageArray({
    fluid: fluid,
    fixed: fixed
  })) {
    if (returnArray) {
      return currentData;
    }

    if ((0, _SimpleUtils.isBrowser)() && (0, _MediaUtils.hasArtDirectionArray)({
      fluid: fluid,
      fixed: fixed
    })) {
      var mediaData = currentData.slice().reverse();
      var foundMedia = mediaData.findIndex(_MediaUtils.matchesMedia);

      if (foundMedia !== -1) {
        return mediaData[foundMedia];
      }
    }

    return currentData[index];
  }

  return currentData;
};

exports.getCurrentSrcData = getCurrentSrcData;

var getSelectedImage = function getSelectedImage(_ref4, index) {
  var fluid = _ref4.fluid,
      fixed = _ref4.fixed;

  if (index === void 0) {
    index = 0;
  }

  var currentData = fluid || fixed;

  if (hasImageArray({
    fluid: fluid,
    fixed: fixed
  })) {
    return currentData[index] || currentData[0];
  }

  return currentData;
};

exports.getSelectedImage = getSelectedImage;

var getUrlString = function getUrlString(_ref5) {
  var imageString = _ref5.imageString,
      _ref5$tracedSVG = _ref5.tracedSVG,
      tracedSVG = _ref5$tracedSVG === void 0 ? false : _ref5$tracedSVG,
      _ref5$addUrl = _ref5.addUrl,
      addUrl = _ref5$addUrl === void 0 ? true : _ref5$addUrl,
      _ref5$returnArray = _ref5.returnArray,
      returnArray = _ref5$returnArray === void 0 ? false : _ref5$returnArray,
      _ref5$hasImageUrls = _ref5.hasImageUrls,
      hasImageUrls = _ref5$hasImageUrls === void 0 ? false : _ref5$hasImageUrls;

  if (Array.isArray(imageString)) {
    var stringArray = imageString.map(function (currentString) {
      if (currentString) {
        var _base = isBase64(currentString);

        var _imageUrl = hasImageUrls || hasImageUrl(currentString);

        var currentReturnString = currentString && tracedSVG ? "\"" + currentString + "\"" : currentString && !_base && !tracedSVG && _imageUrl ? "'" + currentString + "'" : currentString;
        return addUrl && currentString ? "url(" + currentReturnString + ")" : currentReturnString;
      }

      return currentString;
    });
    return returnArray ? stringArray : (0, _SimpleUtils.filteredJoin)(stringArray);
  }

  var base64 = isBase64(imageString);
  var imageUrl = hasImageUrls || hasImageUrl(imageString);
  var returnString = imageString && tracedSVG ? "\"" + imageString + "\"" : imageString && !base64 && !tracedSVG && imageUrl ? "'" + imageString + "'" : imageString;
  return imageString ? addUrl ? "url(" + returnString + ")" : returnString : "";
};

exports.getUrlString = getUrlString;

var isBase64 = function isBase64(base64String) {
  return (0, _SimpleUtils.isString)(base64String) && base64String.indexOf("base64") !== -1;
};

exports.isBase64 = isBase64;

var hasImageUrl = function hasImageUrl(imageString) {
  return (0, _SimpleUtils.isString)(imageString) && imageString.substr(0, 4) === "http";
};

exports.hasImageUrl = hasImageUrl;

var imagePropsChanged = function imagePropsChanged(props, prevProps) {
  return props.fluid && !prevProps.fluid || props.fixed && !prevProps.fixed || imageArrayPropsChanged(props, prevProps) || props.fluid && prevProps.fluid && props.fluid.src !== prevProps.fluid.src || props.fixed && prevProps.fixed && props.fixed.src !== prevProps.fixed.src;
};

exports.imagePropsChanged = imagePropsChanged;

var imageArrayPropsChanged = function imageArrayPropsChanged(props, prevProps) {
  var isPropsFluidArray = Array.isArray(props.fluid);
  var isPrevPropsFluidArray = Array.isArray(prevProps.fluid);
  var isPropsFixedArray = Array.isArray(props.fixed);
  var isPrevPropsFixedArray = Array.isArray(prevProps.fixed);

  if (isPropsFluidArray && !isPrevPropsFluidArray || isPropsFixedArray && !isPrevPropsFixedArray || !isPropsFluidArray && isPrevPropsFluidArray || !isPropsFixedArray && isPrevPropsFixedArray) {
    return true;
  }

  if (isPropsFluidArray && isPrevPropsFluidArray) {
    if (props.fluid.length === prevProps.fluid.length) {
      return props.fluid.some(function (image, index) {
        return image.src !== prevProps.fluid[index].src;
      });
    }

    return true;
  }

  if (isPropsFixedArray && isPrevPropsFixedArray) {
    if (props.fixed.length === prevProps.fixed.length) {
      return props.fixed.some(function (image, index) {
        return image.src !== prevProps.fixed[index].src;
      });
    }

    return true;
  }
};

exports.imageArrayPropsChanged = imageArrayPropsChanged;

var createDummyImageArray = function createDummyImageArray(length) {
  var DUMMY_IMG = "data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==";
  var dummyImageURI = getUrlString({
    imageString: DUMMY_IMG
  });
  return Array(length).fill(dummyImageURI);
};

exports.createDummyImageArray = createDummyImageArray;

var imageLoaded = function imageLoaded(imageRef) {
  return imageRef ? (0, _SimpleUtils.isString)(imageRef) || imageRef.complete && imageRef.naturalWidth !== 0 && imageRef.naturalHeight !== 0 : false;
};

exports.imageLoaded = imageLoaded;