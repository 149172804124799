/**
 * Get optimizely variations.
 * @return Optimizely variations objects
 */
export const getOptimizelyVariations = () => {
  if (!window.optimizely) {
    return []
  }
  const state = window.optimizely.get('state')
  const pages = Object.values(state.getPageStates())

  // Get all experiment/variations visitor has seen
  const experiments = Object.entries(state.getVariationMap()).map(
    ([experimentId, {id: variationId}]) => ({
      experimentId,
      variationId,
    }),
  )

  if (experiments.length < 1) {
    return undefined
  }

  // Find visited campaigns and matching pages
  return Object.values(state.getCampaignStates())
    .map(data => ({
      ...data,
      page: pages.find(
        ({name}) => name === `URL Targeting for ${data.experiment.name}`,
      ),
    }))
    .filter(({experiment, page, variation}) => {
      if (experiment !== null && variation !== null) {
        const matches = experiments.some(
          ({experimentId, variationId}) =>
            experimentId === experiment.id && variationId === variation.id,
        )
        return matches && page !== undefined
      }

      return []
    })
    .map(({id, experiment, page, variation}) => ({
      campaignId: id,
      experimentId: experiment === null ? '' : experiment.id,
      experimentName: experiment === null ? '' : experiment.name,
      variationId: variation === null ? '' : variation.id,
      variationName: variation === null ? '' : variation.name,
      variationPage: page?.staticConditions[1][1].value,
    }))
}
