import {graphql} from 'gatsby'
import * as IO from 'io-ts'
import {KenticoContentData} from '../content'

/** Access Code Data. */
export const KenticoAccessCodeData = IO.intersection(
  [
    KenticoContentData,
    IO.type({
      system: IO.type({
        type: IO.literal('access_code'),
      }),
    }),
  ],
  'KenticoAccessCode',
)

/** Data Type. */
export interface KenticoAccessCodeData
  extends IO.TypeOf<typeof KenticoAccessCodeData> {}

/** Fragment Helper. */
export const fragment = graphql`
  fragment KenticoAccessCode on Node {
    ... on kontent_item_access_code {
      system {
        codename
        id
        name
        type
      }
    }
  }
`
