import {UUID} from 'io-ts-types/lib/UUID'
import React from 'react'
import styled from 'styled-components'
import {Container as BootstrapContainer} from '~/bootstrap/components/layout'
import {Customize} from '~/common/components/customize'
import {validImage} from '../../util'
import {KenticoFixedImage} from '../fixed-image'
import {KenticoPreviewBodyLink} from '../preview-link'
import {KenticoRichText} from '../rich-text'
import {KenticoSecuritySealData} from './data'

interface Props {
  data: KenticoSecuritySealData
}

/** Kentico type data. */
export {KenticoSecuritySealData}

const Container = styled(BootstrapContainer)`
  padding-top: ${({theme}) => theme.spacers[3]};
  padding-bottom: ${({theme}) => theme.spacers[3]};
`

/**
 * Render security seal from Kentico data.
 * @return React component
 */
export const KenticoSecuritySeal = ({
  data: {
    elements: {backgroundColor, body, image, url},
    system: {id, name},
  },
}: Props) => {
  const color =
    backgroundColor.value.length > 0
      ? backgroundColor.value[0].codename
      : undefined

  let imageElement
  if (validImage(image)) {
    imageElement = (
      <Customize textAlign="center">
        <a
          aria-label="Check out our security certificate"
          href={url.value}
          rel="noopener noreferrer"
          target="_blank"
        >
          <KenticoFixedImage image={image.value[0]} />
        </a>
      </Customize>
    )
  }

  // Render preview link
  let editLink
  if (!UUID.is(name)) {
    editLink = <KenticoPreviewBodyLink contentItem={id} />
  }

  return (
    <Customize background={color} component={Container} textColor="light">
      {editLink}
      <KenticoRichText data={body} />
      {imageElement}
    </Customize>
  )
}
