import React, {ReactNode} from 'react'
import {VehiclesContext} from '../../context'
import {useVehicles} from '../../context/default'

interface Props {
  children: ReactNode
}

/**
 * Inject default information into vehicles context.
 * @return React component
 */
export const VehiclesProvider = ({children}: Props) => (
  <VehiclesContext.Provider value={useVehicles()}>
    {children}
  </VehiclesContext.Provider>
)
