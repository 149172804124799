import {graphql} from 'gatsby'
import * as IO from 'io-ts'
import {KenticoContentData} from '../content'

/** Reviews. */
export const KenticoReviewsData = IO.intersection(
  [
    KenticoContentData,
    IO.type({
      elements: IO.type({
        title: IO.type({
          value: IO.string,
        }),
      }),
      system: IO.type({
        type: IO.literal('reviews'),
      }),
    }),
  ],
  'KenticoReviews',
)

/** Data type. */
export interface KenticoReviewsData
  extends IO.TypeOf<typeof KenticoReviewsData> {}

/** Fragment helper. */
export const fragment = graphql`
  fragment KenticoReviews on Node {
    ... on kontent_item_reviews {
      elements {
        title {
          value
        }
      }
      system {
        codename
        id
        name
        type
      }
    }
  }
`
