import {useCallback} from 'react'

interface OnChangeData {
  location?: {
    lat: number
    lng: number
  }
  address?: string
  city?: string
  state?: string
  zip?: string
}

/** Place information. */
export interface Place {
  address_components?: google.maps.GeocoderAddressComponent[]
  geometry?: {
    location: {
      toJSON(): {lat: number; lng: number}
    }
  }
}

interface Options {
  searchBox?: {
    getPlaces(): Place[]
  }
  onChange(data: OnChangeData): void
}

const extractAddressData = (
  data: OnChangeData,
  components: google.maps.GeocoderAddressComponent[],
): OnChangeData => {
  let newData = data
  const streetNumber = components.find(({types}) =>
    types.includes('street_number'),
  )
  const street = components.find(({types}) => types.includes('route'))
  const city = components.find(
    ({types}) => types.includes('locality') && types.includes('political'),
  )
  const state = components.find(
    ({types}) =>
      types.includes('political') &&
      types.includes('administrative_area_level_1'),
  )
  const zip = components.find(({types}) => types.includes('postal_code'))

  // Only add state if this is a state search
  if (state && !city) {
    return {state: state.short_name}
  }

  // Otherwise check each address piece
  if (street) {
    newData = {
      ...newData,
      address: streetNumber
        ? `${streetNumber.long_name} ${street.long_name}`
        : street.long_name,
    }
  }
  if (city) {
    newData = {...newData, city: city.long_name}
  }
  if (state) {
    newData = {...newData, state: state.short_name}
  }
  if (zip) {
    newData = {...newData, zip: zip.long_name}
  }
  return newData
}

/**
 * Create on places changed callback.
 * @return Callback function
 */
export const useOnPlacesChanged = ({onChange, searchBox}: Options) =>
  useCallback(() => {
    if (!searchBox) {
      return
    }
    const places = searchBox.getPlaces()
    if (places.length === 0) {
      return
    }
    const [place] = places
    let data: OnChangeData = {}
    if (place.geometry) {
      data = {
        ...data,
        location: place.geometry.location.toJSON(),
      }
    }
    if (place.address_components) {
      data = extractAddressData(data, place.address_components)
    }
    if (Object.keys(data).length === 0) {
      return
    }
    onChange(data)
  }, [onChange, searchBox])
