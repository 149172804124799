/* eslint-disable sonarjs/cognitive-complexity */
import {UUID} from 'io-ts-types/lib/UUID'
import React, {useContext} from 'react'
import styled from 'styled-components'
import {
  Col,
  Container as BootstrapContainer,
  Row as BootstrapRow,
} from '~/bootstrap/components/layout'
import {Customize} from '~/common/components/customize'
import {CURRENCIES} from '~/common/util'
import {validImage} from '~/kentico/util'
import {WebFormContext} from '~/web-form/context'
import {Noop} from '~/common/components/noop'
import {Link} from '~/common/components/link'
import {KenticoFluidImage} from '../fluid-image'
import {KenticoPreviewBodyLink} from '../preview-link'
import {KenticoApprovedBannerData} from './data'

interface Props {
  data: KenticoApprovedBannerData
}

/** Kentico type data. */
export {KenticoApprovedBannerData}

const Container = styled(BootstrapContainer)`
  padding: ${({theme}) => theme.spacers[5]};

  @media (max-width: ${({theme}) => theme.breakpoints.max.sm}) {
    padding: ${({theme}) => theme.spacers[0]};
    text-align: center;
  }
`

const AustinTextContainer = styled(BootstrapContainer)`
  text-align: center;
`
const AustinText = styled.h4`
  line-height: 1.5;
`

const Row = styled(BootstrapRow)`
  padding: ${({theme}) => theme.spacers[5]} 0;
  margin: 0;
`

const Title = styled.h1`
  color: ${({theme}) => theme.colors.secondary};
  line-height: 1.1;

  @media (max-width: ${({theme}) => theme.breakpoints.max.sm}) {
    font-size: 2rem;
  }
`

const Subtitle = styled.h3`
  line-height: 1.5;
  padding: ${({theme}) => theme.spacers[1]};
`

const Amount = styled.span`
  color: ${({theme}) => theme.colors.tertiary};
`

const logInHereLink = (
  <a
    className="btn btn-secondary mb-3"
    href={"https://secure.cashstore.com/login?utm_source=website&utm_medium=pqty&utm_campaign=welcomeback"}
  >
    Log In Here
  </a>
  )

/**
 * Render approved banner from Kentico data.
 * @return React component
 */
export const KenticoApprovedBanner = ({
  data: {
    elements: {image},
    system: {id, name},
  },
}: Props) => {
  const {data} = useContext(WebFormContext)
  let editLink
  if (!UUID.is(name)) {
    editLink = <KenticoPreviewBodyLink contentItem={id} />
  }

  let imageElement
  if (validImage(image)) {
    imageElement = <KenticoFluidImage image={image.value[0]} />
  }

  let amount = 0;
  let subtitleElement
  if (
    !data ||
    data.status === 'ERROR' ||
    data.status === 'MULTIPLE_ERROR' ||
    data.status === 'DECLINED' ||
    data.status === 'TEMP_DECLINED' ||
    data.status === 'INVALID' ||
    data.status === 'NEW_CUSTOMER'
  ) {
    return (
      <Customize background="light">
        <Container>
          {editLink}
          <Row>
            <Col xl="5">
              <Title>Congratulations!</Title>
              <Subtitle>We&apos;ve received your application!</Subtitle>
            </Col>
            <Col xl>{imageElement}</Col>
          </Row>
        </Container>
      </Customize>
    )
  }

  let welcomeMessage = <Title>Congratulations, {data.name}!</Title>

  if (data.status === 'VALID' || data.type !== 'TITLE') {
    amount = data.amount;
    subtitleElement = (
      <Subtitle>
        You&apos;ve been prequalified for up to{' '}
        <Amount>{CURRENCIES.USD.format(amount)}</Amount> cash!*
      </Subtitle>
    )
  } else if (data.status === 'TITLE_ONLY') {
    amount = data.titleLoanAmount
    subtitleElement = (
      <Subtitle>
        You could get up to{' '}
        <Amount>{CURRENCIES.USD.format(amount)}</Amount> for your
        vehicle with a title loan!*
      </Subtitle>
    )
  } else if (data.titleLoanApproved) {
    amount = data.amount
    subtitleElement = (
      <>
      <Subtitle>
        You&apos;ve been prequalified for a cash loan up to{' '}
        <Amount>{CURRENCIES.USD.format(data.amount)}</Amount>
        {' or you could get up to '}
        <Amount>{CURRENCIES.USD.format(data.titleLoanAmount)}</Amount> for your
        vehicle with a title loan!*
      </Subtitle>
      {data.existingCustomerIndicator === true && logInHereLink}
      </>
    )
  } else {
    subtitleElement = (
      <Subtitle>
        We do not have any up-to-date information on your vehicle, however,
        you&apos;ve been prequalifed for up to{' '}
        <Amount>{CURRENCIES.USD.format(data.amount)}</Amount>!*
      </Subtitle>

    )
  }

  /* eslint-disable max-len */
  // Specify the data.type must be an approved prequal with a closest store
  // in Austin, TX
  // TODO check in the future to verify if this is still necessary
  let austinMessage
  // TODO verify tests in future to remove all istanbul ignore next cases
  // istanbul ignore next
  if (
    (data.type === 'CASH_ADVANCE' ||
      data.type === 'TITLE' ||
      data.type === 'INSTALLMENT') &&
    data.status === 'APPROVED' &&
    data.stores[0]?.city === 'Austin'
  ) {
    austinMessage = (
      <AustinTextContainer>
        <Row>
          <Col>
            <AustinText>
              Our Austin, TX stores are only offering single-payment cash
              advance. For an installment loan with lower payments and more
              time to pay back, please visit one of our neighboring
              cities&apos; stores:{' '}
              <Link to="/local/payday-loans-pflugerville">Plfugerville</Link>,{' '}
              <Link to="/local/payday-loans-kyle-tx">Kyle</Link>, or{' '}
              <Link to="/local/payday-loans-round-rock-tx">Round Rock</Link>.
            </AustinText>
          </Col>
        </Row>
      </AustinTextContainer>
    )
  } else {
    austinMessage = <Noop />
  }

  if (data.status === 'APPROVED' && data.existingCustomerIndicator === true) {
    welcomeMessage =  <Title>Welcome back, {data.name}!</Title>
    if (data.type !== 'TITLE') {
    subtitleElement = (
      <><Subtitle>
        Did you know you can{' '}
        <Amount>get your {CURRENCIES.USD.format(amount)} deposited to your bank immediately</Amount>?
        Just log in to your account and click &quot;Get My Cash!&quot;
      </Subtitle>
        {logInHereLink}</>)
    }
  }

  return (
    <>
      <Customize background="light">
        <Container>
          {editLink}
          <Row>
            <Col xl="5">
              {welcomeMessage}
              {subtitleElement}
            </Col>
            <Col xl>{imageElement}</Col>
          </Row>
        </Container>
      </Customize>
      {austinMessage}
    </>
  )
}
