import {UUID} from 'io-ts-types/lib/UUID'
import React from 'react'
import styled from 'styled-components'
import {Container as BootstrapContainer} from '~/bootstrap/components/layout'
import {linkedItems, validImage} from '../../util'
import {KenticoFixedImage} from '../fixed-image'
import {KenticoPreviewBodyLink} from '../preview-link'
import {KenticoListWithImagesHorizontalData} from './data'

interface Props {
  data: KenticoListWithImagesHorizontalData
}

/** Kentico type data. */
export {KenticoListWithImagesHorizontalData}

const Container = styled(BootstrapContainer)`
  padding-bottom: ${({theme}) => theme.spacers[7]};

  &&& {
    display: flex;
  }

  @media (max-width: ${({theme}) => theme.breakpoints.max.md}) {
    flex-direction: column;
    padding-bottom: 1rem;
  }
`

const Item = styled.div`
  padding: ${({theme}) => theme.spacers[4]};
  text-align: center;
  flex: 1;
`

const Title = styled.h2`
  padding-top: ${({theme}) => theme.spacers[7]};
  padding-bottom: ${({theme}) => theme.spacers[6]};
  text-align: center;

  @media(max-width: ${({theme}) => theme.breakpoints.max.md}){
    padding-top: 2rem;
    padding-bottom: 0.2rem;
  }
`

const ImageContainer = styled.div`
  text-align: center;
  height: 100px;
`

/**
 * Render list with images horizontal from Kentico data.
 * @return React component
 */
export const KenticoListWithImagesHorizontal = ({
  data: {
    elements: {items, title},
    system: {id, name},
  },
}: Props) => {
  const listItems = linkedItems(items).map(item => {
    let imageElement
    if (validImage(item.elements.image)) {
      imageElement = <KenticoFixedImage image={item.elements.image.value[0]} />
    }
    return (
      <Item key={item.system.codename}>
        <ImageContainer>{imageElement}</ImageContainer>
        <div>{item.elements.text.value}</div>
      </Item>
    )
  })
  let editLink
  if (!UUID.is(name)) {
    editLink = <KenticoPreviewBodyLink contentItem={id} />
  }
  return (
    <BootstrapContainer>
      {editLink}
      <Title>{title.value}</Title>
      <Container>{listItems}</Container>
    </BootstrapContainer>
  )
}
