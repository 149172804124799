import {graphql} from 'gatsby'
import * as IO from 'io-ts'
import {LinkedItems} from '../../util'
import {KenticoContentData} from '../content'
import {KenticoPageLinkData} from '../page-link'

/** Table row. */
export const KenticoTableRowData = IO.intersection(
  [
    KenticoContentData,
    IO.type({
      elements: IO.type({
        buttonLink: LinkedItems(KenticoPageLinkData),
        buttonText: IO.type({
          value: IO.string,
        }),
        text1: IO.type({
          value: IO.string,
        }),
        text2: IO.type({
          value: IO.string,
        }),
      }),
      system: IO.type({
        type: IO.literal('table_row'),
      }),
    }),
  ],
  'KenticoTableRow',
)

/** Data type. */
export interface KenticoTableRowData
  extends IO.TypeOf<typeof KenticoTableRowData> {}

/** Fragment helper. */
export const fragment = graphql`
  fragment KenticoTableRow on Node {
    ... on kontent_item_table_row {
      system {
        codename
        id
        name
        type
      }
      elements {
        buttonLink: button_link {
          nodes: value {
            ...KenticoPageLink
          }
        }
        buttonText: button_text {
          value
        }
        text1 {
          value
        }
        text2 {
          value
        }
      }
    }
  }
`
