import {useCallback, useMemo, useState} from 'react'
import {SelectedVehicle} from '.'

interface Options {
  years: number[]
  makes: string[]
  models: string[]
  seriesList: string[]
  styles: string[]
}

/**
 * Construct selection API.
 * @return Selection API
 */
export const useVehiclesSelected = ({
  makes,
  models,
  seriesList,
  styles,
  years,
}: Options) => {
  const [selected, setSelected] = useState<SelectedVehicle>({ready: false})

  const reset = useCallback(() => setSelected({ready: false}), [setSelected])

  const setYear = useCallback(
    (newYear: number) => {
      if (!years.includes(newYear)) {
        return
      }
      setSelected({
        ready: false,
        year: newYear,
      })
    },
    [years],
  )

  const setMake = useCallback(
    (newMake: string) => {
      if (selected.year === undefined) {
        return
      }
      if (!makes.includes(newMake)) {
        return
      }
      setSelected({
        make: newMake,
        ready: false,
        year: selected.year,
      })
    },
    [selected, makes],
  )

  const setModel = useCallback(
    (newModel: string) => {
      if (selected.year === undefined) {
        return
      }
      if (selected.make === undefined) {
        return
      }
      if (!models.includes(newModel)) {
        return
      }
      setSelected({
        make: selected.make,
        model: newModel,
        ready: false,
        year: selected.year,
      })
    },
    [selected, models],
  )

  const setSeries = useCallback(
    (newSeries: string) => {
      if (selected.year === undefined) {
        return
      }
      if (selected.make === undefined) {
        return
      }
      if (selected.model === undefined) {
        return
      }
      if (!seriesList.includes(newSeries)) {
        return
      }
      setSelected({
        make: selected.make,
        model: selected.model,
        ready: false,
        series: newSeries,
        year: selected.year,
      })
    },
    [selected, seriesList],
  )

  const setStyle = useCallback(
    (newStyle: string) => {
      if (selected.year === undefined) {
        return
      }
      if (selected.make === undefined) {
        return
      }
      if (selected.model === undefined) {
        return
      }
      if (selected.series === undefined) {
        return
      }
      if (!styles.includes(newStyle)) {
        return
      }
      setSelected({
        make: selected.make,
        model: selected.model,
        ready: true,
        series: selected.series,
        style: newStyle,
        year: selected.year,
      })
    },
    [selected, styles],
  )

  return useMemo(
    () => ({
      ...selected,
      reset,
      setMake,
      setModel,
      setSeries,
      setStyle,
      setYear,
    }),
    [reset, selected, setMake, setModel, setSeries, setStyle, setYear],
  )
}
