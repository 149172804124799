import {DomElement, domToReact} from 'html-react-parser'
import React from 'react'
import {Noop} from '~/common/components/noop'
import {KenticoPageLink} from '../page-link'
import {KenticoPhoneLink, KenticoPhoneLinkData} from '../phone-link'
import {KenticoRichTextLinkData} from './data'

/** Kentico type data. */
export {KenticoRichTextLinkData}

interface Props {
  id: string
  links: {
    linkId: string
    element: KenticoRichTextLinkData
  }[]
  element: DomElement
  scrub: boolean
}

/**
 * Render rich text link from Kentico data.
 * @param props Component properties
 * @return React component
 */
export const KenticoRichTextLink = ({
  id,
  element: {children = []},
  links,
  scrub,
}: Props) => {
  const link = links.find(({linkId}) => linkId === id)
  if (!link) {
    if (!scrub) {
      throw new Error('Unknown link')
    }
    return <Noop />
  }

  const content = domToReact(children)
  if (KenticoPhoneLinkData.is(link.element)) {
    return <KenticoPhoneLink link={link.element}>{content}</KenticoPhoneLink>
  }

  return <KenticoPageLink link={link.element}>{content}</KenticoPageLink>
}
