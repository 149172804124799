"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

exports.__esModule = true;
exports.matchesMedia = exports.hasArtDirectionArray = exports.hasArtDirectionSupport = exports.createArtDirectionSources = exports.createSourceElementForSrcSet = exports.groupByMedia = void 0;

var _sortMediaQueries = _interopRequireDefault(require("sort-media-queries"));

var _SimpleUtils = require("./SimpleUtils");

var groupByMedia = function groupByMedia(imageVariants) {
  var without = [];
  var sortedVariants = (0, _sortMediaQueries.default)(imageVariants, 'media');
  sortedVariants.forEach(function (variant) {
    return !variant.media && without.push(variant);
  });

  if (without.length > 1 && process.env.NODE_ENV !== "production") {
    console.warn("We've found " + without.length + " sources without a media property. They might be ignored by the browser, see: https://www.gatsbyjs.org/packages/gatsby-image/#art-directing-multiple-images");
  }

  return sortedVariants;
};

exports.groupByMedia = groupByMedia;

var createSourceElementForSrcSet = function createSourceElementForSrcSet(image, type) {
  var source = document.createElement('source');
  var srcSetName = "srcSet" + (0, _SimpleUtils.capitalize)(type);

  if (srcSetName in image) {
    source.type = "image/" + type;
    source.srcset = image[srcSetName];
  }

  if (image.sizes) {
    source.sizes = image.sizes;
  }

  if (image.media) {
    source.media = image.media;
  }

  return source.srcset ? source : null;
};

exports.createSourceElementForSrcSet = createSourceElementForSrcSet;

var createArtDirectionSources = function createArtDirectionSources(_ref) {
  var fluid = _ref.fluid,
      fixed = _ref.fixed;
  var currentSource = fluid || fixed;
  return currentSource.reduce(function (sources, image) {
    if (!image.media) {
      return sources;
    }

    var sourceWebp = createSourceElementForSrcSet(image, 'webp');
    var sourceAvif = createSourceElementForSrcSet(image, 'avif');
    sourceWebp && sources.push(sourceWebp);
    sourceAvif && sources.push(sourceAvif);
    return sources;
  }, []);
};

exports.createArtDirectionSources = createArtDirectionSources;

var hasArtDirectionSupport = function hasArtDirectionSupport(props, prop) {
  return props[prop] && Array.isArray(props[prop]) && props[prop].some(function (image) {
    return !!image && typeof image.media !== 'undefined';
  });
};

exports.hasArtDirectionSupport = hasArtDirectionSupport;

var hasArtDirectionArray = function hasArtDirectionArray(props) {
  return hasArtDirectionSupport(props, 'fluid') || hasArtDirectionSupport(props, 'fixed');
};

exports.hasArtDirectionArray = hasArtDirectionArray;

var matchesMedia = function matchesMedia(_ref2) {
  var media = _ref2.media;
  return media ? (0, _SimpleUtils.isBrowser)() && typeof window.matchMedia !== "undefined" && !!window.matchMedia(media).matches : false;
};

exports.matchesMedia = matchesMedia;