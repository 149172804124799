import {graphql} from 'gatsby'
import * as IO from 'io-ts'
import {KenticoContentData} from '../content'
import {KenticoRichTextData} from '../rich-text'

/** Contact Us Form. */
export const KenticoContactUsFormData = IO.intersection(
  [
    KenticoContentData,
    IO.type({
      elements: IO.type({
        header: KenticoRichTextData,
      }),
      system: IO.type({
        type: IO.literal('contact_us_form'),
      }),
    }),
  ],
  'KenticoContactUsForm',
)

/** Data Type. */
export interface KenticoContactUsFormData
  extends IO.TypeOf<typeof KenticoContactUsFormData> {}

/** Fragment helper. */
export const fragment = graphql`
  fragment KenticoContactUsForm on Node {
    ... on kontent_item_contact_us_form {
      elements {
        header {
          ...KenticoRichText
        }
      }
      system {
        id
        codename
        name
        type
      }
    }
  }
`
