import {graphql} from 'gatsby'
import * as IO from 'io-ts'
import {LinkedItems} from '../../util'
import {KenticoContentData} from '../content'
import {KenticoTableRowData} from '../table-row'

/** Table. */
export const KenticoTableData = IO.intersection(
  [
    KenticoContentData,
    IO.type({
      elements: IO.type({
        heading1: IO.type({
          value: IO.string,
        }),
        heading2: IO.type({
          value: IO.string,
        }),
        rows: LinkedItems(KenticoTableRowData),
      }),
      system: IO.type({
        type: IO.literal('table'),
      }),
    }),
  ],
  'KenticoTable',
)

/** Data type. */
export interface KenticoTableData extends IO.TypeOf<typeof KenticoTableData> {}

/** Fragment helper. */
export const fragment = graphql`
  fragment KenticoTable on Node {
    ... on kontent_item_table {
      system {
        id
        name
        codename
        type
      }
      elements {
        rows: body {
          nodes: value {
            ...KenticoTableRow
          }
        }
        heading1 {
          value
        }
        heading2 {
          value
        }
      }
    }
  }
`
