import React from 'react'
import {Helmet} from 'react-helmet'
import stripHtml from 'string-strip-html'
import {useQuery} from '~/gatsby/util'
import {linkedItems} from '~/kentico/util'
import {KenticoSchemaFaqData} from './data'

interface Props {
  data: KenticoSchemaFaqData
}

/** Kentico type data. */
export {KenticoSchemaFaqData}

/**
 * Render Kentico Schema Faq script.
 * @return React component
 */
export const KenticoSchemaFaq = ({data}: Props) => {
  const FAQ = linkedItems(data.elements.mainEntity).map(faq =>
    linkedItems(faq.elements.items).map(item => ({
      '@type': 'Question',
      acceptedAnswer: {
        '@type': 'Answer',
        text: stripHtml(item.elements.content.value),
      },
      name: item.elements.title.value,
    })),
  )

  const {siteUrl} = useQuery()
  const url = `${siteUrl}${data.elements.primaryImage.value[0].file.url}`
  const schema = {
    '@context': data.elements.context.value,
    '@type': data.elements.type.value,
    description: data.elements.description.value,
    mainEntity: FAQ,
    primaryImageOfPage: url,
  }

  return (
    <Helmet>
      <script type="application/ld+json">{JSON.stringify(schema)}</script>
    </Helmet>
  )
}
