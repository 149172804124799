import * as IO from 'io-ts'
import {useCallback, useContext, useEffect, useMemo, useState} from 'react'
import {CommonContext} from '~/common/context'
import {StoreStorage} from '~/stores/types/storage'
import {useCampaign} from './campaign'
import {usePrequalNonTitle} from './non-title'
import {usePrequalTitle} from './title'
import {WebFormContext, WebFormData} from '.'

const NonTitle = IO.keyof({
  CASH_ADVANCE: undefined,
  INSTALLMENT: undefined,
})

const CampaignFormType = IO.keyof({
  CAMPAIGN: undefined,
  CAMPAIGN_APPOINTMENT: undefined,
})

const PrequalApproved = IO.type({
  amount: IO.number,
  existingCustomerIndicator: IO.boolean,
  name: IO.string,
  status: IO.literal('APPROVED'),
  stores: IO.array(StoreStorage),
})

const PrequalNewCustomer = IO.type({
  name: IO.string,
  status: IO.literal('NEW_CUSTOMER'),
})

const PrequalTitleOnly = IO.type({
  name: IO.string,
  status: IO.literal('TITLE_ONLY'),
  stores: IO.array(StoreStorage),
  titleLoanAmount: IO.number,
  titleLoanApproved: IO.literal(true),
})

const PrequalDeclined = IO.type({
  name: IO.string,
  status: IO.literal('DECLINED'),
})

const PrequalError = IO.type({
  status: IO.literal('ERROR'),
})

const PrequalMultipleError = IO.type({
  status: IO.literal('MULTIPLE_ERROR'),
})

const PrequalNonTitle = IO.intersection([
  IO.type({
    type: NonTitle,
  }),
  IO.union([
    PrequalApproved,
    PrequalNewCustomer,
    PrequalDeclined,
    PrequalError,
    PrequalMultipleError
  ]),
])

const PrequalTitle = IO.intersection([
  IO.type({
    type: IO.literal('TITLE'),
  }),
  IO.union([
    IO.intersection([
      PrequalApproved,
      IO.type({
        titleLoanAmount: IO.number,
        titleLoanApproved: IO.literal(true),
      }),
    ]),
    IO.intersection([
      PrequalApproved,
      IO.type({
        titleLoanApproved: IO.literal(false),
      }),
    ]),
    PrequalTitleOnly,
    PrequalNewCustomer,
    PrequalDeclined,
    PrequalError,
    PrequalMultipleError
  ]),
])

// Form Typing
const CampaignFormValidResponse = IO.type({
  accessCode: IO.string,
  amount: IO.number,
  name: IO.string,
  offerValidation: IO.null,
  status: IO.literal('VALID'),
  stores: IO.array(StoreStorage),
})

const CampaignAppointmentFormValidResponse = IO.type({
  accessCode: IO.string,
  amount: IO.number,
  appointmentDate: IO.string,
  appointmentTime: IO.string,
  name: IO.string,
  offerValidation: IO.null,
  status: IO.literal('VALID'),
  stores: IO.array(StoreStorage),
})

const CampaignFormInvalidResponse = IO.type({
  accessCode: IO.string,
  offerValidation: IO.string,
  status: IO.literal('INVALID'),
})

const CampaignFormErrorResponse = IO.type({
  status: IO.literal('ERROR'),
})

const FormCampaign = IO.intersection([
  IO.type({
    type: CampaignFormType,
  }),
  IO.union([
    CampaignFormValidResponse,
    CampaignAppointmentFormValidResponse,
    CampaignFormInvalidResponse,
    CampaignFormErrorResponse,
  ]),
])

const WebFormStorage = IO.union([PrequalNonTitle, PrequalTitle, FormCampaign])

/**
 * Construct default WebForm context.
 * @return WebForm context
 */
export const useWebForm = () => {
  const {session: baseSession} = useContext(CommonContext)

  // Set up data and session based persistence
  const [data, setData] = useState<WebFormData>()
  const session = baseSession?.useNamespace('webForm')
  const setDataWithSession = useCallback(
    (newData: WebFormData) => {
      setData(newData)
      session?.write('data', newData)
    },
    [session],
  )
  useEffect(() => {
    if (session?.has('data', WebFormStorage)) {
      const cachedData = session.read('data', WebFormStorage)
      setData(cachedData)
    }
  }, [session])

  // Set up higher form APIs for context types
  const campaign = useCampaign('CAMPAIGN', setDataWithSession)
  const campaignAppointment = useCampaign(
    'CAMPAIGN_APPOINTMENT',
    setDataWithSession,
  )
  const cashAdvance = usePrequalNonTitle('CASH_ADVANCE', setDataWithSession)
  const installment = usePrequalNonTitle('INSTALLMENT', setDataWithSession)
  const title = usePrequalTitle(setDataWithSession)

  return useMemo<WebFormContext>(
    () => ({
      campaign,
      campaignAppointment,
      cashAdvance,
      data,
      installment,
      title,
    }),
    [data, campaign, campaignAppointment, cashAdvance, installment, title],
  )
}
