import React, {ReactNode, useContext, useEffect, useMemo} from 'react'
import {FormContext, useForm} from 'react-hook-form'
import styled from 'styled-components'
import {Button} from '~/bootstrap/components/button'
import {Form, FormGroup, FormItem, FormText} from '~/bootstrap/components/form'
import {Col, Row} from '~/bootstrap/components/layout'
import {Spinner} from '~/common/components/spinner'
import {CommonContext} from '~/common/context'
import {MASKS, PATTERNS} from '~/common/util'
import {Noop} from '~/common/components/noop'
import {WebFormContext} from '../../context'
import {CampaignFormData} from '../../types'
import {useOnSubmit} from './hooks'

type WebFormProps = import('../form').WebFormProps

interface Props extends WebFormProps {
  type: 'CAMPAIGN' | 'CAMPAIGN_APPOINTMENT'
  tcpa: ReactNode
}

const MAX_LENGTH_MESSAGE = "You've exceeded the max length"

const errorValue = (errorMessage: string, errorNumber: number) => ({
  message: errorMessage,
  value: errorNumber,
})

const Disclaimer = styled.div`
  p {
    margin: 0;
    margin-left: ${({theme}) => theme.spacers[2]};
  }
`

/**
 * Render campaign form.
 * @return React component
 */
export const CampaignForm = ({
  privacyConsent,
  tcpa,
  submissionLinks,
  type,
}: Props) => {
  const {session: baseSession} = useContext(CommonContext)
  const {campaign, campaignAppointment, data} = useContext(WebFormContext)
  const api = type === 'CAMPAIGN' ? campaign : campaignAppointment
  const form = useForm<CampaignFormData>({
    mode: 'onBlur',
    reValidateMode: 'onChange',
  })
  const {
    handleSubmit,
    setError,
    formState: {isValid},
  } = form
  const onSubmit = useOnSubmit({api, submissionLinks})
  const formSubmitDisabled = !isValid || !api.ready
  const spinner = api.ready ? 'Submit' : <Spinner />
  // TODO Learn how to mock session storage for proper testing here
  // istanbul ignore next
  const session = baseSession?.useNamespace('webForm')

  // TODO Learn how to mock session storage for proper testing here
  // istanbul ignore next
  useEffect(() => {
    if (data && data.status === 'INVALID') {
      session?.clear('data')
    }
  })

  const privacyConsentDisclaimer = <Disclaimer>{privacyConsent}</Disclaimer>
  const tcpaDisclaimer = <Disclaimer>{tcpa}</Disclaimer>

  const today = new Date().toString()
  const timeOptions = (
    <>
      <option disabled value="1">
        Select an Appointment Time
      </option>
      <option value="10:00am - 12:00pm">10:00am - 12:00pm</option>
      <option value="12:00pm - 2:00pm">12:00pm - 2:00pm</option>
      <option value="2:00pm - 4:00pm">2:00pm - 4:00pm</option>
      <option value="4:00pm - 6:00pm">4:00pm - 6:00pm</option>
    </>
  )
  const appointment =
    type === 'CAMPAIGN_APPOINTMENT' ? (
      <Row>
        <Col md sm="12">
          <FormItem
            label="Appointment Date"
            min={today}
            name="appointmentDate"
            required="Please set an Appointment Date"
            type="date"
          />
        </Col>
        <Col md sm="12">
          <FormItem
            defaultValue="1"
            label="Appointment Time"
            name="appointmentTime"
            required="Please set an Appointment Time"
            type="select"
          >
            {timeOptions}
          </FormItem>
        </Col>
      </Row>
    ) : (
      <Noop />
    )

  const accessCodeErrorMessage = useMemo(() => {
    if (
      data &&
      data.status === 'INVALID' &&
      data.offerValidation === 'InvalidAccessCode'
    ) {
      const errorMessage = 'The Access Code you entered is incorrect'
      setError('accessCode', 'invalid', errorMessage)
      return errorMessage
    }
    return 'Please enter your access code'
  }, [data, setError])

  const ssnErrorMessage = useMemo(() => {
    if (
      data &&
      data.status === 'INVALID' &&
      data.offerValidation === 'InvalidLast4SSN'
    ) {
      const errorMessage = 'No match found! Please validate code and SSN'
      setError('last4SSN', 'invalid', errorMessage)
      return errorMessage
    }
    return 'Please enter the last 4 digits of your SSN'
  }, [data, setError])

  return (
    <FormContext {...form}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FormGroup>
          <FormText color="secondary">*Required Fields</FormText>
        </FormGroup>
        <Row>
          <Col md sm="12">
            <FormItem
              label="First Name"
              maxLength={errorValue(MAX_LENGTH_MESSAGE, 50)}
              name="firstName"
              required="Please enter a first name"
              type="text"
            />
          </Col>
          <Col md sm="12">
            <FormItem
              label="Last Name"
              maxLength={errorValue(MAX_LENGTH_MESSAGE, 50)}
              name="lastName"
              required="Please enter a last name"
              type="text"
            />
          </Col>
        </Row>
        <Row>
          <Col md sm="12">
            <FormItem
              label="Email Address"
              maxLength={errorValue(MAX_LENGTH_MESSAGE, 254)}
              name="emailAddress"
              pattern={PATTERNS.EMAIL}
              required="Please enter an email"
              type="email"
            />
          </Col>
          <Col md sm="12">
            <FormItem
              protect
              label="Phone"
              mask={MASKS.PHONE}
              name="phoneNumber"
              pattern={/^\([0-9]{3}\) [0-9]{3}-[0-9]{4}/}
              required="Please enter your phone number"
              type="tel"
            />
          </Col>
        </Row>
        <Row>
          <Col md sm="12">
            <FormItem
              label="Access Code"
              mask={MASKS.ACCESS_CODE}
              name="accessCode"
              pattern={/^[A-Za-z]{2}[0-9]{4}-[0-9]{7}$/}
              placeholder="xxxxxx-xxxxxxx"
              required={accessCodeErrorMessage}
              type="text"
            />
          </Col>
          <Col md sm="12">
            <FormItem
              protect
              label="SSN (Last 4 Digits)"
              mask={MASKS.SSN}
              name="last4SSN"
              pattern={/^[0-9]{4}$/}
              required={ssnErrorMessage}
              type="social"
            />
          </Col>
        </Row>
        {appointment}
        <FormItem
          label={privacyConsentDisclaimer}
          name="privacyConsent"
          required="You must agree to these terms and conditions"
          type="checkbox"
        />
        <FormItem label={tcpaDisclaimer} name="tcpa" type="checkbox" />
        <FormGroup>
          <Button color="secondary" disabled={formSubmitDisabled}>
            {spinner}
          </Button>
        </FormGroup>
      </Form>
    </FormContext>
  )
}
