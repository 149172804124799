import {darken} from 'polished'
import React, {ReactNode} from 'react'
import styled from 'styled-components'
import {Button} from '~/bootstrap/components/button'
import {Col, Row} from '~/bootstrap/components/layout'
import {KenticoFixedImage, KenticoFixedImageData} from '../fixed-image'
import {KenticoLink, KenticoLinkData} from '../link'
import {KenticoRichText, KenticoRichTextData} from '../rich-text'
import {HeaderDropdown as BaseHeaderDropdown} from './dropdown'

interface Props {
  dropdownLinks: ReactNode
  ddscButton: ReactNode
  ddscImage: KenticoFixedImageData
  ddscLink: KenticoLinkData[]
  ddscText: KenticoRichTextData
}

const HeaderDropdown = styled(BaseHeaderDropdown)`
  && {
    padding: 0;
  }

  > .dropdown-menu {
    width: 100vw;
    max-width: 720px;
  }
`

const LoanLinks = styled(Col)`
  border-right: 1px solid ${({theme}) => darken(0.2, theme.colors.light)};

  && {
    padding: 0;
  }
`

/**
 * Render loan type dropdown from Kentico data.
 * @return React component
 */
export const LoanType = ({
  dropdownLinks,
  ddscButton,
  ddscImage,
  ddscLink,
  ddscText,
}: Props) => (
  <HeaderDropdown title="Loan Types">
    <Row>
      <LoanLinks>{dropdownLinks}</LoanLinks>
      <Col>
        <Row>
          <Col>
            <KenticoFixedImage image={ddscImage} />
          </Col>
          <Col>
            <KenticoRichText data={ddscText} />
          </Col>
        </Row>
        <Row>
          <Col>
            <KenticoLink data={ddscLink}>
              <Button block size="lg">
                {ddscButton}
              </Button>
            </KenticoLink>
          </Col>
        </Row>
      </Col>
    </Row>
  </HeaderDropdown>
)
