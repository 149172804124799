import parse, {HTMLReactParserOptions} from 'html-react-parser'
import React, {Fragment, ReactElement, useContext, useMemo} from 'react'
import {Helmet} from 'react-helmet'
import {CommonContext} from '~/common/context'
import {KenticoGlobalScriptData} from '~/kentico/components/global-script'
import {KenticoPageData} from '~/kentico/components/page'
import {KenticoPageLinkData} from '~/kentico/components/page-link'
import {KenticoSchema, KenticoSchemaData} from '~/kentico/components/schema'
import {KenticoSchemaBlogData} from '~/kentico/components/schema/blog'
import {createUrl, linkedItems} from '~/kentico/util'

type Replacer = () => HTMLReactParserOptions['replace']

interface Props {
  page: KenticoPageLinkData
  description: string
  replace: Replacer
  robots: KenticoPageData['elements']['robots']
  pageScripts: KenticoPageData['elements']['scripts']
  globalScripts: KenticoGlobalScriptData
  title: string
  schema?: KenticoSchemaData | KenticoSchemaData[] | KenticoSchemaBlogData
}

/**
 * Render SEO-specific metadata.
 * @return React component
 */
export const GatsbySEO = ({
  description,
  page,
  replace,
  robots,
  pageScripts,
  globalScripts,
  title,
  schema,
}: Props) => {
  const {language} = useContext(CommonContext)

  // Grab global and page scripts
  const scripts = useMemo(
    () => [
      ...linkedItems(globalScripts).map(
        script => script.elements.script.value,
      ),
      ...linkedItems(pageScripts).map(script => script.elements.script.value),
    ],
    [pageScripts, globalScripts],
  )

  // Split up the script elements based on what goes in helmet
  const [helmetChildren, nonHelmetChildren] = useMemo(() => {
    const [helmet, nonHelmet] = scripts.reduce(
      (childrens, script) => {
        const result = parse(script, {replace: replace()})
        if (typeof result === 'string') {
          return childrens
        }
        const elements = Array.isArray(result) ? result : [result]
        const scrStuff = elements.filter(e => e.type === 'script')
        const nonScrStuff = elements.filter(e => !scrStuff.includes(e))
        return [
          [...childrens[0], ...scrStuff],
          [...childrens[1], ...nonScrStuff],
        ]
      },
      [[], []] as ReactElement[][],
    )
    return [
      // No better key
      // eslint-disable-next-line react/no-array-index-key
      helmet.map((x, key) => <Fragment key={key}>{x}</Fragment>),
      // eslint-disable-next-line react/no-array-index-key
      nonHelmet.map((x, key) => <Fragment key={key}>{x}</Fragment>),
    ]
  }, [replace, scripts])

  let metaDescription
  if (description.length > 0) {
    metaDescription = <meta content={description} name="description" />
  }

  const robotValues = useMemo(() => [...robots.value.map(x => x.codename)], [
    robots,
  ])

  let metaRobots
  if (robotValues.length > 0) {
    metaRobots = <meta content={robotValues.join(',')} name="robots" />
  }

  const htmlAttributes = useMemo(
    () => ({
      lang: language,
    }),
    [language],
  )

  const schemaScript =
    schema === undefined ? undefined : <KenticoSchema data={schema} />

  const canonicalHref =
    page.elements.url.value === '/'
      ? createUrl(page, {canonical: true})
      : `${createUrl(page, {canonical: true})}`

  return (
    <>
      <Helmet htmlAttributes={htmlAttributes}>
        {helmetChildren}
        <title>{title}</title>
        {metaDescription}
        {metaRobots}
        <link href={canonicalHref} rel="canonical" />
      </Helmet>
      {schemaScript}
      {nonHelmetChildren}
    </>
  )
}
