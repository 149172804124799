import React, {ComponentType, ReactNode} from 'react'
import {KenticoPhoneLinkData} from './data'

/** Kentico type data. */
export {KenticoPhoneLinkData}

interface Props {
  link: KenticoPhoneLinkData
  component?: ComponentType | 'a'
  children?: ReactNode
  className?: string
}

/**
 * Render phone link from Kentico data.
 * @param props Component properties
 * @return React component
 */
export const KenticoPhoneLink = ({
  component: Component = 'a',
  link,
  children,
  className,
}: Props) => (
  <Component className={className} href={`tel:${link.elements.url.value}`}>
    {children}
  </Component>
)
