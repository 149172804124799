const STATES_LOOKUP: Record<string, number> = {
  ID: 161,
  IL: 100,
  MI: 235,
  NM: 50,
  TX: 142,
  UT: 161,
  WI: 115,
}

interface FormValues {
  state: string
}

interface Form {
  getValues(): FormValues
}

/**
 * Returns onChange function for Installment Loan calculator inputs.
 * @param form from input
 * @return input approval or !approval
 */
export const createOnChange = (form: Form) => (value: number) => {
  const {state} = form.getValues()
  if (state) {
    const stateMinLoan = STATES_LOOKUP[state]
    if (value >= stateMinLoan || isNaN(value)) {
      return undefined
    }
    return 'We do not offer loans based on the income you have entered.'
  } else {
    return 'Please select your state before proceeding.'
  }
}
