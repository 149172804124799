import React from 'react'
import {Helmet} from 'react-helmet'
import {useQuery} from '~/gatsby/util'
import {linkedItems} from '~/kentico/util'
import {KenticoSchemaCorporationData} from './data'

interface Props {
  data: KenticoSchemaCorporationData
}

/** Kentico type data. */
export {KenticoSchemaCorporationData}

/**
 * Render Kentico Schema Corporation script.
 * @return React component
 */
export const KenticoSchemaCorporation = ({data}: Props) => {
  const {siteUrl} = useQuery()
  const schema = {
    '@context': data.elements.context.value,
    '@type': data.elements.type.value,
    alternateName: data.elements.altName.value,
    description: data.elements.description.value,
    logo: data.elements.logo.value[0].file.url,
    name: data.elements.name.value,
    sameAs: linkedItems(data.elements.sameAs).map(
      item => item.elements.url.value,
    ),
    url: siteUrl,
  }
  return (
    <Helmet>
      <script type="application/ld+json">{JSON.stringify(schema)}</script>
    </Helmet>
  )
}
