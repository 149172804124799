import {darken} from 'polished'
import React from 'react'
import styled from 'styled-components'
import {Button as BootstrapButton} from '~/bootstrap/components/button'
import {Col, Container, Row} from '~/bootstrap/components/layout'
import {Customize} from '~/common/components/customize'
import {Link} from '~/common/components/link'
import {CURRENCIES} from '~/common/util'

interface Props {
  loanAmount: number
  paymentAmount: number
  aprAmount: number
  buttonText: string
  buttonLink: string
  finePrint: string
}

const MainCol = styled(Col)`
  font-weight: bold;
  border: 1px solid ${({theme}) => darken(0.2, theme.colors.light)};
  padding-top: ${({theme}) => theme.spacers[6]};
  padding-bottom: ${({theme}) => theme.spacers[6]};
`

const DisclaimerCol = styled(Col)`
  border: 1px solid ${({theme}) => darken(0.2, theme.colors.light)};
  padding-top: ${({theme}) => theme.spacers[4]};
  padding-bottom: ${({theme}) => theme.spacers[4]};
  margin-bottom: ${({theme}) => theme.spacers[4]};
`

const NewMexicoCol = styled(MainCol)`
  display: flex;
  align-items: center;
`

const LoanAmountText = styled.span`
  font-size: 6rem;

  @media (max-width: ${({theme}) => theme.breakpoints.max.xs}) {
    font-size: 3rem;
  }
`

const Button = styled(BootstrapButton)`
  margin-top: ${({theme}) => theme.spacers[4]};
`

/**
 * Render Calculators Results.
 * @return React component
 */
export const CalculatorsResults = ({
  aprAmount,
  buttonLink,
  buttonText,
  finePrint,
  loanAmount,
  paymentAmount,
}: Props) => {
  let howMuchWillItCost

  let cleanFinePrint = "";
  for (let i = 0; i < finePrint.length; i++) {
    if (finePrint.charCodeAt(i) <= 127) {
      cleanFinePrint += finePrint.charAt(i);
  }
  }
  if (paymentAmount > 0 || aprAmount > 0) {
    howMuchWillItCost = (
      <MainCol lg>
        <h2>How much will it cost?</h2>
        <Container>
          <Row>
            <Customize component={Col} textAlign="left">
              Payment Amount
            </Customize>
            <Customize component={Col} textAlign="right" textColor="tertiary">
              {CURRENCIES.PAYMENT.format(paymentAmount)}
            </Customize>
          </Row>
          <Row>
            <Customize component={Col} textAlign="left">
              APR
            </Customize>
            <Customize component={Col} textAlign="right" textColor="tertiary">
              {CURRENCIES.PERCENTAGE.format(aprAmount)}
            </Customize>
          </Row>
          <Button block tag={Link} to={buttonLink}>
            {buttonText}
          </Button>
        </Container>
      </MainCol>
    )
  } else {
    howMuchWillItCost = (
      <NewMexicoCol lg>
        <Container>
          <Button block tag={Link} to={buttonLink}>
            {buttonText}
          </Button>
        </Container>
      </NewMexicoCol>
    )
  }

  return (
    <Customize component={Container} textAlign="center">
      <Row>
        <MainCol lg>
          <h4>You could get up to*</h4>
          <Customize component={LoanAmountText} textColor="tertiary">
            {CURRENCIES.USD.format(loanAmount)}
          </Customize>
        </MainCol>
        {howMuchWillItCost}
      </Row>
      <Row>
        <DisclaimerCol>
          <small>{cleanFinePrint}</small>
        </DisclaimerCol>
      </Row>
    </Customize>
  )
}
