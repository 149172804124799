import {DomElement} from 'html-react-parser'
import * as IO from 'io-ts'
import React from 'react'
import {Helmet} from 'react-helmet'
import {Noop} from '~/common/components/noop'
import {KenticoFileLink, KenticoFileLinkData} from './components/file-link'
import {
  KenticoFluidImage,
  KenticoFluidImageFileData,
} from './components/fluid-image'
import {
  KenticoLinkedItem,
  KenticoLinkedItemData,
} from './components/linked-items'
import {
  KenticoRichTextLink,
  KenticoRichTextLinkData,
} from './components/rich-text-link'

interface Options {
  scrub?: boolean
}

type Item =
  | KenticoFileLinkData
  | KenticoFluidImageFileData
  | KenticoRichTextNode

interface KenticoRichTextNode {
  element:
    | IO.TypeOf<typeof KenticoLinkedItemData>
    | IO.TypeOf<typeof KenticoRichTextLinkData>
  linkId: string
}

/** Modified element type. */
export interface Element extends Omit<DomElement, 'attribs'> {
  attribs?: {
    [s: string]: string | undefined
  }
}

const KenticoLinkedItemNode = IO.type({
  element: KenticoLinkedItemData,
  linkId: IO.string,
})

const KenticoRichTextLinkNode = IO.type({
  element: KenticoRichTextLinkData,
  linkId: IO.string,
})

const getElementType = ({attribs = {}, name}: Element) => {
  const keys = Object.keys(attribs)
  let id
  if (keys.every(key => !key.startsWith('data-'))) {
    return {type: name === 'script' ? 'script' : 'html'} as const
  }

  // Special use case in Kentico for external link with new window option
  if (name === 'a' && keys.includes('data-new-window')) {
    return {type: 'html'} as const
  }

  id = attribs['data-item-id']
  if (id !== undefined) {
    return {id, type: 'link'} as const
  }

  id = attribs['data-image-id']
  if (id !== undefined) {
    return {id, type: 'image'} as const
  }

  id = attribs['data-codename']
  if (id !== undefined) {
    return {id, type: 'linked-item'} as const
  }

  id = attribs['data-asset-id']
  if (id !== undefined) {
    return {id, type: 'asset'} as const
  }

  return {type: 'unknown'} as const
}

/**
 * Construct linked item builder.
 * @param items Linked items to replace
 * @return React node replacer
 */
export const createReplace = (
  items: Item[] = [],
  {scrub = false}: Options = {},
) => {
  // Massage data to take advantage of type inference
  const files = items.filter(KenticoFileLinkData.is)
  const images = items.filter(KenticoFluidImageFileData.is)
  const links = items.filter(KenticoRichTextLinkNode.is)
  const linkedItems = items.filter(KenticoLinkedItemNode.is)

  // Render regular HTML
  const renderHtml = (element: DomElement) =>
    scrub || element.name === 'br' ? <Noop /> : undefined

  // Render script(s)
  // eslint-disable-next-line react/no-multi-comp
  const renderScript = (element: DomElement) => {
    const data =
      element.children && element.children.length > 0
        ? element.children[0].data
        : undefined
    const src = element.attribs ? element.attribs.src : undefined
    const isAsync = element.attribs?.async !== undefined

    let children
    if (isAsync === true && typeof src === 'string') {
      children = (
        <script key={data} async={isAsync} src={src}>
          {data}
        </script>
      )
    } else {
      children =
        typeof src === 'string' ? (
          <script key={data} src={src}>
            {data}
          </script>
        ) : (
          <script key={data}>{data}</script>
        )
    }
    return <Helmet>{children}</Helmet>
  }

  return (element: DomElement) => {
    let error
    try {
      const elementType = getElementType(element)
      switch (elementType.type) {
        case 'html':
          return renderHtml(element)
        case 'script':
          return renderScript(element)
        case 'link':
          return (
            <KenticoRichTextLink
              element={element}
              id={elementType.id}
              links={links}
              scrub={scrub}
            />
          )
        case 'image':
          return (
            <KenticoFluidImage
              element={element}
              id={elementType.id}
              images={images}
              scrub={scrub}
            />
          )
        case 'asset':
          return (
            <KenticoFileLink
              element={element}
              files={files}
              id={elementType.id}
              scrub={scrub}
            />
          )
        case 'linked-item':
          return (
            <KenticoLinkedItem
              id={elementType.id}
              linkedItems={linkedItems}
              scrub={scrub}
            />
          )
        default:
          throw new Error('Unexpected item to render')
      }
    } catch (e) {
      error = e
    }

    // Throw exception unless in preview mode (as data may be invalid)
    if (process.env.KENTICO_PREVIEW_ENABLED === 'true') {
      // TODO Perhaps add a toast notification for users?
      console.warn(error)
      return undefined
    }
    throw error
  }
}
