import {ContactContext} from '~/contact/context'
import {ContactForm} from '~/contact/types'
import {flatten as flatUtil} from 'flat'
import {DeepPartial} from 'react-hook-form'


/** Form data structure. */
export interface FormData {
  firstName: string
  lastName: string
  dateOfBirth: {
    month: string
    day: string
    year: string
  }
  email: string
  phone: string
  contactPreference?: string
  state?: string
  existingCustomer?: string
  comments?: string
}

interface Options {
  reset: (values?: DeepPartial<FormData> | undefined) => void
  contact: ContactContext
  setSuccess(value: boolean): void;

}

/**
 * Create on submit handler.
 * @return Submit handler
 */
export const createOnSubmit = ({contact, setSuccess, reset}: Options) => async (
  formData: FormData,
) => {
  let data: ContactForm = {
    comments: formData.comments,
    dateOfBirth: new Date(
      Number(formData.dateOfBirth.year),
      Number(formData.dateOfBirth.month) - 1,
      Number(formData.dateOfBirth.day),
    ),
    email: formData.email,
    name: {
      first: formData.firstName,
      last: formData.lastName,
    },
    phone: formData.phone,
    state: formData.state,
  }

  switch (formData.contactPreference) {
    case 'EMAIL':
    case 'PHONE':
      data = {
        ...data,
        preference: formData.contactPreference,
      }
      break
    default:
      break
  }

  switch (formData.existingCustomer) {
    case 'true':
      data = {...data, existingCustomer: true}
      break
    case 'false':
      data = {...data, existingCustomer: false}
      break
    default:
      break
  }

  const wnd = window as any;
  if ('appInsights' in wnd) {
      wnd.appInsights.trackEvent('Cashstore:ContactUsForm', flatUtil.flatten(data))
  }

  const result = await contact.submit(data)
  if(result === true){
    reset()
  }

  setSuccess(result)
}
