import React, {useCallback, useContext, useMemo} from 'react'
import styled from 'styled-components'
import {FormGroup, Input, Label} from '~/bootstrap/components/form'
import {VehiclesContext} from '../../context'
import {createOnChange} from './change'

const SelectInput = styled(Input)`
  &&,
  &&:disabled {
    background-color: ${({theme}) => theme.colors.light};
  }
`

/**
 * Render vechiles model input.
 * @return React component
 */
export const VehiclesModel = () => {
  const {selected, ready, models} = useContext(VehiclesContext)

  const options = useMemo(
    () =>
      models.map(model => (
        <option key={model} value={model}>
          {model}
        </option>
      )),
    [models],
  )

  const onChange = useCallback(createOnChange(selected), [selected])

  return (
    <FormGroup>
      <small>
        <Label>Model*</Label>
      </small>
      <SelectInput
        required
        aria-label="Vehicle Model"
        disabled={!ready}
        name="model"
        type="select"
        value={selected.model ?? ''}
        onChange={onChange}
      >
        <option disabled value="">
          Model
        </option>
        {options}
      </SelectInput>
    </FormGroup>
  )
}
