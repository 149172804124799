import {graphql} from 'gatsby'
import * as IO from 'io-ts'

/** Youtube video. */
export const KenticoYoutubeVideoData = IO.type(
  {
    elements: IO.type({
          title:IO.type({
            value: IO.string
          }),
          id:IO.type({
            value: IO.string
          }),
    }),
    system: IO.type({
      id: IO.string,
    }),
  },
  'KenticoYoutubeVideo',
)

/** Data Type. */
export interface KenticoYoutubeVideoData
  extends IO.TypeOf<typeof KenticoYoutubeVideoData> {}

/** Fragment Helper. */
export const fragment = graphql`
  fragment KenticoYoutubeVideo on Node {
    ... on kontent_item_youtube_video {
      elements {
        id {
          value
        }
        title {
          value
        }
      }
      system {
        id
      }
    }
  }
`
