import {format} from 'date-fns'
import * as IO from 'io-ts'
import {useCallback, useContext, useMemo, useState} from 'react'
import {CommonContext} from '~/common/context'
import { isMultipleError } from '~/kentico/util'
import {getOptimizelyVariations} from '~/optimizely/util'
import {Store} from '~/stores/types'
import {StoreResponse, parseStoreResponse} from '~/stores/types/api'
import {PrequalTitleForm} from '../types'

type TitleStatus = TitleDataState['status']

type TitleDataState =
  | TitleDataNewCustomer
  | TitleDataApproved
  | TitleDataApprovedNoTitle
  | TitleDataTitleOnly
  | TitleDataDeclined
  | TitleDataTempDeclined
  | TitleDataError
  | TitleDataMultipleError

interface TitleDataBase {
  type: 'TITLE'
}

interface TitleDataNewCustomer {
  status: 'NEW_CUSTOMER'
  name: string
}

interface TitleDataApproved {
  status: 'APPROVED'
  name: string
  amount: number
  existingCustomerIndicator: boolean
  stores: Store[]
  titleLoanAmount: number
  titleLoanApproved: true
}

interface TitleDataApprovedNoTitle {
  status: 'APPROVED'
  name: string
  amount: number
  existingCustomerIndicator: boolean
  stores: Store[]
  titleLoanApproved: false
}

interface TitleDataTitleOnly {
  status: 'TITLE_ONLY'
  name: string
  stores: Store[]
  titleLoanAmount: number
  titleLoanApproved: true
}

interface TitleDataDeclined {
  status: 'DECLINED'
  name: string
}

interface TitleDataTempDeclined {
  status: 'TEMP_DECLINED'
  name: string
}

interface TitleDataError {
  status: 'ERROR'
}

interface TitleDataMultipleError {
  status: 'MULTIPLE_ERROR'
}

const PrequalResponse = IO.type({
  existingCustomerIndicator: IO.boolean,
  firstName: IO.string,
  loanEstimate: IO.union([
    IO.type({
      result: IO.type({
        loanAmount: IO.number,
      }),
      success: IO.literal(true),
    }),
    IO.type({
      success: IO.literal(false),
    }),
  ]),
  offer: IO.type({
    offerAmount: IO.number,
  }),
  prequalStatus: IO.keyof({
    Approved: undefined,
    Declined: undefined,
    Error: undefined,
  }),
  stores: IO.array(StoreResponse),
})

/** Prequal title API. */
export type TitleAPI = TitleBase

/** Prequal title API base. */
export interface TitleBase {
  ready: boolean
  error?: Error
  submit(form: PrequalTitleForm): Promise<TitleStatus | undefined>
}

/** Prequal title data. */
export type TitleData = TitleDataBase & TitleDataState

/**
 * Construct prequal title API.
 * @param setData Callback to update data
 * @return Prequal title API
 */
export const usePrequalTitle = (setData: (data: TitleData) => void) => {
  const {api} = useContext(CommonContext)
  const [ready, setReady] = useState(true)
  const [error, setError] = useState<Error>()

  const submit = useCallback(
    // eslint-disable-next-line sonarjs/cognitive-complexity
    async (form: PrequalTitleForm): Promise<TitleStatus | undefined> => {
      if (!ready) {
        return undefined
      }
      const dateOfBirth = format(form.dateOfBirth, 'yyyy-MM-dd')
      const optimizelyVariations = getOptimizelyVariations()
      setReady(false)
      setError(undefined)
      let response
      try {
        response = await api({
          data: {
            captcha: form.captcha,
            TCPAApproval: form.tcpa,
            addressLat: form.lat,
            addressLng: form.lng,
            city: form.address.city,
            dateOfBirth,
            emailAddress: form.email,
            firstName: form.name.first,
            last4SSN: form.ssn,
            lastName: form.name.last,
            optimizelyVariations,
            phoneNumber: form.phone,
            selectedBodyStyle: form.vehicle.style,
            selectedMake: form.vehicle.make,
            selectedModel: form.vehicle.model,
            selectedSeries: form.vehicle.series,
            selectedState: form.address.state,
            selectedYear: form.vehicle.year,
            state: form.address.state,
            streetAddress1: form.address.street1,
            streetAddress2: form.address.street2,
            termsAndConditionsAgreed: form.privacyConsent,
            zipCode: form.address.zip,
          },
          method: 'POST',
          type: PrequalResponse,
          url: 'v1/forms/prequal-title-loan-submit',
        })
      } catch (e) {
        if (process.env.LOG_API_ERRORS === 'true') {
          console.error(e, form)
        }
        setError(new Error('Unable to complete request'))
        setReady(true)
        return 'ERROR'
      }

      setReady(true)

      let {stores, prequalStatus} = response
      if (form.address.state === 'IL') {
        stores = stores.filter(obj => obj.state !== 'IL')
        if (stores.length < 1) {
          prequalStatus = 'Declined'
        }
      }

      switch (prequalStatus) {
        case 'Approved':
          if (response.loanEstimate.success) {
            setData({
              amount: response.offer.offerAmount,
              existingCustomerIndicator: response.existingCustomerIndicator,
              name: response.firstName,
              status: 'APPROVED',
              stores: stores.map(parseStoreResponse),
              titleLoanAmount: response.loanEstimate.result.loanAmount,
              titleLoanApproved: true,
              type: 'TITLE',
            })
          } else {
            setData({
              amount: response.offer.offerAmount,
              existingCustomerIndicator: response.existingCustomerIndicator,
              name: response.firstName,
              status: 'APPROVED',
              stores: stores.map(parseStoreResponse),
              titleLoanApproved: false,
              type: 'TITLE',
            })
          }
          return 'APPROVED'
        case 'Declined': {
          if (form.address.state.toUpperCase() === 'IL') {
            setData({
              name: response.firstName,
              status: 'TEMP_DECLINED',
              type: 'TITLE',
            })

            return 'TEMP_DECLINED'
          } else if (response.loanEstimate.success && stores.length > 0) {
            setData({
              name: response.firstName,
              status: 'TITLE_ONLY',
              stores: response.stores.map(parseStoreResponse),
              titleLoanAmount: response.loanEstimate.result.loanAmount,
              titleLoanApproved: true,
              type: 'TITLE',
            })
            return 'APPROVED'
          }
          setData({
            name: response.firstName,
            status: 'DECLINED',
            titleLoanAmount: 0,
            titleLoanApproved: response.loanEstimate.success,
            type: 'TITLE',
          })
          return 'DECLINED'
        }
        case 'Error':
          if(isMultipleError()){
            setData({status: 'MULTIPLE_ERROR', type: 'TITLE'})
            return 'MULTIPLE_ERROR'
          }
          setData({status: 'ERROR', type: 'TITLE'})
          return 'ERROR'
        default:
          return undefined
      }
    },
    [api, ready, setData],
  )

  return useMemo<TitleAPI>(
    () => ({
      error,
      ready,
      submit,
    }),
    [error, ready, submit],
  )
}
