import {graphql} from 'gatsby'
import * as IO from 'io-ts'

export const Placeholder = IO.type( {
  fallback: IO.string,
});

export const KenticoGatsbyImageData = IO.type({
  gatsbyImageData: 
    IO.type({
      layout: IO.string,
      backgroundColor: IO.union([IO.string, IO.undefined]),
      images: IO.type({
        fallback: IO.type({
          src: IO.string,
          srcSet: IO.string,
          sizes: IO.string,
        }),
        sources: IO.array(IO.type({
          srcSet: IO.string,
          sizes: IO.string,
          type: IO.string,
        })),
      }),       
      width: IO.number,
      height: IO.number,
      placeholder: IO.union([Placeholder, IO.undefined]),
    })          
})

/** Fluid image file. */
export const KenticoFluidImageFileData = IO.intersection(
  [
    IO.type({
      file: IO.type({
        childImageSharp: KenticoGatsbyImageData,
        id: IO.string,
      }) as any,
    }),
    IO.partial({
      id: IO.string,
      url: IO.string,
      description: IO.union([IO.string, IO.null]),
    }),
  ],
  'KenticoFluidImageFile',
)

/** Fluid image. */
export const KenticoFluidImageData = IO.intersection(
  [
    IO.type({
      file: IO.type({
        childImageSharp: KenticoGatsbyImageData,        
        id: IO.string,
      }) as any,
    }),
    IO.partial({
      id: IO.string,
      url: IO.string,
      description: IO.union([IO.string, IO.null]),
    }),
  ],
  'KenticoFluidImage',
)

/** Data type. */
export interface KenticoFluidImageFileData
  extends IO.TypeOf<typeof KenticoFluidImageFileData> {}

/** Data type. */
export interface KenticoFluidImageData
  extends IO.TypeOf<typeof KenticoFluidImageData> {}

export interface KenticoGatsbyImageData
  extends IO.TypeOf<typeof KenticoGatsbyImageData> {}

export interface Placeholder
  extends IO.TypeOf<typeof Placeholder> {}

/** Fragment helper. */
export const fragment = graphql`
  fragment KenticoFluidImage on kontent_item_asset_element {
    description
    file {
      ...KenticoFluidImageFile
    }
  }

  fragment KenticoFluidImageFile on File {
    id
    url: publicURL
    childImageSharp {
      gatsbyImageData(
          layout: CONSTRAINED
          placeholder: BLURRED
        )
    }
  }
`
