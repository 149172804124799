import {graphql} from 'gatsby'
import * as IO from 'io-ts'
import {LinkedItems} from '../../util'
import {KenticoContentData} from '../content'
import {KenticoImageLinkData} from '../image-link'
import {KenticoPageLinkData} from '../page-link'
import {KenticoRichTextData} from '../rich-text'
import {KenticoPageScriptData} from '../page-script'

/** Legal Data. */
export const KenticoLegalData = IO.intersection(
  [
    KenticoContentData,
    IO.type({
      elements: IO.type({
        copyright: IO.type({
          value: IO.string,
        }),
        imageLinks: LinkedItems(KenticoImageLinkData),
        links: LinkedItems(KenticoPageLinkData),
        pdfLinks: KenticoRichTextData,
        scripts: LinkedItems(KenticoPageScriptData),
      }),
      system: IO.type({
        type: IO.literal('legal'),
      }),
    }),
  ],
  'KenticoLegal',
)

/** Data Type. */
export interface KenticoLegalData extends IO.TypeOf<typeof KenticoLegalData> {}

/** Fragment Helper. */
export const fragment = graphql`
  fragment KenticoLegal on Node {
    ... on kontent_item_legal {
      elements {
        copyright {
          value
        }
        imageLinks: image_links {
          nodes: value {
            ...KenticoImageLink
            ... on kontent_item_image_link {
              elements {
                image {
                  value {
                    file {
                      childImageSharp {
                        gatsbyImageData(layout: FIXED, height: 32)
                      }
                    }
                  }
                }
              }
            }
          }
        }
        links {
          nodes: value {
            ...KenticoPageLink
          }
        }
        pdfLinks: pdf_links {
          ...KenticoRichText
        }
        scripts: scripts {
          nodes: value {
            ...KenticoPageScript
          }
        }
      }
      system {
        id
        codename
        name
        type
      }
    }
  }
`
