import {format} from 'date-fns'
import * as IO from 'io-ts'
import {useCallback, useContext, useMemo, useState} from 'react'
import {CommonContext} from '~/common/context'
import { isMultipleError } from '~/kentico/util'
import {getOptimizelyVariations} from '~/optimizely/util'
import {Store} from '~/stores/types'
import {StoreResponse, parseStoreResponse} from '~/stores/types/api'
import {PrequalNonTitleForm} from '../types'

type NonTitleType = 'INSTALLMENT' | 'CASH_ADVANCE'

type NonTitleStatus = NonTitleDataState['status']

type NonTitleDataState =
  | NonTitleDataApproved
  | NonTitleDataNewCustomer
  | NonTitleDataDeclined
  | NonTitleDataError
  | NonTitleDataMultipleError
  | NonTitleDataTempDeclined

interface NonTitleDataBase {
  type: NonTitleType
}

interface NonTitleDataApproved {
  status: 'APPROVED'
  name: string
  amount: number
  stores: Store[]
  existingCustomerIndicator: boolean
}

interface NonTitleDataNewCustomer {
  status: 'NEW_CUSTOMER'
  name: string
}

interface NonTitleDataDeclined {
  status: 'DECLINED'
  name: string
}

interface NonTitleDataTempDeclined {
  status: 'TEMP_DECLINED'
  name: string
}

interface NonTitleDataError {
  status: 'ERROR'
}

interface NonTitleDataMultipleError {
  status: 'MULTIPLE_ERROR'
}

const PrequalResponse = IO.type({
  existingCustomerIndicator: IO.boolean,
  firstName: IO.string,
  offer: IO.type({
    offerAmount: IO.number,
  }),
  prequalStatus: IO.keyof({
    Approved: undefined,
    Declined: undefined,
    Error: undefined,
  }),
  stores: IO.array(StoreResponse),
})

/** Prequal non title API. */
export type NonTitleAPI = NonTitleBase

/** Prequal non-title API base. */
export interface NonTitleBase {
  ready: boolean
  error?: Error
  submit(form: PrequalNonTitleForm): Promise<NonTitleStatus | undefined>
}

/** Prequal non-title data. */
export type NonTitleData = NonTitleDataBase & NonTitleDataState

/**
 * Construct prequal non title API.
 * @param type Prequal type
 * @param setData Callback to update data
 * @return Prequal non title API
 */
export const usePrequalNonTitle = (
  type: NonTitleType,
  setData: (data: NonTitleData) => void,
) => {
  const {api} = useContext(CommonContext)
  const [ready, setReady] = useState(true)
  const [error, setError] = useState<Error>()

  const submit = useCallback(
    async (form: PrequalNonTitleForm): Promise<NonTitleStatus | undefined> => {
      if (!ready) {
        return undefined
      }
      const dateOfBirth = format(form.dateOfBirth, 'yyyy-MM-dd')
      const optimizelyVariations = getOptimizelyVariations()
      setReady(false)
      setError(undefined)
      let response
      try {
        response = await api({
          data: {
            TCPAApproval: form.tcpa,
            addressLat: form.lat,
            addressLng: form.lng,
            city: form.address.city,
            dateOfBirth,
            emailAddress: form.email,
            firstName: form.name.first,
            last4SSN: form.ssn,
            lastName: form.name.last,
            optimizelyVariations,
            phoneNumber: form.phone,
            state: form.address.state,
            streetAddress1: form.address.street1,
            streetAddress2: form.address.street2,
            termsAndConditionsAgreed: form.privacyConsent,
            zipCode: form.address.zip,
            captcha: form.captcha
          },
          method: 'POST',
          type: PrequalResponse,
          url:
            type === 'INSTALLMENT'
              ? 'v1/forms/prequal-submit'
              : 'v1/forms/prequal-cash-advance-submit',
        })
      } catch (e) {
        if (process.env.LOG_API_ERRORS === 'true') {
          console.error(e, form)
        }
        setError(new Error('Unable to complete request'))
        setReady(true)
          if(isMultipleError()){
            return 'MULTIPLE_ERROR'
          }
        return 'ERROR'
      }
      setReady(true)

      let {stores, prequalStatus} = response
      if (form.address.state === 'IL') {
        stores = stores.filter(obj => obj.state !== 'IL')
        if (stores.length < 1) {
          prequalStatus = 'Declined'
        }
      }

      // Check status and return APPROVED, DECLINED, or ERROR
      switch (prequalStatus) {
        case 'Approved':
          setData({
            amount: response.offer.offerAmount,
            existingCustomerIndicator: response.existingCustomerIndicator,
            name: response.firstName,
            status: 'APPROVED',
            stores: stores.map(parseStoreResponse),
            type,
          })
          return 'APPROVED'
        case 'Declined': {
          const declinedStatus =
            form.address.state.toUpperCase() === 'IL'
              ? 'TEMP_DECLINED'
              : 'DECLINED'

          setData({
            name: response.firstName,
            status: declinedStatus,
            type,
          })
          return declinedStatus
        }
        case 'Error':
          setData({status: 'ERROR', type})
          if(isMultipleError()){
            setData({status: 'MULTIPLE_ERROR', type})
            return 'MULTIPLE_ERROR'
          }
        return 'ERROR'
        default:
          return undefined
      }
    },
    [api, ready, setData, type],
  )

  return useMemo<NonTitleAPI>(
    () => ({
      error,
      ready,
      submit,
    }),
    [error, ready, submit],
  )
}
