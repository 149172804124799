import {noop} from 'lodash'
import {createContext} from 'react'
import {Review} from '../types'

/** Reviews information. */
export interface ReviewsContext {
  ready: boolean
  reviews: Review[]
  average: number
  request(): void
}

/** Default context. */
export const DEFAULT_CONTEXT: ReviewsContext = {
  average: 0,
  ready: false,
  request: noop,
  reviews: [],
}

/** Context implementation. */
export const ReviewsContext = createContext(DEFAULT_CONTEXT)

// eslint-disable-next-line functional/immutable-data
ReviewsContext.displayName = 'Reviews'
