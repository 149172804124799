import {UUID} from 'io-ts-types/lib/UUID'
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import {StoresContext} from '~/stores/context'
import {linkedItems} from '../../util'
import {KenticoPreviewBodyLink} from '../preview-link'
import {KenticoLoanOptionsData} from './data'
import {createEffect, createItems, createSetStateHandler} from './factories'
import {LoanOptions, LoanOptionsFooter, LoanOptionsHeader} from './template'
import {buildUrl} from './util'

interface Props {
  data: KenticoLoanOptionsData
}

/** Kentico type data. */
export {KenticoLoanOptionsData}

/**
 * Render loan options using Kentico data.
 * @return React component
 */
export const KenticoLoanOptions = ({
  data: {
    system: {id, name},
    elements,
  },
}: Props) => {
  const {location} = useContext(StoresContext)
  const [state, setStateBase] = useState<string | undefined>()

  const states = useMemo(
    () => linkedItems(elements.items).map(i => i.elements.state.value),
    [elements.items],
  )

  const setState = useCallback(
    createSetStateHandler({
      setStateBase,
      states,
    }),
    [setStateBase, states],
  )

  useEffect(
    createEffect({
      location,
      setState,
    }),
    [location.ready, setState],
  )

  const header = useMemo<LoanOptionsHeader>(
    () => ({
      notFound: elements.locationNotFound.value,
      title: elements.headerTitle.value,
    }),
    [elements],
  )
  const footer = useMemo<LoanOptionsFooter>(
    () => ({
      button: {
        text: elements.footerButtonText.value,
        url: buildUrl(linkedItems(elements.footerButtonLink)),
      },
      title: elements.footerTitle.value,
    }),
    [elements],
  )

  const items = useMemo(() => createItems({elements, state}), [
    elements,
    state,
  ])
  let editLink
  if (!UUID.is(name)) {
    editLink = <KenticoPreviewBodyLink contentItem={id} />
  }
  return (
    <LoanOptions
      dynamic={states.length > 0}
      editLink={editLink}
      footer={footer}
      header={header}
      items={items}
      state={state}
      states={states}
      onChange={setState}
    />
  )
}
