import useInterval from '@use-it/interval'
import {format, formatDistanceToNow} from 'date-fns'
import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import {
  Card,
  CardBody,
  CardText as BootstrapCardText,
} from '~/bootstrap/components/card'
import {Stars as StarsBase} from '~/common/components/stars'
import {Review} from '../../types'

interface Props {
  review: Review
}

const StarsContainer = styled.div`
  font-weight: 500;
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  padding-top: ${({theme}) => theme.spacers[2]};
  padding-bottom: ${({theme}) => theme.spacers[4]};
`

const Stars = styled(StarsBase)`
  font-size: 1.4rem;
  transform: translateY(-1px);
  padding-right: ${({theme}) => theme.spacers[1]};
`

const UserSummary = styled.div`
  display: flex;
  align-items: center;
`

const ReviewInfo = styled.div`
  flex: 1;
  padding-left: ${({theme}) => theme.spacers[3]};

  &,
  > * {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`

const ReviewerName = styled.h4`
  padding-bottom: 3px;
`

const AVATAR_SIZE = 70

const UserAvatar = styled.img`
  width: ${AVATAR_SIZE}px;
`

const REVIEW_HEIGHT = 8

// TODO Remove -webkit once relevant items are standardized
const CardText = styled(BootstrapCardText)`
  height: ${({theme}) =>
    REVIEW_HEIGHT *
    theme.text.lineHeight *
    parseFloat(theme.text.fontSize.base)}rem;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: ${REVIEW_HEIGHT};
  -webkit-box-orient: vertical;
`

/**
 * Render reviews card.
 * @return React component
 */
export const ReviewsCard = ({review}: Props) => {
  const formattedTimestamp = format(review.timestamp, 'MMM. d, y')
  const [reviewDate, setReviewDate] = useState(formattedTimestamp)

  // After initial render, show date relative
  const updateDate = () => {
    setReviewDate(formatDistanceToNow(review.timestamp))
  }
  useEffect(updateDate, [])
  useInterval(updateDate, 60000)

  return (
    <Card>
      <CardBody>
        <UserSummary>
          <UserAvatar
            alt={review.name}
            height={AVATAR_SIZE}
            loading="lazy"
            src={`${review.image}-w${AVATAR_SIZE * 2}-h${AVATAR_SIZE * 2}`}
            width={AVATAR_SIZE}
          />
          <ReviewInfo>
            <ReviewerName>{review.name}</ReviewerName>
            <span>{reviewDate}</span>
          </ReviewInfo>
        </UserSummary>
        <StarsContainer>
          <Stars value={review.rating} />
          {review.rating} of 5 stars
        </StarsContainer>
        <CardText>{review.comment}</CardText>
      </CardBody>
    </Card>
  )
}
