import * as IO from 'io-ts'
import React, {ComponentType, ReactElement} from 'react'
import styled from 'styled-components'
import {KenticoAccessCode, KenticoAccessCodeData} from '../access-code'
import {KenticoAccordion, KenticoAccordionData} from '../accordion'
import {
  KenticoApprovedBanner,
  KenticoApprovedBannerData,
} from '../approved-banner'
import {KenticoBulletedList, KenticoBulletedListData} from '../bulleted-list'
import {
  KenticoButtonWithImage,
  KenticoButtonWithImageData,
} from '../button-with-image'
import {
  KenticoCampaignLanding,
  KenticoCampaignLandingData,
} from '../campaign-landing'
import {KenticoCards, KenticoCardsData} from '../cards'
import {KenticoChecklist, KenticoChecklistData} from '../checklist'
import {KenticoCityMap, KenticoCityMapData} from '../city-map'
import {
  KenticoContactUsForm,
  KenticoContactUsFormData,
} from '../contact-us-form'
import {KenticoErrorBody, KenticoErrorBodyData} from '../error-body'
import {KenticoFooter, KenticoFooterData} from '../footer'
import {KenticoForm, KenticoFormData} from '../form'
import {KenticoFormDeclined, KenticoFormDeclinedData} from '../form-declined'
import {
  KenticoFullSizeImage,
  KenticoFullSizeImageData,
} from '../full-size-image'
import {KenticoHeader, KenticoHeaderData} from '../header'
import {
  KenticoHeroBanner as KenticoHeroBannerBase,
  KenticoHeroBannerData,
} from '../hero-banner'
import {KenticoHowItWorks, KenticoHowItWorksData} from '../how-it-works'
import {
  KenticoInstallmentLoanCalculator,
  KenticoInstallmentLoanCalculatorData,
} from '../installment-loan-calculator'
import {KenticoLegal, KenticoLegalData} from '../legal'
import {KenticoLinkList, KenticoLinkListData} from '../link-list'
import {KenticoLinksColumn, KenticoLinksColumnData} from '../links-column'
import {
  KenticoListWithImages,
  KenticoListWithImagesData,
} from '../list-with-images'
import {
  KenticoListWithImagesHorizontal,
  KenticoListWithImagesHorizontalData,
} from '../list-with-images-horizontal'
import {KenticoLoanOptions, KenticoLoanOptionsData} from '../loan-options'
import {
  KenticoLocationSearch,
  KenticoLocationSearchData,
} from '../location-search'
import {KenticoNeedMoreCash, KenticoNeedMoreCashData} from '../need-more-cash'
import {
  KenticoPageMenuSwitcher,
  KenticoPageMenuSwitcherData,
} from '../page-menu-switcher'
import {KenticoPageSwitcher, KenticoPageSwitcherData} from '../page-switcher'
import {
  KenticoProductDetails,
  KenticoProductDetailsData,
} from '../product-details'
import {KenticoReferralForm, KenticoReferralFormData} from '../referral-form'
import {KenticoReviews, KenticoReviewsData} from '../reviews'
import {KenticoSecuritySeal, KenticoSecuritySealData} from '../security-seal'
import {KenticoSpecialOffer, KenticoSpecialOfferData} from '../special-offer'
import {
  KenticoSpecialOfferRebate,
  KenticoSpecialOfferRebateData,
} from '../special-offer-rebate'
import {
  KenticoStaticChecklist,
  KenticoStaticChecklistData,
} from '../static-checklist'
import {
  KenticoStoreLocation,
  KenticoStoreLocationData,
} from '../store-location'
import {KenticoStoreLocator, KenticoStoreLocatorData} from '../store-locator'
import {KenticoTable, KenticoTableData} from '../table'
import {KenticoText as KenticoTextBase, KenticoTextData} from '../text'
import {
  KenticoTextWithButtonLink,
  KenticoTextWithButtonLinkData,
} from '../text-with-button-link'
import {
  KenticoTextWithImage,
  KenticoTextWithImageData,
} from '../text-with-image'
import {
  KenticoTitleLoanCalculator,
  KenticoTitleLoanCalculatorData,
} from '../title-loan-calculator'
import {KenticoBodyItemBaseData} from './data'
import {
  KenticoListWithVideos,
  KenticoListWithVideosData,
} from '../list-with-videos'

const KenticoText = styled(KenticoTextBase)`
  padding-top: ${({theme}) => theme.spacers[5]};
  padding-bottom: ${({theme}) => theme.spacers[5]};
`

// Height of hero banner
const HERO_HEIGHT = 651

const KenticoHeroBanner = styled(KenticoHeroBannerBase)`
  min-height: ${HERO_HEIGHT * 0.4}px;
  padding-top: ${({theme}) => theme.spacers[4]};
  padding-bottom: ${({theme}) => theme.spacers[4]};

  @media (min-width: ${({theme}) => theme.breakpoints.min.sm}) {
    min-height: ${HERO_HEIGHT * 0.55}px;
  }

  @media (min-width: ${({theme}) => theme.breakpoints.min.md}) {
    min-height: ${HERO_HEIGHT * 0.7}px;
  }

  @media (min-width: ${({theme}) => theme.breakpoints.min.lg}) {
    min-height: ${HERO_HEIGHT * 0.85}px;
  }

  @media (min-width: ${({theme}) => theme.breakpoints.min.xl}) {
    min-height: ${HERO_HEIGHT}px;
  }
`

type Codename = KenticoBodyItemBaseData['system']['type']

type Renderer = (data: KenticoBodyItemBaseData) => ReactElement

interface RenderOptions<A> {
  component: ComponentType<{data: A}>
  type: IO.Type<A>
}

const createRender = <A extends object>({
  component: Component,
  type,
}: // eslint-disable-next-line react/display-name
RenderOptions<A>): Renderer => data => {
  if (!type.is(data)) {
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    throw new Error(`Invalid data for body item: ${data.system.type}`)
  }
  return <Component data={data} />
}

/** Renderers for body item base items. */
export const RENDERERS: Record<Codename, Renderer | undefined> = {
  access_code: createRender({
    component: KenticoAccessCode,
    type: KenticoAccessCodeData,
  }),
  accordion: createRender({
    component: KenticoAccordion,
    type: KenticoAccordionData,
  }),
  approved_banner: createRender({
    component: KenticoApprovedBanner,
    type: KenticoApprovedBannerData,
  }),
  bulleted_list: createRender({
    component: KenticoBulletedList,
    type: KenticoBulletedListData,
  }),
  button_with_image: createRender({
    component: KenticoButtonWithImage,
    type: KenticoButtonWithImageData,
  }),
  campaign_landing: createRender({
    component: KenticoCampaignLanding,
    type: KenticoCampaignLandingData,
  }),
  cards: createRender({
    component: KenticoCards,
    type: KenticoCardsData,
  }),
  checklist: createRender({
    component: KenticoChecklist,
    type: KenticoChecklistData,
  }),
  city_map: createRender({
    component: KenticoCityMap,
    type: KenticoCityMapData,
  }),
  contact_us_form: createRender({
    component: KenticoContactUsForm,
    type: KenticoContactUsFormData,
  }),
  error_body: createRender({
    component: KenticoErrorBody,
    type: KenticoErrorBodyData,
  }),
  footer: createRender({
    component: KenticoFooter,
    type: KenticoFooterData,
  }),
  form: createRender({
    component: KenticoForm,
    type: KenticoFormData,
  }),
  form_declined: createRender({
    component: KenticoFormDeclined,
    type: KenticoFormDeclinedData,
  }),
  full_size_image: createRender({
    component: KenticoFullSizeImage,
    type: KenticoFullSizeImageData,
  }),
  header: createRender({
    component: KenticoHeader,
    type: KenticoHeaderData,
  }),
  hero_banner: createRender({
    component: KenticoHeroBanner as typeof KenticoHeroBannerBase,
    type: KenticoHeroBannerData,
  }),
  how_it_works: createRender({
    component: KenticoHowItWorks,
    type: KenticoHowItWorksData,
  }),
  installment_loan_calculator: createRender({
    component: KenticoInstallmentLoanCalculator,
    type: KenticoInstallmentLoanCalculatorData,
  }),
  legal: createRender({
    component: KenticoLegal,
    type: KenticoLegalData,
  }),
  link_list: createRender({
    component: KenticoLinkList,
    type: KenticoLinkListData,
  }),
  links_column: createRender({
    component: KenticoLinksColumn,
    type: KenticoLinksColumnData,
  }),
  list_with_images: createRender({
    component: KenticoListWithImages,
    type: KenticoListWithImagesData,
  }),
  list_with_images_horizontal: createRender({
    component: KenticoListWithImagesHorizontal,
    type: KenticoListWithImagesHorizontalData,
  }),
  list_with_videos: createRender({
    component: KenticoListWithVideos,
    type: KenticoListWithVideosData,
  }),
  loan_options: createRender({
    component: KenticoLoanOptions,
    type: KenticoLoanOptionsData,
  }),
  location_search: createRender({
    component: KenticoLocationSearch,
    type: KenticoLocationSearchData,
  }),
  need_more_cash: createRender({
    component: KenticoNeedMoreCash,
    type: KenticoNeedMoreCashData,
  }),
  page_menu_switcher: createRender({
    component: KenticoPageMenuSwitcher,
    type: KenticoPageMenuSwitcherData,
  }),
  page_switcher: createRender({
    component: KenticoPageSwitcher,
    type: KenticoPageSwitcherData,
  }),
  prequal_store_location: createRender({
    component: KenticoStoreLocation,
    type: KenticoStoreLocationData,
  }),
  product_details: createRender({
    component: KenticoProductDetails,
    type: KenticoProductDetailsData,
  }),
  referral_form: createRender({
    component: KenticoReferralForm,
    type: KenticoReferralFormData,
  }),
  reviews: createRender({
    component: KenticoReviews,
    type: KenticoReviewsData,
  }),
  security_seal: createRender({
    component: KenticoSecuritySeal,
    type: KenticoSecuritySealData,
  }),
  special_offer: createRender({
    component: KenticoSpecialOffer,
    type: KenticoSpecialOfferData,
  }),
  special_offer_rebate: createRender({
    component: KenticoSpecialOfferRebate,
    type: KenticoSpecialOfferRebateData,
  }),
  static_checklist: createRender({
    component: KenticoStaticChecklist,
    type: KenticoStaticChecklistData,
  }),
  store_locator: createRender({
    component: KenticoStoreLocator,
    type: KenticoStoreLocatorData,
  }),
  table: createRender({
    component: KenticoTable,
    type: KenticoTableData,
  }),
  text: createRender({
    component: KenticoText,
    type: KenticoTextData,
  }),
  text_with_button_link: createRender({
    component: KenticoTextWithButtonLink,
    type: KenticoTextWithButtonLinkData,
  }),
  text_with_image: createRender({
    component: KenticoTextWithImage,
    type: KenticoTextWithImageData,
  }),
  title_loan_calculator: createRender({
    component: KenticoTitleLoanCalculator,
    type: KenticoTitleLoanCalculatorData,
  }),
}
