import {LoadScript} from '@react-google-maps/api'
import React, {ReactNode} from 'react'

interface Props {
  children: ReactNode
}

/**
 * Ensure Google API is loaded.
 * @return React component
 */
export const GoogleLoader = ({children}: Props) => (
  <LoadScript
    googleMapsApiKey={process.env.GOOGLE_MAPS_KEY as string}
    libraries={['places']}
    region="US"
  >
    {children}
  </LoadScript>
)
