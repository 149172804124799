import * as IO from 'io-ts'
import {BooleanFromString} from 'io-ts-types/lib/BooleanFromString'
import {UUID} from 'io-ts-types/lib/UUID'
import React, {useCallback, useContext} from 'react'
import {FormContext, useForm} from 'react-hook-form'
import styled from 'styled-components'
import {
  Col,
  Container as BootstrapContainer,
  Row,
} from '~/bootstrap/components/layout'
import {Noop} from '~/common/components/noop'
import {Spinner as SpinnerBase} from '~/common/components/spinner'
import {useURLQuery} from '~/common/util'
import {useQuery} from '~/gatsby/util'
import {GooglePlaces} from '~/google/components/places'
import {StoresList} from '~/stores/components/list'
import {StoresMap} from '~/stores/components/map'
import {StoresContext} from '~/stores/context'
import {linkedItems} from '../../util'
import {KenticoPreviewBodyLink} from '../preview-link'
import {KenticoLocationSearchData} from './data'
import {createOnChange} from './factories'
import {NoLocationResults} from './no-results'

interface Props {
  data: KenticoLocationSearchData
}

/** Kentico type data. */
export {KenticoLocationSearchData}

const Container = styled(BootstrapContainer)`
  padding-bottom: ${({theme}) => theme.spacers[5]};
`

const Spinner = styled(SpinnerBase)`
  width: 56px;
  height: 56px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  && > * {
    border-width: 5px;
  }
`

const StoresContainer = styled.div`
  @media (min-width: ${({theme}) => theme.breakpoints.min.lg}) {
    height: 400px;
    overflow-x: hidden;
    overflow-y: auto;
  }
`

const TitleText = styled(Col)`
  padding-top: ${({theme}) => theme.spacers[4]};
  padding-bottom: ${({theme}) => theme.spacers[4]};
`

const ResultsRow = styled(Row)`
  @media (max-width: ${({theme}) => theme.breakpoints.max.md}) {
    flex-direction: column-reverse;
  }
`

const SearchCol = styled(Col)`
  @media (max-width: ${({theme}) => theme.breakpoints.max.md}) {
    padding-top: ${({theme}) => theme.spacers[4]};
  }
`

const Query = IO.type({
  showStoreNumbers: BooleanFromString,
})

/**
 * Render Location Search from Kentico data.
 * @return React component
 */
export const KenticoLocationSearch = ({data}: Props) => {
  const {search} = useContext(StoresContext)
  const form = useForm<FormData>({mode: 'onChange'})
  const {mapMarker} = useQuery()
  const spinner = search.ready ? <Noop /> : <Spinner />
  const query = useURLQuery(Query)

  let editLink
  if (!UUID.is(data.system.name)) {
    editLink = <KenticoPreviewBodyLink contentItem={data.system.id} />
  }

  let stores
  if (search.stores && search.stores.length > 0) {
    stores = (
      <StoresList
        showStoreNumbers={query?.showStoreNumbers}
        stores={search.stores}
      />
    )
  }

  let noResults
  if ((!search.stores || search.stores.length === 0) && search.ready) {
    noResults = (
      <NoLocationResults
        errorMessage={search.stores ? data.elements.errorMessage.value : ''}
        states={linkedItems(data.elements.states)}
      />
    )
  }

  const onChange = useCallback(createOnChange(search), [search])

  return (
    <FormContext {...form}>
      <Container>
        {editLink}
        <Row>
          <TitleText>
            <h3>{data.elements.header.value}</h3>
          </TitleText>
        </Row>
        <ResultsRow>
          <Col lg="6">
            <Row>
              <SearchCol>
                <GooglePlaces
                  required
                  disabled={!search.ready}
                  name="places"
                  placeholder="Enter City, State, or Zip Code"
                  onChange={onChange}
                />
              </SearchCol>
            </Row>
            <StoresContainer>
              {noResults}
              {stores}
              {spinner}
            </StoresContainer>
          </Col>
          <Col lg="6">
            <StoresMap
              icon={mapMarker}
              showStoreNumbers={query?.showStoreNumbers}
              stores={search.stores}
            />
          </Col>
        </ResultsRow>
      </Container>
    </FormContext>
  )
}
