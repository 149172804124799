import {graphql} from 'gatsby'
import * as IO from 'io-ts'
import {KenticoContentData} from '../content'
import {KenticoFluidImageData} from '../fluid-image'

/** Approved Banner Data. */
export const KenticoApprovedBannerData = IO.intersection(
  [
    KenticoContentData,
    IO.type({
      elements: IO.type({
        image: IO.type({
          value: IO.array(KenticoFluidImageData),
        }),
      }),
      system: IO.type({
        type: IO.literal('approved_banner'),
      }),
    }),
  ],
  'KenticoApprovedBanner',
)

/** Data Type. */
export interface KenticoApprovedBannerData
  extends IO.TypeOf<typeof KenticoApprovedBannerData> {}

/** Fragment Helper. */
export const fragment = graphql`
  fragment KenticoApprovedBanner on Node {
    ... on kontent_item_approved_banner {
      elements {
        image {
          value {
            ...KenticoFluidImage
          }
        }
      }
      system {
        id
        codename
        name
        type
      }
    }
  }
`
