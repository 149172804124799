import React from 'react'
import styled from 'styled-components'
import {Col, ColProps} from '~/bootstrap/components/layout'

interface Props extends Omit<ColProps, 'ref'> {
  side: 'left' | 'right'
}

const ImageColBase = ({side: _, ...props}: Props) => <Col {...props} />

/**
 * ImageCol styled with gradient.
 * @return React component
 */
export const ImageCol = styled(ImageColBase)`
  @media (min-width: ${({theme}) => theme.breakpoints.min.lg}) {
    ${({side}) => side}: 0;
    top: 0;
    bottom: 0;
    display: flex;

    && {
      position: absolute;
      width: 50vw;
    }
  }

  @media (max-width: ${({theme}) => theme.breakpoints.max.md}) {
    display: none;
  }
`
