import * as IO from 'io-ts'

/** Fixed size image. */
export const KenticoFixedImageData = IO.intersection(
  [
    IO.type({
      file: IO.type({
        childImageSharp: IO.type({
          gatsbyImageData:
            IO.type({
              layout: IO.string,
              backgroundColor: IO.string,
              images: IO.type({
                fallback: IO.type({
                  src: IO.string,
                  srcSet: IO.string,
                  sizes: IO.string,
                }),
                sources: IO.array(IO.type({
                  srcSet: IO.string,
                  sizes: IO.string,
                  type: IO.string,
                })) as any,  // ~SMB-TODO: Probably needs attention still
              }),
              width: IO.number,
              height: IO.number,
            })
        }),
      }),
    }),
    IO.partial({
      id: IO.string,
      url: IO.string,
      description: IO.union([IO.string, IO.null]),
    }),
  ],
  'KenticoFixedImage',
)

/** Data type. */
export interface KenticoFixedImageData
  extends IO.TypeOf<typeof KenticoFixedImageData> {}
