import React, {ElementType} from 'react'
import styled from 'styled-components'
import {
  Col,
  Container as BootstrapContainer,
  Row,
} from '~/bootstrap/components/layout'
import {linkedItems} from '../../util'
import {Column} from './column'
import {KenticoTwoColumnLayoutData} from './data'

interface Props {
  data: KenticoTwoColumnLayoutData
}

const Container = styled(BootstrapContainer)`
  && {
    padding-top: 0;
    padding-bottom: 0;
  }
`

const RightFirstRow = styled(Row)`
  @media (max-width: ${({theme}) => theme.breakpoints.max.md}) {
    flex-direction: column-reverse;
  }
`

const LeftCol = styled(Col)`
  && {
    padding-right: ${({theme}) => theme.spacers[4]};

    @media (min-width: ${({theme}) => theme.breakpoints.min.sm}) {
      padding-right: 15px;
    }
  }
`

/** Kentico type data. */
export {KenticoTwoColumnLayoutData}

/**
 * Render two column layout from Kentico data.
 * @param row Two column layout content type data
 * @return React component
 */
export const KenticoTwoColumnLayout = ({
  data: {
    elements: {column1, column2, options, size},
  },
}: Props) => {
  let width1 = 6
  let width2 = 6
  if (size.value.length > 0) {
    switch (size.value[0].codename) {
      case 'n3_1':
        width1 = 9
        width2 = 3
        break
      case 'n2_1':
        width1 = 8
        width2 = 4
        break
      case 'n1_2':
        width1 = 4
        width2 = 8
        break
      case 'n1_3':
        width1 = 3
        width2 = 9
        break
      case 'n1_1':
      default:
        break
    }
  }

  const isRightFirst = options.value.some(
    ({codename}) => (codename as string) === 'right_first',
  )

  const RenderedRow: ElementType = isRightFirst ? RightFirstRow : Row

  return (
    <Container>
      <RenderedRow noGutters>
        <LeftCol lg={width1} md="12">
          <div>
            <Column data={linkedItems(column1)} />
          </div>
        </LeftCol>
        <Col lg={width2} md="12">
          <div>
            <Column data={linkedItems(column2)} />
          </div>
        </Col>
      </RenderedRow>
    </Container>
  )
}
