import * as IO from 'io-ts'
import {useCallback, useContext, useMemo, useState} from 'react'
import {CommonContext} from '~/common/context'
import {Review} from '../types'
import {ReviewsContext} from '.'

const ReviewsResponse = IO.type({
  overallAverageStars: IO.number,
  reviews: IO.array(
    IO.type({
      comment: IO.string,
      reviewer: IO.type({
        displayName: IO.string,
        profilePhotoUrl: IO.string,
      }),
      starRating: IO.number,
      updateTime: IO.string,
    }),
  ),
})

/**
 * Construct default reviews context.
 * @return Reviews context
 */
export const useReviews = () => {
  const {api} = useContext(CommonContext)
  const [calling, setCalling] = useState(false)
  const [ready, setReady] = useState(false)
  const [reviews, setReviews] = useState<Review[]>([])
  const [average, setAverage] = useState(0)

  const request = useCallback(async () => {
    if (calling || ready) {
      return
    }
    setCalling(true)
    try {
      const response = await api({
        data: {
          count: '10',
          showOnlyApproved: 'true',
          stars: '45',
        },
        method: 'GET',
        type: ReviewsResponse,
        url: 'v1/google/reviews',
      })
      setReviews(
        response.reviews.map(review => ({
          comment: review.comment,
          image: review.reviewer.profilePhotoUrl,
          name: review.reviewer.displayName,
          rating: review.starRating,
          timestamp: new Date(review.updateTime),
        })),
      )
      setAverage(response.overallAverageStars)
      setReady(true)
      return
    } finally {
      setCalling(true)
    }
  }, [calling, ready, api])

  return useMemo<ReviewsContext>(
    () => ({
      average,
      ready,
      request,
      reviews,
    }),
    [average, ready, request, reviews],
  )
}
