import * as IO from 'io-ts'
import {UUID} from 'io-ts-types/lib/UUID'
import React from 'react'
import styled from 'styled-components'
import {
  Col,
  Container as BootstrapContainer,
  Row as BootstrapRow,
} from '~/bootstrap/components/layout'
import {Customize} from '~/common/components/customize'
import {linkedItems} from '../../util'
import {KenticoBodyItemBase} from '../body-item-base'
import {
  KenticoFullSizeImage,
  KenticoFullSizeImageData,
} from '../full-size-image'
import {KenticoHeroBannerData} from '../hero-banner'
import {KenticoPreviewBodyLink} from '../preview-link'
import {Background} from './background'
import {KenticoFullSizeRowData} from './data'
import {HeroBannerColumn} from './hero-banner'
import {ImageCol} from './image-col'

interface Props {
  data: KenticoFullSizeRowData
}

const Container = styled(BootstrapContainer)`
  position: static;
  min-height: 300px;

  && {
    padding: 0;

    @media (max-width: ${({theme}) => theme.breakpoints.max.md}) {
      max-width: 100%;
    }
  }
`

const Row = styled(BootstrapRow)`
  min-height: inherit;
`

const Column = IO.union([KenticoFullSizeImageData, KenticoHeroBannerData])

const calculateWidth = (
  size: KenticoFullSizeRowData['elements']['size'],
  columns: KenticoFullSizeRowData['elements']['columns'],
) => {
  const [column1, column2] = linkedItems(columns)
  let width1 = 6
  let width2 = 6
  if (size.value.length > 0 && ![column1, column2].some(Column.is)) {
    switch (size.value[0].codename) {
      case 'n3_1':
        width1 = 9
        width2 = 3
        break
      case 'n2_1':
        width1 = 8
        width2 = 4
        break
      case 'n1_2':
        width1 = 4
        width2 = 8
        break
      case 'n1_3':
        width1 = 3
        width2 = 9
        break
      case 'n1_1':
      default:
        break
    }
  }
  return [width1, width2] as const
}

/** Kentico type data. */
export {KenticoFullSizeRowData}

/**
 * Render row from Kentico data.
 * @return React component
 */
export const KenticoFullSizeRow = ({
  data: {
    elements: {columns, size},
    system: {id, name},
  },
}: Props) => {
  const [column1, column2] = linkedItems(columns)
  const [width1, width2] = calculateWidth(size, columns)

  const color1 =
    'backgroundColor' in column1.elements
      ? column1.elements.backgroundColor.value[0].codename
      : undefined
  const color2 =
    'backgroundColor' in column2.elements
      ? column2.elements.backgroundColor.value[0].codename
      : undefined

  let editLink
  if (!UUID.is(name)) {
    editLink = <KenticoPreviewBodyLink contentItem={id} />
  }

  let child1
  if (KenticoHeroBannerData.is(column1)) {
    child1 = <HeroBannerColumn data={column1} side="left" />
  } else if (KenticoFullSizeImageData.is(column1)) {
    child1 = (
      <>
        <ImageCol lg={width1} md="12" side="left">
          <KenticoFullSizeImage data={column1} />
        </ImageCol>
        <Col lg={width1} md="0" />
      </>
    )
  } else {
    child1 = (
      <Customize background={color1} component={Col} lg={width1} md="12">
        <KenticoBodyItemBase data={column1} />
      </Customize>
    )
  }

  let child2
  if (KenticoHeroBannerData.is(column2)) {
    child2 = <HeroBannerColumn data={column2} side="right" />
  } else if (KenticoFullSizeImageData.is(column2)) {
    child2 = (
      <ImageCol lg={width2} md="12" side="right">
        <KenticoFullSizeImage data={column2} />
      </ImageCol>
    )
  } else {
    child2 = (
      <Customize background={color2} component={Col} lg={width2} md="12">
        <KenticoBodyItemBase data={column2} />
      </Customize>
    )
  }

  return (
    <Background breakpoint={width1 / 12} color1={color1} color2={color2}>
      <Container>
        <Row noGutters>
          {child1}
          {child2}
        </Row>
        {editLink}
      </Container>
    </Background>
  )
}
