import React, {useEffect, useState} from 'react'
import {FaCookieBite} from 'react-icons/fa'
import styled from 'styled-components'
import {Noop} from '../noop'

const Error = styled.div`
  background-color: ${({theme}) => theme.colors.red};
  color: ${({theme}) => theme.colors.light};
  text-align: center;
`

const ErrorIcon = styled(FaCookieBite)`
  margin-right: ${({theme}) => theme.spacers[2]};
`

const ErrorText = styled.span`
  display: block;
  padding: ${({theme}) => theme.spacers[3]};
`

/**
 * Render Cookie Error.
 * @return React component
 */
export const CookieError = () => {
  const [cookie, setCookie] = useState(<Noop />)
  useEffect(() => {
    if (!navigator.cookieEnabled) {
      setCookie(
        <Error>
          <ErrorText>
            <ErrorIcon size={22} />
            Oops! It looks like you have cookies disabled. Please enable
            cookies for a better user experience.
          </ErrorText>
        </Error>,
      )
    }
  }, [cookie, setCookie])
  return <>{cookie}</>
}
