import * as IO from 'io-ts'
import {isEmpty, noop} from 'lodash'
import {useContext, useMemo, useState} from 'react'
import {CommonContext} from '~/common/context'
import {useEffectAsync} from '~/common/util'
import {useVehiclesSelected} from './selected'
import {VehiclesContext} from '.'

const OLDEST_YEAR = 1981
const NEWEST_YEAR = new Date().getFullYear() + 1
const YEARS = Array.from({length: NEWEST_YEAR - OLDEST_YEAR + 1}).map(
  (_, index) => NEWEST_YEAR - index,
)

const MakesResponse = IO.type({
  makes: IO.array(IO.string),
})

const ModelsResponse = IO.type({
  models: IO.array(IO.string),
})

const SeriesResponse = IO.type({
  series: IO.array(IO.string),
})

const StylesResponse = IO.type({
  bodyStyles: IO.array(IO.string),
})

/**
 * Construct default vehicles context.
 * @return Vehicles context
 */
export const useVehicles = () => {
  const {api} = useContext(CommonContext)
  const [error, setError] = useState<Error>()
  const [ready, setReady] = useState(true)
  const [makes, setMakes] = useState<string[]>([])
  const [models, setModels] = useState<string[]>([])
  const [seriesList, setSeries] = useState<string[]>([])
  const [styles, setStyles] = useState<string[]>([])
  const selected = useVehiclesSelected({
    makes,
    models,
    seriesList,
    styles,
    years: YEARS,
  })

  useEffectAsync(async () => {
    if (!ready) {
      return
    }
    setReady(false)
    try {
      if (selected.series !== undefined) {
        let response
        try {
          response = await api({
            method: 'GET',
            type: StylesResponse,
            url: [
              'v1/bb/vehicles/bodyStyles',
              selected.year,
              selected.make,
              selected.model,
              /*
                  There are use cases where the series has a '/' and the
                  backend thinks it is part of the route so we change them to a
                  '|' and then replace that character back to a '/' in the
                  backend.
              */
              selected.series.replace('/', '|'),
            ].join('/'),
          })
        } catch (e) {
          selected.setModel(selected.model)
          throw e
        }
        setStyles(response.bodyStyles)
      } else if (selected.model !== undefined) {
        let response
        try {
          response = await api({
            method: 'GET',
            type: SeriesResponse,
            url: [
              'v1/bb/vehicles/series',
              selected.year,
              selected.make,
              selected.model,
            ].join('/'),
          })
        } catch (e) {
          selected.setMake(selected.make)
          throw e
        }
        setSeries(response.series)
        setStyles([])
      } else if (selected.make !== undefined) {
        let response
        try {
          response = await api({
            method: 'GET',
            type: ModelsResponse,
            url: `v1/bb/vehicles/models/${selected.year}/${selected.make}`,
          })
        } catch (e) {
          selected.setYear(selected.year)
          throw e
        }
        setModels(response.models)
        setSeries([])
        setStyles([])
      } else if (selected.year !== undefined) {
        let response
        try {
          response = await api({
            method: 'GET',
            type: MakesResponse,
            url: `v1/bb/vehicles/makes/${selected.year}`,
          })
        } catch (e) {
          selected.reset()
          throw e
        }
        setMakes(response.makes)
        setModels([])
        setSeries([])
        setStyles([])
      } else if (![makes, models, seriesList, styles].every(isEmpty)) {
        setMakes([])
        setModels([])
        setSeries([])
        setStyles([])
      }
      setError(undefined)
    } catch {
      setError(new Error('Failed to update'))
    } finally {
      setReady(true)
    }
  }, [selected])

  return useMemo<VehiclesContext>(
    () => ({
      error,
      makes,
      models,
      ready,
      selected: ready
        ? selected
        : {
            ...selected,
            setMake: noop,
            setModel: noop,
            setSeries: noop,
            setStyle: noop,
            setYear: noop,
          },
      series: seriesList,
      styles,
      years: YEARS,
    }),
    [error, makes, models, ready, seriesList, styles, selected],
  )
}
