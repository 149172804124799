import {UUID} from 'io-ts-types/lib/UUID'
import {darken} from 'polished'
import React from 'react'
import styled from 'styled-components'
import {Container, Row as BootstrapRow} from '~/bootstrap/components/layout'
import {Customize} from '~/common/components/customize'
import {linkedItems} from '../../util'
import {KenticoPreviewBodyLink} from '../preview-link'
import {KenticoPageSwitcherData} from './data'
import {Option, SHADOW_SIZE} from './option'

interface Props {
  data: KenticoPageSwitcherData
}

/** Kentico type data. */
export {KenticoPageSwitcherData}

const OptionsContainer = styled(Container)`
  @media (max-width: ${({theme}) => theme.breakpoints.max.md}) {
    padding: 0;
    border-bottom: 1px solid ${({theme}) => darken(0.2, theme.colors.light)};
  }
`

const TITLE_SIZE = 200

const Wrapper = styled.div`
  position: relative;
  height: ${TITLE_SIZE}px;
  display: flex;
  align-items: center;

  @media (min-width: ${({theme}) => theme.breakpoints.min.lg}) {
    height: ${TITLE_SIZE + SHADOW_SIZE}px;
    padding-bottom: ${SHADOW_SIZE}px;
  }
`

const Row = styled(BootstrapRow)`
  justify-content: center;
  margin-right: 0;
  margin-left: 0;

  @media (min-width: ${({theme}) => theme.breakpoints.min.lg}) {
    transform: translateY(-${SHADOW_SIZE}px);
  }
`

/**
 * Render page switcher from Kentico data.
 * @return React component
 */
export const KenticoPageSwitcher = ({
  data: {
    elements: {title, items},
    system: {id, name},
  },
}: Props) => {
  // Render preview link
  let editLink
  if (!UUID.is(name)) {
    editLink = <KenticoPreviewBodyLink contentItem={id} />
  }

  const options = linkedItems(items).map(({elements}, i, {length}) => (
    <Option
      // No better key
      // eslint-disable-next-line react/no-array-index-key
      key={i}
      image={elements.image.value}
      link={linkedItems(elements.link)}
      md={length === 3 ? '3' : '5'}
      title={elements.title.value}
      xs={length === 3 ? '4' : '6'}
    />
  ))

  return (
    <>
      <Customize
        background="secondary"
        component={Wrapper}
        textAlign="center"
        textColor="light"
      >
        {editLink}
        <Container>
          <h1>{title.value}</h1>
        </Container>
      </Customize>
      <OptionsContainer>
        <Row>{options}</Row>
      </OptionsContainer>
    </>
  )
}
