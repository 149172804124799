import * as IO from 'io-ts'
import {DateFromISOString} from 'io-ts-types/lib/DateFromISOString'

const StoreHours = IO.type({
  close: DateFromISOString,
  open: DateFromISOString,
})

/** Store data in storage. */
export const StoreStorage = IO.intersection([
  IO.type({
    address: IO.string,
    bilingual: IO.boolean,
    city: IO.string,
    hours: IO.partial({
      friday: StoreHours,
      monday: StoreHours,
      saturday: StoreHours,
      sunday: StoreHours,
      thursday: StoreHours,
      tuesday: StoreHours,
      wednesday: StoreHours,
    }),
    loans: IO.type({
      cashAdvance: IO.boolean,
      installment: IO.boolean,
      title: IO.boolean,
    }),
    location: IO.type({
      lat: IO.number,
      lng: IO.number,
    }),
    number: IO.string,
    phone: IO.string,
    state: IO.string,
    zip: IO.string,
  }),
  IO.partial({
    gmbUrl: IO.string,
    id: IO.string,
    url: IO.string,
  }),
])
