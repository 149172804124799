import {UUID} from 'io-ts-types/lib/UUID'
import React, {useCallback} from 'react'
import {FormContext, useForm} from 'react-hook-form'
import styled from 'styled-components'
import {
  Col,
  Container as BootstrapContainer,
  Row as BootstrapRow,
} from '~/bootstrap/components/layout'
import {NavLink as BootstrapNavLink} from '~/bootstrap/components/nav'
import {Customize} from '~/common/components/customize'
import {Link} from '~/common/components/link'
import {GooglePlaces} from '~/google/components/places'
import {createUrl, getLinkText, linkedItems} from '../../util'
import {KenticoPreviewBodyLink} from '../preview-link'
import {KenticoStoreLocatorData} from './data'
import {createOnChange} from './factories'

interface Props {
  data: KenticoStoreLocatorData
}

/** Kentico type data. */
export {KenticoStoreLocatorData}

const NavLink = styled(BootstrapNavLink)`
  color: ${({theme}) => theme.colors.light};
  text-decoration: underline;
  width: max-content;

  &&:hover,
  &&:focus {
    color: ${({theme}) => theme.colors.light};
    text-decoration: underline;
  }
`

const Container = styled(BootstrapContainer)`
  && {
    padding-top: ${({theme}) => theme.spacers[7]};
    padding-bottom: ${({theme}) => theme.spacers[7]};

    @media (max-width: ${({theme}) => theme.breakpoints.max.sm}) {
      padding-top: ${({theme}) => theme.spacers[2]};
      padding-bottom: ${({theme}) => theme.spacers[2]};
    }
  }
`

const HeaderText = styled.h2`
  @media (max-width: ${({theme}) => theme.breakpoints.max.sm}) {
    padding-top: ${({theme}) => theme.spacers[4]};
    padding-bottom: ${({theme}) => theme.spacers[4]};
    text-align: center;
  }
`

const FooterText = styled.h4`
  @media (max-width: ${({theme}) => theme.breakpoints.max.sm}) {
    padding-top: ${({theme}) => theme.spacers[4]};
    padding-bottom: ${({theme}) => theme.spacers[2]};
    text-align: center;
  }
`

const Row = styled(BootstrapRow)`
  padding-left: ${({theme}) => theme.spacers[7]};
  padding-right: ${({theme}) => theme.spacers[7]};

  @media (max-width: ${({theme}) => theme.breakpoints.max.sm}) {
    padding-left: initial;
    padding-right: initial;
  }
`

/**
 * Render store locator from Kentico data.
 * @return React component
 */
export const KenticoStoreLocator = ({
  data: {
    elements: {backgroundColor, footerText, headerText, link, locations},
    system: {id, name},
  },
}: Props) => {
  const form = useForm<FormData>({mode: 'onChange'})
  let editLink
  if (!UUID.is(name)) {
    editLink = <KenticoPreviewBodyLink contentItem={id} />
  }

  const onChange = useCallback(createOnChange(linkedItems(link)), [link])

  const links = linkedItems(locations).map(state => (
    <NavLink key={state.system.id} tag={Link} to={createUrl(state)}>
      {getLinkText(state)}
    </NavLink>
  ))

  const leftColumn = links.slice(0, 4)
  const rightColumn = links.slice(4, 8)

  return (
    <Customize
      background={backgroundColor.value[0].codename}
      component={Container}
    >
      {editLink}
      <Row>
        <Customize component={Col} textColor="light">
          <HeaderText>{headerText.value}</HeaderText>
          <FormContext {...form}>
            <GooglePlaces
              required
              name="places"
              placeholder="Enter City, State, or Zip Code"
              onChange={onChange}
            />
          </FormContext>
          <FooterText>{footerText.value}</FooterText>
        </Customize>
      </Row>
      <Row>
        <Col xs>{leftColumn}</Col>
        <Col xs>{rightColumn}</Col>
      </Row>
    </Customize>
  )
}
