import * as IO from 'io-ts'
import {useCallback, useContext, useMemo, useState} from 'react'
import {CommonContext} from '~/common/context'
import {InstallmentCalculatorForm} from '../types'

const Response = IO.type({
  result: IO.type({
    apr: IO.number,
    finePrint: IO.string,
    loanAmount: IO.number,
    payment: IO.number,
  }),
})

/** Installment calculator data. */
export interface InstallmentCalculatorData {
  amount: number
  apr: number
  payment: number
  finePrint: string
}

/** Prequal non title API. */
export interface InstallmentCalculatorAPI {
  ready: boolean
  error?: Error
  submit(
    form: InstallmentCalculatorForm,
  ): Promise<InstallmentCalculatorData | undefined>
}

/**
 * Construct installment calculator API.
 * @return Installment calculator API
 */
export const useCalculatorsInstallment = () => {
  const {api} = useContext(CommonContext)
  const [ready, setReady] = useState(true)
  const [error, setError] = useState<Error>()

  const submit = useCallback(
    async (
      form: InstallmentCalculatorForm,
    ): Promise<InstallmentCalculatorData | undefined> => {
      if (!ready) {
        return undefined
      }
      setReady(false)
      setError(undefined)
      let response
      try {
        response = await api({
          data: {
            income: form.income,
            selectedState: form.state,
          },
          method: 'POST',
          type: Response,
          url: 'v1/forms/loan-calculator-submit',
        })
      } catch {
        setError(new Error('Unable to complete request'))
        setReady(true)
        return undefined
      }
      setReady(true)
      return {
        amount: response.result.loanAmount,
        apr: response.result.apr / 100,
        finePrint: response.result.finePrint,
        payment: response.result.payment,
      }
    },
    [api, ready],
  )

  return useMemo<InstallmentCalculatorAPI>(
    () => ({
      error,
      ready,
      submit,
    }),
    [error, ready, submit],
  )
}
