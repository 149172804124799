import {graphql} from 'gatsby'
import * as IO from 'io-ts'
import {KenticoPageLinkData} from '../page-link'
import {KenticoPhoneLinkData} from '../phone-link'

/** Data type. */
export type KenticoRichTextLinkData =
  | KenticoPageLinkData
  | KenticoPhoneLinkData

/** Rich Text Link. */
export const KenticoRichTextLinkData = IO.union(
  [KenticoPageLinkData, KenticoPhoneLinkData],
  'KenticoRichTextLink',
)

/** Fragment helper. */
export const fragment = graphql`
  fragment KenticoRichTextLink on Node {
    ...KenticoPageLink
    ...KenticoPhoneLink
  }
`
