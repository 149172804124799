import React from 'react'
import {FaLinkedin, FaPinterestP, FaTwitter, FaYoutube} from 'react-icons/fa'
import styled from 'styled-components'
import {Customize} from '~/common/components/customize'

const IconPadding = styled.div`
  display: inline-block;
  text-align: center;
  width: 40px;
  height: 40px;
  padding: ${({theme}) => theme.spacers[1]};
  margin: ${({theme}) => theme.spacers[1]};
  border-radius: 50%;
  background-color: ${({theme}) => theme.colors.secondary};
  cursor: pointer;
`

const ConnectWithUs = styled.div`
  padding-bottom: ${({theme}) => theme.spacers[2]};
`

/**
 * Render Social media buttons.
 * @return React component
 */
export const SocialMedia = () => {
  const socialMediums = [
    // Because we have dealt with this before, facebook may come back.
    // In case it does, uncomment this and push it up.
    // {
    //   icon: FaFacebookF,
    //   name: 'Facebook',
    //   url: 'https://facebook.com/cashstore',
    // },
    {
      icon: FaTwitter,
      name: 'Twitter',
      url: 'https://twitter.com/cashstore',
    },
    {
      icon: FaLinkedin,
      name: 'LinkedIn',
      url: 'https://www.linkedin.com/company/cash-store',
    },
    {
      icon: FaPinterestP,
      name: 'Pinterest',
      url: 'https://www.pinterest.com/cashstore/',
    },
    {
      icon: FaYoutube,
      name: 'YouTube',
      url: 'https://www.youtube.com/user/cashstore',
    },
  ].map(m => (
    <a
      key={m.name}
      aria-label={`Visit our ${m.name}`}
      href={m.url}
      rel="noopener noreferrer"
      target="_blank"
    >
      <IconPadding>
        <Customize component={m.icon} textColor="light" />
      </IconPadding>
    </a>
  ))
  return (
    <>
      <ConnectWithUs>Connect With Us</ConnectWithUs>
      {socialMediums}
    </>
  )
}
