import React, {useCallback, useContext, useMemo} from 'react'
import styled from 'styled-components'
import {FormGroup, Input, Label} from '~/bootstrap/components/form'
import {VehiclesContext} from '../../context'
import {createOnChange} from './change'

const SelectInput = styled(Input)`
  &&,
  &&:disabled {
    background-color: ${({theme}) => theme.colors.light};
  }
`

/**
 * Render vehicles series input.
 * @return React component
 */
export const VehiclesSeries = () => {
  const {selected, ready, series} = useContext(VehiclesContext)

  const options = useMemo(
    () =>
      series.map(currentSeries => (
        <option key={currentSeries} value={currentSeries}>
          {currentSeries}
        </option>
      )),
    [series],
  )

  const onChange = useCallback(createOnChange(selected), [selected])

  return (
    <FormGroup>
      <small>
        <Label>Series*</Label>
      </small>
      <SelectInput
        required
        aria-label="Vehicle Series"
        disabled={!ready}
        name="series"
        type="select"
        value={selected.series ?? ''}
        onChange={onChange}
      >
        <option disabled value="">
          Series
        </option>
        {options}
      </SelectInput>
    </FormGroup>
  )
}
