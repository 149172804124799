import {useCallback, useContext, useMemo, useState} from 'react'
import {CommonContext} from '~/common/context'
import {ReferralForm} from '../types'
import {ReferralContext} from '.'

/**
 * Construct default referral context.
 * @return Referral context
 */
export const useReferral = () => {
  const {api} = useContext(CommonContext)
  const [ready, setReady] = useState(true)
  const [error, setError] = useState<Error>()

  const submit = useCallback(
    async (form: ReferralForm) => {
      if (!ready) {
        return false
      }
      setReady(false)
      setError(undefined)
      try {
        await api({
          data: {
            emailAddress: form.email,
            firstName: form.name.first,
            lastName: form.name.last,
            referrals: form.referrals,
          },
          method: 'POST',
          url: 'v1/forms/referral-submit',
        })
        setReady(true)
        return true
      } catch (e) {
        setReady(true)
        setError(new Error('Unable to fulfill request'))
        return false
      }
    },
    [api, ready, setReady, setError],
  )

  return useMemo<ReferralContext>(
    () => ({
      error,
      ready,
      submit,
    }),
    [error, ready, submit],
  )
}
