import {graphql} from 'gatsby'
import * as IO from 'io-ts'
import {LinkedItems} from '~/kentico/util'
import {KenticoContentData} from '../content'
import {KenticoRichTextData} from '../rich-text'
import {KenticoFormSubmissionRedirectData} from '../form-submission-redirect'

/** Form Component. */
export const KenticoFormData = IO.intersection(
  [
    KenticoContentData,
    IO.type({
      elements: IO.type({
        formType: IO.type({
          value: IO.array(
            IO.type({
              codename: IO.keyof({
                campaign: undefined,
                campaign_appointment: undefined,
              }),
            }),
          ),
        }),
        privacyConsent: KenticoRichTextData,
        submissionLinks: LinkedItems(KenticoFormSubmissionRedirectData),
        tcpa: KenticoRichTextData,
        text: KenticoRichTextData,
      }),
      system: IO.type({
        type: IO.literal('form'),
      }),
    }),
  ],
  'KenticoForm',
)

/** Data Type. */
export interface KenticoFormData extends IO.TypeOf<typeof KenticoFormData> {}

/** Fragment helper. */
export const fragment = graphql`
  fragment KenticoForm on Node {
    ... on kontent_item_form {
      elements {
        submissionLinks: submission_links {
          nodes: value {
            ...KenticoFormSubmissionRedirect
          }
        }
        tcpa {
          ...KenticoRichText
        }
        privacyConsent: privacy_consent {
          ...KenticoRichText
        }
        formType: form_type {
          value {
            codename
          }
        }
        text: heading_text {
          ...KenticoRichText
        }
      }
      system {
        id
        codename
        name
        type
      }
    }
  }
`
