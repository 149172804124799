import { getImage, IGatsbyImageData } from 'gatsby-plugin-image'
import { BgImage } from 'gbimage-bridge'
import {UUID} from 'io-ts-types/lib/UUID'
import React from 'react'
import styled from 'styled-components'
import {Container as BootstrapContainer} from '~/bootstrap/components/layout'
import {Customize} from '~/common/components/customize'
import {validImage} from '../../util'
import {KenticoPreviewBodyLink} from '../preview-link'
import {KenticoRichText} from '../rich-text'
import {KenticoFullSizeImageData} from './data'

interface Props {
  data: KenticoFullSizeImageData
}

/** Kentico type data. */
export {KenticoFullSizeImageData}

// Vertical centerting in IE with min-height
// https://stackoverflow.com/a/33222765

const Container = styled(BootstrapContainer)`
  display: flex;
  flex-direction: column;
`

const TextWrapper = styled.div`
  display: flex;
  flex-direction: center;
  align-items: center;
  min-height: 200px;

  @media (max-width: ${({theme}) => theme.breakpoints.max.sm}) {
    justify-content: center;
  }
`

const NoTextWrapper = styled(TextWrapper)`
  min-height: 600px;

  @media (max-width: ${({theme}) => theme.breakpoints.max.md}) {
    min-height: 200px;
  }
`

const BackgroundImage = styled(BgImage)`
  height: 100%;
  width: 100%;
`

/**
 * Render full size image from Kentico data.
 * @return React component
 */
export const KenticoFullSizeImage = ({
  data: {
    elements: {image, imageOptions, text, textColor},
    system: {id, name},
  },
}: Props) => {
  let backgroundImage
  if (validImage(image)) {
    backgroundImage = image.value[0].file.childImageSharp.gatsbyImageData
  }
  let editLink
  if (!UUID.is(name)) {
    editLink = <KenticoPreviewBodyLink contentItem={id} />
  }

  const isTall = imageOptions.value.some(
    ({codename}) => (codename as string) === 'tall',
  )
  const children = <KenticoRichText data={text} />
  let mainImage = <TextWrapper>{children}</TextWrapper>
  if (isTall) {
    mainImage = <NoTextWrapper>{children}</NoTextWrapper>
  }

  const textColorValue =
    textColor.value.length > 0 ? textColor.value[0].codename : undefined

  let myGatsbyImageData = backgroundImage as IGatsbyImageData;
  let pluginImage = getImage(myGatsbyImageData);  
  return (
    <BackgroundImage image={pluginImage}>
      {editLink}
      <Customize component={Container} textColor={textColorValue}>
        {mainImage}
      </Customize>
    </BackgroundImage>
  )
}
