import {constant} from 'lodash'
import {createContext} from 'react'
import {ReferralForm} from '../types'

/** Referral information. */
export interface ReferralContext {
  ready: boolean
  error?: Error
  submit(form: ReferralForm): Promise<boolean>
}

/** Default context. */
export const DEFAULT_CONTEXT: ReferralContext = {
  ready: false,
  submit: constant(Promise.resolve(false)),
}

/** Context implementation. */
export const ReferralContext = createContext(DEFAULT_CONTEXT)

// eslint-disable-next-line functional/immutable-data
ReferralContext.displayName = 'Referral'
