import {Link} from 'gatsby'
import React from 'react'
import {Noop} from '~/common/components/noop'
import {createUrl, isExternal, linkedItems, validImage} from '../../util'
import {KenticoFixedImage} from '../fixed-image'
import {KenticoFluidImage, KenticoFluidImageData} from '../fluid-image'
import {KenticoImageLinkData} from './data'

interface Props {
  imageLink: KenticoImageLinkData
}

/** Kentico type data. */
export {KenticoImageLinkData}

/**
 * Render fluid image link from Kentico data.
 * @return React component
 */
export const KenticoImageLink = ({imageLink}: Props) => {
  let image
  if (validImage(imageLink.elements.image)) {
    const [imageValue] = imageLink.elements.image.value
    image = imageValue
  } else {
    return <Noop />
  }

  const children = KenticoFluidImageData.is(image) ? (
    <KenticoFluidImage image={image} />
  ) : (
    <KenticoFixedImage image={image} />
  )
  const url = createUrl(imageLink.elements.link)

  return isExternal(linkedItems(imageLink.elements.link)) ? (
    <a href={url} rel="noopener noreferrer" target="_blank">
      {children}
    </a>
  ) : (
    <Link to={url}>{children}</Link>
  )
}
