import {graphql} from 'gatsby'
import * as IO from 'io-ts'
import {Color} from '~/common/types'
import {KenticoContentData} from '../content'
import {KenticoFluidImageData} from '../fluid-image'
import {KenticoRichTextData} from '../rich-text'

/** Full Size Image. */
export const KenticoFullSizeImageData = IO.intersection(
  [
    KenticoContentData,
    IO.type({
      elements: IO.type({
        image: IO.type({
          value: IO.array(KenticoFluidImageData),
        }),
        imageOptions: IO.type({
          value: IO.array(
            IO.type({
              codename: IO.literal('tall'),
            }),
          ),
        }),
        text: KenticoRichTextData,
        textColor: IO.type({
          value: IO.array(
            IO.type({
              codename: Color,
            }),
          ),
        }),
      }),
      system: IO.type({
        type: IO.literal('full_size_image'),
      }),
    }),
  ],
  'KenticoFullSizeImage',
)

/** Data Type. */
export interface KenticoFullSizeImageData
  extends IO.TypeOf<typeof KenticoFullSizeImageData> {}

/** Fragment helper. */
export const fragment = graphql`
  fragment KenticoFullSizeImage on Node {
    ... on kontent_item_full_size_image {
      elements {
        image {
          value {
            ...KenticoFluidImage
          }
        }
        imageOptions: image_options {
          value {
            codename
          }
        }
        text {
          ...KenticoRichText
        }
        textColor: text_color {
          value {
            codename
          }
        }
      }
      system {
        id
        codename
        name
        type
      }
    }
  }
`
