import {graphql} from 'gatsby'
import * as IO from 'io-ts'
import {LinkedItems} from '~/kentico/util'
import {KenticoContentData} from '../content'
import {KenticoFullSizeImageData} from '../full-size-image'

/** Kentico UTM Data. */
export const KenticoUtmParamsData = IO.intersection(
  [
    KenticoContentData,
    IO.type({
      elements: IO.type({
        campaignImage: LinkedItems(KenticoFullSizeImageData),
        utmParams: IO.type({
          value: IO.string,
        }),
      }),
      system: IO.type({
        type: IO.literal('utm_params'),
      }),
    }),
  ],
  'KenticoUtmParams',
)

/** UTM Params Data type. */
export interface KenticoUtmParamsData
  extends IO.TypeOf<typeof KenticoUtmParamsData> {}

/** Fragment helper. */
export const fragment = graphql`
  fragment KenticoUtmParams on Node {
    ... on kontent_item_utm_params {
      elements {
        campaignImage: campaign_image {
          nodes: value {
            ...KenticoFullSizeImage
          }
        }
        utmParams: utm_params {
          value
        }
      }
      system {
        id
        codename
        name
        type
      }
    }
  }
`
