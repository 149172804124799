import {graphql} from 'gatsby'
import * as IO from 'io-ts'
import {KenticoSchemaCorporationData} from '../schema-corporation'
import {KenticoSchemaFaqData} from '../schema-faq'
import {KenticoSchemaFinancialServiceData} from '../schema-financial-service'
import {KenticoSchemaLoanOrCreditData} from '../schema-loan-or-credit'

/** Data type. */
export type KenticoSchemaData =
  | KenticoSchemaCorporationData
  | KenticoSchemaLoanOrCreditData
  | KenticoSchemaFaqData
  | KenticoSchemaFinancialServiceData

/** Kentico Schema data. */
export const KenticoSchemaData = IO.union(
  [
    KenticoSchemaCorporationData,
    KenticoSchemaLoanOrCreditData,
    KenticoSchemaFaqData,
    KenticoSchemaFinancialServiceData,
  ],
  'KenticoSchema',
)

/** Fragment helper. */
export const fragment = graphql`
  fragment KenticoSchema on Node {
    ...KenticoSchemaCorporation
    ...KenticoSchemaLoanOrCredit
    ...KenticoSchemaFaq
    ...KenticoSchemaFinancialService
  }
`
