import {Container as BootstrapContainer} from 'reactstrap'
import styled from 'styled-components'

/** Expose relevant components. */
export {Col, Row} from 'reactstrap'

/** Include ColProps. */
export type ColProps = import('reactstrap').ColProps

/** Base bootstrap container. */
export const ContainerBase = styled(BootstrapContainer)`
  position: relative;
`

/** Customized Bootstrap container. */
export const Container = styled(ContainerBase)`
  &&& {
    display: block;
  }
`
