import {UUID} from 'io-ts-types/lib/UUID'
import React, {useCallback, useContext, useState} from 'react'
import {FormContext, useForm} from 'react-hook-form'
import styled from 'styled-components'
import {Button} from '~/bootstrap/components/button'
import {Form, FormGroup, FormItem, FormText} from '~/bootstrap/components/form'
import {
  Col as BootstrapCol,
  Container,
  Row,
} from '~/bootstrap/components/layout'
import {CalculatorsResults} from '~/calculators/components/results'
import {CalculatorsContext} from '~/calculators/context'
import {Customize} from '~/common/components/customize'
import {Spinner} from '~/common/components/spinner'
import {StateOptions} from '~/common/components/state-options'
import {createUrl} from '~/kentico/util'
import {VehiclesMake} from '~/vehicles/components/make'
import {VehiclesModel} from '~/vehicles/components/model'
import {VehiclesSeries} from '~/vehicles/components/series'
import {VehiclesStyle} from '~/vehicles/components/style'
import {VehiclesYear} from '~/vehicles/components/year'
import {VehiclesContext} from '~/vehicles/context'
import {KenticoPreviewBodyLink} from '../preview-link'
import {KenticoRichText} from '../rich-text'
import {KenticoTitleLoanCalculatorData} from './data'
import {FormData, Response, createOnSubmit} from './form'

interface Props {
  data: KenticoTitleLoanCalculatorData
  response?: Response
}

/** Kentico type data. */
export {KenticoTitleLoanCalculatorData}

const Col = styled(BootstrapCol)`
  padding-top: ${({theme}) => theme.spacers[2]};
  padding-bottom: ${({theme}) => theme.spacers[2]};
`

const PageTitleCol = styled(BootstrapCol)`
  padding-top: ${({theme}) => theme.spacers[2]};
  padding-bottom: ${({theme}) => theme.spacers[2]};

  h1,
  h2,
  h3,
  h4 {
    line-height: 1.5;
  }
`
  const SubmitButton = styled(Button)`
    @media (max-width: 370px){
      padding: 1rem 1.5rem;
    }
  `

const ErrorMessage = styled.span`
  color: ${({theme}) => theme.colors.red};
`

/**
 * Render Title Loan Calculator from Kentico data.
 * @return React component
 */
export const KenticoTitleLoanCalculator = ({
  data: {
    elements: {body, buttonText, buttonLink, submitText},
    system: {name, id},
  },
  response: defaultResponse = {},
}: Props) => {
  const {title} = useContext(CalculatorsContext)
  const {selected} = useContext(VehiclesContext)
  const form = useForm<FormData>({mode: 'onChange'})
  const {
    handleSubmit,
    formState: {isValid},
  } = form
  const [response, setResponse] = useState<Response>(defaultResponse)
  const onSubmit = useCallback(
    createOnSubmit({
      selected,
      setResponse,
      title,
    }),
    [setResponse, title, selected],
  )

  let responseMessage
  let calculatorResults
  switch (response.success) {
    case false:
      responseMessage = (
        <ErrorMessage>
          We are unable to submit your information at this time. Please try
          again later.
        </ErrorMessage>
      )
      break
    case true:
      calculatorResults = (
        <CalculatorsResults
          aprAmount={response.data.apr}
          buttonLink={createUrl(buttonLink)}
          buttonText={buttonText.value}
          finePrint={response.data.finePrint}
          loanAmount={response.data.amount}
          paymentAmount={response.data.payment}
        />
      )
      break
    default:
      break
  }

  const submitButtonText = title.ready ? submitText.value : <Spinner />

  let editLink
  if (!UUID.is(name)) {
    editLink = <KenticoPreviewBodyLink contentItem={id} />
  }

  return (
    <>
      <FormContext {...form}>
        <Container>
          <Form id="titleCalculatorForm" onSubmit={handleSubmit(onSubmit)}>
            {editLink}
            <Row>
              <PageTitleCol>
                <KenticoRichText data={body} />
                <FormGroup>
                  <FormText color="secondary">*Required Fields</FormText>
                </FormGroup>
              </PageTitleCol>
            </Row>

            <Row>
              <Col md>
                <VehiclesYear />
              </Col>
              <Col md>
                <VehiclesMake />
              </Col>
            </Row>

            <Row>
              <Col md>
                <VehiclesModel />
              </Col>
              <Col md>
                <VehiclesSeries />
              </Col>
            </Row>

            <Row>
              <Col md>
                <VehiclesStyle />
              </Col>
              <Col md>
                <FormGroup>
                  <FormItem
                    defaultValue=""
                    label="State"
                    name="state"
                    required="Please select a state"
                    type="select"
                  >
                    <option disabled value="">
                      Select State
                    </option>
                    <StateOptions titleLoan />
                  </FormItem>
                </FormGroup>
              </Col>
            </Row>
            {responseMessage}
            <Customize component={FormGroup} textAlign="center">
              <FormGroup>
                <SubmitButton disabled={!title.ready || !isValid}>
                  {submitButtonText}
                </SubmitButton>
              </FormGroup>
            </Customize>
          </Form>
        </Container>
      </FormContext>
      {calculatorResults}
    </>
  )
}
