import {UUID} from 'io-ts-types/lib/UUID'
import React, {useCallback, useEffect, useState} from 'react'
import styled from 'styled-components'
import {
  Col,
  Container,
  Row as BootstrapRow,
} from '~/bootstrap/components/layout'
import {Customize} from '~/common/components/customize'
import {KenticoPreviewBodyLink} from '../preview-link'
import {KenticoHowItWorksData} from './data'

/** Kentico type data. */
export {KenticoHowItWorksData}

interface Props {
  data: KenticoHowItWorksData
}

type ActiveColumn = 0 | 1 | 2 | 3

const Row = styled(BootstrapRow)`
  margin-bottom: ${({theme}) => theme.spacers[5]};
`

const HeaderCol = styled(Col)`
  text-align: center;
  margin: ${({theme}) => theme.spacers[5]} 0 ${({theme}) => theme.spacers[4]};
`

const ActiveCol = styled(Col)`
  color: ${({theme}) => theme.colors.light};
  height: 272px;
  opacity: 1;
  transition: opacity 1s;

  p {
    visibility: visible;
    opacity: 1;
    transition: opacity 1s;
  }

  h3 {
    transition: margin-top 1s;
  }
`

const InactiveCol = styled(ActiveCol)`
  cursor: pointer;
  opacity: 0.5;
  transition: opacity 1s;

  p {
    visibility: hidden;
    opacity: 0;
  }

  &:hover {
    opacity: 0.8;

    h3 {
      margin-top: 0;
      transition: margin-top 1s;
    }
  }

  h3 {
    margin: 0;
    margin-top: 6rem;
    transition: margin-top 1s;
  }
`

const ColNumber = styled.div`
  font-size: 4rem;
`

/**
 * Render How It Works row from Kentico data.
 * @return React component
 */
export const KenticoHowItWorks = ({
  data: {
    elements: {header1, text1, header2, text2, header3, text3, mainHeader},
    system: {id, name},
  },
}: Props) => {
  const [active, setActive] = useState<ActiveColumn>(0)

  useEffect(() => {
    setActive(1)
  }, [])

  const onClick1 = useCallback(() => {
    setActive(1)
  }, [setActive])

  const onClick2 = useCallback(() => {
    setActive(2)
  }, [setActive])

  const onClick3 = useCallback(() => {
    setActive(3)
  }, [setActive])

  const Col1 = active === 0 || active === 1 ? ActiveCol : InactiveCol
  const Col2 = active === 0 || active === 2 ? ActiveCol : InactiveCol
  const Col3 = active === 0 || active === 3 ? ActiveCol : InactiveCol

  let editLink
  if (!UUID.is(name)) {
    editLink = <KenticoPreviewBodyLink contentItem={id} />
  }

  return (
    <Container>
      <Row>
        <HeaderCol xs="12">
          <h2>{mainHeader.value}</h2>
        </HeaderCol>
        <Customize
          background="secondary"
          component={Col1}
          lg={{offset: 0, size: 4}}
          md={{offset: 2, size: 8}}
          sm="12"
          onClick={onClick1}
        >
          <ColNumber>1</ColNumber>
          <h3>{header1.value}</h3>
          <p>{text1.value}</p>
        </Customize>
        <Customize
          background="tertiary"
          component={Col2}
          lg={{offset: 0, size: 4}}
          md={{offset: 2, size: 8}}
          sm="12"
          onClick={onClick2}
        >
          <ColNumber>2</ColNumber>
          <h3>{header2.value}</h3>
          <p>{text2.value}</p>
        </Customize>
        <Customize
          background="primary"
          component={Col3}
          lg={{offset: 0, size: 4}}
          md={{offset: 2, size: 8}}
          sm="12"
          onClick={onClick3}
        >
          <ColNumber>3</ColNumber>
          <h3>{header3.value}</h3>
          <p>{text3.value}</p>
        </Customize>
      </Row>
      {editLink}
    </Container>
  )
}
