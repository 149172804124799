import React from 'react'
import styled from 'styled-components'
import {Button as BootstrapButton} from '~/bootstrap/components/button'
import {Link} from '~/common/components/link'
import {createUrl} from '../../util'
import {KenticoTableRowData} from './data'

/** Kentico type data. */
export {KenticoTableRowData}

interface Props {
  data: KenticoTableRowData
}

const Button = styled(BootstrapButton)`
  align-content: center;
`

const LoanTypeText = styled.td`
  && {
    vertical-align: middle;
  }
`

const LoanTypeHeader = styled(LoanTypeText)`
  @media (max-width: ${({theme}) => theme.breakpoints.max.sm}) {
    font-size: 0.9rem;
  }
`

/**
 * Render Kentico table row.
 * @return React component
 */
export const KenticoTableRow = ({
  data: {
    elements: {buttonLink, buttonText, text1, text2},
  },
}: Props) => (
  <>
    <LoanTypeHeader>
      <strong>{text1.value}</strong>
    </LoanTypeHeader>
    <LoanTypeText>{text2.value}</LoanTypeText>
    <td>
      <Link to={createUrl(buttonLink)}>
        <Button size="sm">{buttonText.value}</Button>
      </Link>
    </td>
  </>
)
