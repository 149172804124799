import {UUID} from 'io-ts-types/lib/UUID'
import {darken} from 'polished'
import React from 'react'
import styled from 'styled-components'
import {
  FormGroup as BootstrapFormGroup,
  Input as BootstrapInput,
  Label as BootstrapLabel,
} from '~/bootstrap/components/form'
import {
  Col,
  Container as BootstrapContainer,
  Row,
} from '~/bootstrap/components/layout'
import {linkedItems} from '../../util'
import {KenticoFixedImage} from '../fixed-image'
import {KenticoPreviewBodyLink} from '../preview-link'
import {KenticoChecklistData} from './data'

interface Props {
  data: KenticoChecklistData
}

/** Kentico type data. */
export {KenticoChecklistData}

const Container = styled(BootstrapContainer)`
  padding: ${({theme}) => theme.spacers[5]} ${({theme}) => theme.spacers[4]};
`

const ImageCol = styled(Col)`
  text-align: center;

  @media (min-width: ${({theme}) => theme.breakpoints.min.lg}) {
    text-align: right;
  }
`

const Title = styled.h2`
  padding: ${({theme}) => theme.spacers[3]} 0;

  @media (max-width: ${({theme}) => theme.breakpoints.max.sm}) {
    text-align: center;
  }
`

const FormGroup = styled(BootstrapFormGroup)`
  && {
    padding-left: 30px;
  }
`

const Label = styled(BootstrapLabel)`
  font-weight: bold;
  user-select: none;
  cursor: pointer;
`

/* eslint-disable max-len */
const Input = styled(BootstrapInput)`
  width: 21px;
  height: 21px;
  transform: translate(-10px, -2px);
  cursor: pointer;

  &:after {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    content: '';
    background-color: white;
    border: 1px solid ${({theme}) => darken(0.2, theme.colors.light)};
    border-radius: 5px;
    transition: background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    background-size: 75%;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
  }

  &:checked:after {
    background-color: ${({theme}) => theme.colors.secondary};
  }
`
/* eslint-enable max-len */

const CheckboxCol = styled(Col)`
  padding-top: ${({theme}) => theme.spacers[3]};
`

/**
 * Render checklist from Kentico data.
 * @param props Component properties
 * @return React component
 */
export const KenticoChecklist = ({
  data: {
    elements: {image, items, title, subtitle},
    system: {id, name},
  },
}: Props) => {
  let editLink
  if (!UUID.is(name)) {
    editLink = <KenticoPreviewBodyLink contentItem={id} />
  }
  const options = linkedItems(items).map(item => (
    <CheckboxCol key={item.system.codename} lg="6">
      <FormGroup check>
        <Label check>
          <Input type="checkbox" />
          {item.elements.text.value}
        </Label>
      </FormGroup>
    </CheckboxCol>
  ))
  return (
    <Container>
      {editLink}
      <Row>
        <ImageCol lg="2">
          <KenticoFixedImage image={image.value[0]} />
        </ImageCol>
        <Col lg>
          <Title>{title.value}</Title>
          <p>{subtitle.value}</p>
          <Row>{options}</Row>
        </Col>
      </Row>
    </Container>
  )
}
