import 'bootstrap/scss/_navbar.scss'
import {NavbarBrand as BootstrapNavbarBrand} from 'reactstrap'
import styled from 'styled-components'

/** Expose relevant components. */
export {Navbar} from 'reactstrap'

/** Use inline-flex by default for navbar brand. */
export const NavbarBrand = styled(BootstrapNavbarBrand)`
  && {
    display: inline-flex;
  }
`
