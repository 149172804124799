import {UUID} from 'io-ts-types/lib/UUID'
import {darken} from 'polished'
import React, {useContext} from 'react'
import styled from 'styled-components'
import {Button} from '~/bootstrap/components/button'
import {Container as BootstrapContainer} from '~/bootstrap/components/layout'
import {Customize} from '~/common/components/customize'
import {CommonContext} from '~/common/context'
import {linkedItems, validImage} from '../../util'
import {KenticoFixedImage} from '../fixed-image'
import {KenticoLink} from '../link'
import {KenticoPreviewBodyLink} from '../preview-link'
import {KenticoRichText} from '../rich-text'
import {KenticoButtonWithImageData} from './data'

interface Props {
  data: KenticoButtonWithImageData
}

/** Kentico type data. */
export {KenticoButtonWithImageData}

const Container = styled(BootstrapContainer)`
  height: 100%;
  display: flex;

  @media (max-width: ${({theme}) => theme.breakpoints.max.sm}) {
    && {
      padding: ${({theme}) => theme.spacers[1]};
    }
  }
`

const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: ${({theme}) => theme.spacers[3]};
  margin-bottom: ${({theme}) => theme.spacers[3]};
  box-shadow: 0px 2px 5px 2px ${({theme}) => darken(0.1, theme.colors.light)};
  border-radius: ${({theme}) => theme.spacers[1]};
  padding: ${({theme}) => theme.spacers[3]};

  p,
  h1,
  h2,
  h3,
  h4 {
    word-break: break-word;
    width: 100%;
  }

  @media (max-width: ${({theme}) => theme.breakpoints.max.sm}) {
    margin-left: ${({theme}) => theme.spacers[2]};
    margin-right: ${({theme}) => theme.spacers[2]};
  }
`

/**
 * Render Button with Image from Kentico data.
 * @return React component
 */
export const KenticoButtonWithImage = ({
  data: {
    elements: {
      buttonColor,
      buttonText,
      image,
      label,
      link,
      textColor,
      utmPassthrough,
    },
    system: {id, name},
  },
}: Props) => {
  const {history} = useContext(CommonContext)

  // Render preview link
  let editLink
  if (!UUID.is(name)) {
    editLink = <KenticoPreviewBodyLink contentItem={id} />
  }

  let imageElement
  if (validImage(image)) {
    imageElement = <KenticoFixedImage image={image.value[0]} />
  }

  // TODO lets figure out these istanbul cases in the future
  // Target the url, if it has a ?, grab those parameters and attach to the
  // targetUrl. Secondary if statement ensures it's only attached once.
  let utmLink
  // istanbul ignore else
  if (
    utmPassthrough.value.some(
      ({codename}) => (codename as string) === 'enabled',
    )
  ) {
    const targetUrl = linkedItems(link)[0].elements.url.value
    // istanbul ignore else
    if (!targetUrl.includes('?')) {
      utmLink = `${history.location.search.split('?')[1]}`
    }
  }

  if (buttonText.value.trim() !== '') {
    // Add bootstrap button
    return (
      <Customize
        fluid
        component={Container}
        textAlign="center"
        textColor={textColor.value[0].codename}
      >
        <Content>
          {editLink}
          {imageElement}
          <KenticoRichText data={label} />
          <Button
            color={buttonColor.value[0].codename}
            data={linkedItems(link)}
            tag={KenticoLink}
          >
            {buttonText.value}
          </Button>
        </Content>
      </Customize>
    )
  }

  return (
    <Customize
      fluid
      component={Container}
      textAlign="center"
      textColor={textColor.value[0].codename}
    >
      {editLink}
      <KenticoLink data={linkedItems(link)} params={utmLink}>
        <Content>
          {imageElement}
          <KenticoRichText data={label} />
        </Content>
      </KenticoLink>
    </Customize>
  )
}
