import {graphql} from 'gatsby'
import * as IO from 'io-ts'
import {Color} from '~/common/types'
import {LinkedItems} from '../../util'
import {KenticoContentData} from '../content'
import {KenticoFixedImageData} from '../fixed-image'
import {KenticoLinkData} from '../link'

/** Special offer row. */
export const KenticoSpecialOfferData = IO.intersection(
  [
    KenticoContentData,
    IO.type({
      elements: IO.type({
        backgroundColor: IO.type({
          value: IO.array(
            IO.type({
              codename: Color,
            }),
          ),
        }),
        image: IO.type({
          value: IO.array(KenticoFixedImageData),
        }),
        link: LinkedItems(KenticoLinkData),
        text: IO.type({
          value: IO.string,
        }),
        textColor: IO.type({
          value: IO.array(
            IO.type({
              codename: Color,
            }),
          ),
        }),
      }),
      system: IO.type({
        type: IO.literal('special_offer'),
      }),
    }),
  ],
  'KenticoSpecialOffer',
)

/** Data type. */
export interface KenticoSpecialOfferData
  extends IO.TypeOf<typeof KenticoSpecialOfferData> {}

/** Fragment helper. */
export const fragment = graphql`
  fragment KenticoSpecialOffer on Node {
    ... on kontent_item_special_offer {
      elements {
        backgroundColor: background_color {
          value {
            codename
          }
        }
        textColor: text_color {
          value {
            codename
          }
        }
        image {
          value {
            description
            file {
              childImageSharp {
                gatsbyImageData(layout: FIXED, height: 32, width: 32)
              }
            }
          }
        }
        link {
          nodes: value {
            ...KenticoLink
          }
        }
        text {
          value
        }
      }
      system {
        id
        codename
        name
        type
      }
    }
  }
`
