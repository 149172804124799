import {UUID} from 'io-ts-types/lib/UUID'
import {partial} from 'lodash'
import React, {useCallback, useContext, useState} from 'react'
import {FormContext, useForm} from 'react-hook-form'
import styled from 'styled-components'
import {Button} from '~/bootstrap/components/button'
import {Form, FormGroup, FormItem, FormText} from '~/bootstrap/components/form'
import {
  Col as BootstrapCol,
  Container as BootstrapContainer,
  Row,
} from '~/bootstrap/components/layout'
import {CalculatorsResults} from '~/calculators/components/results'
import {CalculatorsContext} from '~/calculators/context'
import {InstallmentCalculatorForm} from '~/calculators/types'
import {Customize} from '~/common/components/customize'
import {Spinner} from '~/common/components/spinner'
import {StateOptions} from '~/common/components/state-options'
import {MASKS} from '~/common/util'
import {createUrl} from '../../util'
import {KenticoPreviewBodyLink} from '../preview-link'
import {KenticoRichText} from '../rich-text'
import {createOnChange} from './change'
import {KenticoInstallmentLoanCalculatorData} from './data'
import {Response, createOnSubmit} from './form'
import './InstalmentLoanCalculator.css'

interface Props {
  data: KenticoInstallmentLoanCalculatorData
  /** Reserved for testing. This should not be used normally. */
  response?: Response
}

/** Kentico type data. */
export {KenticoInstallmentLoanCalculatorData}

const Container = styled(BootstrapContainer)`
  padding-top: ${({theme}) => theme.spacers[2]};
  padding-bottom: ${({theme}) => theme.spacers[2]};

  h1,
  h2,
  h3,
  h4 {
    line-height: ${({theme}) => theme.spacers[5]};
  }
`

const Col = styled(BootstrapCol)`
  padding-top: ${({theme}) => theme.spacers[3]};
  padding-bottom: ${({theme}) => theme.spacers[3]};
`

const ErrorMessage = styled.span`
  color: ${({theme}) => theme.colors.red};
  margin-bottom: ${({theme}) => theme.spacers[3]};
`

/**
 * Render Installment Loan Calculator from Kentico data.
 * @return React component
 */
export const KenticoInstallmentLoanCalculator = ({
  data: {
    elements: {body, buttonText, buttonLink, submitText},
    system: {name, id},
  },
  response: defaultResponse = {},
}: Props) => {
  const {installment} = useContext(CalculatorsContext)
  const form = useForm<InstallmentCalculatorForm>({mode: 'onChange'})
  const {
    handleSubmit,
    formState: {isValid},
    triggerValidation,
  } = form
  const [response, setResponse] = useState<Response>(defaultResponse)
  const onSubmit = useCallback(
    createOnSubmit({
      installment,
      setResponse,
    }),
    [installment, setResponse],
  )

  let responseMessage
  let calculatorResults
  switch (response.success) {
    case false:
      responseMessage = (
        <ErrorMessage>
          We are unable to submit your information at this time. Please try
          again later.
        </ErrorMessage>
      )
      break
    case true:
      calculatorResults = (
        <CalculatorsResults
          aprAmount={response.data.apr}
          buttonLink={createUrl(buttonLink)}
          buttonText={buttonText.value}
          finePrint={response.data.finePrint}
          loanAmount={response.data.amount}
          paymentAmount={response.data.payment}
        />
      )
      break
    default:
      break
  }

  const onIncomeChange = useCallback(createOnChange(form), [isValid])

  const inputChangeValidation = useCallback(
    partial(triggerValidation, undefined, undefined),
    [triggerValidation],
  )

  const submitButtonText = installment.ready ? submitText.value : <Spinner />

  let editLink
  if (!UUID.is(name)) {
    editLink = <KenticoPreviewBodyLink contentItem={id} />
  }

  return (
    <>
      <FormContext {...form}>
        <Container>
          <Form
            id="installmentCalculatorForm"
            onSubmit={handleSubmit(onSubmit)}
          >
            {editLink}
            <Row>
              <Col>
                <KenticoRichText data={body} />
                <FormGroup>
                  <FormText color="secondary">*Required Fields</FormText>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col lg>
                <FormItem
                  defaultValue=""
                  label="State"
                  name="state"
                  required="Please select a state"
                  type="select"
                  onChange={inputChangeValidation}
                >
                  <option disabled value="">
                    Select State
                  </option>
                  <StateOptions />
                </FormItem>
              </Col>
              <Col lg>
                <FormItem
                  label="Monthly Take Home (After Deductions)"
                  mask={MASKS.CASH}
                  name="income"
                  placeholder="&#36;"
                  required="Please enter an amount"
                  type="tel"
                  validate={onIncomeChange}
                />
              </Col>
            </Row>
            {responseMessage}
            <Customize component={FormGroup} textAlign="center">
              <FormGroup>
                <Button className={'submitButton'} disabled={!installment.ready || !isValid}>
                  {submitButtonText}
                </Button>
              </FormGroup>
            </Customize>
          </Form>
        </Container>
      </FormContext>
      {calculatorResults}
    </>
  )
}
