import {graphql} from 'gatsby'
import * as IO from 'io-ts'
import {LinkedItems} from '../../util'
import {KenticoContentData} from '../content'
import {KenticoPageLinkData} from '../page-link'

/** Location Search. */
export const KenticoStoreLocationData = IO.intersection(
  [
    KenticoContentData,
    IO.type({
      elements: IO.type({
        viewMore: LinkedItems(KenticoPageLinkData),
      }),
      system: IO.type({
        type: IO.literal('prequal_store_location'),
      }),
    }),
  ],
  'KenticoStoreLocation',
)

/** Location Search Data. */
export interface KenticoStoreLocationData
  extends IO.TypeOf<typeof KenticoStoreLocationData> {}

/** Fragment helper. */
export const fragment = graphql`
  fragment KenticoStoreLocation on Node {
    ... on kontent_item_prequal_store_location {
      elements {
        viewMore: view_more {
          nodes: value {
            ...KenticoPageLink
          }
        }
      }
      system {
        id
        name
        codename
        type
      }
    }
  }
`
