import {isRight, Right} from 'fp-ts/lib/Either'
import * as IO from 'io-ts'
import reporter from 'io-ts-reporters'
import {parse} from 'query-string'
import {useContext, useEffect, useMemo} from 'react'
import {CommonContext} from '../context'
import * as CURRENCIES from './currency'
import * as MASKS from './mask'
import * as PATTERNS from './pattern'

/** Common input masks and currencies. */
export {MASKS, CURRENCIES, PATTERNS}

/**
 * IO decoder.
 * @param type IO type
 * @param value Value to attempt to decode
 * @param fallback Optional fallback value to avoid error thrown
 * @return Successful value
 */
export const decode = <T>(
  type: IO.Decoder<unknown, T>,
  value: unknown,
  fallback?: T,
) => {
  const validation = type.decode(value)
  if (!isRight(validation)) {
    if (fallback === undefined) {
      throw new Error(reporter.report(validation).join('\n'))
    }
    return fallback
  }
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
  return (validation as Right<T>).right
}

/**
 * Extrapolate query data from URL if able.
 * @param type IO type
 * @return Successful value or undefined if unavailable
 */
export const useURLQuery = <T>(type: IO.Decoder<unknown, T>) => {
  const {search} = useContext(CommonContext).history.location
  try {
    return useMemo(() => decode(type, parse(search)), [type, search])
  } catch {
    return undefined
  }
}

/**
 * Like useEffect that will accept promises. Note that return values will not
 * be taken into consideration as they are not cancellable.
 * @param call Callback
 * @param deps Activate when changes are made
 * @return Same as useEffect
 */
// TODO write test for this
// istanbul ignore next
export const useEffectAsync = (call: () => Promise<void>, deps?: unknown[]) =>
  useEffect(() => {
    // The point of this hook is to run a promise
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    call()
  }, deps) // eslint-disable-line react-hooks/exhaustive-deps

/**
 * Format phone number to a more readable format.
 * @param phoneNumber Phone number to format
 * @return Formatted phone number
 */
export const formatPhoneNumber = (phoneNumber: string) => {
  const cleaned = phoneNumber.replace(/\D/g, '')
  const match = /^1?(\d{3})(\d{3})(\d{4})$/.exec(cleaned)
  return match ? `(${match[1]}) ${match[2]}-${match[3]}` : undefined
}
