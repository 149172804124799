import classes from 'classnames'
import React, {ElementType} from 'react'
import {Align, Color} from '../../types'

type Breakpoint = 'xs' | 'sm' | 'md' | 'lg' | 'xl'

type Props<P> = P & {
  background?: Color
  borderColor?: Color
  className?: string
  textAlign?: Align
  textColor?: Color
  component?: ElementType<P>
  hiddenOver?: Breakpoint
  hiddenUnder?: Breakpoint
  flex?: {
    direction?: 'row' | 'column'
    justify?: Align
    align?: Align
  }
}

/**
 * Customize component with Boostrap theming.
 * @return React component
 */
export const Customize = <P extends {}>({
  background,
  borderColor,
  className: baseClassName,
  flex,
  hiddenOver,
  hiddenUnder,
  textAlign,
  textColor,
  component,
  ...props
}: Props<P>) => {
  const className = classes(baseClassName, {
    'align-items-center': flex && flex.align === 'center',
    'align-items-end': flex && flex.align === 'right',
    'align-items-start': flex && flex.align === 'left',
    'bg-dark': background === 'dark',
    'bg-light': background === 'light',
    'bg-primary': background === 'primary',
    'bg-secondary': background === 'secondary',
    'bg-tertiary': background === 'tertiary',
    'border-dark': borderColor === 'dark',
    'border-light': borderColor === 'light',
    'border-primary': borderColor === 'primary',
    'border-secondary': borderColor === 'secondary',
    'border-tertiary': borderColor === 'tertiary',
    'd-flex': flex !== undefined,
    'd-lg-block': hiddenUnder === 'lg',
    'd-lg-none': hiddenOver === 'lg',
    'd-md-block': hiddenUnder === 'md',
    'd-md-none': hiddenOver === 'md',
    'd-none': hiddenUnder !== undefined,
    'd-sm-block': hiddenUnder === 'sm',
    'd-sm-none': hiddenOver === 'sm',
    'd-xl-block': hiddenUnder === 'xl',
    'd-xl-none': hiddenOver === 'xl',
    'd-xs-block': hiddenUnder === 'xs',
    'd-xs-none': hiddenOver === 'xs',
    'flex-column': flex && flex.direction === 'column',
    'flex-row': flex && flex.direction === 'row',
    'justify-content-center': flex && flex.justify === 'center',
    'justify-content-end': flex && flex.justify === 'right',
    'justify-content-start': flex && flex.justify === 'left',
    'text-center': textAlign === 'center',
    'text-dark': textColor === 'dark',
    'text-left': textAlign === 'left',
    'text-light': textColor === 'light',
    'text-primary': textColor === 'primary',
    'text-right': textAlign === 'right',
    'text-secondary': textColor === 'secondary',
    'text-tertiary': textColor === 'tertiary',
  })
  const Tag: ElementType = component ?? 'div'
  return <Tag {...props} className={className} />
}
