import {graphql} from 'gatsby'
import * as IO from 'io-ts'
import {LinkedItems} from '../../util'
import {KenticoContentData} from '../content'
import {KenticoUtmParamsData} from '../utm-params'
import {KenticoFormData} from '../form'

/** Campaign Landing. */
export const KenticoCampaignLandingData = IO.intersection(
  [
    KenticoContentData,
    IO.type({
      elements: IO.type({
        form: LinkedItems(KenticoFormData),
        utmData: LinkedItems(KenticoUtmParamsData),
      }),
      system: IO.type({
        type: IO.literal('campaign_landing'),
      }),
    }),
  ],
  'KenticoCampaignLanding',
)

/** Data type. */
export interface KenticoCampaignLandingData
  extends IO.TypeOf<typeof KenticoCampaignLandingData> {}

/** Fragment helper. */
export const fragment = graphql`
  fragment KenticoCampaignLanding on Node {
    ... on kontent_item_campaign_landing {
      elements {
        form {
          nodes: value {
            ...KenticoForm
          }
        }
        utmData: utm_data {
          nodes: value {
            ...KenticoUtmParams
          }
        }
      }
      system {
        id
        codename
        name
        type
      }
    }
  }
`
