/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import {darken} from 'polished'
import React, {ReactNode} from 'react'
import {FaUserCircle} from 'react-icons/fa'
import styled from 'styled-components'
import {Button} from '~/bootstrap/components/button'
import {
  Nav as BootstrapNav,
  NavItem as BootstrapNavItem,
} from '~/bootstrap/components/nav'
import {Navbar as BootstrapNavbar} from '~/bootstrap/components/navbar'
import {Customize} from '~/common/components/customize'
import {KenticoLink, KenticoLinkData} from '../link'
import {KenticoPageLinkData} from '../page-link'
import {KenticoPhoneLinkData} from '../phone-link'
import {KenticoHeaderDropdownData} from './data'
import {Locations} from './locations'
import {Sidenav} from './sidenav'
import {Link} from '~/common/components/link'

interface Props {
  logo: ReactNode
  phoneNumber: KenticoPhoneLinkData[]
  buttonText: string
  buttonLink: KenticoLinkData[]
  locatorLink: string
  links: (KenticoPageLinkData | KenticoHeaderDropdownData)[]
  mobileButtonText: string
  mobileButtonLink: KenticoLinkData[]
  signInButtonDisplay: string
  signInButtonUrl: string
  signInButtonText: string
  shouldRenderButton: boolean
}

const Navbar = styled(BootstrapNavbar)`
  border-bottom: 1px solid ${({theme}) => darken(0.2, theme.colors.light)};
`

const TopNavbar = styled(Navbar)`
  background-color: ${({theme}) => darken(0.02, theme.colors.light)};

  && {
    padding-top: 0;
    padding-bottom: 0;
  }
`

const BottomNavbar = styled(Navbar)`
  && {
    padding: ${({theme}) => theme.spacers[3]} 0;
  }
`

const NavItem = styled(BootstrapNavItem)`
  && > a {
    font-size: 0.9rem;
  }
`

const Nav = styled(BootstrapNav)`
  flex: 1;
  align-items: center;
  justify-content: space-between;
`

const SideNavItem = styled(NavItem)`
  align-self: stretch;
`

const LogoNavItem = styled(NavItem)`
  flex: 1;
  transform: translate(6px, 2px);
`

const NavButtonItem = styled(NavItem)`
  padding-right: ${({theme}) => theme.spacers[2]};

  @media (max-width: ${({theme}) => theme.breakpoints.max.sm}) {
    button {
      font-size: 0.8rem;
      padding: ${({theme}) => theme.spacers[2]};
    }
  }
`
const SignInLink = styled(Link)`
  flex: 1;
  text-align: right !important;
  color: ${({theme}) => theme.colors.secondary};
`

const UserIcon = styled(FaUserCircle)`
  margin-right: ${({theme}) => theme.spacers[2]};
  margin-bottom: ${({theme}) => theme.spacers[1]};
`

/**
 * Render Mobile header component.
 * @return React component
 */
export const MobileHeader = ({
  buttonLink,
  buttonText,
  links,
  locatorLink,
  logo,
  mobileButtonLink,
  mobileButtonText,
  phoneNumber,
  signInButtonDisplay,
  signInButtonUrl,
  signInButtonText,
  shouldRenderButton,
}: Props) => {
    // The behavior/logic for the mobile sign in button is different from the desktop
    const signInButton =
    signInButtonDisplay === 'display' ? (
      <SignInLink to={signInButtonUrl}>
        <UserIcon />
        {signInButtonText}
      </SignInLink>
    ) : (
      <></>
    )

    return (
      <Customize hiddenOver="md">
        <TopNavbar>
          <Nav>
          <NavItem>
              <Locations locatorLink={locatorLink} />
          </NavItem>
          <NavItem>
            {signInButton}
          </NavItem>
          </Nav>
        </TopNavbar>
        <BottomNavbar>
          <Nav>
            <SideNavItem>
              <Sidenav
                buttonLink={mobileButtonLink}
                buttonText={mobileButtonText}
                links={links}
                phoneNumber={phoneNumber}
              />
            </SideNavItem>
            <LogoNavItem>{logo}</LogoNavItem>
            {shouldRenderButton && (
              <NavButtonItem>
                <Button
                  color="secondary"
                  data={buttonLink}
                  size="sm"
                  tag={KenticoLink}
                >
                  {buttonText}
                </Button>
              </NavButtonItem>
            )}
          </Nav>
        </BottomNavbar>
      </Customize>
    )
}
