import {StandaloneSearchBox} from '@react-google-maps/api'
import {get} from 'lodash'
import React, {ReactNode, useState} from 'react'
import {useFormContext} from 'react-hook-form'
import {ValidationOptions} from 'react-hook-form/dist/types'
import {FormFeedback, FormGroup, Input, Label} from 'reactstrap'
import {GoogleLoader} from '../loader'
import {useOnPlacesChanged} from './hooks'

/** Data provided for onChange. */
export interface OnChangeData {
  location?: {
    lat: number
    lng: number
  }
  address?: string
  city?: string
  state?: string
  zip?: string
}

interface Props extends ValidationOptions {
  label?: ReactNode
  name: string
  placeholder?: string
  disabled?: boolean
  onChange(data: OnChangeData): void
}

const US_BOUNDS = {
  east: -67,
  north: 49,
  south: 25,
  west: -125,
}

/**
 * Render Google Places Search.
 * @return React component
 */
export const GooglePlaces = ({
  disabled,
  label,
  name,
  placeholder,
  onChange,
  ...options
}: Props) => {
  const {errors, register, formState} = useFormContext()
  const error = get(errors, name)
  const ref = register(options)
  const [searchBox, setSearchBox] = useState<google.maps.places.SearchBox>()

  let errorMessage
  if (error && 'type' in error) {
    const message = error.message === '' ? 'Invalid field' : error.message
    errorMessage = <FormFeedback>{message}</FormFeedback>
  }

  let labelMessage
  if (label !== undefined) {
    const message =
      options.required !== undefined && typeof label === 'string' ? (
        <>{label}*</>
      ) : (
        label
      )
    labelMessage = (
      <small>
        <Label htmlFor={name}>{message}</Label>
      </small>
    )
  }

  const onPlacesChanged = useOnPlacesChanged({onChange, searchBox})
  const valid = !error && (get(formState.touched, name) as boolean | undefined)

  const handleChange = (e: { preventDefault: () => void }) => {
    e.preventDefault();
  };

  return (
    <GoogleLoader>
      <FormGroup>
        {labelMessage}
        <StandaloneSearchBox
          bounds={US_BOUNDS}
          onLoad={setSearchBox}
          onPlacesChanged={onPlacesChanged}
        >
          <Input
            disabled={disabled}
            id={name}
            innerRef={ref}
            invalid={Boolean(error)}
            name={name}
            placeholder={placeholder}
            valid={valid}
            onCut={handleChange}
            onCopy={handleChange}
            onPaste={handleChange}
          />
        </StandaloneSearchBox>
        {errorMessage}
      </FormGroup>
    </GoogleLoader>
  )
}
