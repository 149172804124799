// https://github.com/JustFly1984/react-google-maps-api
//   /issues/113#issuecomment-490505877
// istanbul ignore file
import {InfoWindow} from '@react-google-maps/api'
import React, {ReactNode} from 'react'
import styled from 'styled-components'
import {Button as BootstrapButton} from '~/bootstrap/components/button'
import {Col, Container} from '~/bootstrap/components/layout'
import {Link} from '~/common/components/link'
import {formatPhoneNumber} from '~/common/util'
import {Store} from '../../types'

const InfoWindowContainer = styled(Container)`
  && {
    padding: ${({theme}) => theme.spacers[2]} 0;
  }
`

const InfoWindowText = styled(Col)`
  && > p {
    margin-bottom: ${({theme}) => theme.spacers[1]};
  }
`

const Button = styled(BootstrapButton)`
  margin-top: ${({theme}) => theme.spacers[2]};

  && {
    font-size: 0.8rem;
  }
`

const PhoneLink = styled.a`
  text-decoration: underline;
`

const StoreTitle = styled.p`
  font-weight: bold;
`

interface Props {
  logo?: ReactNode
  anchor: google.maps.Marker
  store: Store
  showStoreNumbers?: boolean
  onClose(): void
}

/**
 * Render the store info window.
 * @return React component
 */
export const StoresMapInfoWindow = ({
  logo,
  store,
  onClose,
  anchor,
  showStoreNumbers,
}: Props) => {
  let button
  if (store.url !== undefined) {
    button = (
      <Button block size="sm" tag={Link} to={store.url}>
        See More Info
      </Button>
    )
  }
  return (
    <InfoWindow anchor={anchor} onCloseClick={onClose}>
      <InfoWindowContainer>
        <InfoWindowText>
          {logo}
          <StoreTitle>
            Cash Store {showStoreNumbers ? `- ${store.number}` : ''}
          </StoreTitle>
          <p>{store.address}</p>
          <p>
            {store.city}, {store.state} {store.zip}
          </p>
          <p>
            <PhoneLink href={`tel:${store.phone}`}>
              {formatPhoneNumber(store.phone)}
            </PhoneLink>
          </p>
          {button}
        </InfoWindowText>
      </InfoWindowContainer>
    </InfoWindow>
  )
}
