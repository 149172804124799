import {useCallback, useEffect, useState} from 'react'
import {FormContextValues} from 'react-hook-form'

type Form = FormContextValues<FormData>

/** Form data. */
export interface FormData {
  firstName: string
  lastName: string
  email: string
  dateOfBirth: {
    month: string
    day: string
    year: string
  }
  socialNumber: string
  addressOne: string
  addressTwo: string
  city: string
  state: string
  zipCode: string
  phone: string
  recaptcha: string
  tcpa: boolean
  privacyConsent: boolean
  lat: number
  lng: number
}

/**
 * Create onChange handler.
 * @param form Form data
 * @return onChange handler
 */
// istanbul ignore next
export const useOnGeocode = (form: Form) => {
  useEffect(() => {
    form.register({name: 'lat'})
    form.register({name: 'lng'})
  }, [form])
  const {setValue} = form

  const [lastAddress, setLastAddress] = useState('')

  return useCallback(() => {
    const geocoder = new google.maps.Geocoder()

    if (
      form.getValues().addressOne &&
      form.getValues().city &&
      form.getValues().zipCode.length >= 5
    ) {
      const address = `${form.getValues().addressOne}, ${
        form.getValues().city
      }, ${form.getValues().zipCode}`

      if (lastAddress !== address) {
        setLastAddress(address)
        geocoder.geocode(
          {
            address,
          },
          (results, status) => {
            if (status == google.maps.GeocoderStatus.OK) {
              setValue('lat', results[0].geometry.location.lat(), true)
              setValue('lng', results[0].geometry.location.lng(), true)
            }
          },
        )
      }
    }
  }, [form, setValue, lastAddress])
}
