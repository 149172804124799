import {createContext} from 'react'

/** Context information. */
export interface KenticoContext {
  contentId?: string
}

/** Default context. */
export const DEFAULT_CONTEXT: KenticoContext = {}

/** Context implementation. */
export const KenticoContext = createContext(DEFAULT_CONTEXT)

// eslint-disable-next-line functional/immutable-data
KenticoContext.displayName = 'Kentico'
