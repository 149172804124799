import {History, Link, globalHistory} from '@reach/router'
import {constant, noop} from 'lodash'
import {ComponentType, createContext} from 'react'

// The defaults in here should never be used.
/* eslint-disable @typescript-eslint/no-non-null-assertion */

/** Storage API. */
export type StorageAPI = import('./storage').StorageAPI

// Create dummy storage for default context
const storage = {
  clear: noop,
  has: constant(false),
  read: constant(undefined!),
  useNamespace: undefined!,
  write: noop,
}

// eslint-disable-next-line functional/immutable-data
Object.assign(storage, {useNamespace: constant(storage)})

/** Language types. */
export type Language = 'en' | 'es' // | 'fr' | ...

/** Context information. */
export interface CommonContext {
  history: History
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  link: ComponentType<any>
  language: Language
  platform: 'mobile' | 'web'
  session: StorageAPI
  storage: StorageAPI
  api: import('./api').APICall
  setLanguage(language: Language): void
}

/** Default language. */
export const DEFAULT_LANGUAGE: Language = 'en'

/** Default context. */
export const DEFAULT_CONTEXT: CommonContext = {
  api: constant(undefined!),
  history: globalHistory,
  language: DEFAULT_LANGUAGE,
  link: Link,
  platform: 'web',
  session: storage,
  setLanguage: noop,
  storage,
}

/** Context implementation. */
export const CommonContext = createContext(DEFAULT_CONTEXT)

// eslint-disable-next-line functional/immutable-data
CommonContext.displayName = 'Common'
