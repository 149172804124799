import {graphql} from 'gatsby'
import * as IO from 'io-ts'
import {LinkedItems} from '../../util'
import {KenticoContentData} from '../content'
import {KenticoFixedImageData} from '../fixed-image'
import {KenticoLinkData} from '../link'
import {KenticoRichTextData} from '../rich-text'

/** Footer. */
export const KenticoFooterData = IO.intersection(
  [
    KenticoContentData,
    IO.type({
      elements: IO.type({
        header1: IO.type({value: IO.string}),
        header2: IO.type({value: IO.string}),
        header3: IO.type({value: IO.string}),
        info: KenticoRichTextData,
        links1: LinkedItems(KenticoLinkData),
        links2: LinkedItems(KenticoLinkData),
        links3: LinkedItems(KenticoLinkData),
        logo: IO.type({
          value: IO.array(KenticoFixedImageData),
        }),
      }),
      system: IO.type({
        type: IO.literal('footer'),
      }),
    }),
  ],
  'KenticoFooter',
)

/** Data Type. */
export interface KenticoFooterData
  extends IO.TypeOf<typeof KenticoFooterData> {}

/** Fragment helper. */
export const fragment = graphql`
  fragment KenticoFooter on Node {
    ... on kontent_item_footer {
      elements {
        info {
          ...KenticoRichText
        }
        header1 {
          value
        }
        links1 {
          nodes: value {
            ...KenticoLink
          }
        }
        header2 {
          value
        }
        links2 {
          nodes: value {
            ...KenticoLink
          }
        }
        header3 {
          value
        }
        links3 {
          nodes: value {
            ...KenticoLink
          }
        }
        logo {
          value {
            file {
              childImageSharp {
                gatsbyImageData(layout: FIXED, height: 40)
              }
            }
          }
        }
      }
      system {
        id
        codename
        name
        type
      }
    }
  }
`
