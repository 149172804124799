import {noop} from 'lodash'
import {createContext} from 'react'

interface Unselected {
  ready: false
  year?: undefined
  make?: undefined
  model?: undefined
  series?: undefined
  style?: undefined
}

interface SelectedYear {
  ready: false
  year: number
  make?: undefined
  model?: undefined
  series?: undefined
  style?: undefined
}

interface SelectedMake {
  ready: false
  year: number
  make: string
  model?: undefined
  series?: undefined
  style?: undefined
}

interface SelectedModel {
  ready: false
  year: number
  make: string
  model: string
  series?: undefined
  style?: undefined
}

interface SelectedSeries {
  ready: false
  year: number
  make: string
  model: string
  series: string
  style?: undefined
}

interface SelectedStyle {
  ready: true
  year: number
  make: string
  model: string
  series: string
  style?: string
}

interface SelectedCalls {
  reset(): void
  setYear(year: number): void
  setMake(make: string): void
  setModel(model: string): void
  setSeries(series: string): void
  setStyle(style: string): void
}

/** Selected vehicle. */
export type SelectedVehicle =
  | Unselected
  | SelectedYear
  | SelectedMake
  | SelectedModel
  | SelectedSeries
  | SelectedStyle

/** Vehicles information. */
export interface VehiclesContext {
  ready: boolean
  selected: SelectedVehicle & SelectedCalls
  makes: string[]
  models: string[]
  series: string[]
  styles: string[]
  years: number[]
  error?: Error
}

/** Default context. */
export const DEFAULT_CONTEXT: VehiclesContext = {
  makes: [],
  models: [],
  ready: false,
  selected: {
    ready: false,
    reset: noop,
    setMake: noop,
    setModel: noop,
    setSeries: noop,
    setStyle: noop,
    setYear: noop,
  },
  series: [],
  styles: [],
  years: [],
}

/** Context implementation. */
export const VehiclesContext = createContext(DEFAULT_CONTEXT)

// eslint-disable-next-line functional/immutable-data
VehiclesContext.displayName = 'Vehicles'
