import {graphql} from 'gatsby'
import * as IO from 'io-ts'
import {LinkedItems} from '../../util'
import {KenticoContentData} from '../content'
import {KenticoFluidImageData} from '../fluid-image'
import {KenticoPageLinkData} from '../page-link'
import {KenticoRichTextData} from '../rich-text'

/** Single column row. */
export const KenticoErrorBodyData = IO.intersection(
  [
    KenticoContentData,
    IO.type({
      elements: IO.type({
        body: KenticoRichTextData,
        image: IO.type({
          value: IO.array(KenticoFluidImageData),
        }),
        links: LinkedItems(KenticoPageLinkData),
      }),
      system: IO.type({
        type: IO.literal('error_body'),
      }),
    }),
  ],
  'KenticoErrorBody',
)

/** Row data type. */
export interface KenticoErrorBodyData
  extends IO.TypeOf<typeof KenticoErrorBodyData> {}

/** Fragment helper. */
export const fragment = graphql`
  fragment KenticoErrorBody on Node {
    ... on kontent_item_error_body {
      elements {
        image {
          value {
            ...KenticoFluidImage
          }
        }
        links {
          nodes: value {
            ...KenticoPageLink
          }
        }
        body {
          ...KenticoRichText
        }
      }
      system {
        codename
        id
        name
        type
      }
    }
  }
`
