import {ReferralContext} from '~/referral/context'
import {ReferralForm} from '~/referral/types'
import {flatten as flatUtil} from 'flat'

interface Options {
  referral: ReferralContext
  setSuccess(value: boolean): void
}

/**
 * Create on submit handler.
 * @return Submit handler
 */
export const createOnSubmit = ({referral, setSuccess}: Options) => async (
  formData: ReferralForm,
) => {
  const data: ReferralForm = {
    email: formData.email,
    name: {
      first: formData.name.first,
      last: formData.name.last,
    },
    referrals: formData.referrals.filter(
      element => element.email.trim() !== '' && element.name.trim() !== '',
    ),
  }
  const wnd = window as any;
  if ('appInsights' in wnd) {
      wnd.appInsights.trackEvent('Cashstore:ReferralForm', flatUtil.flatten(data))
  }
  const result = await referral.submit(data)
  setSuccess(result)
}
