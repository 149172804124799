import {UUID} from 'io-ts-types/lib/UUID'
import React, {useContext, useEffect, useMemo} from 'react'
import styled, {ThemeContext} from 'styled-components'
import {Col, Container, Row} from '~/bootstrap/components/layout'
import {Customize} from '~/common/components/customize'
import {Noop} from '~/common/components/noop'
import {Slider, SliderOptions} from '~/common/components/slider'
import {Stars} from '~/common/components/stars'
import {ReviewsCard} from '~/reviews/components/card'
import {ReviewsContext} from '~/reviews/context'
import {KenticoPreviewBodyLink} from '../preview-link'
import {Arrow} from './arrows'
import {KenticoReviewsData} from './data'

interface Props {
  data: KenticoReviewsData
}

/** Kentico type data. */
export {KenticoReviewsData}

const ContentContainer = styled.div`
  padding: ${({theme}) => theme.spacers[5]} 0;
`

const SliderRow = styled(Row)`
  padding: 0 ${({theme}) => theme.spacers[3]};
`

const SliderCol = styled(Col)`
  .slick-prev:before,
  .slick-next:before {
    color: black;
  }

  .slick-slide > div {
    margin: 0 ${({theme}) => theme.spacers[2]};
  }
`

const ReviewSummary = styled(Row)`
  padding-top: ${({theme}) => theme.spacers[5]};
  padding-bottom: ${({theme}) => theme.spacers[4]};
  align-items: center;
`

const AverageRating = styled.h1`
  font-size: 7.25rem;
  font-weight: 100;
`

const AverageRatingCol = styled(Col)`
  text-align: right;

  @media (max-width: ${({theme}) => theme.breakpoints.max.sm}) {
    text-align: center;
  }
`

const LargeStarsRow = styled(Row)`
  justify-content: flex-start;

  @media (max-width: ${({theme}) => theme.breakpoints.max.sm}) {
    justify-content: center;
  }
`

const LargeStars = styled(Stars)`
  font-size: 3.5rem;
`

/**
 * Render reviews from Kentico data.
 * @return React component
 */
export const KenticoReviews = ({
  data: {
    elements: {title},
    system: {id, name},
  },
}: Props) => {
  const theme = useContext(ThemeContext)
  const {average, ready, reviews, request} = useContext(ReviewsContext)
  useEffect(() => {
    request()
  }, [request])

  const settings = useMemo<SliderOptions>(
    () => ({
      arrows: true,
      infinite: true,
      nextArrow: <Arrow next />,
      prevArrow: <Arrow previous />,
      responsive: [
        {
          breakpoint: parseFloat(theme.breakpoints.max.md),
          settings: {
            slidesToShow: 1,
          },
        },
      ],
      slidesToScroll: 1,
      slidesToShow: 3,
      speed: 500,
    }),
    [theme.breakpoints.max.md],
  )
  if (!ready) {
    return <Noop />
  }

  const userReviews = reviews.map((review, index) => (
    // No better key
    // eslint-disable-next-line react/no-array-index-key
    <ReviewsCard key={index} review={review} />
  ))

  let editLink
  if (!UUID.is(name)) {
    editLink = <KenticoPreviewBodyLink contentItem={id} />
  }
  return (
    <Customize background="light" component={ContentContainer}>
      <Container>
        {editLink}
        <Row>
          <Col align="center">
            <h2>{title.value}</h2>
          </Col>
        </Row>
        <ReviewSummary>
          <AverageRatingCol md="6" sm="12">
            <AverageRating>{average}</AverageRating>
          </AverageRatingCol>
          <Col md="6" sm="12">
            <LargeStarsRow>
              <LargeStars value={Math.floor(average)} />
            </LargeStarsRow>
          </Col>
        </ReviewSummary>
        <SliderRow>
          <SliderCol>
            <Slider {...settings}>{userReviews}</Slider>
          </SliderCol>
        </SliderRow>
      </Container>
    </Customize>
  )
}
