import {graphql} from 'gatsby'
import * as IO from 'io-ts'
import {LinkedItems} from '../../util'
import {KenticoContentData} from '../content'
import {KenticoFixedImageData} from '../fixed-image'

/** List With Images Horizontal Data. */
export const KenticoListWithImagesHorizontalData = IO.intersection(
  [
    KenticoContentData,
    IO.type({
      elements: IO.type({
        items: LinkedItems(
          IO.type(
            {
              elements: IO.type({
                image: IO.type({
                  value: IO.array(KenticoFixedImageData),
                }),
                text: IO.type({
                  value: IO.string,
                }),
              }),
              system: IO.type({
                codename: IO.string,
              }),
            },
            'KenticoListWithImagesHorizontalItem',
          ),
        ),
        title: IO.type({
          value: IO.string,
        }),
      }),
      system: IO.type({
        type: IO.literal('list_with_images_horizontal'),
      }),
    }),
  ],
  'KenticoListWithImagesHorizontal',
)

/** Data Type. */
export interface KenticoListWithImagesHorizontalData
  extends IO.TypeOf<typeof KenticoListWithImagesHorizontalData> {}

/** Fragment Helper. */
export const fragment = graphql`
  fragment KenticoListWithImagesHorizontal on Node {
    ... on kontent_item_list_with_images_horizontal {
      elements {
        items {
          nodes: value {
            ... on kontent_item_list_with_images_item {
              elements {
                image {
                  value {
                    description
                    file {
                      childImageSharp {
                        gatsbyImageData(layout: FIXED, width: 72)
                      }
                    }
                  }
                }
                text {
                  value
                }
              }
              system {
                codename
              }
            }
          }
        }
        title {
          value
        }
      }
      system {
        id
        codename
        name
        type
      }
    }
  }
`
