import React from 'react'
import {FaChevronLeft, FaChevronRight} from 'react-icons/fa'
import {Customize} from '~/common/components/customize'
import {SliderArrowProps} from '~/common/components/slider'

interface NextSide {
  next: true
}

interface PreviousSide {
  previous: true
}

interface BaseProps extends SliderArrowProps {
  next?: true
  previous?: true
}

type Props = BaseProps & (NextSide | PreviousSide)

/**
 * Render arrow.
 * @return React component
 */
export const Arrow = ({
  currentSlide: _currentSlide,
  next,
  previous: _right,
  slideCount: _slideCount,
  ...props
}: Props) => (
  <Customize
    {...props}
    aria-label={next ? 'Next Review' : 'Previous Review'}
    component={next ? FaChevronRight : FaChevronLeft}
    data-role="button"
    role="button"
    textColor="dark"
  />
)
