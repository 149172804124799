// TODO update / figure out testing for this file
// istanbul ignore file
import {noop} from 'lodash'
import {useCallback, useMemo} from 'react'
import {KenticoPageLinkData} from '~/kentico/components/page-link'
import {createUrl, linkedItems} from '~/kentico/util'
import {navigate} from '@reach/router'
import {CampaignAPI} from '../../context'
import {CampaignFormData} from '../../types'

type WebFormProps = import('../form').WebFormProps

interface OnSubmitOptions extends Pick<WebFormProps, 'submissionLinks'> {
  api: CampaignAPI
}

interface RedirectLink {
  code: string
  url: KenticoPageLinkData
}

/**
 * Create onSubmit handler.
 * @return onSubmit handler
 */
export const useOnSubmit = ({api, submissionLinks}: OnSubmitOptions) => {
  let redirectLinks: RedirectLink[] = []
  submissionLinks.forEach(
    link =>
      (redirectLinks = [
        ...redirectLinks,
        {
          code:
            link.elements.responseStatus.value
              .find(statusCode => statusCode.codename)
              ?.codename.toUpperCase() ?? '',
          url: linkedItems(link.elements.redirectPage)[0],
        },
      ]),
  )

  // Construct redirect links using appropriate react hooks
  const validLink = useMemo(() => {
    const url = redirectLinks.find(item => item.code === 'APPROVED')?.url
    return url === undefined ? url : createUrl(url)
  }, [redirectLinks])
  const errorLink = useMemo(() => {
    const url = redirectLinks.find(item => item.code === 'ERROR')?.url
    return url === undefined ? url : createUrl(url)
  }, [redirectLinks])

  // Declare navigation functions for appropriate responses
  const onValid = useCallback(
    async () => (validLink === undefined ? noop : navigate(validLink)),
    [validLink],
  )
  const onError = useCallback(
    async () => (errorLink === undefined ? noop : navigate(errorLink)),
    [errorLink],
  )

  return useCallback(
    async (formData: CampaignFormData) => {
      const wnd = window as any;
      if ('appInsights' in wnd) {
          wnd.appInsights.trackEvent('Cashstore:CampaignForm', formData)
      }

      const result = await api.submit({
        accessCode: formData.accessCode,
        appointmentDate: formData.appointmentDate,
        appointmentTime: formData.appointmentTime,
        emailAddress: formData.emailAddress,
        firstName: formData.firstName,
        last4SSN: formData.last4SSN,
        lastName: formData.lastName,
        phoneNumber: formData.phoneNumber,
        termsAndConditionsAgreed: formData.termsAndConditionsAgreed,
      })
      switch (result) {
        case 'VALID':
          await onValid()
          break
        case 'ERROR':
          await onError()
          break
        case 'INVALID':
        default:
          break
      }
    },
    [api, onError, onValid],
  )
}
