import {Marker} from '@react-google-maps/api'
import React, {useCallback, useState} from 'react'

interface Props<T> {
  icon?: string
  data: T
  opacity?: number
  position: google.maps.LatLngLiteral
  onClick(marker: google.maps.Marker, data: T): void
}

/**
 * Render the map marker.
 * @return React component
 */
export const StoresMapMarker = <T extends {}>({
  icon,
  data,
  onClick,
  position,
}: Props<T>) => {
  const [marker, setMarker] = useState<google.maps.Marker>()
  const onClickMarker = useCallback(() => {
    if (!marker) {
      return
    }
    onClick(marker, data)
  }, [onClick, data, marker])
  return (
    <Marker
      icon={icon}
      position={position}
      onClick={onClickMarker}
      onLoad={setMarker}
    />
  )
}
