import {UUID} from 'io-ts-types/lib/UUID'
import React, {useContext} from 'react'
import styled from 'styled-components'
import {
  Col,
  Container as BootstrapContainer,
  Row,
} from '~/bootstrap/components/layout'
import {Noop} from '~/common/components/noop'
import {Spinner as SpinnerBase} from '~/common/components/spinner'
import {useQuery} from '~/gatsby/util'
import {StoresList} from '~/stores/components/list'
import {StoresMap} from '~/stores/components/map'
import {StoresContext} from '~/stores/context'
import {KenticoPreviewBodyLink} from '../preview-link'
import {KenticoCityMapData} from './data'

interface Props {
  data: KenticoCityMapData
}

/** Kentico type data. */
export {KenticoCityMapData}

const Container = styled(BootstrapContainer)`
  && {
    padding: 0;
  }
`

const Title = styled(Row)`
  padding: ${({theme}) => theme.spacers[4]} ${({theme}) => theme.spacers[3]};
`

const ResultsRow = styled(Row)`
  @media (max-width: ${({theme}) => theme.breakpoints.max.md}) {
    flex-direction: column-reverse;
  }
`

const ResultsCol = styled(Col)`
  @media (min-width: ${({theme}) => theme.breakpoints.min.lg}) {
    height: 635px;
    overflow-x: hidden;
    overflow-y: auto;
  }
`

const StoresContainer = styled.div`
  padding: ${({theme}) => theme.spacers[2]};
`

const Spinner = styled(SpinnerBase)`
  width: 56px;
  height: 56px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  && > * {
    border-width: 5px;
  }
`

/**
 * Render city map from Kentico data.
 * @return React component
 */
export const KenticoCityMap = ({
  data: {
    system: {id, name},
  },
}: Props) => {
  const {search, store, stores} = useContext(StoresContext)
  const {mapMarker} = useQuery()
  if (store || !stores || stores.length === 0) {
    return <Noop />
  }
  const [{city, state}] = stores
  const spinner = search.ready ? <Noop /> : <Spinner />

  let editLink
  if (!UUID.is(name)) {
    editLink = <KenticoPreviewBodyLink contentItem={id} />
  }
  return (
    <Container>
      {editLink}
      <Title noGutters>
        <Col lg="6">
          <h2>
            Cash Store Locations in {city}, {state}
          </h2>
        </Col>
      </Title>
      <ResultsRow noGutters>
        <ResultsCol lg>
          <StoresContainer>
            {spinner}
            <StoresList stores={stores} />
          </StoresContainer>
        </ResultsCol>
        <Col lg>
          <StoresMap icon={mapMarker} stores={stores} />
        </Col>
      </ResultsRow>
    </Container>
  )
}
