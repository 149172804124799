"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

exports.__esModule = true;
exports.convertProps = exports.stripRemainingProps = void 0;

var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));

var _filterInvalidDomProps = _interopRequireDefault(require("filter-invalid-dom-props"));

var _MediaUtils = require("./MediaUtils");

var stripRemainingProps = function stripRemainingProps(props) {
  return (0, _filterInvalidDomProps.default)(props);
};

exports.stripRemainingProps = stripRemainingProps;

var convertProps = function convertProps(props) {
  var convertedProps = (0, _extends2.default)({}, props);
  var fixed = convertedProps.fixed,
      fluid = convertedProps.fluid;

  if (fluid && (0, _MediaUtils.hasArtDirectionSupport)(props, 'fluid')) {
    convertedProps.fluid = (0, _MediaUtils.groupByMedia)(convertedProps.fluid);
  }

  if (fixed && (0, _MediaUtils.hasArtDirectionSupport)(props, 'fixed')) {
    convertedProps.fixed = (0, _MediaUtils.groupByMedia)(convertedProps.fixed);
  }

  return convertedProps;
};

exports.convertProps = convertProps;