import {darken} from 'polished'
import React from 'react'
import styled from 'styled-components'

// There is no other key that can be used
/* eslint-disable react/no-array-index-key */

interface Props {
  className?: string
  value: number
}

const STAR = String.fromCharCode(0x2605)

const EmptyStar = styled.span`
  display: inline-block;
  position: relative;
  color: transparent;

  &:before,
  &:after {
    color: ${({theme}) => darken(0.3, theme.colors.light)};
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    content: '\u2605';
  }
`

const HalfStar = styled(EmptyStar)`
  &:before {
    transform: translateX(100%) scaleX(-1);
    width: 50%;
  }

  &:after {
    color: ${({theme}) => theme.colors.primary};
    width: 50%;
  }
`

const FilledStar = styled(EmptyStar)`
  &:before,
  &:after {
    color: ${({theme}) => theme.colors.primary};
  }
`

const Container = styled.span`
  display: inline-block;
`

const STAR_VALUES = [1, 2, 3, 4, 5] as const

/**
 * Render stars for review.
 * @return React component
 */
export const Stars = ({className, value}: Props) => {
  const children = STAR_VALUES.map((star, index) => {
    const delta = value - star
    if (delta >= 0) {
      return <FilledStar key={index}>{STAR}</FilledStar>
    } else if (delta <= -1) {
      return <EmptyStar key={index}>{STAR}</EmptyStar>
    }

    return <HalfStar key={index}>{STAR}</HalfStar>
  })
  return <Container className={className}>{children}</Container>
}
