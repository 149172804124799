import * as IO from 'io-ts'
import {useCallback, useContext, useMemo, useState} from 'react'
import {CommonContext} from '~/common/context'
import {Store} from '../types'
import {StoreResponse, parseStoreResponse} from '../types/api'
import {StoresSearch} from '.'

/**
 * Construct search API.
 * @return Search API
 */
export const useStoresSearch = () => {
  const {api} = useContext(CommonContext)
  const [error, setError] = useState<Error>()
  const [ready, setReady] = useState(true)
  const [stores, setStores] = useState<Store[]>()

  const run = useCallback<StoresSearch['run']>(
    async location => {
      if (!ready) {
        return
      }
      setError(undefined)
      setReady(false)
      setStores(undefined)
      try {
        // Check data from specific to general
        let response
        if ('lat' in location) {
          response = await api({
            data: {
              latitude: location.lat,
              longitude: location.lng,
            },
            method: 'GET',
            type: IO.array(StoreResponse),
            url: 'v1/stores/search',
          })
        } else {
          response = await api({
            data: {
              state: location.state,
            },
            method: 'GET',
            type: IO.array(StoreResponse),
            url: 'v1/stores/in-state',
          })
        }
        setStores(response.map(parseStoreResponse))
      } catch {
        setError(new Error('Unable to query stores'))
      } finally {
        setReady(true)
      }
    },
    [api, ready, setReady, setStores],
  )

  return useMemo<StoresSearch>(
    () => ({
      error,
      ready,
      run,
      stores,
    }),
    [error, ready, run, stores],
  )
}
