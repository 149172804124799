import {graphql} from 'gatsby'
import * as IO from 'io-ts'
import {KenticoBodyItemBaseData} from '../body-item-base'
import {KenticoFullSizeRowData} from '../full-size-row'
import {KenticoRowData} from '../row'
import {KenticoTwoColumnLayoutData} from '../two-column-layout'

/** Data type. */
export type KenticoBodyItemData =
  | KenticoBodyItemBaseData
  | KenticoFullSizeRowData
  | KenticoRowData
  | KenticoTwoColumnLayoutData

/** Kentico body item data. */
export const KenticoBodyItemData = IO.union(
  [
    KenticoBodyItemBaseData,
    KenticoFullSizeRowData,
    KenticoRowData,
    KenticoTwoColumnLayoutData,
  ],
  'KenticoBodyItem',
)

/** Fragment helper. */
export const fragment = graphql`
  fragment KenticoBodyItem on Node {
    ...KenticoBodyItemBase
    ...KenticoFullSizeRow
    ...KenticoTwoColumnLayout
    ...KenticoRow
  }
`
