import {graphql} from 'gatsby'
import * as IO from 'io-ts'
import {Color} from '~/common/types'
import {LinkedItems} from '../../util'
import {KenticoContentData} from '../content'
import {KenticoFixedImageData} from '../fixed-image'
import {KenticoLinkData} from '../link'
import {KenticoRichTextData} from '../rich-text'

/** Button with Image. */
export const KenticoButtonWithImageData = IO.intersection(
  [
    KenticoContentData,
    IO.type({
      elements: IO.type({
        buttonColor: IO.type({
          value: IO.array(
            IO.type({
              codename: Color,
            }),
          ),
        }),
        buttonText: IO.type({value: IO.string}),
        image: IO.type({
          value: IO.array(KenticoFixedImageData),
        }),
        label: KenticoRichTextData,
        link: LinkedItems(KenticoLinkData),
        textColor: IO.type({
          value: IO.array(
            IO.type({
              codename: Color,
            }),
          ),
        }),
        utmPassthrough: IO.type({
          value: IO.array(
            IO.type({
              codename: IO.literal('enabled'),
            }),
          ),
        }),
      }),
      system: IO.type({
        type: IO.literal('button_with_image'),
      }),
    }),
  ],
  'KenticoButtonWithImage',
)

/** Data Type. */
export interface KenticoButtonWithImageData
  extends IO.TypeOf<typeof KenticoButtonWithImageData> {}

/** Fragment helper. */
export const fragment = graphql`
  fragment KenticoButtonWithImage on Node {
    ... on kontent_item_button_with_image {
      elements {
        buttonColor: button_color {
          value {
            codename
          }
        }
        buttonText: button_text {
          value
        }
        image {
          value {
            file {
              childImageSharp {
                gatsbyImageData(layout: FIXED, height: 100)
              }
            }
          }
        }
        label {
          ...KenticoRichText
        }
        link {
          nodes: value {
            ...KenticoLink
          }
        }
        textColor: text_color {
          value {
            codename
          }
        }
        utmPassthrough: utm_passthrough {
          value {
            codename
          }
        }
      }
      system {
        id
        codename
        name
        type
      }
    }
  }
`
