import {graphql} from 'gatsby'
import * as IO from 'io-ts'
import {KenticoContentData} from '../content'
import {KenticoFixedImageData} from '../fixed-image'
import {KenticoRichTextData} from '../rich-text'

/** Cards row. */
export const KenticoCardData = IO.intersection(
  [
    KenticoContentData,
    IO.type({
      elements: IO.type({
        body: KenticoRichTextData,
        image: IO.type({
          value: IO.array(KenticoFixedImageData),
        }),
      }),
      system: IO.type({
        type: IO.literal('card'),
      }),
    }),
  ],
  'KenticoCard',
)

/** Data type. */
export interface KenticoCardData extends IO.TypeOf<typeof KenticoCardData> {}

/** Fragment helper. */
export const fragment = graphql`
  fragment KenticoCard on Node {
    ... on kontent_item_card {
      system {
        codename
        id
        name
        type
      }
      elements {
        body {
          ...KenticoRichText
        }
        image {
          value {
            file {
              childImageSharp {
                gatsbyImageData(layout: FIXED, height: 65)
              }
            }
          }
        }
      }
    }
  }
`
