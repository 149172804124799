import {constant} from 'lodash'
import {createContext} from 'react'
import {ContactForm} from '../types'

/** Contact information. */
export interface ContactContext {
  ready: boolean
  error?: Error
  submit(form: ContactForm): Promise<boolean>
}

/** Default context. */
export const DEFAULT_CONTEXT: ContactContext = {
  ready: false,
  submit: constant(Promise.resolve(false)),
}

/** Context implementation. */
export const ContactContext = createContext(DEFAULT_CONTEXT)

// eslint-disable-next-line functional/immutable-data
ContactContext.displayName = 'Contact'
