import {Store} from '../../types'

interface Options {
  map?: google.maps.Map
  stores: Store[]
}

/**
 * Create map bounds.
 * @return Function to create map bounds
 */
export const createMapBounds = ({map, stores}: Options) => () => {
  if (map !== undefined && stores.length > 0) {
    const bounds = new google.maps.LatLngBounds()
    stores.forEach(store => {
      bounds.extend(store.location)
    })
    map.fitBounds(bounds)
  }
}
