import {
  CalculatorsContext,
  InstallmentCalculatorData,
} from '~/calculators/context'
import {InstallmentCalculatorForm} from '~/calculators/types'

interface Options {
  installment: CalculatorsContext['installment']
  setResponse(value: Response): void
}

/** Response data type. */
export type Response = ResponseDefault | ResponseSuccess | ResponseError

interface ResponseDefault {
  success?: undefined
}

interface ResponseSuccess {
  success: true
  data: InstallmentCalculatorData
}

interface ResponseError {
  success: false
}

/**
 * Create on submit handler.
 * @return Submit handler
 */
export const createOnSubmit = ({installment, setResponse}: Options) => async (
  formData: InstallmentCalculatorForm,
) => {
  const data: InstallmentCalculatorForm = {
    income: formData.income,
    state: formData.state,
  }

  const wnd = window as any;
  if ('appInsights' in wnd) {
      wnd.appInsights.trackEvent('Cashstore:InstallmentLoanCalcForm', data)
  }

  const result = await installment.submit(data)

  if (result) {
    setResponse({data: result, success: true})
  } else {
    setResponse({success: false})
  }
}
