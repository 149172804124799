import * as IO from 'io-ts'
import {useCallback, useContext, useMemo, useState} from 'react'
import {CommonContext} from '~/common/context'
import {Store} from '~/stores/types'
import {StoreResponse, parseStoreResponse} from '~/stores/types/api'
import {getOptimizelyVariations} from '~/optimizely/util'
import {CampaignFormData} from '../types'

type CampaignType = 'CAMPAIGN' | 'CAMPAIGN_APPOINTMENT'

type CampaignStatus = CampaignDataState['status']

type CampaignDataState =
  | CampaignDataValid
  | CampaignAppointmentDataValid
  | CampaignDataInvalid
  | CampaignDataError

interface CampaignDataBase {
  type: CampaignType
}

interface CampaignDataValid {
  accessCode: string
  appointmentDate?: string
  appointmentTime?: string
  amount: number
  name: string
  offerValidation: null
  status: 'VALID'
  stores: Store[]
}

interface CampaignAppointmentDataValid extends CampaignDataValid {
  appointmentDate: string
  appointmentTime: string
}

interface CampaignDataInvalid {
  accessCode: string
  offerValidation: string
  status: 'INVALID'
}

interface CampaignDataError {
  status: 'ERROR'
}

const CampaignResponse = IO.type({
  accessCode: IO.string,
  appointmentDate: IO.union([IO.string, IO.null]),
  appointmentTime: IO.union([IO.string, IO.null]),
  closestStores: IO.union([IO.array(StoreResponse), IO.null]),
  firstName: IO.string,
  offerAmount: IO.number,
  offerValidation: IO.union([
    IO.keyof({
      Error: undefined,
      InvalidAccessCode: undefined,
      InvalidLast4SSN: undefined,
    }),
    IO.null,
  ]),
})

/** Campaign API. */
export type CampaignAPI = CampaignBase

/** Campaign API base. */
export interface CampaignBase {
  ready: boolean
  error?: Error
  submit(form: CampaignFormData): Promise<CampaignStatus | undefined>
}

/** Campaign data. */
export type CampaignData = CampaignDataBase & CampaignDataState

/**
 * Construct campaign API.
 * @param type Campaign type
 * @param setData Callback to update data
 * @return Campaign API
 */
export const useCampaign = (
  type: CampaignType,
  setData: (data: CampaignData) => void,
) => {
  const {api} = useContext(CommonContext)
  const [ready, setReady] = useState(true)
  const [error, setError] = useState<Error>()

  const submit = useCallback(
    async (form: CampaignFormData): Promise<CampaignStatus | undefined> => {
      if (!ready) {
        return undefined
      }
      const optimizelyVariations = getOptimizelyVariations()
      setReady(false)
      setError(undefined)
      let response
      try {
        response = await api({
          data: {
            accessCode: form.accessCode,
            appointmentDate: form.appointmentDate,
            appointmentTime: form.appointmentTime,
            emailAddress: form.emailAddress,
            firstName: form.firstName,
            last4SSN: form.last4SSN,
            lastName: form.lastName,
            optimizelyVariations,
            phoneNumber: form.phoneNumber,
            termsAndConditionsAgreed: form.termsAndConditionsAgreed,
          },
          method: 'POST',
          type: CampaignResponse,
          url: 'v1/forms/qr-submit',
        })
      } catch (e) {
        if (process.env.LOG_API_ERRORS === 'true') {
          console.error(e, form)
        }
        setError(new Error('Unable to complete request'))
        setReady(true)
        return 'ERROR'
      }

      setReady(true)
      switch (response.offerValidation) {
        case null:
          if (type === 'CAMPAIGN' && response.closestStores) {
            setData({
              accessCode: response.accessCode,
              amount: response.offerAmount,
              name: response.firstName,
              offerValidation: response.offerValidation,
              status: 'VALID',
              stores: response.closestStores.map(parseStoreResponse),
              type,
            })
          } else if (
            type === 'CAMPAIGN_APPOINTMENT' &&
            response.closestStores
          ) {
            setData({
              accessCode: response.accessCode,
              amount: response.offerAmount,
              appointmentDate: response.appointmentDate ?? '',
              appointmentTime: response.appointmentTime ?? '',
              name: response.firstName,
              offerValidation: response.offerValidation,
              status: 'VALID',
              stores: response.closestStores.map(parseStoreResponse),
              type,
            })
          }
          return 'VALID'
        case 'InvalidAccessCode':
        case 'InvalidLast4SSN':
          setData({
            accessCode: response.accessCode,
            offerValidation: response.offerValidation,
            status: 'INVALID',
            type,
          })
          return 'INVALID'
        case 'Error':
          setData({status: 'ERROR', type})
          setError(new Error('Unable to complete request'))
          return 'ERROR'
        default:
          return undefined
      }
    },
    [api, ready, setData, type],
  )

  return useMemo<CampaignAPI>(
    () => ({
      error,
      ready,
      submit,
    }),
    [error, ready, submit],
  )
}
