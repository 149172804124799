import {graphql} from 'gatsby'
import * as IO from 'io-ts'
import {KenticoPageLinkData} from '../page-link'
import {KenticoPhoneLinkData} from '../phone-link'

/** Data type. */
export type KenticoLinkData = KenticoPhoneLinkData | KenticoPageLinkData

/** Link. */
export const KenticoLinkData = IO.union([
  KenticoPhoneLinkData,
  KenticoPageLinkData,
])

/** Fragment helper. */
export const fragment = graphql`
  fragment KenticoLink on Node {
    ...KenticoPhoneLink
    ...KenticoPageLink
  }
`
