import {graphql} from 'gatsby'
import * as IO from 'io-ts'
import {KenticoContentData} from '../content'

/** City Map. */
export const KenticoCityMapData = IO.intersection(
  [
    KenticoContentData,
    IO.type({
      system: IO.type({
        type: IO.literal('city_map'),
      }),
    }),
  ],
  'KenticoCityMap',
)

/** Data Type. */
export interface KenticoCityMapData
  extends IO.TypeOf<typeof KenticoCityMapData> {}

/** Fragment helper. */
export const fragment = graphql`
  fragment KenticoCityMap on Node {
    ... on kontent_item_city_map {
      system {
        id
        codename
        name
        type
      }
    }
  }
`
