import React, {ReactNode} from 'react'
import {KenticoFormSubmissionRedirectData} from '~/kentico/components/form-submission-redirect'
import {CampaignForm} from '../campaign'
import {NonTitleForm} from '../non-title-form'
import {PrequalTitleForm} from '../title-form'

interface Props extends WebFormProps {
  type: WebFormType
}

/** Web form type. */
export type WebFormType =
  | 'INSTALLMENT'
  | 'TITLE'
  | 'CASH_ADVANCE'
  | 'CAMPAIGN'
  | 'CAMPAIGN_APPOINTMENT'

/** Form properties. */
export interface WebFormProps {
  privacyConsent?: ReactNode
  tcpa: ReactNode
  submissionLinks: KenticoFormSubmissionRedirectData[]
}

/**
 * Render web form.
 * @return React component
 */
export const WebForm = ({type, ...props}: Props) => {
  switch (type) {
    case 'CAMPAIGN_APPOINTMENT':
    case 'CAMPAIGN':
      return <CampaignForm {...props} type={type} />
    case 'CASH_ADVANCE':
    case 'INSTALLMENT':
      return <NonTitleForm {...props} type={type} />
    case 'TITLE':
      return <PrequalTitleForm {...props} type={type} />
    default:
      throw new Error('Unsupported form type')
  }
}
