import {format} from 'date-fns'
import {UUID} from 'io-ts-types/lib/UUID'
import {darken} from 'polished'
import React, {useContext} from 'react'
import styled from 'styled-components'
import {
  Col,
  Container as BootstrapContainer,
  Row,
} from '~/bootstrap/components/layout'
import {Customize} from '~/common/components/customize'
import {Noop} from '~/common/components/noop'
import {WebFormContext} from '~/web-form/context'
import {KenticoPreviewBodyLink} from '../preview-link'
import {KenticoAccessCodeData} from './data'

interface Props {
  data: KenticoAccessCodeData
}

/** Kentico type data. */
export {KenticoAccessCodeData}

const Container = styled(BootstrapContainer)`
  && {
    width: 70%;
  }

  @media (max-width: ${({theme}) => theme.breakpoints.max.sm}) {
    && {
      width: 100%;
    }
  }
`

const Subtitle = styled.h3`
  text-align: center;
  line-height: 1.5;
`

const Highlight = styled.span`
  color: ${({theme}) => theme.colors.tertiary};
`

const OfferRow = styled(Row)`
  padding-top: ${({theme}) => theme.spacers[5]};
  padding-bottom: ${({theme}) => theme.spacers[5]};
`

const AppointmentContainer = styled(BootstrapContainer)`
  padding-top: ${({theme}) => theme.spacers[4]};
  padding-bottom: ${({theme}) => theme.spacers[4]};
  margin-bottom: ${({theme}) => theme.spacers[5]};
`

const AppointmentCol = styled(Col)`
  display: flex;
  justify-content: center;
`

const AppointmentText = styled(Subtitle)`
  &&& {
    max-width: 65%;
  }
`

const DividerCol = styled(Col)`
  display: flex;
  justify-content: center;
`

const Divider = styled.div`
  margin: ${({theme}) => theme.spacers[3]} 0 ${({theme}) => theme.spacers[4]};
  content: '';
  border-bottom: ${({theme}) => theme.spacers[1]} solid
    ${({theme}) => darken(0.2, theme.colors.light)};
  width: 15%;
`

const AccessCodeCol = styled(Col)`
  display: flex;
  justify-content: flex-end;

  && {
    padding: ${({theme}) => theme.spacers[1]};
  }

  :last-child {
    justify-content: flex-start;
  }

  @media (max-width: ${({theme}) => theme.breakpoints.max.sm}) {
    justify-content: center;

    :last-child {
      justify-content: center;
    }
  }
`

/**
 * Render access code.
 * @return React component
 */
export const KenticoAccessCode = ({
  data: {
    system: {id, name},
  },
}: Props) => {
  const {data} = useContext(WebFormContext)

  let editLink
  if (!UUID.is(name)) {
    editLink = <KenticoPreviewBodyLink contentItem={id} />
  }

  if (!data || data.status !== 'VALID') {
    return <Noop />
  }

  // Format the date so appointmentDate is of type Date() so it can be
  // formatted by the date-fns library
  const aptDate =
    data.appointmentDate === undefined
      ? ''
      : format(new Date(`${data.appointmentDate}T00:00:00`), 'MMMM d, yyyy')
  const aptTime = data.appointmentTime ?? ''

  let offerAppointment = (
    <>
      <Row>
        <AppointmentCol>
          <AppointmentText>
            Thank you! Your appointment has been scheduled for{' '}
            <Highlight>{aptDate}</Highlight> between{' '}
            <Highlight>{aptTime}</Highlight>
          </AppointmentText>
        </AppointmentCol>
      </Row>
      <Row>
        <DividerCol>
          <Divider />
        </DividerCol>
      </Row>
    </>
  )

  if (aptDate === '' || aptTime === '') {
    offerAppointment = (
      <>
        <Row>
          <AppointmentCol>
            <AppointmentText>
              Sorry! It looks like we had trouble scheduling your appointment.
              You can still go to a local Cash Store for your offer!
            </AppointmentText>
          </AppointmentCol>
        </Row>
        <Row>
          <DividerCol>
            <Divider />
          </DividerCol>
        </Row>
      </>
    )
  }

  if (data.type === 'CAMPAIGN') {
    offerAppointment = <Noop />
  }

  return (
    <Container>
      {editLink}
      <OfferRow>
        <Col>
          <Subtitle>
            Your offer is ready for pickup <Highlight>today</Highlight> from
            the Cash Store at the following location near you!
          </Subtitle>
        </Col>
      </OfferRow>
      <Customize background="light" component={AppointmentContainer}>
        {offerAppointment}
        <Row>
          <AccessCodeCol md="6">
            <Subtitle>Access Code:</Subtitle>
          </AccessCodeCol>
          <AccessCodeCol md="6">
            <Subtitle>{data.accessCode.toUpperCase()}</Subtitle>
          </AccessCodeCol>
        </Row>
      </Customize>
    </Container>
  )
}
