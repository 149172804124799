import * as IO from 'io-ts'

interface ImageData {
  value: {
    file: {
      childImageSharp?: object | null
    }
  }[]
}

interface ValidImageData {
  value: {
    file: {
      childImageSharp: object
    }
  }[]
}

interface LinkNode {
  nodes?: LinkData[] | null
}

/** Link data structure. */
export interface LinkData {
  system: {
    type: string
  }
  elements: {
    canonicalPage?: LinkNode
    parent?: {
      nodes?:
        | {
            elements: {
              url: {
                value: string
              }
            }
          }[]
        | null
    }
    url: {
      value: string
    }
    linkText?: {
      value: string
    }
  }
}

interface LinkedItem<T> {
  nodes?: T[] | null
}

const EMPTY_LINKED_ITEMS: unknown[] = []

/**
 * Extract linked item data.
 * @return Linked item list
 */
export const linkedItems = <T>({nodes}: LinkedItem<T>) =>
  nodes ?? (EMPTY_LINKED_ITEMS as T[])

/**
 * Helper to construct linked items type.
 * @param type IO type
 * @return New type
 */
export const LinkedItems = <A, O>(type: IO.Type<A, O>) =>
  IO.partial({
    nodes: IO.union([IO.array(type), IO.null]),
  })

/**
 * Determine if a page link is external or internal.
 * @param data Link data
 * @return If true then link is considered external
 */
export const isExternal = (data: LinkData | LinkData[]): boolean => {
  if (Array.isArray(data)) {
    return isExternal(data[0])
  }
  return data.system.type === 'external_link'
}

interface CreateUrlOptions {
  page?: number
  canonical?: boolean
}

type UrlData = LinkData | LinkData[] | LinkNode | LinkNode[]

// Actual implementation to create URL
const createUrlInternal = (data: LinkData, page: number) => {
  const {
    elements: {url, parent},
  } = data
  let link = encodeURI(url.value)
  if (isExternal(data)) {
    return link
  }
  if (parent) {
    if (!parent.nodes || parent.nodes.length === 0) {
      throw new Error('Content item link does not have a parent reference')
    }
    link = `${encodeURI(parent.nodes[0].elements.url.value)}/${link}`
  }
  link = link.replace(/\/+/g, '/').replace(/(?:^\/|\/$)/g, '')
  if (page > 0) {
    link = `${link}/${page}`
  }
  return `/${link}`
}

/**
 * Generate URL from link data.
 * @param data Link data
 * @param page Pagination
 * @return URL string
 */
export const createUrl = (
  data: UrlData,
  {canonical = false, page = 0}: CreateUrlOptions = {},
): string => {
  if (Array.isArray(data)) {
    if (data.length === 0) {
      throw new Error('No link found')
    }
    return createUrl(data[0], {canonical, page})
  }
  if (!('elements' in data)) {
    return createUrl(linkedItems(data))
  }
  const {canonicalPage} = data.elements
  if (
    canonical &&
    canonicalPage?.nodes &&
    canonicalPage.nodes.length > 0 &&
    typeof window !== 'undefined'
  ) {
    return `${window.location.origin}${createUrl(canonicalPage, {page})}`
  }

  return createUrlInternal(data, page)
}

/**
 * Validate image.
 * @param image Image data
 * @return If image data is valid
 */
export const validImage = (image: ImageData): image is ValidImageData => {
  let isValidImage = false
  if (image.value.length > 0) {
    isValidImage = true
  } else if (process.env.KENTICO_PREVIEW_ENABLED === 'true') {
    console.warn('Invalid image detected')
  } else {
    throw new Error('Invalid image')
  }
  return isValidImage
}

/**
 * Check for link text.
 * @param data Link data
 * @return Link text
 */
export const getLinkText = (data: UrlData): string => {
  if (Array.isArray(data)) {
    if (data.length === 0) {
      throw new Error('No link found')
    }
    return getLinkText(data[0])
  }
  if (!('elements' in data)) {
    return getLinkText(linkedItems(data))
  }
  const {
    elements: {linkText},
  } = data
  if (data.system.type === 'phone_link') {
    return data.elements.url.value
  }
  if (!linkText) {
    throw new Error('Content item link does not have link text')
  }
  return linkText.value
}

export const isMultipleError = () => {
  const lastErrorTimestamp = localStorage.getItem('lastErrorTimestamp')
  localStorage.setItem('lastErrorTimestamp', new Date().getTime().toString())
  if (!lastErrorTimestamp) return

  const multpleErrorThreshold =
    process.env.PREQUAL_MULTIPLE_ERROR_THRESHOLD_IN_MS === undefined ?
      172800000 : parseInt(process.env.PREQUAL_MULTIPLE_ERROR_THRESHOLD_IN_MS)

  const errorDelta = new Date().getTime() - parseInt(lastErrorTimestamp)
  return errorDelta < multpleErrorThreshold
}
