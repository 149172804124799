import {darken} from 'polished'
import React from 'react'
import {
  MdAccessTime,
  MdArrowDropDown,
  MdPhone,
  MdRecordVoiceOver,
} from 'react-icons/md'
import {UncontrolledCollapse} from 'reactstrap'
import styled from 'styled-components'
import {Col, Row} from '~/bootstrap/components/layout'
import {Customize} from '~/common/components/customize'
import {Link} from '~/common/components/link'
import {formatPhoneNumber} from '~/common/util'
import {KenticoFluidImage} from '~/kentico/components/fluid-image'
import {Store} from '../../types'
import {StoresHours} from '../hours'
import { NotificationMessage } from '../notificationMessage'
import { RecommendedDisclaimer } from '../recommendedDisclaimer'
import { BsExclamationCircle } from 'react-icons/bs'

interface Props {
  stores: Store[]
  showStoreNumbers?: boolean
}

const StoreLink = styled(Link)`
  color: ${({theme}) => theme.colors.secondary};

  &:hover {
    text-decoration: underline;
  }
`

const StoreInfo = styled.span`
  padding-left: ${({theme}) => theme.spacers[1]};
`

const Recommended = styled.span`
  color: ${({theme}) => theme.colors.light};
  background-color: ${({theme}) => theme.colors.secondary};
  border-radius: 0.5rem;
  padding: 0 0.4rem 0 0.4rem;
  font-size: initial;
  white-space: nowrap;
  font-weight: 700;
`

const NotificationWarning = styled.span`
  color: ${({theme}) => theme.colors.red};
`
const StoreTitle = styled.h4`
  @media (max-width: ${({theme}) => theme.breakpoints.max.xs}) {
    padding-top: ${({theme}) => theme.spacers[4]};
  }
`

const StoreDetail = styled.p`
  &:first-of-type {
    margin: 0;
  }

  > svg {
    margin-right: ${({theme}) => theme.spacers[2]};
  }
`

const StoreHours = styled(StoreDetail)`
  display: inline-block;
  cursor: pointer;
`

const MobileIndex = styled.span`
  @media (min-width: ${({theme}) => theme.breakpoints.min.sm}) {
    display: none;
  }
`

const StoreResult = styled(Row)`
  padding-top: ${({theme}) => theme.spacers[4]};

  &:not(:last-child) {
    border-bottom: 1px solid ${({theme}) => darken(0.1, theme.colors.light)};
  }
`

const ErrorText = styled.p`
  color: ${({theme}) => theme.colors.red};
  padding-bottom: ${({theme}) => theme.spacers[7]};

  @media (max-width: ${({theme}) => theme.breakpoints.max.md}) {
    padding-top: ${({theme}) => theme.spacers[4]};
    padding-bottom: ${({theme}) => theme.spacers[6]};
  }
`

const AddressLine = styled.span`
  display: block;
`

const StoreImage = styled(KenticoFluidImage)`
  width: 100%;
`

/**
 * Render the stores list.
 * @return React component
 */
export const StoresList = ({stores, showStoreNumbers = false}: Props) => {
  if (stores.length === 0) {
    return (
      <ErrorText>
        We&apos;re sorry, we don&apos;t have a store within 50 miles of your
        location.
      </ErrorText>
    )
  }

  // FIXME Centralize this code for use in all four places
  const items = stores.map((store, index) => {
    const addressLine = `${store.city}, ${store.state} ${store.zip}`
    let imageElement
    if (store.image) {
      imageElement = (
        <StoreImage
          alt={`The Cash Store -  #${store.number}`}
          image={store.image}
        />
      )
    }

    let bilingualCheck
    if (store.bilingual) {
      bilingualCheck = (
        <StoreDetail>
          <MdRecordVoiceOver />
          Bilingual
        </StoreDetail>
      )
    }

    let showDetails
    if (store.url !== undefined) {
      showDetails = (
        <p>
          <StoreLink to={store.url}>Show Details</StoreLink>
        </p>
      )
    }
    let recomended = <></>
    let recommendedDisclaimer
    if(store.showRecomended && store.showRecomended === true
      && store.enableDallasStoreChanges){
        recomended = (<Recommended>RECOMMENDED STORE*</Recommended>)
        recommendedDisclaimer = (<RecommendedDisclaimer />)
    }

    let notification = <></>
    let notificationMessage
    if(store.notificationMessage && store.enableDallasStoreChanges){
      notification = <NotificationWarning><BsExclamationCircle /></NotificationWarning>
      notificationMessage = <NotificationMessage notificationText={store.notificationMessage}/>
    }

    let storeLocationLink = (
      <>
        {store.address}
        <AddressLine>{addressLine}</AddressLine>
      </>
    )
    if (store.gmbUrl !== undefined) {
      storeLocationLink = (
        <a href={store.gmbUrl} rel="noopener noreferrer" target="_blank">
          {storeLocationLink}
        </a>
      )
    }

    return (
      // No better key
      // eslint-disable-next-line react/no-array-index-key
      <StoreResult key={index}>
        <Customize component={Col} hiddenUnder="sm" sm="1">
          <h4>{index + 1}.</h4>
        </Customize>
        <Col sm="4">{imageElement}</Col>
        <Col>
          <StoreTitle>
            <MobileIndex>{index + 1}. </MobileIndex>
            Cash Store {showStoreNumbers ? `- ${store.number}` : ''} {recomended} {notification}
          </StoreTitle>
          <p>{storeLocationLink}</p>
          <p>
            <MdPhone />
            <StoreInfo>
              <a href={`tel:${store.phone}`}>
                {formatPhoneNumber(store.phone)}
              </a>
            </StoreInfo>
          </p>
          {bilingualCheck}
          <StoreHours id={`dropDown${index}`}>
            <MdAccessTime />
            Store Hours
            <MdArrowDropDown />
          </StoreHours>
          <UncontrolledCollapse toggler={`#dropDown${index}`}>
            <StoresHours store={store} />
          </UncontrolledCollapse>
          {showDetails}
        </Col>
        {recommendedDisclaimer}
        {notificationMessage}
      </StoreResult>
    )
  })
  return <>{items}</>
}
