import {getImage, IGatsbyImageData} from 'gatsby-plugin-image'
import { BgImage } from 'gbimage-bridge'
import {UUID} from 'io-ts-types/lib/UUID'
import React from 'react'
import styled from 'styled-components'
import {Container as BootstrapContainer} from '~/bootstrap/components/layout'
import {linkedItems, validImage} from '../../util'
import {KenticoPreviewBodyLink} from '../preview-link'
import {KenticoStaticChecklistData} from './data'

interface Props {
  data: KenticoStaticChecklistData
}

/** Kentico type data. */
export {KenticoStaticChecklistData}

const Container = styled(BootstrapContainer)`
  padding: ${({theme}) => theme.spacers[5]} ${({theme}) => theme.spacers[4]};
  border-radius: ${({theme}) => theme.spacers[1]};
`

const Title = styled.h3`
  padding-bottom: ${({theme}) => theme.spacers[4]};

  @media (max-width: ${({theme}) => theme.breakpoints.max.sm}) {
    text-align: center;
  }
`

const List = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
`

const ListItem = styled(BgImage)`
  padding-left: 40px;
  width: 100% !important;
  height: auto !important;
  min-height: 45px;

  &:before,
  &:after {
    transform: translateY(-2px);
    width: 30px !important;
    height: 30px !important;
  }
`

/**
 * Render static checklist from Kentico data.
 * @return React component
 */
export const KenticoStaticChecklist = ({
  data: {
    elements: {items, tickImage, title},
    system: {id, name},
  },
}: Props) => {
  if (!validImage(tickImage)) {
    throw new Error('Invalid image')
  }
  const {gatsbyImageData} = tickImage.value[0].file.childImageSharp
  const myGatsbyImageData = gatsbyImageData as IGatsbyImageData;
  const pluginImage = getImage(myGatsbyImageData);
  const listItems = linkedItems(items).map(item => (
    <ListItem key={item.system.codename} image={pluginImage}>
      {item.elements.item.value}
    </ListItem>
  ))
  let editLink
  if (!UUID.is(name)) {
    editLink = <KenticoPreviewBodyLink contentItem={id} />
  }
  return (
    <Container>
      {editLink}
      <Title>{title.value}</Title>
      <List>{listItems}</List>
    </Container>
  )
}
