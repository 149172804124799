import React from 'react'

/**
 * Render year options.
 * @return React component
 */
export const YearOptions = () => {
  const currentYear = new Date().getFullYear()
  const options = Array.from({length: 83}).map((_, index) => {
    const year = currentYear - 18 - index
    return (
      <option key={year} value={String(year)}>
        {year}
      </option>
    )
  })
  return <>{options}</>
}
