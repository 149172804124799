import {UUID} from 'io-ts-types/lib/UUID'
import {toString} from 'lodash'
import React, {useCallback, useState} from 'react'
import styled from 'styled-components'
import {Button as BootstrapButton} from '~/bootstrap/components/button'
import {
  Container as BootstrapContainer,
  Row as BootstrapRow,
} from '~/bootstrap/components/layout'
import {Customize} from '~/common/components/customize'
import {validImage} from '../../util'
import {KenticoFixedImage} from '../fixed-image'
import {KenticoPreviewBodyLink} from '../preview-link'
import {KenticoRichText} from '../rich-text'
import {KenticoNeedMoreCashData} from './data'
import {createSetShowFormHandler} from './factories'
import {KenticoNoMoreCash} from './no-more-cash'
import {KenticoYesMoreCash} from './yes-more-cash'

/** Kentico type data. */
export {KenticoNeedMoreCashData}

interface Props {
  data: KenticoNeedMoreCashData
  /** Reserved for testing. This should not be used normally. */
  state?: State
}

type State = 'YES' | 'NO' | 'UNKNOWN'

const Container = styled(BootstrapContainer)`
  padding-top: ${({theme}) => theme.spacers[6]};
  padding-bottom: ${({theme}) => theme.spacers[6]};
  min-height: 550px;
`

const RichTextField = styled.div`
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  padding: ${({theme}) => theme.spacers[5]} ${({theme}) => theme.spacers[2]};
`

const ButtonRow = styled(BootstrapRow)`
  align-items: center;
  justify-content: center;
  padding-bottom: ${({theme}) => theme.spacers[5]};
`

const Button = styled(BootstrapButton)`
  justify-content: center;
  margin-right: ${({theme}) => theme.spacers[3]};
`

/**
 * Render need more cash component from Kentico data.
 * @return React component
 */
export const KenticoNeedMoreCash = ({
  data: {
    elements: {
      backgroundColor,
      buttonText1,
      body,
      buttonText2,
      image,
      mainText,
      subText,
      yesMainText,
      yesSubText,
    },
    system: {name, id},
  },
  state: defaultState = 'UNKNOWN',
}: Props) => {
  const [showForm, setShowForm] = useState(defaultState)

  const onYesClick = useCallback(
    createSetShowFormHandler({
      setShowForm,
    }),
    [setShowForm],
  )

  const onNoClick = useCallback(() => {
    setShowForm('NO')
  }, [setShowForm])

  let editLink
  if (!UUID.is(name)) {
    editLink = <KenticoPreviewBodyLink contentItem={id} />
  }

  const backgroundColorProvided = backgroundColor.value[0].codename

  // Render no more cash component
  if (showForm === 'NO') {
    let imageElement
    if (validImage(image)) {
      imageElement = (
        <KenticoFixedImage
          alt={toString(image.value[0].description)}
          image={image.value[0]}
        />
      )
    }
    return (
      <KenticoNoMoreCash
        backgroundColor={backgroundColorProvided}
        editLink={editLink}
        image={imageElement}
        mainText={mainText}
        subText={subText}
      />
    )
  } else if (showForm === 'YES') {
    return (
      <KenticoYesMoreCash
        backgroundColor={backgroundColorProvided}
        editLink={editLink}
        mainText={yesMainText}
        subText={yesSubText}
      />
    )
  }

  return (
    <Customize
      background={backgroundColorProvided}
      component={Container}
      textColor="light"
    >
      <RichTextField>
        <KenticoRichText data={body} />
      </RichTextField>
      <ButtonRow>
        <Button color="primary" size="md" onClick={onYesClick}>
          {buttonText1.value}
        </Button>
        <Button color="primary" size="md" onClick={onNoClick}>
          {buttonText2.value}
        </Button>
      </ButtonRow>
      {editLink}
    </Customize>
  )
}
