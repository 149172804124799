import {graphql} from 'gatsby'
import * as IO from 'io-ts'
import {KenticoContentData} from '../content'

/** How It Works row. */
export const KenticoHowItWorksData = IO.intersection(
  [
    KenticoContentData,
    IO.type({
      elements: IO.type({
        header1: IO.type({
          value: IO.string,
        }),
        header2: IO.type({
          value: IO.string,
        }),
        header3: IO.type({
          value: IO.string,
        }),
        mainHeader: IO.type({
          value: IO.string,
        }),
        text1: IO.type({
          value: IO.string,
        }),
        text2: IO.type({
          value: IO.string,
        }),
        text3: IO.type({
          value: IO.string,
        }),
      }),
      system: IO.type({
        type: IO.literal('how_it_works'),
      }),
    }),
  ],
  'KenticoHowItWorks',
)

/** Data Type. */
export interface KenticoHowItWorksData
  extends IO.TypeOf<typeof KenticoHowItWorksData> {}

/** Fragment helper. */
export const fragment = graphql`
  fragment KenticoHowItWorks on Node {
    ... on kontent_item_how_it_works {
      elements {
        header1 {
          value
        }
        text1 {
          value
        }
        header2 {
          value
        }
        text2 {
          value
        }
        header3 {
          value
        }
        text3 {
          value
        }
        mainHeader: main_header {
          value
        }
      }
      system {
        id
        name
        codename
        type
      }
    }
  }
`
