import React from 'react'

/**
 * Render day options.
 * @return React component
 */
export const DayOptions = () => {
  const options = Array.from({length: 31}).map((_, day) => (
    // This is the only kind of key available for this scenario
    // eslint-disable-next-line react/no-array-index-key
    <option key={day} value={String(day + 1)}>
      {day + 1}
    </option>
  ))
  return <>{options}</>
}
