import React, {ReactNode} from 'react'
import {ReferralContext} from '../../context'
import {useReferral} from '../../context/default'

interface Props {
  children: ReactNode
}

/**
 * Inject default information into referrral context.
 * @return React component
 */
export const ReferralProvider = ({children}: Props) => (
  <ReferralContext.Provider value={useReferral()}>
    {children}
  </ReferralContext.Provider>
)
