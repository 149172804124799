import {graphql} from 'gatsby'
import * as IO from 'io-ts'
import {Color} from '~/common/types'
import {KenticoContentData} from '../content'
import {KenticoRichTextData} from '../rich-text'

/** Form Declined. */
export const KenticoFormDeclinedData = IO.intersection(
  [
    KenticoContentData,
    IO.type({
      elements: IO.type({
        backgroundColor: IO.type({
          value: IO.array(
            IO.type({
              codename: Color,
            }),
          ),
        }),
        message: KenticoRichTextData,
      }),
      system: IO.type({
        type: IO.literal('form_declined'),
      }),
    }),
  ],
  'KenticoFormDeclined',
)

/** Data Type. */
export interface KenticoFormDeclinedData
  extends IO.TypeOf<typeof KenticoFormDeclinedData> {}

/** Fragment helper. */
export const fragment = graphql`
  fragment KenticoFormDeclined on Node {
    ... on kontent_item_form_declined {
      elements {
        backgroundColor: background_color {
          value {
            codename
          }
        }
        message {
          ...KenticoRichText
        }
      }
      system {
        id
        codename
        name
        type
      }
    }
  }
`
