import React from 'react'
import styled from 'styled-components'
import {Col, Row as BootstrapRow} from '~/bootstrap/components/layout'
import {NavLink} from '~/bootstrap/components/nav'
import {Link} from '~/common/components/link'
import {createUrl, getLinkText} from '~/kentico/util'
import {KenticoPageLinkData} from '../page-link'

interface Props {
  states: KenticoPageLinkData[]
  errorMessage: string
}

const ErrorText = styled.p`
  color: ${({theme}) => theme.colors.red};
  padding-bottom: ${({theme}) => theme.spacers[7]};

  @media (max-width: ${({theme}) => theme.breakpoints.max.md}) {
    padding-top: ${({theme}) => theme.spacers[2]};
    padding-bottom: ${({theme}) => theme.spacers[4]};
  }
`

const StateNavLink = styled(NavLink)`
  text-decoration: underline;

  && {
    padding-left: 0;
    padding-right: 0;

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }
`

const OtherStates = styled.h4`
  font-weight: normal;
`

const StatesContainer = styled(Col)`
  display: flex;
  flex-wrap: wrap;
`

const StatesList = styled(BootstrapRow)`
  flex-basis: 50%;

  @media (max-width: ${({theme}) => theme.breakpoints.max.md}) {
    flex-basis: 100%;
  }
`

const Row = styled(BootstrapRow)`
  @media (max-width: ${({theme}) => theme.breakpoints.max.md}) {
    flex-direction: column-reverse;
  }
`

/**
 * Render No Location Results.
 * @return React component
 */
export const NoLocationResults = ({states, errorMessage}: Props) => {
  const stateLinks = states.map(state => {
    try {
      return (
        <StatesList key={state.system.id}>
          <StateNavLink key={state.system.id} tag={Link} to={createUrl(state)}>
            {getLinkText(state)}
          </StateNavLink>
        </StatesList>
      )
    } catch {
      return undefined
    }
  })

  return (
    <Row>
      <Col sm>
        <ErrorText>{errorMessage}</ErrorText>
        <OtherStates>
          The Cash Store is located in the following states.
        </OtherStates>
        <StatesContainer>{stateLinks}</StatesContainer>
      </Col>
    </Row>
  )
}
