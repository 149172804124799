import {format} from 'date-fns'
import React from 'react'
import {Col, Row} from '~/bootstrap/components/layout'
import {Store} from '../../types'

interface Props {
  store: Store
}

/**
 * Render Stores Hours.
 * @return React component
 */
export const StoresHours = ({store}: Props) => {
  const hoursData = [
    {
      hours: store.hours.monday,
      title: 'Monday',
    },
    {
      hours: store.hours.tuesday,
      title: 'Tuesday',
    },
    {
      hours: store.hours.wednesday,
      title: 'Wednesday',
    },
    {
      hours: store.hours.thursday,
      title: 'Thursday',
    },
    {
      hours: store.hours.friday,
      title: 'Friday',
    },
    {
      hours: store.hours.saturday,
      title: 'Saturday',
    },
    {
      hours: store.hours.sunday,
      title: 'Sunday',
    },
  ].map(({title, hours}, i) => {
    const label = hours
      ? `${format(hours.open, 'h a')} - ${format(hours.close, 'h a')}`
      : 'Closed'
    return (
      // No better key to use
      // eslint-disable-next-line react/no-array-index-key
      <Row key={i}>
        <Col>
          <small>{title}</small>
        </Col>
        <Col>
          <small>{label}</small>
        </Col>
      </Row>
    )
  })
  return <>{hoursData}</>
}
