import {UUID} from 'io-ts-types/lib/UUID'
import React from 'react'
import styled from 'styled-components'
import {Container} from '~/bootstrap/components/layout'
import {Customize} from '~/common/components/customize'
import {linkedItems, validImage} from '../../util'
import {KenticoFluidImage} from '../fluid-image'
import {KenticoLink} from '../link'
import {KenticoPreviewBodyLink} from '../preview-link'
import {KenticoSpecialOfferData} from './data'

/** Kentico type data. */
export {KenticoSpecialOfferData}

interface Props {
  data: KenticoSpecialOfferData
}

const Link = styled(KenticoLink)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${({theme}) => theme.spacers[1]} 0;

  @media (min-width: ${({theme}) => theme.breakpoints.min.md}) {
    padding: ${({theme}) => theme.spacers[2]} 0;
  }
`

const Body = styled.small`
  padding-left: ${({theme}) => theme.spacers[2]};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

// const GiftImage = styled(KenticoFluidImage)`
//   padding: ${({theme}) => theme.spacers[3]};
// `

/**
 * Render special offer row from Kentico data.
 * @return React component
 */
export const KenticoSpecialOffer = ({
  data: {
    elements: {backgroundColor, textColor, image, link, text},
    system: {id, name},
  },
}: Props) => {
  const background =
    backgroundColor.value.length > 0
      ? backgroundColor.value[0].codename
      : undefined

  const color =
    textColor.value.length > 0 ? textColor.value[0].codename : undefined

  let editLink
  if (!UUID.is(name)) {
    editLink = <KenticoPreviewBodyLink contentItem={id} />
  }

  let imageElement
  if (validImage(image)) {
    imageElement = <KenticoFluidImage alt="Cash Store" image={image.value[0]} />
  }

  return (
    <Customize background={background}>
      <Container>
        <Customize component={Link} data={linkedItems(link)} textColor={color}>
          {imageElement}
          <Body>{text.value}</Body>
        </Customize>
        {editLink}
      </Container>
    </Customize>
  )
}
