import {navigate} from 'gatsby'
import {stringify} from 'query-string'
import {LinkData, createUrl} from '../../util'

interface OnChangeData {
  location?: {
    lat: number
    lng: number
  }
}

/**
 * Create on change handler.
 * @param link Link to redirect
 * @return On change handler
 */
export const createOnChange = (link: LinkData[]) => async ({
  location,
}: OnChangeData) => {
  if (!location) {
    return
  }
  await navigate(`${createUrl(link)}?${stringify(location)}`)
}
