import {graphql} from 'gatsby'
import * as IO from 'io-ts'
import {KenticoImageLinkData} from '../image-link'
import {KenticoYoutubeVideoData} from '../youtube-video'

/** Data type. */
export type KenticoLinkedItemData =
  | KenticoImageLinkData
  | KenticoYoutubeVideoData

/** Linked items in rich text. */
export const KenticoLinkedItemData = IO.union(
  [KenticoImageLinkData, KenticoYoutubeVideoData],
  'KenticoLinkedItem',
)

/** Fragment helper. */
export const fragment = graphql`
  fragment KenticoLinkedItem on Node {
    ... on kontent_item_image_link {
      ...KenticoImageLink
      elements {
        image {
          value {
            ...KenticoFluidImage
          }
        }
      }
    }
    ...KenticoYoutubeVideo
  }
`
