import {graphql} from 'gatsby'
import * as IO from 'io-ts'
import {LinkedItems} from '../../util'
import {KenticoContentData} from '../content'
import {KenticoPageLinkData} from '../page-link'
import {KenticoRichTextData} from '../rich-text'

/** Title Loan Calculator. */
export const KenticoTitleLoanCalculatorData = IO.intersection(
  [
    KenticoContentData,
    IO.type({
      elements: IO.type({
        body: KenticoRichTextData,
        buttonLink: LinkedItems(KenticoPageLinkData),
        buttonText: IO.type({
          value: IO.string,
        }),
        submitText: IO.type({
          value: IO.string,
        }),
      }),
      system: IO.type({
        type: IO.literal('title_loan_calculator'),
      }),
    }),
  ],
  'KenticoTitleLoanCalculator',
)

/** Title Loan Calculator data type. */
export interface KenticoTitleLoanCalculatorData
  extends IO.TypeOf<typeof KenticoTitleLoanCalculatorData> {}

/** Fragment helper. */
export const fragment = graphql`
  fragment KenticoTitleLoanCalculator on Node {
    ... on kontent_item_title_loan_calculator {
      elements {
        buttonLink: button_link {
          nodes: value {
            ...KenticoPageLink
          }
        }
        buttonText: button_text {
          value
        }
        submitText: submit_text {
          value
        }
        body: main_text {
          ...KenticoRichText
        }
      }
      system {
        codename
        id
        name
        type
      }
    }
  }
`
