import {UUID} from 'io-ts-types/lib/UUID'
import React from 'react'
import styled from 'styled-components'
import {
  Col,
  Container as BootstrapContainer,
} from '~/bootstrap/components/layout'
import {Customize} from '~/common/components/customize'
import {Link as BaseLink} from '~/common/components/link'
import {createUrl, getLinkText, linkedItems} from '../../util'
import {KenticoPreviewBodyLink} from '../preview-link'
import {KenticoRichText} from '../rich-text'
import {KenticoLinksColumnData} from './data'

interface Props {
  data: KenticoLinksColumnData
}

/** Kentico type data. */
export {KenticoLinksColumnData}

const Container = styled(BootstrapContainer)`
  padding: ${({theme}) => theme.spacers[5]} 0;
`

const LinkContainer = styled.div`
  padding-bottom: ${({theme}) => theme.spacers[3]};
`

const TitleCol = styled(Col)`
  padding-bottom: ${({theme}) => theme.spacers[5]};
`

const Link = styled(BaseLink)`
  text-decoration: underline;
`

const Wrapper = styled.div`
  height: 100%;
`

/**
 * Render links column from Kentico data.
 * @return React component
 */
// TODO Rename this. Consider the other Links... Komponents
export const KenticoLinksColumn = ({
  data: {
    elements: {backgroundColor, links, textColor, title},
    system: {id, name},
  },
}: Props) => {
  const backgroundColorProvided = backgroundColor.value[0].codename
  const textColorProvided = textColor.value[0].codename

  // Render preview link
  let editLink
  if (!UUID.is(name)) {
    editLink = <KenticoPreviewBodyLink contentItem={id} />
  }

  const linkElements = linkedItems(links).map((link, i) => (
    <Customize
      // No better key
      // eslint-disable-next-line react/no-array-index-key
      key={i}
      borderColor={textColorProvided}
      component={LinkContainer}
      textAlign="center"
    >
      <Customize
        component={Link}
        textColor={textColorProvided}
        to={createUrl(link)}
      >
        {getLinkText(link)}
      </Customize>
    </Customize>
  ))

  return (
    <Customize
      background={backgroundColorProvided}
      component={Wrapper}
      textColor={textColorProvided}
    >
      <Container>
        {editLink}
        <Customize component={TitleCol} textAlign="center">
          <KenticoRichText data={title} />
        </Customize>
        {linkElements}
      </Container>
    </Customize>
  )
}
