/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import {darken} from 'polished'
import React, {ReactNode} from 'react'
import {FaPhone} from 'react-icons/fa'
import styled from 'styled-components'
import {Button as BootstrapButton} from '~/bootstrap/components/button'
import {Container} from '~/bootstrap/components/layout'
import {Nav, NavItem, NavLink} from '~/bootstrap/components/nav'
import {Navbar as BootstrapNavbar} from '~/bootstrap/components/navbar'
import {Customize} from '~/common/components/customize'
import {KenticoFixedImageData} from '../fixed-image'
import {KenticoLink, KenticoLinkData} from '../link'
import {KenticoPhoneLink, KenticoPhoneLinkData} from '../phone-link'
import {KenticoRichTextData} from '../rich-text'
import {LoanType} from './loan-type'
import {Locations} from './locations'

interface Props {
  editLink: ReactNode
  logo: ReactNode
  phoneNumber: KenticoPhoneLinkData[]
  linkElements: ReactNode
  locatorLink: string
  buttonText: string
  buttonLink: KenticoLinkData[]
  dropdownLinks: ReactNode
  ddscButton: string
  ddscImage: KenticoFixedImageData
  ddscLink: KenticoLinkData[]
  ddscText: KenticoRichTextData
  signInButton: ReactNode
  shouldRenderButton: boolean
}

const Button = styled(BootstrapButton)`
  && {
    padding: 0.5rem 1rem;
  }
`

const TopNav = styled(Nav)`
  flex: 1;
  padding: ${({theme}) => theme.spacers[4]} 0;
  align-items: center;
`

const BottomNav = styled(TopNav)`
  padding: ${({theme}) => theme.spacers[3]} 0;
`

const PhoneNavLink = styled(NavLink)`
  && {
    display: inline;
  }
`

const PhoneIcon = styled(FaPhone)`
  margin-right: ${({theme}) => theme.spacers[2]};
  margin-bottom: ${({theme}) => theme.spacers[1]};
  transform: rotate(90deg);
  color: ${({theme}) => theme.colors.dark};
`

const Navbar = styled(BootstrapNavbar)`
  border-bottom: 1px solid ${({theme}) => darken(0.2, theme.colors.light)};

  && {
    padding: 0;
  }
`

const FlexNavItem = styled(NavItem)`
  flex: 1;

  > a {
    color: ${({theme}) => theme.colors.dark};
  }
`

const FlexNavItemCentered = styled(NavItem)`
  flex: 1;
  text-align: center;
`

/**
 * Render desktop header component.
 * @return React component
 */
export const DesktopHeader = ({
  buttonLink,
  buttonText,
  dropdownLinks,
  ddscButton,
  ddscImage,
  ddscLink,
  ddscText,
  editLink,
  linkElements,
  locatorLink,
  logo,
  phoneNumber,
  signInButton,
  shouldRenderButton,
}: Props) => (
  <Customize hiddenUnder="md">
    <Navbar>
      <Container fluid>
        {editLink}
        <TopNav>
          <FlexNavItem>
            <PhoneNavLink link={phoneNumber[0]} tag={KenticoPhoneLink}>
              <PhoneIcon />
              {phoneNumber[0].elements.url.value}
            </PhoneNavLink>
          </FlexNavItem>
          <FlexNavItemCentered>{logo}</FlexNavItemCentered>
          {signInButton}
        </TopNav>
      </Container>
    </Navbar>
    <Navbar>
      <Container fluid>
        <BottomNav>
          <Customize component={FlexNavItem} hiddenUnder="lg">
            <NavLink />
          </Customize>
          <NavItem>
            <LoanType
              ddscButton={ddscButton}
              ddscImage={ddscImage}
              ddscLink={ddscLink}
              ddscText={ddscText}
              dropdownLinks={dropdownLinks}
            />
          </NavItem>
          {linkElements}
          <NavItem>
            <Locations locatorLink={locatorLink} />
          </NavItem>
          <Customize component={FlexNavItem} textAlign="right">
            {shouldRenderButton && (
              <KenticoLink data={buttonLink}>
                <Button color="secondary">{buttonText}</Button>
              </KenticoLink>
            )}
          </Customize>
        </BottomNav>
      </Container>
    </Navbar>
  </Customize>
)
