import {UUID} from 'io-ts-types/lib/UUID'
import React from 'react'
import styled from 'styled-components'
import {Accordion} from '~/bootstrap/components/accordion'
import {
  Col,
  Container as BoostrapContainer,
  Row,
} from '~/bootstrap/components/layout'
import {linkedItems} from '../../util'
import {KenticoPreviewBodyLink} from '../preview-link'
import {KenticoRichText} from '../rich-text'
import {KenticoAccordionData} from './data'

interface Props {
  data: KenticoAccordionData
}

/** Kentico type data. */
export {KenticoAccordionData}

const Container = styled(BoostrapContainer)`
  padding-top: ${({theme}) => theme.spacers[5]};
  padding-bottom: ${({theme}) => theme.spacers[5]};
`

/**
 * Render accordion from Kentico data.
 * @return React component
 */
export const KenticoAccordion = ({
  data: {
    elements: {items, options, title},
    system: {id, name},
  },
}: Props) => {
  const accordionItems = linkedItems(items).map(item => (
    <Accordion
      key={item.system.codename}
      ariaControls={item.system.codename}
      title={item.elements.title.value}
    >
      <KenticoRichText data={item.elements.content} />
    </Accordion>
  ))
  const isVertical = options.value.some(
    ({codename}) => (codename as string) === 'vertical',
  )
  let children = (
    <Row>
      <Col lg="3">
        <h3>{title.value}</h3>
      </Col>
      <Col lg>{accordionItems}</Col>
    </Row>
  )
  if (isVertical) {
    children = (
      <>
        <h3>{title.value}</h3>
        <div>{accordionItems}</div>
      </>
    )
  }
  let editLink
  if (!UUID.is(name)) {
    editLink = <KenticoPreviewBodyLink contentItem={id} />
  }
  return (
    <Container>
      {editLink}
      {children}
    </Container>
  )
}
