import {graphql} from 'gatsby'
import * as IO from 'io-ts'
import {KenticoContentData} from '../content'

/** Special Offer Rebate. */
export const KenticoSpecialOfferRebateData = IO.intersection(
  [
    KenticoContentData,
    IO.type({
      elements: IO.type({
        heading: IO.type({
          value: IO.string,
        }),
        leftColumn: IO.type({
          value: IO.string,
        }),
        rightColumn: IO.type({
          value: IO.string,
        }),
      }),
      system: IO.type({
        type: IO.literal('special_offer_rebate'),
      }),
    }),
  ],
  'KenticoSpecialOfferRebate',
)

/** Special Offer Rebate data type. */
export interface KenticoSpecialOfferRebateData
  extends IO.TypeOf<typeof KenticoSpecialOfferRebateData> {}

/** Fragment helper. */
export const fragment = graphql`
  fragment KenticoSpecialOfferRebate on Node {
    ... on kontent_item_special_offer_rebate {
      elements {
        heading {
          value
        }
        leftColumn: left_column_text {
          value
        }
        rightColumn: right_column_text {
          value
        }
      }
      system {
        codename
        id
        name
        type
      }
    }
  }
`
