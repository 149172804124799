// extracted by mini-css-extract-plugin
export var textFontSizeBase = "1.125rem";
export var textFontSizeH1 = "3.4375rem";
export var textFontSizeH2 = "2.5rem";
export var textFontSizeH3 = "2rem";
export var textFontSizeH4 = "1.5rem";
export var textFontSizeMobileH1 = "2.5rem";
export var textFontSizeMobileH2 = "2rem";
export var textFontSizeMobileH3 = "1.5rem";
export var textFontSizeMobileH4 = "1.375rem";
export var textLineHeight = "1.5";
export var colorsPrimary = "#fcdb01";
export var colorsSecondary = "#5558af";
export var colorsTertiary = "#59d1a9";
export var colorsDark = "#3c3c3c";
export var colorsLight = "#f5f5f5";
export var colorsRed = "#dc3545";
export var spacer0 = "0";
export var spacer1 = ".25rem";
export var spacer2 = ".5rem";
export var spacer3 = "1rem";
export var spacer4 = "1.5rem";
export var spacer5 = "3rem";
export var spacer6 = "4.5rem";
export var spacer7 = "6rem";
export var breakpointsMinSm = "576px";
export var breakpointsMinMd = "768px";
export var breakpointsMinLg = "992px";
export var breakpointsMinXl = "1200px";
export var breakpointsMaxXs = "575px";
export var breakpointsMaxSm = "767px";
export var breakpointsMaxMd = "991px";
export var breakpointsMaxLg = "1199px";