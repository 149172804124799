import * as IO from 'io-ts'
import {NumberFromString} from 'io-ts-types/lib/NumberFromString'
import {useContext, useEffect, useMemo} from 'react'
import {CommonContext} from '~/common/context'
import {useURLQuery} from '~/common/util'
import {Store} from '../types'
import {useStoresLocation} from './location'
import {useStoresSearch} from './search'
import {StoresContext} from '.'

interface Options {
  stores?: Store[]
  store?: Store
}

const Query = IO.type({
  lat: NumberFromString,
  lng: NumberFromString,
})

/**
 * Construct default stores context.
 * @return Stores context
 */
export const useStores = ({stores, store}: Options) => {
  const {session: baseSession} = useContext(CommonContext)
  const session = baseSession?.useNamespace('stores')
  const location = useStoresLocation(session)
  const search = useStoresSearch()
  const query = useURLQuery(Query)

  // Automatically search if data is available via query or default location
  useEffect(() => {
    if (query) {
      search.run(query)
    } else if (location.ready) {
      search.run(location.position)
    }
    // Don't run search if search(.run) changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, location.ready, location.position])

  return useMemo<StoresContext>(
    () => ({
      location,
      search,
      store,
      stores,
    }),
    [location, stores, store, search],
  )
}
