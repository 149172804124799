import React, {HTMLProps} from 'react'
import styled from 'styled-components'
import {Color} from '~/common/types'

interface Props extends HTMLProps<HTMLDivElement> {
  color1?: Color
  color2?: Color
  breakpoint: number
}

const BackgroundBase = ({
  color1: _color1,
  color2: _color2,
  breakpoint: _breakpoint,
  ...props
}: Props) => <div {...props} />

const pickColor = (type: 'color1' | 'color2') => ({
  [type]: color,
  theme,
}: ThemeProps) =>
  typeof color === 'string' ? theme.colors[color] : 'transparent'

const breakpointPercentage = ({breakpoint}: Props) =>
  breakpoint.toLocaleString(undefined, {style: 'percent'})

/** Background styled with gradient. */
export const Background = styled(BackgroundBase)`
  position: relative;
  background: linear-gradient(
    0.25turn,
    ${pickColor('color1')},
    ${pickColor('color1')} ${breakpointPercentage},
    ${pickColor('color2')} ${breakpointPercentage},
    ${pickColor('color2')}
  );
`

interface ThemeProps extends Props {
  theme: {
    colors: {
      dark: string
      light: string
      primary: string
      secondary: string
      tertiary: string
    }
  }
}
