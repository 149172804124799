import parse from 'html-react-parser'
import React, {useMemo} from 'react'
import {createReplace} from '~/kentico/renderer'
import {linkedItems} from '~/kentico/util'
import {KenticoRichTextData} from './data'

interface Props {
  data: KenticoRichTextData
}

/** Kentico type data. */
export {KenticoRichTextData}

/**
 * Render rich text from Kentico data.
 * @return React component
 */
export const KenticoRichText = ({data}: Props) => {
  const children = useMemo(() => {
    const {files = [], images = [], value} = data
    //split out the images and files and linked items into their respective list-objects   
    const replace = createReplace([
      ...files,
      ...images,      
      ...linkedItems(data),
    ])    
    return parse(value, {replace})
  }, [data])
  return <>{children}</>
}
