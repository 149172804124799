import {graphql} from 'gatsby'
import * as IO from 'io-ts'
import {Color} from '~/common/types'
import {KenticoContentData} from '../content'
import {KenticoFixedImageData} from '../fixed-image'
import {KenticoRichTextData} from '../rich-text'

/** Need more cash data. */
export const KenticoNeedMoreCashData = IO.intersection(
  [
    KenticoContentData,
    IO.type({
      elements: IO.type({
        backgroundColor: IO.type({
          value: IO.array(
            IO.type({
              codename: Color,
            }),
          ),
        }),
        body: KenticoRichTextData,
        buttonText1: IO.type({
          value: IO.string,
        }),
        buttonText2: IO.type({
          value: IO.string,
        }),
        image: IO.type({
          value: IO.array(KenticoFixedImageData),
        }),
        mainText: KenticoRichTextData,
        subText: KenticoRichTextData,
        yesMainText: KenticoRichTextData,
        yesSubText: KenticoRichTextData,
      }),
      system: IO.type({
        type: IO.literal('need_more_cash'),
      }),
    }),
  ],
  'KenticoNeedMoreCash',
)

/** Data type. */
export interface KenticoNeedMoreCashData
  extends IO.TypeOf<typeof KenticoNeedMoreCashData> {}

/** Fragment helper. */
export const fragment = graphql`
  fragment KenticoNeedMoreCash on Node {
    ... on kontent_item_need_more_cash {
      elements {
        # Styling
        backgroundColor: background_color {
          value {
            codename
          }
        }

        # Body 1
        buttonText1: button_text1 {
          value
        }
        buttonText2: button_text2 {
          value
        }
        body {
          ...KenticoRichText
        }

        # Body 2
        image {
          value {
            description
            file {
              childImageSharp {
                gatsbyImageData(layout: FIXED, height: 132)
              }
            }
          }
        }
        mainText: main_text {
          ...KenticoRichText
        }
        subText: sub_text {
          ...KenticoRichText
        }

        # Body 3
        yesMainText: yes_main_text {
          ...KenticoRichText
        }
        yesSubText: yes_sub_text {
          ...KenticoRichText
        }
      }
      system {
        codename
        id
        name
        type
      }
    }
  }
`
