import {graphql} from 'gatsby'
import * as IO from 'io-ts'
import {LinkedItems} from '../../util'
import {KenticoContentData} from '../content'
import {KenticoPageLinkData} from '../page-link'

/** Location Search. */
export const KenticoLocationSearchData = IO.intersection(
  [
    KenticoContentData,
    IO.type({
      elements: IO.type({
        errorMessage: IO.type({
          value: IO.string,
        }),
        header: IO.type({
          value: IO.string,
        }),
        states: LinkedItems(KenticoPageLinkData),
      }),
      system: IO.type({
        type: IO.literal('location_search'),
      }),
    }),
  ],
  'KenticoLocationSearch',
)

/** Location Search Data. */
export interface KenticoLocationSearchData
  extends IO.TypeOf<typeof KenticoLocationSearchData> {}

/** Fragment helper. */
export const fragment = graphql`
  fragment KenticoLocationSearch on Node {
    ... on kontent_item_location_search {
      elements {
        header {
          value
        }
        states {
          nodes: value {
            ...KenticoPageLink
          }
        }
        errorMessage: error_message {
          value
        }
      }
      system {
        id
        name
        codename
        type
      }
    }
  }
`
