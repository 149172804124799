import {useMemo} from 'react'
import {useCalculatorsInstallment} from './installment'
import {useCalculatorsTitle} from './title'
import {CalculatorsContext} from '.'

/**
 * Construct default calculators context.
 * @return Calculators context
 */
export const useCalculators = () => {
  // Set up higher form APIs for context types
  const installment = useCalculatorsInstallment()
  const title = useCalculatorsTitle()

  return useMemo<CalculatorsContext>(
    () => ({
      installment,
      title,
    }),
    [installment, title],
  )
}
