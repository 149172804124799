import {graphql} from 'gatsby'
import * as IO from 'io-ts'
import {Color} from '~/common/types'
import {LinkedItems} from '../../util'
import {KenticoContentData} from '../content'
import {KenticoPageLinkData} from '../page-link'
import {KenticoRichTextData} from '../rich-text'

/** Link List. */
export const KenticoLinkListData = IO.intersection(
  [
    KenticoContentData,
    IO.type({
      elements: IO.type({
        backgroundColor: IO.type({
          value: IO.array(
            IO.type({
              codename: Color,
            }),
          ),
        }),
        links: LinkedItems(KenticoPageLinkData),
        textColor: IO.type({
          value: IO.array(
            IO.type({
              codename: Color,
            }),
          ),
        }),
        title: KenticoRichTextData,
      }),
      system: IO.type({
        type: IO.literal('link_list'),
      }),
    }),
  ],
  'KenticoLinkList',
)

/** Data Type. */
export interface KenticoLinkListData
  extends IO.TypeOf<typeof KenticoLinkListData> {}

/** Fragment helper. */
export const fragment = graphql`
  fragment KenticoLinkList on Node {
    ... on kontent_item_link_list {
      elements {
        backgroundColor: background_color {
          value {
            codename
          }
        }
        links {
          nodes: value {
            ...KenticoPageLink
          }
        }
        textColor: text_color {
          value {
            codename
          }
        }
        title {
          ...KenticoRichText
        }
      }
      system {
        id
        codename
        name
        type
      }
    }
  }
`
