import {graphql} from 'gatsby'
import * as IO from 'io-ts'
import {LinkedItems} from '../../util'
import {KenticoFixedImageData} from '../fixed-image'
import {KenticoFluidImageData} from '../fluid-image'
import {KenticoPageLinkData} from '../page-link'

/** Image Link. */
export const KenticoImageLinkData = IO.type(
  {
    elements: IO.type({
      image: IO.type({
        value: IO.array(
          IO.union([KenticoFixedImageData, KenticoFluidImageData]),
        ),
      }),
      link: LinkedItems(KenticoPageLinkData),
    }),
    system: IO.type({
      codename: IO.string,
      id: IO.string,
      type: IO.literal('image_link'),
    }),
  },
  'KenticoImageLink',
)

/** Data Type. */
export interface KenticoImageLinkData
  extends IO.TypeOf<typeof KenticoImageLinkData> {}

/** Fragment Helper. */
export const fragment = graphql`
  fragment KenticoImageLink on Node {
    ... on kontent_item_image_link {
      elements {
        image {
          value {
            description
          }
        }
        link {
          nodes: value {
            ...KenticoPageLink
          }
        }
      }
      system {
        codename
        id
        type
      }
    }
  }
`
