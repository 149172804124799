import {UUID} from 'io-ts-types/lib/UUID'
import React, {useContext} from 'react'
import {FaCheckCircle} from 'react-icons/fa'
import {
  MdAccessTime,
  MdArrowDropDown,
  MdPhone,
  MdRecordVoiceOver,
} from 'react-icons/md'
import {UncontrolledCollapse} from 'reactstrap'
import styled from 'styled-components'
import {Button} from '~/bootstrap/components/button'
import {
  Col,
  Container as BootstrapContainer,
  Row,
} from '~/bootstrap/components/layout'
import {Link} from '~/common/components/link'
import {Noop} from '~/common/components/noop'
import {formatPhoneNumber} from '~/common/util'
import {useQuery} from '~/gatsby/util'
import {WebFormContext} from '~/web-form/context'
import {StoresHours} from '~/stores/components/hours'
import {StoresMap} from '~/stores/components/map'
import {Store} from '~/stores/types'
import {createUrl} from '../../util'
import {KenticoFluidImage} from '../fluid-image'
import {KenticoPreviewBodyLink} from '../preview-link'
import {KenticoStoreLocationData} from './data'
import { NotificationMessage } from '~/stores/components/notificationMessage'
import { RecommendedDisclaimer } from '~/stores/components/recommendedDisclaimer'

interface Props {
  data: KenticoStoreLocationData
}

/** Kentico type data. */
export {KenticoStoreLocationData}

const Container = styled(BootstrapContainer)`
  padding-top: ${({theme}) => theme.spacers[4]};
`

const StoreDetail = styled.p`
  font-size: normal;

  &:first-of-type {
    margin: 0;
  }

  > svg {
    margin-right: ${({theme}) => theme.spacers[2]};
  }
`

const Divider = styled.hr`
  margin: ${({theme}) => theme.spacers[3]} 0 ${({theme}) => theme.spacers[4]};
`

const LastDivider = styled(Divider)`
  margin-top: ${({theme}) => theme.spacers[4]};
`

const SectionTitle = styled.h5`
  margin-bottom: ${({theme}) => theme.spacers[4]};
`

const Checkmark = styled(FaCheckCircle)`
  color: ${({theme}) => theme.colors.tertiary};
  margin-right: ${({theme}) => theme.spacers[3]};
  font-size: 1.4rem;
`

const ServiceAvailable = styled(Col)`
  display: flex;
`

const NearbyLocation = styled(Col)`
  padding-bottom: ${({theme}) => theme.spacers[3]};

  & > p {
    margin-bottom: 0;
  }
`

const MapContainer = styled(Col)`
  @media (max-width: ${({theme}) => theme.breakpoints.max.sm}) {
    display: none;
  }
`

const DropdownCol = styled(Col)`
  display: flex;
  flex-direction: column;
  padding-bottom: ${({theme}) => theme.spacers[3]};
`

const DropdownButton = styled(StoreDetail)`
  display: inline-block;
  align-items: center;
  cursor: pointer;
`

const OtherStoreLink = styled.a`
  color: ${({theme}) => theme.colors.secondary};

  &:hover {
    color: ${({theme}) => theme.colors.secondary};
    border-bottom: 1px solid ${({theme}) => theme.colors.secondary};
  }
`

const ImageCol = styled(Col)`
  padding-bottom: ${({theme}) => theme.spacers[3]};
`

const ButtonCol = styled(Col)`
  padding-top: ${({theme}) => theme.spacers[3]};
`

const NoStoreDataText = styled.h3`
  font-weight: 300;
  text-align: center;
  line-height: inherit;
`
const Recommended = styled.span`
  color: ${({theme}) => theme.colors.light};
  background-color: ${({theme}) => theme.colors.secondary};
  border-radius: 0.5rem;
  padding: 0 0.4rem 0 0.4rem;
  font-size: initial;
  white-space: nowrap;
  font-weight: 700;
`
const StyledRow = styled(Row)`
  @media (min-width: ${({theme}) => theme.breakpoints.min.lg}) {
    margin-left: 0.0em;
  }
  @media (max-width: ${({theme}) => theme.breakpoints.max.md}) {
    margin-left: 0.0em;
  }
  @media (max-width: ${({theme}) => theme.breakpoints.max.sm}) {
    margin-left: 0.0em;
  }
`

const createStoreLocationLink = (store: Store) => {
  let storeLocationLink = (
    <>
      <StoreDetail>{store.address}</StoreDetail>
      <StoreDetail>
        {store.city}, {store.state} {store.zip}
      </StoreDetail>
    </>
  )
  if (store.gmbUrl !== undefined) {
    storeLocationLink = (
      <a href={store.gmbUrl} rel="noopener noreferrer" target="_blank">
        {storeLocationLink}
      </a>
    )
  }
  return storeLocationLink
}

/**
 * Render the Store Location with Google map.
 * @return react component
 */
export const KenticoStoreLocation = ({
  data: {
    elements: {viewMore},
    system: {id, name},
  },
}: Props) => {
  const {mapMarker} = useQuery()
  const {data} = useContext(WebFormContext)

  if (
    !data ||
    (data.status !== 'APPROVED' &&
      data.status !== 'TITLE_ONLY' &&
      data.status !== 'VALID')
  ) {
    return <Noop />
  }

  const {
    stores: [store, ...nearbyStores],
  } = data

  let editLink
  if (!UUID.is(name)) {
    editLink = <KenticoPreviewBodyLink contentItem={id} />
  }

  if (store === undefined) {
    return (
      <Container>
        {editLink}
        <Row>
          <Col>
            <NoStoreDataText>
              Sorry, we could not find any Closest Store information for you!
              <br />
              <br />
              Don&apos;t worry, you are still qualified for the amount above!
            </NoStoreDataText>
          </Col>
        </Row>
      </Container>
    )
  }

  let imageElement
  if (store.image) {
    imageElement = (
      <ImageCol sm>
        <KenticoFluidImage alt="Cash Store" image={store.image} />
      </ImageCol>
    )
  }

  let bilingualCheck
  if (store.bilingual) {
    bilingualCheck = (
      <StoreDetail>
        <MdRecordVoiceOver />
        Bilingual
      </StoreDetail>
    )
  }

  let cashAdvanceCheck
  if (store.loans.cashAdvance) {
    cashAdvanceCheck = (
      <ServiceAvailable sm="6">
        <Checkmark />
        <p>Cash Advance Loans</p>
      </ServiceAvailable>
    )
  }

  let installmentCheck
  if (store.loans.installment) {
    installmentCheck = (
      <ServiceAvailable sm="6">
        <Checkmark />
        <p>Installment Loans</p>
      </ServiceAvailable>
    )
  }

  let titleLoanCheck
  if (store.loans.title) {
    titleLoanCheck = (
      <ServiceAvailable sm="6">
        <Checkmark />
        <p>Title Loans</p>
      </ServiceAvailable>
    )
  }

  let disclaimerMsg = store.showRecomended && store.enableDallasStoreChanges &&
    <RecommendedDisclaimer/>

  let notificationMsg = store.showRecomended && store.enableDallasStoreChanges &&
    <NotificationMessage  notificationText={nearbyStores[0].notificationMessage}/>
  const closestLocations = nearbyStores.map(currentStore => (
    <NearbyLocation key={currentStore.number} sm>
      <StoreDetail>
        <OtherStoreLink href={currentStore.url}>Cash Store</OtherStoreLink>
      </StoreDetail>
      <p>{currentStore.address}</p>
      <p>
        {currentStore.city}, {currentStore.state} {currentStore.zip}
      </p>
      <a href={`tel:${currentStore.phone}`}>
        {formatPhoneNumber(currentStore.phone)}
      </a>
    </NearbyLocation>
  ))

  const storeLocationLink = createStoreLocationLink(store)

  return (
    <Container>
      {editLink}
      <Row>
        <Col lg="6" md="12">
          <Row>
            {imageElement}
            <Col sm>
              <StyledRow>
                <h4>Cash Store</h4>&nbsp;
                {store.showRecomended && store.enableDallasStoreChanges &&
                  <Recommended>RECOMMENDED STORE*</Recommended>
                }
              </StyledRow>
              {storeLocationLink}
              <StoreDetail>
                <MdPhone />
                <a href={`tel:${store.phone}`}>
                  {formatPhoneNumber(store.phone)}
                </a>
              </StoreDetail>
              {bilingualCheck}
              <DropdownButton id="timeDropdown">
                <MdAccessTime />
                <span>Store Hours</span>
                <MdArrowDropDown />
              </DropdownButton>
              <UncontrolledCollapse toggler="#timeDropdown">
                <Row>
                  <DropdownCol>
                    <StoresHours store={store} />
                  </DropdownCol>
                </Row>
              </UncontrolledCollapse>
            </Col>
            {disclaimerMsg}
          </Row>
          <Divider />

          <Row>
            <Col sm="12">
              <SectionTitle>Services Available</SectionTitle>
            </Col>
            {cashAdvanceCheck}
            {installmentCheck}
            {titleLoanCheck}
          </Row>

          <Divider />
          <Row>
            <Col sm="12">
              <SectionTitle>Nearby Locations</SectionTitle>
            </Col>
            {closestLocations}
            {notificationMsg}
          </Row>
        </Col>
        <MapContainer lg="6" md="12">
          <StoresMap icon={mapMarker} stores={[data.stores[0]]} />
        </MapContainer>
      </Row>
      <Row>
        <ButtonCol>
          <Button outline tag={Link} to={createUrl(viewMore)}>
            View More
          </Button>
        </ButtonCol>
      </Row>
      <LastDivider />
    </Container>
  )
}
