import React, {ReactNode} from 'react'
import {ReviewsContext} from '../../context'
import {useReviews} from '../../context/default'

interface Props {
  children: ReactNode
}

/**
 * Inject default information into reviews context.
 * @return React component
 */
export const ReviewsProvider = ({children}: Props) => (
  <ReviewsContext.Provider value={useReviews()}>
    {children}
  </ReviewsContext.Provider>
)
