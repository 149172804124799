import {BgImage} from 'gbimage-bridge'
import { getImage, IGatsbyImageData } from 'gatsby-plugin-image'
import {UUID} from 'io-ts-types/lib/UUID'
import React from 'react'
import styled from 'styled-components'
import {Container, Row as BootstrapRow} from '~/bootstrap/components/layout'
import {linkedItems, validImage} from '../../util'
import {KenticoCard} from '../card'
import {KenticoPreviewBodyLink} from '../preview-link'
import {KenticoRichText} from '../rich-text'
import {KenticoCardsData} from './data'

/** Kentico type data. */
export {KenticoCardsData} from './data'

interface Props {
  data: KenticoCardsData
}

// Formatting options
const FORMATTING = {
  center: 'center',
  left: 'flex-start',
  right: 'flex-end',
}

const Row = styled(BootstrapRow)`
  padding-bottom: ${({theme}) => theme.spacers[5]};
`

const BackgroundContainer = styled(Container)`
  flex-direction: column;
  padding-top: ${({theme}) => theme.spacers[5]};
  padding-bottom: ${({theme}) => theme.spacers[5]};
`

interface TitleProps {
  format: 'left' | 'center' | 'right'
}

const Title = styled.div<TitleProps>`
  flex: 1;
  display: flex;
  color: white;
  justify-content: center;
  text-align: center;
  padding-bottom: ${({theme}) => theme.spacers[5]};

  @media (min-width: ${({theme}) => theme.breakpoints.min.md}) {
    justify-content: ${({format}) => FORMATTING[format]};
  }
`

/**
 * Render Kentico Cards Row.
 * @return React component
 */
export const KenticoCards = ({
  data: {
    system: {id, name},
    elements: {background, title, formatting, cards: body},
  },
}: Props) => {
  // Grab background image
  let backgroundImage
  if (validImage(background)) {
    backgroundImage = background.value[0].file.childImageSharp.gatsbyImageData;
  }
  // Determine title and formatting
  const justification = formatting.value[0].codename

  // Render cards
  const cards = linkedItems(body).map(card => (
    <KenticoCard key={card.system.id} data={card} lg="3" md="6" />
  ))

  // Render preview link in row
  let editLink
  if (!UUID.is(name)) {
    editLink = <KenticoPreviewBodyLink contentItem={id} />
  }
  let myGatsbyImageData = backgroundImage as IGatsbyImageData;
  let pluginImage = getImage(myGatsbyImageData);  
  return (
    <BgImage image={pluginImage}>
      <BackgroundContainer>
        {editLink}
        <Title format={justification}>
          <KenticoRichText data={title} />
        </Title>
        <Row>{cards}</Row>
      </BackgroundContainer>
    </BgImage>
  )
}
