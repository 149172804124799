import {UUID} from 'io-ts-types/lib/UUID'
import React, {useContext} from 'react'
import {Container} from '~/bootstrap/components/layout'
import {CommonContext} from '~/common/context'
import {Noop} from '~/common/components/noop'
import styled from 'styled-components'
import {linkedItems} from '../../util'
import {KenticoFullSizeImage} from '../full-size-image'
import {KenticoPreviewBodyLink} from '../preview-link'
import {KenticoForm} from '../form'
import {KenticoCampaignLandingData} from './data'

interface Props {
  data: KenticoCampaignLandingData
}

const ImageContainer = styled.div`
  position: relative;
`

/** Kentico data type. */
export {KenticoCampaignLandingData}

/**
 * Render Campaign Landing from Kentico Data.
 * @return react component.
 */
export const KenticoCampaignLanding = ({
  data: {
    elements: {form, utmData},
    system: {id, name},
  },
}: Props) => {
  const {search} = useContext(CommonContext).history.location

  const matchParamsData = linkedItems(utmData).find(
    utm => utm.elements.utmParams.value === search,
  )

  const imageElement = matchParamsData ? (
    <KenticoFullSizeImage
      data={linkedItems(matchParamsData.elements.campaignImage)[0]}
    />
  ) : (
    <Noop />
  )

  let editLink
  if (!UUID.is(name)) {
    editLink = <KenticoPreviewBodyLink contentItem={id} />
  }

  let campaignForm
  if (linkedItems(form).length > 0) {
    campaignForm = (
      <KenticoForm data={linkedItems(form)[0]} utmData={matchParamsData} />
    )
  }

  return (
    <>
      <ImageContainer>{imageElement}</ImageContainer>
      <Container>
        {editLink}
        {campaignForm}
      </Container>
    </>
  )
}
