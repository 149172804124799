import React, {useCallback, useContext, useMemo} from 'react'
import styled from 'styled-components'
import {FormGroup, Input, Label} from '~/bootstrap/components/form'
import {VehiclesContext} from '../../context'
import {createOnChange} from './change'

const SelectInput = styled(Input)`
  &&,
  &&:disabled {
    background-color: ${({theme}) => theme.colors.light};
  }
`

/**
 * Render vehicles year input.
 * @return React component
 */
export const VehiclesYear = () => {
  const {selected, ready, years} = useContext(VehiclesContext)

  const options = useMemo(
    () =>
      years.map(year => (
        <option key={year} value={year}>
          {year}
        </option>
      )),
    [years],
  )

  const onChange = useCallback(createOnChange(selected), [selected])

  return (
    <FormGroup>
      <small>
        <Label>Year*</Label>
      </small>
      <SelectInput
        required
        aria-label="Vehicle Year"
        disabled={!ready}
        name="year"
        type="select"
        value={selected.year ?? ''}
        onChange={onChange}
      >
        <option disabled value="">
          Year
        </option>
        {options}
      </SelectInput>
    </FormGroup>
  )
}
