import {darken, transparentize} from 'polished'
import React, {useContext} from 'react'
import styled from 'styled-components'
import {Col, ColProps} from '~/bootstrap/components/layout'
import {Customize} from '~/common/components/customize'
import {Link} from '~/common/components/link'
import {KenticoContext} from '../../context'
import {createUrl, validImage} from '../../util'
import {KenticoFixedImageData} from '../fixed-image'
import {KenticoFluidImage} from '../fluid-image'
import {KenticoPageLinkData} from '../page-link'

interface Props extends Omit<ColProps, 'as'> {
  title: string
  link: KenticoPageLinkData[]
  image: KenticoFixedImageData[]
}

/** Shadow size. */
export const SHADOW_SIZE = 150

const UnselectedCol = styled(Col)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (min-width: ${({theme}) => theme.breakpoints.min.lg}) {
    padding: ${({theme}) => theme.spacers[4]};
    background: ${({theme}) => theme.colors.light};
    border: 1px solid ${({theme}) => darken(0.2, theme.colors.light)};
    transition: transform 0.2s ease-in-out, border 0.2s ease-in-out;

    &&:hover {
      border-bottom: ${({theme}) => theme.spacers[2]} solid
        ${({theme}) => theme.colors.tertiary};
    }
  }
`

const SelectedCol = styled(UnselectedCol)`
  && {
    border-bottom: ${({theme}) => theme.spacers[2]} solid
      ${({theme}) => theme.colors.tertiary};
  }

  @media (min-width: ${({theme}) => theme.breakpoints.min.lg}) {
    background: white;
    transform: scale(1.1);
    z-index: 2;
    box-shadow: 0px 0px ${SHADOW_SIZE}px
      ${({theme}) => transparentize(0.3, theme.colors.dark)};
  }
`

// Because Gatsby image overrides display inline,
// !important is a necessary evil here
// const Image = styled(KenticoFixedImage)`
//   @media (max-width: ${({theme}) => theme.breakpoints.max.md}) {
//     display: none !important;
//   }
// `

const Title = styled.h5`
  padding-top: ${({theme}) => theme.spacers[4]};
  margin-bottom: 0;

  @media (max-width: ${({theme}) => theme.breakpoints.max.md}) {
    padding-bottom: ${({theme}) => theme.spacers[4]};
  }
`



/**
 * Render page switch option.
 * @return React component
 */
export const Option = ({title, image, link, ...props}: Props) => {
  const {contentId} = useContext(KenticoContext)

  let imageElement
  if (validImage({value: image})) {
    imageElement = <KenticoFluidImage alt={title} className="page-switcher-option-img" image={image[0]} />
  }

  const id = link.length > 0 ? link[0].system.id : ''

  return (
    <Customize
      {...props}
      component={id === contentId ? SelectedCol : UnselectedCol}
      textAlign="center"
    >
      <Link to={createUrl(link)}>
        {imageElement}
        <Customize component={Title} textAlign="center">
          {title}
        </Customize>
      </Link>
    </Customize>
  )
}
