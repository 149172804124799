import {graphql} from 'gatsby'
import * as IO from 'io-ts'
import {LinkedItems} from '~/kentico/util'
import {KenticoFluidImageFileData} from '../fluid-image'
import {KenticoLinkedItemData} from '../linked-items'
import {KenticoRichTextLinkData} from '../rich-text-link'
import {KenticoFileLinkData} from '../file-link'

/** Rich text. */
export const KenticoRichTextData = IO.intersection(
  [
    LinkedItems(
      IO.type({
        element: IO.union([KenticoLinkedItemData, KenticoRichTextLinkData]),
        linkId: IO.string,
      }),
    ),
    IO.type({
      value: IO.string,
    }),
    IO.partial({
      files: IO.array(KenticoFileLinkData),
      images: IO.array(KenticoFluidImageFileData),
    }),
  ],
  'KenticoRichText',
)

/** Rich text data type. */
export interface KenticoRichTextData
  extends IO.TypeOf<typeof KenticoRichTextData> {}

/** Fragment helper. */
export const fragment = graphql`
  fragment KenticoRichText on kontent_item_rich_text_element_value {
    files {
      ...KenticoFileLink
    }
    images {
      file {
        ...KenticoFluidImageFile
      }
    }
    nodes: links {
      element {
        ...KenticoLinkedItem
        ...KenticoRichTextLink
      }
      linkId: link_id
    }
    value
  }
`
