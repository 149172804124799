import {UUID} from 'io-ts-types/lib/UUID'
import React from 'react'
import styled from 'styled-components'
import {Accordion} from '~/bootstrap/components/accordion'
import {
  Col,
  Container as BootstrapContainer,
  Row,
} from '~/bootstrap/components/layout'
import {getLinkText, linkedItems} from '../../util'
import {KenticoFixedImage} from '../fixed-image'
import {KenticoLink, KenticoLinkData} from '../link'
import {KenticoPreviewBodyLink} from '../preview-link'
import {KenticoRichText} from '../rich-text'
import {KenticoFooterData} from './data'
import {SocialMedia} from './social-media'

interface Props {
  data: KenticoFooterData
}

/** Kentico type data. */
export {KenticoFooterData}

const Container = styled(BootstrapContainer)`
  padding-top: ${({theme}) => theme.spacers[5]};
  padding-bottom: ${({theme}) => theme.spacers[5]};
`

const InfoCol = styled(Col)`
  * {
    font-weight: normal;
  }

  @media (max-width: ${({theme}) => theme.breakpoints.max.md}) {
    text-align: center;
  }

  @media (min-width: ${({theme}) => theme.breakpoints.min.lg}) {
    && {
      padding-right: ${({theme}) => theme.spacers[5]};
    }
  }
`

const NavLink = styled(Col)`
  display: inline-block;

  > a {
    color: ${({theme}) => theme.colors.dark};
  }

  @media (max-width: ${({theme}) => theme.breakpoints.max.md}) {
    && {
      padding: ${({theme}) => theme.spacers[3]}
        ${({theme}) => theme.spacers[5]};
    }
  }
`

/**
 * Render footer from Kentico data.
 * @return React component
 */
export const KenticoFooter = ({
  data: {
    elements: {info, header1, links1, header2, links2, header3, links3, logo},
    system: {id, name},
  },
}: Props) => {
  // Render preview link
  let editLink
  if (!UUID.is(name)) {
    editLink = <KenticoPreviewBodyLink contentItem={id} />
  }

  let logoElement
  if (logo.value.length > 0) {
    logoElement = <KenticoFixedImage image={logo.value[0]} />
  }

  const listLinks = (links: KenticoLinkData[], twoCol: boolean) =>
    links.map((link, index) => (
      // No better key
      // eslint-disable-next-line react/no-array-index-key
      <NavLink key={index} lg={twoCol ? 6 : 12}>
        <KenticoLink data={link}>
          <small>{getLinkText(link)}</small>
        </KenticoLink>
      </NavLink>
    ))

  return (
    <Container>
      <Row>
        <InfoCol lg="4">
          {logoElement}
          <small>
            <KenticoRichText data={info} />
          </small>
          <SocialMedia />
        </InfoCol>
        <Col lg="7">
          <Row>
            <Col lg="3">
              <Accordion
                showTitle
                ariaControls="footerLinkOne"
                hiddenOver="lg"
                title={header1.value}
              >
                <Row>{listLinks(linkedItems(links1), false)}</Row>
              </Accordion>
            </Col>
            <Col lg="4">
              <Accordion
                showTitle
                ariaControls="footerLinkTwo"
                hiddenOver="lg"
                title={header2.value}
              >
                <Row>{listLinks(linkedItems(links2), false)}</Row>
              </Accordion>
            </Col>
            <Col lg="5">
              <Accordion
                showTitle
                ariaControls="footerLinkThree"
                hiddenOver="lg"
                title={header3.value}
              >
                <Row>{listLinks(linkedItems(links3), true)}</Row>
              </Accordion>
            </Col>
          </Row>
        </Col>
      </Row>
      {editLink}
    </Container>
  )
}
