import React from 'react'
import {
  KenticoSchemaCorporation,
  KenticoSchemaCorporationData,
} from '../schema-corporation'
import {KenticoSchemaFaq, KenticoSchemaFaqData} from '../schema-faq'
import {
  KenticoSchemaFinancialService,
  KenticoSchemaFinancialServiceData,
} from '../schema-financial-service'
import {
  KenticoSchemaLoanOrCredit,
  KenticoSchemaLoanOrCreditData,
} from '../schema-loan-or-credit'
import {KenticoSchemaBlog, KenticoSchemaBlogData} from './blog'
import {KenticoSchemaData} from './data'

interface Props {
  data: KenticoSchemaData | KenticoSchemaData[] | KenticoSchemaBlogData
}

/** Kentico type data. */
export {KenticoSchemaData, KenticoSchemaBlogData}

/**
 * Render Kentico Schema.
 * @return React component
 */
export const KenticoSchema = ({data}: Props) => {
  if (Array.isArray(data)) {
    const items = data.map(schema => (
      <KenticoSchema key={schema.system.id} data={schema} />
    ))
    return <>{items}</>
  } else if ('authorName' in data) {
    return <KenticoSchemaBlog data={data} />
  } else if (KenticoSchemaCorporationData.is(data)) {
    return <KenticoSchemaCorporation data={data} />
  } else if (KenticoSchemaLoanOrCreditData.is(data)) {
    return <KenticoSchemaLoanOrCredit data={data} />
  } else if (KenticoSchemaFaqData.is(data)) {
    return <KenticoSchemaFaq data={data} />
  } else if (KenticoSchemaFinancialServiceData.is(data)) {
    return <KenticoSchemaFinancialService data={data} />
  }
  throw new Error('Unsupported schema')
}
