import React, {ReactNode, useContext} from 'react'
import {CommonContext, DEFAULT_LANGUAGE} from '../../context'

/** Expose relevant components. */
export {ChangeLanguageLink} from './change-language'

interface Props {
  className?: string
  children: ReactNode
  to: string
}

/**
 * I18n-focused link.
 * @return React component
 */
export const Link = ({to, ...props}: Props) => {
  const {language, platform, link: ContextLink} = useContext(CommonContext)
  const newTo =
    platform === 'web' && language !== DEFAULT_LANGUAGE
      ? `/${language}/${to}`
      : to
  return <ContextLink {...props} to={newTo} />
}
