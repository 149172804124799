import {graphql} from 'gatsby'
import * as IO from 'io-ts'
import {LinkedItems} from '../../util'
import {KenticoAccordionData} from '../accordion'
import {KenticoContentData} from '../content'

/** Kentico Schema Faq. */
export const KenticoSchemaFaqData = IO.intersection(
  [
    KenticoContentData,
    IO.type({
      elements: IO.type({
        context: IO.type({
          value: IO.string,
        }),
        description: IO.type({
          value: IO.string,
        }),
        mainEntity: LinkedItems(KenticoAccordionData),
        primaryImage: IO.type({
          value: IO.array(
            IO.type({
              file: IO.type({
                url: IO.string,
              }),
            }),
          ),
        }),
        type: IO.type({
          value: IO.string,
        }),
      }),
      system: IO.type({
        type: IO.literal('schema_faq'),
      }),
    }),
  ],
  'KenticoSchemaFaq',
)

/** Data Type. */
export interface KenticoSchemaFaqData
  extends IO.TypeOf<typeof KenticoSchemaFaqData> {}

/** Fragment helper. */
export const fragment = graphql`
  fragment KenticoSchemaFaq on Node {
    ... on kontent_item_schema_faq {
      elements {
        context {
          value
        }
        type {
          value
        }
        primaryImage: primary_image_of_page {
          value {
            file {
              url: publicURL
            }
          }
        }
        description {
          value
        }
        mainEntity: main_entity {
          nodes: value {
            ...KenticoAccordion
          }
        }
      }
      system {
        id
        codename
        name
        type
      }
    }
  }
`
