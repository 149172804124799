import {graphql} from 'gatsby'
import * as IO from 'io-ts'

/** Global script data. */
export const KenticoGlobalScriptData = IO.partial(
  {
    nodes: IO.union([
      IO.array(
        IO.type({
          elements: IO.type({
            script: IO.type({
              value: IO.string,
            }),
          }),
        }),
      ),
      IO.null,
    ]),
  },
  'KenticoGlobalScripts',
)

/** Data type. */
export interface KenticoGlobalScriptData
  extends IO.TypeOf<typeof KenticoGlobalScriptData> {}

/** Fragment helper. */
export const fragment = graphql`
  fragment KenticoGlobalScripts on Query {
    globalScripts: allKontentItemGlobalScript {
      nodes {
        elements {
          script {
            value
          }
        }
      }
    }
  }
`
