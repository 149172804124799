import {graphql} from 'gatsby'
import * as IO from 'io-ts'
import {KenticoContentData} from '../content'

/** Kentico Schema Corporation. */
export const KenticoSchemaCorporationData = IO.intersection(
  [
    KenticoContentData,
    IO.type({
      elements: IO.type({
        altName: IO.type({
          value: IO.string,
        }),
        context: IO.type({
          value: IO.string,
        }),
        description: IO.type({
          value: IO.string,
        }),
        logo: IO.type({
          value: IO.array(
            IO.type({
              file: IO.type({
                url: IO.string,
              }),
            }),
          ),
        }),
        name: IO.type({
          value: IO.string,
        }),
        sameAs: IO.type({
          nodes: IO.array(
            IO.type({
              elements: IO.type({
                url: IO.type({
                  value: IO.string,
                }),
              }),
            }),
          ),
        }),
        type: IO.type({
          value: IO.string,
        }),
      }),
      system: IO.type({
        type: IO.literal('schema_corporation'),
      }),
    }),
  ],
  'KenticoSchemaCorporation',
)

/** Data Type. */
export interface KenticoSchemaCorporationData
  extends IO.TypeOf<typeof KenticoSchemaCorporationData> {}

/** Fragment helper. */
export const fragment = graphql`
  fragment KenticoSchemaCorporation on Node {
    ... on kontent_item_schema_corporation {
      elements {
        context {
          value
        }
        type {
          value
        }
        name {
          value
        }
        altName: alternate_name {
          value
        }
        description {
          value
        }
        logo {
          value {
            file {
              url: publicURL
            }
          }
        }
        sameAs: same_as {
          nodes: value {
            ... on kontent_item_external_link {
              elements {
                url {
                  value
                }
              }
            }
          }
        }
      }
      system {
        id
        codename
        name
        type
      }
    }
  }
`
