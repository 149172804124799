import React, {ReactNode} from 'react'
import {StoresContext} from '../../context'
import {useStores} from '../../context/default'
import {Store} from '../../types'

interface Props {
  children: ReactNode
  stores?: Store[]
  store?: Store
}

/**
 * Inject default information into stores context.
 * @return React component
 */
export const StoresProvider = ({children, ...props}: Props) => (
  <StoresContext.Provider value={useStores(props)}>
    {children}
  </StoresContext.Provider>
)
