import {graphql} from 'gatsby'
import * as IO from 'io-ts'
import {KenticoContentData} from '../content'
import {KenticoFluidImageData} from '../fluid-image'
import {KenticoRichTextData} from '../rich-text'

/** Text with image Data. */
export const KenticoTextWithImageData = IO.intersection(
  [
    KenticoContentData,
    IO.type({
      elements: IO.type({
        image: IO.type({
          value: IO.array(KenticoFluidImageData),
        }),
        info: KenticoRichTextData,
      }),
      system: IO.type({
        type: IO.literal('text_with_image'),
      }),
    }),
  ],
  'KenticoTextWithImage',
)

/** Data Type. */
export interface KenticoTextWithImageData
  extends IO.TypeOf<typeof KenticoTextWithImageData> {}

/** Fragment Helper. */
export const fragment = graphql`
  fragment KenticoTextWithImage on Node {
    ... on kontent_item_text_with_image {
      elements {
        image {
          value {
            ...KenticoFluidImage
          }
        }
        info {
          ...KenticoRichText
        }
      }
      system {
        id
        codename
        name
        type
      }
    }
  }
`
