import {graphql} from 'gatsby'
import * as IO from 'io-ts'
import {Ratio} from '~/common/types'
import {LinkedItems} from '../../util'
import {KenticoBodyItemBaseData} from '../body-item-base'
import {KenticoContentData} from '../content'
import {KenticoRowData} from '../row'

/** Single column row. */
export const KenticoTwoColumnLayoutData = IO.intersection([
  KenticoContentData,
  IO.type({
    elements: IO.type({
      column1: LinkedItems(
        IO.union([KenticoBodyItemBaseData, KenticoRowData]),
      ),
      column2: LinkedItems(
        IO.union([KenticoBodyItemBaseData, KenticoRowData]),
      ),
      options: IO.type({
        value: IO.array(
          IO.type({
            codename: IO.literal('right_first'),
          }),
        ),
      }),
      size: IO.type({
        value: IO.array(
          IO.type({
            codename: Ratio,
          }),
        ),
      }),
    }),
    system: IO.type({
      type: IO.literal('two_column_layout'),
    }),
  }),
])

/** Two column layout data type. */
export interface KenticoTwoColumnLayoutData
  extends IO.TypeOf<typeof KenticoTwoColumnLayoutData> {}

/** Fragment helper. */
export const fragment = graphql`
  fragment KenticoTwoColumnLayout on Node {
    ... on kontent_item_two_column_layout {
      system {
        codename
        id
        name
        type
      }
      elements {
        size {
          value {
            codename
          }
        }
        options {
          value {
            codename
          }
        }
        column1: layout_column_1__column {
          nodes: value {
            ...KenticoBodyItemBase
            ...KenticoRow
          }
        }
        column2: layout_column_2__column {
          nodes: value {
            ...KenticoBodyItemBase
            ...KenticoRow
          }
        }
      }
    }
  }
`
