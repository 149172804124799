import React from 'react'
import {Noop} from '~/common/components/noop'
import {createUrl, validImage} from '../../util'
import {KenticoFixedImage, KenticoFixedImageData} from '../fixed-image'
import {KenticoPageLinkData} from '../page-link'

/**
 * Construct URL from link.
 * @param link Page link data
 * @return URL if generatable
 */
export const buildUrl = (link: KenticoPageLinkData[]) => {
  if (link.length === 0) {
    if (process.env.KENTICO_PREVIEW_ENABLED !== 'true') {
      throw new Error('Invalid URL')
    }
    console.warn('Invalid URL detected')
    return undefined
  }
  return createUrl(link)
}

/**
 * Construct image from URL.
 * @param image Image data
 * @return React component
 */
export const buildImage = (image: KenticoFixedImageData[]) =>
  validImage({value: image}) ? (
    <KenticoFixedImage image={image[0]} />
  ) : (
    <Noop />
  )
