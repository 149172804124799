import {graphql, useStaticQuery} from 'gatsby'
import * as IO from 'io-ts'
import {useMemo} from 'react'
import {decode} from '~/common/util'

const Query = IO.type({
  file: IO.type({
    mapMarker: IO.string,
  }),
  site: IO.type({
    siteMetadata: IO.type({
      siteUrl: IO.string,
    }),
  }),
})

/**
 * Decode wrapped with memoization. Understand that if arguments always change
 * in value (arrays, functions, etc.) then memozation is non existant. This
 * should only be used with Gatsby queries.
 * @param type IO type
 * @param value Value to attempt to decode
 * @return Successful value
 */
export const useDecode = <T>(type: IO.Decoder<unknown, T>, value: unknown) =>
  useMemo(() => decode(type, value), [type, value])

/**
 * Get static Gatsby data.
 * @return mapMarker and siteUrl
 */
export const useQuery = () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: {eq: "map-marker.png"}) {
        mapMarker: publicURL
      }
      site {
        siteMetadata {
          siteUrl
        }
      }
    }
  `)
  const {
    file: {mapMarker},
    site: {
      siteMetadata: {siteUrl},
    },
  } = useDecode(Query, data)
  return {mapMarker, siteUrl}
}
