import {UUID} from 'io-ts-types/lib/UUID'
import React, {useCallback, useContext, useState} from 'react'
import {FormContext, useForm} from 'react-hook-form'
import styled from 'styled-components'
import {Button} from '~/bootstrap/components/button'
import {Form, FormGroup, FormItem, FormText} from '~/bootstrap/components/form'
import {
  Col,
  Container as BootstrapContainer,
  Row,
} from '~/bootstrap/components/layout'
import {DayOptions} from '~/common/components/day-options'
import {MonthOptions} from '~/common/components/month-options'
import {Spinner} from '~/common/components/spinner'
import {StateOptions} from '~/common/components/state-options'
import {YearOptions} from '~/common/components/year-options'
import {MASKS, PATTERNS} from '~/common/util'
import {ContactContext} from '~/contact/context'
import {KenticoPreviewBodyLink} from '../preview-link'
import {KenticoRichText} from '../rich-text'
import {KenticoContactUsFormData} from './data'
import {FormData, createOnSubmit} from './form'

interface Props {
  data: KenticoContactUsFormData
  /** Reserved for testing. This should not be used normally. */
  success?: boolean
  onChangeHandler?: () => void
}

/** Kentico type data. */
export {KenticoContactUsFormData}

const Container = styled(BootstrapContainer)`
  padding: ${({theme}) => theme.spacers[4]};
`

const ErrorMessage = styled.span`
  color: ${({theme}) => theme.colors.red};
  font-weight:600;
  margin-bottom: 15px;
  display: inline-block;
`

const SuccessMessage = styled.span`
  color: ${({theme}) => theme.colors.secondary};
  font-weight: 600;
  margin-bottom: 15px;
  display: inline-block;
`

const DateOfBirthTitle = styled.h4`
  @media (max-width: ${({theme}) => theme.breakpoints.max.sm}) {
    padding-top: ${({theme}) => theme.spacers[5]};
  }
`

const MAX_LENGTH_MESSAGE = "You've exceeded the max length"

const errorValue = (errorMessage: string, errorNumber: number) => ({
  message: errorMessage,
  value: errorNumber,
})

/**
 * Render contact us form.
 * @return React component
 */
export const KenticoContactUsForm = ({
  data: {
    elements: {header},
    system: {id, name},
  },
  success: defaultSuccess,
  onChangeHandler: handleChange,
}: Props) => {
  const contact = useContext(ContactContext)
  const form = useForm<FormData>({
    mode: 'onChange',
    reValidateMode: 'onChange',
  })
  const {
    handleSubmit,
    formState: {isValid},
    reset
  } = form
  const [success, setSuccess] = useState<boolean | undefined>(defaultSuccess)
  const onSubmit = useCallback(
    createOnSubmit({
      contact,
      setSuccess,
      reset
    }),
    [contact, setSuccess],
  )

  let response
  switch (success) {
    case true:
      response = (
        <SuccessMessage>
          Your question or comment has been submitted! A Cash Store representative will respond
           to your inquiry as soon as possible.
        </SuccessMessage>
      )
      break
    case false:
      response = (
        <ErrorMessage>
          We are unable to submit your information at this time. Please try
          again later.
        </ErrorMessage>
      )
      break
    default:
      break
  }

  let editLink
  if (!UUID.is(name)) {
    editLink = <KenticoPreviewBodyLink contentItem={id} />
  }
  return (
    <Container>
      {editLink}
      <KenticoRichText data={header} />
      <FormContext {...form}>
        <Form id="contactUsForm" onSubmit={handleSubmit(onSubmit)}>
          <FormGroup>
            <FormText color="secondary">*Required Fields</FormText>
          </FormGroup>
          <Row>
            <Col lg>
              <FormItem
                label="First Name"
                maxLength={errorValue(MAX_LENGTH_MESSAGE, 50)}
                name="firstName"
                required="Please enter a first name"
                type="text"
              />
            </Col>
            <Col lg>
              <FormItem
                label="Last Name"
                maxLength={errorValue(MAX_LENGTH_MESSAGE, 50)}
                name="lastName"
                required="Please enter a last name"
                type="text"
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <DateOfBirthTitle>Date of Birth</DateOfBirthTitle>
            </Col>
          </Row>
          <Row>
            <Col lg>
              <Row>
                <Col md sm="12">
                  <FormItem
                    defaultValue=""
                    label="Month"
                    name="dateOfBirth.month"
                    required="Please select a month"
                    type="select"
                    onChange={handleChange}
                  >
                    <option disabled value="">
                      Month
                    </option>
                    <MonthOptions />
                  </FormItem>
                </Col>
                <Col md sm="12">
                  <FormItem
                    defaultValue=""
                    label="Day"
                    name="dateOfBirth.day"
                    required="Please select a day"
                    type="select"
                    onChange={handleChange}
                  >
                    <option disabled value="">
                      Day
                    </option>
                    <DayOptions />
                  </FormItem>
                </Col>
                <Col md sm="12">
                  <FormItem
                    defaultValue=""
                    label="Year"
                    name="dateOfBirth.year"
                    required="Please select a year"
                    type="select"
                    onChange={handleChange}
                  >
                    <option disabled value="">
                      Year
                    </option>
                    <YearOptions />
                  </FormItem>
                </Col>
              </Row>
            </Col>
            <Col lg>
              <FormItem
                label="Email Address"
                maxLength={errorValue(MAX_LENGTH_MESSAGE, 254)}
                name="email"
                pattern={PATTERNS.EMAIL}
                required="Please enter an email"
                type="email"
              />
            </Col>
          </Row>
          <Row>
            <Col lg>
              <FormItem
                label="Phone"
                mask={MASKS.PHONE}
                name="phone"
                pattern={/^\([0-9]{3}\) [0-9]{3}-[0-9]{4}/}
                required="Please enter a phone number"
                type="tel"
              />
            </Col>
            <Col lg>
              <FormItem
                defaultValue=""
                label="Preferred Method of Contact"
                name="contactPreference"
                type="select"
              >
                <option value="" />
                <option value="EMAIL">Email</option>
                <option value="PHONE">Phone</option>
              </FormItem>
            </Col>
          </Row>
          <Row>
            <Col lg>
              <FormItem
                defaultValue=""
                label="State"
                name="state"
                type="select"
              >
                <option value="" />
                <StateOptions all />
              </FormItem>
            </Col>
            <Col lg>
              <FormItem
                defaultValue=""
                label="Have you ever been a Cash Store Customer?"
                name="existingCustomer"
                type="select"
              >
                <option value="" />
                <option value="true">Yes</option>
                <option value="false">No</option>
              </FormItem>
            </Col>
          </Row>
          <Row>
            <Col lg>
              <FormItem
                defaultValue=""
                label="Comments"
                name="comments"
                type="textarea"
              />
            </Col>
          </Row>
          {response}
          <FormGroup>
            <Button color="tertiary" disabled={!contact.ready || !isValid}>
              {contact.ready ? 'Submit' : <Spinner />}
            </Button>
          </FormGroup>
        </Form>
      </FormContext>
    </Container>
  )
}
