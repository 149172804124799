import {UUID} from 'io-ts-types/lib/UUID'
import React from 'react'
import styled from 'styled-components'
import {
  Col as BootstrapCol,
  Container as BootstrapContainer,
  Row as BootstrapRow,
} from '~/bootstrap/components/layout'
import {Customize} from '~/common/components/customize'
import {Link} from '~/common/components/link'
import {createUrl, getLinkText, linkedItems} from '../../util'
import {KenticoImageLink} from '../image-link'
import {KenticoPreviewBodyLink} from '../preview-link'
import {KenticoRichText} from '../rich-text'
import {KenticoLegalData} from './data'

interface Props {
  data: KenticoLegalData
}

/** Kentico type data. */
export {KenticoLegalData}

const Container = styled(BootstrapContainer)`
  padding-top: ${({theme}) => theme.spacers[3]};
  padding-bottom: ${({theme}) => theme.spacers[3]};
`

const Col = styled(BootstrapCol)`
  && {
    padding: ${({theme}) => theme.spacers[1]} 0;
  }

  @media (max-width: ${({theme}) => theme.breakpoints.max.md}) {
    text-align: center;
  }
`

const Row = styled(BootstrapRow)`
  align-items: center;
`

const ImageCol = styled(Col)`
  @media (min-width: ${({theme}) => theme.breakpoints.min.lg}) {
    text-align: right;
  }
  @media (max-width: ${({theme}) => theme.breakpoints.max.md}) {
    text-align: center;
  }
`

const InnerColumnsLinks = styled.p`
  > a {
    color: ${({theme}) => theme.colors.dark};
  }

  @media (max-width: ${({theme}) => theme.breakpoints.max.sm}) {
    &&:after {
      content: '';
    }
  }
`

const ImageWrapper = styled.div`
  display: inline-block;
  &:not(:last-child) {
    padding-right: ${({theme}) => theme.spacers[4]};
  }
`

const LinkCol = styled(Col)`
  display: inline-flex;
  justify-content: center;
  align-items: center;

  p {
    margin: 0;

    &:not(:last-child) {
      &:after {
        content: ' | ';
        white-space: pre;
      }
    }

    > a {
      color: ${({theme}) => theme.colors.dark};

      &:hover {
        /* Same color as <a> links on hover */
        color: #3b3d7d;
      }
    }
  }

  @media (max-width: ${({theme}) => theme.breakpoints.max.sm}) {
    flex-direction: column;

    p {
      &:after {
        content: '';
      }
    }
  }
`

const LinkColInnerRow = styled(Row)`
  margin-left: 0px !important;
  margin-right: 0px !important;
  justify-content: center;

  @media (max-width: ${({theme}) => theme.breakpoints.max.sm}) {
    display: grid;
    justify-items: center;

    > p {
      height: 48px;
      display: flex;
      align-items: center;
    }

    > p:after {
      display: none;
    }
  }
`

/**
 * Render legal from Kentico data.
 * @return React component
 */
export const KenticoLegal = ({
  data: {
    elements: {copyright, imageLinks, links, pdfLinks},
    system: {id, name},
  },
}: Props) => {
  const externalImageLinks = linkedItems(imageLinks).map(item => (
    <ImageWrapper key={item.system.codename}>
      <KenticoImageLink imageLink={item} />
    </ImageWrapper>
  ))

  const legalLinks = linkedItems(links).map(item => (
    <InnerColumnsLinks key={item.system.id}>
      <Link to={createUrl(item)}>{getLinkText(item)}</Link>
    </InnerColumnsLinks>
  ))

  let editLink
  if (!UUID.is(name)) {
    editLink = <KenticoPreviewBodyLink contentItem={id} />
  }

  return (
    <Customize background="light">
      <Container>
        {editLink}
        <Row>
          <Col lg="4" tag="small">
            {copyright.value}
          </Col>
          <LinkCol align="center" lg="5" tag="small">
            <LinkColInnerRow>
              <KenticoRichText data={pdfLinks} />
              {legalLinks}
            </LinkColInnerRow>
          </LinkCol>
          <ImageCol lg="3">
            {externalImageLinks}
          </ImageCol>
        </Row>
      </Container>
    </Customize>
  )
}
