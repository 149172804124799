"use strict";

exports.__esModule = true;
exports.listenToIntersections = exports.getIO = exports.callbackIO = void 0;

var _SimpleUtils = require("./SimpleUtils");

var io;
var listeners = new WeakMap();

var callbackIO = function callbackIO(entries) {
  entries.forEach(function (entry) {
    if (listeners.has(entry.target)) {
      var callback = listeners.get(entry.target);

      if (entry.isIntersecting || entry.intersectionRatio > 0) {
        io.unobserve(entry.target);
        listeners.delete(entry.target);
        callback();
      }
    }
  });
};

exports.callbackIO = callbackIO;

var getIO = function getIO(rootMargin) {
  if (typeof io === "undefined" && (0, _SimpleUtils.isBrowser)() && window.IntersectionObserver) {
    io = new window.IntersectionObserver(callbackIO, {
      rootMargin: rootMargin
    });
  }

  return io;
};

exports.getIO = getIO;

var listenToIntersections = function listenToIntersections(element, callback, rootMargin) {
  if (rootMargin === void 0) {
    rootMargin = "200px";
  }

  var observer = getIO(rootMargin);

  if (observer) {
    observer.observe(element);
    listeners.set(element, callback);
    return function () {
      observer.unobserve(element);
      listeners.delete(element);
    };
  }

  return function () {};
};

exports.listenToIntersections = listenToIntersections;