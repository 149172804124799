interface Location {
  location: {
    lat: number
    lng: number
  }
}

interface State {
  state: string
}

interface Options {
  run(data: Location['location'] | State): void
}

/**
 * Create on change handler.
 * @return On change handler
 */
export const createOnChange = ({run}: Options) => (data: Location | State) => {
  // Check data from specific to general
  if ('location' in data) {
    run(data.location)
  } else {
    run(data)
  }
}
