import {graphql} from 'gatsby'
import * as IO from 'io-ts'
import {LinkedItems} from '../../util'
import {KenticoContentData} from '../content'
import {KenticoRichTextData} from '../rich-text'

/** Accordion. */
export const KenticoAccordionData = IO.intersection(
  [
    KenticoContentData,
    IO.type({
      elements: IO.type({
        items: LinkedItems(
          IO.type({
            elements: IO.type({
              content: KenticoRichTextData,
              title: IO.type({
                value: IO.string,
              }),
            }),
            system: IO.type({
              codename: IO.string,
            }),
          }),
        ),
        options: IO.type({
          value: IO.array(
            IO.type({
              codename: IO.literal('vertical'),
            }),
          ),
        }),
        title: IO.type({
          value: IO.string,
        }),
      }),
      system: IO.type({
        type: IO.literal('accordion'),
      }),
    }),
  ],
  'KenticoAccordion',
)

/** Data Type. */
export interface KenticoAccordionData
  extends IO.TypeOf<typeof KenticoAccordionData> {}

/** Fragment helper. */
export const fragment = graphql`
  fragment KenticoAccordion on Node {
    ... on kontent_item_accordion {
      elements {
        items {
          nodes: value {
            ... on kontent_item_accordion_item {
              elements {
                content {
                  ...KenticoRichText
                }
                title {
                  value
                }
              }
              system {
                codename
              }
            }
          }
        }
        options {
          value {
            codename
          }
        }
        title {
          value
        }
      }
      system {
        id
        codename
        name
        type
      }
    }
  }
`
