import {GoogleMap} from '@react-google-maps/api'
import React, {ReactNode, useCallback, useEffect, useState} from 'react'
import {GoogleLoader} from '~/google/components/loader'
import {Store} from '../../types'
import {createMapBounds} from './factories'
import {StoresMapInfoWindow} from './info-window'
import {StoresMapMarker} from './marker'

interface Props {
  icon?: string
  logo?: ReactNode
  className?: string
  stores?: Store[]
  zoom?: number
  showStoreNumbers?: boolean
}

const CENTER_USA = {
  lat: 32.885121,
  lng: -96.974532,
  zoom: 4,
}

/**
 * Render store map base.
 * @return React component
 */
export const StoresMapBase = ({
  className,
  icon,
  logo,
  stores = [],
  zoom = 9,
  showStoreNumbers = false,
}: Props) => {
  const [map, setMap] = useState<google.maps.Map>()
  const [activeMarker, setActiveMarker] = useState<google.maps.Marker>()
  const [storeInfo, setStoreInfo] = useState<Store>({
    address: '',
    bilingual: false,
    city: '',
    hours: {},
    loans: {
      cashAdvance: false,
      installment: false,
      title: false,
    },
    location: {
      lat: 0,
      lng: 0,
    },
    number: '',
    phone: '',
    state: '',
    zip: '',
  })

  const clearMarker = useCallback(() => {
    setActiveMarker(undefined)
  }, [setActiveMarker])

  const onMarkerClick = useCallback(
    (marker: google.maps.Marker, store: Store) => {
      // Sets marker to undefined so only one info window appears
      setActiveMarker(undefined)
      setActiveMarker(marker)
      setStoreInfo(store)
    },
    [setActiveMarker],
  )

  let displayInfoWindow
  const markerDataMap = stores.map(store => (
    <StoresMapMarker
      key={store.number}
      data={store}
      icon={icon}
      position={store.location}
      onClick={onMarkerClick}
    />
  ))
  if (activeMarker && stores.length > 1) {
    displayInfoWindow = (
      <StoresMapInfoWindow
        anchor={activeMarker}
        logo={logo}
        showStoreNumbers={showStoreNumbers}
        store={storeInfo}
        onClose={clearMarker}
      />
    )
  }

  useEffect(createMapBounds({map, stores}), [stores])

  return (
    <GoogleLoader>
      <GoogleMap
        center={stores.length > 0 ? stores[0].location : CENTER_USA}
        mapContainerClassName={className}
        zoom={stores.length > 0 ? zoom : CENTER_USA.zoom}
        onClick={clearMarker}
        onLoad={setMap}
      >
        {markerDataMap}
        {displayInfoWindow}
      </GoogleMap>
    </GoogleLoader>
  )
}
