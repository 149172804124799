import {graphql} from 'gatsby'
import * as IO from 'io-ts'
import {KenticoContentData} from '../content'
import {KenticoYoutubeVideoData} from '../youtube-video'
import { LinkedItems } from '~/kentico/util'

/** List With Videos Data. */
export const KenticoListWithVideosData = IO.intersection(
  [
    KenticoContentData,
    IO.type({
      elements: IO.type({
        videos: LinkedItems(KenticoYoutubeVideoData),
      }),
      system: IO.type({
        type: IO.literal('list_with_videos'),
      }),
    }),
  ],
  'KenticoListWithVideos',
)

/** Data Type. */
export interface KenticoListWithVideosData
  extends IO.TypeOf<typeof KenticoListWithVideosData> {}

/** Fragment Helper. */
export const fragment = graphql`
  fragment KenticoListWithVideos on Node {
    ... on kontent_item_list_with_videos {
      elements {
        videos {
          nodes: value {
            ...KenticoYoutubeVideo
          }
        }
      }
      system {
        id
        codename
        name
        type
      }
    }
  }
`
