import React from 'react'
import styled from 'styled-components'
import {Container as BootstrapContainer} from '~/bootstrap/components/layout'
import {KenticoListWithVideosData} from './data'
import { KenticoYoutubeVideo } from '../youtube-video'

interface Props {
  data: KenticoListWithVideosData
}

/** Kentico type data. */
export {KenticoListWithVideosData}

const Container = styled(BootstrapContainer)`
    display: grid;
    max-width: 40rem;
`
/**
 * Render list with Videos from Kentico data.
 * @return React component
 */
export const KenticoListWithVideos = ({
  data: {
    elements: {videos},
  },
}: Props) => {
  const isBrowser = typeof window !== "undefined"

  if (process.env.ENABLE_VIDEOS_PAGE !== 'true' && isBrowser) {
    window.location.href = '/404'
    return <></>
  }

  return (
    <Container>
      {videos.nodes?.map(v => (
        <KenticoYoutubeVideo key={v.system.id} youtubeVideo={v} />
      ))}
    </Container>
  )
}
