import {graphql} from 'gatsby'
import * as IO from 'io-ts'
import {Color} from '~/common/types'
import {KenticoContentData} from '../content'
import {KenticoFixedImageData} from '../fixed-image'
import {KenticoRichTextData} from '../rich-text'

/** Security Seal. */
export const KenticoSecuritySealData = IO.intersection(
  [
    KenticoContentData,
    IO.type({
      elements: IO.type({
        backgroundColor: IO.type({
          value: IO.array(
            IO.type({
              codename: Color,
            }),
          ),
        }),
        body: KenticoRichTextData,
        image: IO.type({
          value: IO.array(KenticoFixedImageData),
        }),
        url: IO.type({
          value: IO.string,
        }),
      }),
      system: IO.type({
        type: IO.literal('security_seal'),
      }),
    }),
  ],
  'KenticoSecuritySeal',
)

/** Data Type. */
export interface KenticoSecuritySealData
  extends IO.TypeOf<typeof KenticoSecuritySealData> {}

/** Fragment helper. */
export const fragment = graphql`
  fragment KenticoSecuritySeal on Node {
    ... on kontent_item_security_seal {
      elements {
        backgroundColor: background_color {
          value {
            codename
          }
        }
        body {
          ...KenticoRichText
        }
        image {
          value {
            file {
              childImageSharp {
                gatsbyImageData(layout: FIXED, height: 32)
              }
            }
          }
        }
        url {
          value
        }
      }
      system {
        id
        codename
        name
        type
      }
    }
  }
`
