import {VehiclesContext} from '../../context'

interface Event {
  target: {
    value: string
  }
}

/**
 * Returns onChange function for Title Loan input items.
 * @param selected from VehiclesContext
 * @return vehicle context changing
 */
export const createOnChange = (selected: VehiclesContext['selected']) => ({
  target,
}: Event) => selected.setSeries(target.value)
