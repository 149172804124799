import React from 'react'
import {Noop} from '~/common/components/noop'
import {KenticoImageLink, KenticoImageLinkData} from '../image-link'
import {KenticoYoutubeVideo} from '../youtube-video'
import {KenticoLinkedItemData} from './data'

interface Props {
  linkedItems: {
    linkId: string
    element: KenticoLinkedItemData
  }[]
  scrub: boolean
  id: string
}

/** Kentico type data. */
export {KenticoLinkedItemData} from './data'

/**
 * Render linked items from Kentico data.
 * @param props Component properties
 * @return React component
 */
export const KenticoLinkedItem = ({linkedItems, scrub, id}: Props) => {
  const linkedItem = linkedItems.find(({linkId}) => linkId === id)

  if (!linkedItem) {
    if (!scrub) {
      throw new Error('Unknown linked item')
    }
    return <Noop />
  }

  if (KenticoImageLinkData.is(linkedItem.element)) {
    return <KenticoImageLink imageLink={linkedItem.element} />
  }

  return <KenticoYoutubeVideo youtubeVideo={linkedItem.element} />
}
