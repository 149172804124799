import * as IO from 'io-ts'
import {useMemo} from 'react'
import {decode} from '../util'

/**
 * Construct storage instance.
 * @param storage Storage instance
 * @param prefix Prefix to store/restore items
 * @return API caller
 */
export const useStorage = (storage: Storage, prefix: string) =>
  useMemo(() => {
    if (storage === undefined) {
      return
    }
    const makeKey = (key: string) => `${prefix}.${key}`

    const clear = (key: string) => {
      storage.removeItem(makeKey(key))
    }

    const has = <T>(key: string, type?: IO.Decoder<unknown, T>) => {
      try {
        const raw = storage.getItem(makeKey(key))
        if (raw === null) {
          return false
        }
        const response = JSON.parse(raw)
        if (!type) {
          return true
        }
        decode(type, response)
        return true
      } catch {
        clear(key)
        return false
      }
    }

    const read = <T>(key: string, type?: IO.Decoder<unknown, T>): T => {
      const prefixedKey = makeKey(key)
      if (!has(key, type)) {
        throw new Error(`No such key in storage: ${prefixedKey}`)
      }
      // Check above should assure storage item is valid
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const data = JSON.parse(storage.getItem(prefixedKey)!)
      return type ? decode(type, data) : data
    }

    const write = (key: string, data: unknown) => {
      storage.setItem(makeKey(key), JSON.stringify(data))
    }

    const useNamespace = (key: string) => useStorage(storage, makeKey(key))

    return {
      clear,
      has,
      read,
      useNamespace,
      write,
    }
  }, [storage, prefix])

/** Storage API. */
export type StorageAPI = ReturnType<typeof useStorage>
