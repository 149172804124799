import React, {ReactNode} from 'react'
import {Link} from '~/common/components/link'
import {createUrl} from '../../util'
import {KenticoPageLinkData} from './data'

interface Props {
  link: KenticoPageLinkData
  className?: string
  children?: ReactNode
  params?: string
}

/** Kentico type data. */
export {KenticoPageLinkData}

/**
 * Render rich text link to a page from Kentico data.
 * @return React component
 */
export const KenticoPageLink = ({
  link,
  className,
  children,
  params,
}: Props) => {
  if (link.system.type === 'external_link') {
    return (
      <a
        className={className}
        href={`${link.elements.url.value}${
          params === undefined ? '' : `?${params}`
        }`}
      >
        {children}
      </a>
    )
  }
  return (
    <Link
      className={className}
      to={`${createUrl(link)}${params === undefined ? '' : `?${params}`}`}
    >
      {children}
    </Link>
  )
}
