import React, {ReactNode} from 'react'
import styled from 'styled-components'
import {
  Col,
  Container as BootstrapContainer,
  Row,
} from '~/bootstrap/components/layout'
import {Customize} from '~/common/components/customize'
import {Color} from '~/common/types'
import {CURRENCIES} from '~/common/util'
import {KenticoRichText, KenticoRichTextData} from '../rich-text'

interface Props {
  backgroundColor: Color
  mainText: KenticoRichTextData
  subText: KenticoRichTextData
  finePrint: string
  loanAmount: number
  editLink?: ReactNode
}

const Container = styled(BootstrapContainer)`
  text-align: center;

  h1,
  h2,
  h3,
  h4 {
    line-height: ${({theme}) => theme.spacers[5]};
  }

  && {
    padding: ${({theme}) => theme.spacers[5]};
  }
`

const AmountCol = styled(Col)`
  font-size: ${({theme}) => theme.spacers[7]};
  font-weight: bold;
  color: ${({theme}) => theme.colors.primary};
  border-bottom: 2px solid ${({theme}) => theme.colors.light};

  @media (max-width: ${({theme}) => theme.breakpoints.max.sm}) {
    font-size: ${({theme}) => theme.spacers[5]};
  }
`

const StyledBottomRow = styled(Row)`
  padding-top: ${({theme}) => theme.spacers[4]};
`

const StyledBottomCol = styled(Col)`
  && {
    padding: 0 ${({theme}) => theme.spacers[5]};
  }
`

const Amount = styled.span`
  color: ${({theme}) => theme.colors.primary};
`

/**
 * Render Final Amount for need more cash yes path.
 * @return React component
 */
export const FinalAmount = ({
  backgroundColor,
  mainText,
  subText,
  finePrint,
  loanAmount,
  editLink,
}: Props) => (
  <Customize
    background={backgroundColor}
    component={Container}
    textColor="light"
  >
    {editLink}
    <KenticoRichText data={mainText} />
    <KenticoRichText data={subText} />
    <Row>
      <Col>
        <strong>You could get up to*:</strong>
      </Col>
    </Row>
    <Row>
      <AmountCol>
        <Amount>{CURRENCIES.USD.format(loanAmount)}</Amount>
      </AmountCol>
    </Row>
    <StyledBottomRow>
      <StyledBottomCol>
        <p>
          Just bring in your vehicle title with the documents shown above to
          get additional cash today!
        </p>
      </StyledBottomCol>
    </StyledBottomRow>
    <small>{finePrint}</small>
  </Customize>
)
