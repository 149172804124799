import {graphql} from 'gatsby'
import * as IO from 'io-ts'
import {Align} from '~/common/types'
import {KenticoContentData} from '../content'
import {KenticoRichTextData} from '../rich-text'

/** Text. */
export const KenticoTextData = IO.intersection(
  [
    KenticoContentData,
    IO.type({
      elements: IO.type({
        align: IO.type({
          value: IO.array(
            IO.type({
              codename: Align,
            }),
          ),
        }),
        body: KenticoRichTextData,
        options: IO.type({
          value: IO.array(
            IO.type({
              codename: IO.literal('small'),
            }),
          ),
        }),
      }),
      system: IO.type({
        type: IO.literal('text'),
      }),
    }),
  ],
  'KenticoText',
)

/** Data type. */
export interface KenticoTextData extends IO.TypeOf<typeof KenticoTextData> {}

/** Fragment helper. */
export const fragment = graphql`
  fragment KenticoText on Node {
    ... on kontent_item_text {
      elements {
        align {
          value {
            codename
          }
        }
        body {
          ...KenticoRichText
        }
        options {
          value {
            codename
          }
        }
      }
      system {
        codename
        id
        name
        type
      }
    }
  }
`
