import { getImage, IGatsbyImageData } from 'gatsby-plugin-image'
import { BgImage } from 'gbimage-bridge'
import React from 'react'
import styled from 'styled-components'
import {Button} from '~/bootstrap/components/button'
import {
  Col as BootstrapCol,
  Container as BootstrapContainer,
  Row as BootstrapRow,
} from '~/bootstrap/components/layout'
import {Customize} from '~/common/components/customize'
import {Link} from '~/common/components/link'
import {createUrl, linkedItems, validImage} from '../../util'
import {KenticoHeroBanner, KenticoHeroBannerData} from '../hero-banner'
import {KenticoRichText} from '../rich-text'

interface Props {
  side: 'left' | 'right'
  data: KenticoHeroBannerData
}

const Row = styled(BootstrapRow)`
  min-height: inherit;
`

const Col = styled(BootstrapCol)`
  padding: ${({theme}) => theme.spacers[5]};

  @media (max-width: ${({theme}) => theme.breakpoints.max.md}) {
    padding: ${({theme}) => theme.spacers[3]};
    text-align: center !important;
  }
`

const BackgroundImage = styled(BgImage)`
  @media (min-width: ${({theme}) => theme.breakpoints.min.lg}) {
    &:before,
    &:after {
      display: none !important;
    }
  }
`

const ImageContainer = styled(BootstrapContainer)`
  display: flex;
  flex-direction: column;
`

const ImageBackgroundBase = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;

  @media (max-width: ${({theme}) => theme.breakpoints.max.md}) {
    display: none;
  }
`

const ImageBackgroundLeft = styled(ImageBackgroundBase)`
  left: 0;
  right: 50%;
`

const ImageBackgroundRight = styled(ImageBackgroundBase)`
  left: 50%;
  right: 0;
`

const CONTAINER_FLEX = {
  align: 'center',
} as const

/**
 * Render hero banner column.
 * @return React component
 */
export const HeroBannerColumn = ({data, side}: Props) => {
  const {
    body: _body,
    textColor,
    textJustify,
    link: _link,
    buttonText: _buttonText,
    buttonColor: _buttonColor,
    ...elements
  } = data.elements
  const ImageBackground =
    side === 'left' ? ImageBackgroundLeft : ImageBackgroundRight
  const bannerImage = {...data, elements}
  const richText = <KenticoRichText data={data.elements.body} />
  const buttonColorValue =
    data.elements.buttonColor.value.length > 0
      ? data.elements.buttonColor.value[0].codename
      : undefined
  let imageData
  // if (validImage(data.elements.image)) {
    
  // }
  if (validImage(data.elements.image)) {
    imageData = data.elements.image.value[0].file.childImageSharp.gatsbyImageData
  }
  let buttonLink
  if (linkedItems(data.elements.link).length > 0) {
    buttonLink = (
      <Button
        color={buttonColorValue}
        tag={Link}
        to={createUrl(data.elements.link)}
      >
        {data.elements.buttonText.value}
      </Button>
    )
  }
  let myGatsbyImageData = imageData as IGatsbyImageData;
  let pluginImage = getImage(myGatsbyImageData);  
  return (
    <>
      <ImageBackground>
        <KenticoHeroBanner data={bannerImage} />
      </ImageBackground>
      <Customize
        className="col-md-0 col-lg-6"
        component={BackgroundImage}
        flex={CONTAINER_FLEX}
        image={pluginImage}
        textAlign={textJustify.value[0].codename}
        textColor={textColor.value[0].codename}
      >
        <ImageContainer>
          <Row>
            <Col>
              {richText}
              {buttonLink}
            </Col>
          </Row>
        </ImageContainer>
      </Customize>
    </>
  )
}
