import {graphql} from 'gatsby'
import * as IO from 'io-ts'
import {LinkedItems} from '../../util'
import {KenticoContentData} from '../content'
import {KenticoPageLinkData} from '../page-link'
import {KenticoRichTextData} from '../rich-text'

/** Page Menu Switcher. */
export const KenticoPageMenuSwitcherData = IO.intersection(
  [
    KenticoContentData,
    IO.type({
      elements: IO.type({
        header: KenticoRichTextData,
        links: LinkedItems(KenticoPageLinkData),
      }),
      system: IO.type({
        type: IO.literal('page_menu_switcher'),
      }),
    }),
  ],
  'KenticoPageMenuSwitcher',
)

/** Data Type. */
export interface KenticoPageMenuSwitcherData
  extends IO.TypeOf<typeof KenticoPageMenuSwitcherData> {}

/** Fragment helper. */
export const fragment = graphql`
  fragment KenticoPageMenuSwitcher on Node {
    ... on kontent_item_page_menu_switcher {
      elements {
        header {
          ...KenticoRichText
        }
        links {
          nodes: value {
            ...KenticoPageLink
          }
        }
      }
      system {
        id
        codename
        name
        type
      }
    }
  }
`
