import {darken} from 'polished'
import React, {ReactNode, useCallback, useEffect, useState} from 'react'
import {FaChevronDown, FaChevronUp} from 'react-icons/fa'
import styled from 'styled-components'
import {Customize} from '~/common/components/customize'
import {Noop} from '~/common/components/noop'
import {Collapse as BootstrapCollapse} from '../transitions'

interface Props {
  children: ReactNode
  title: string
  ariaControls: string
  hiddenOver?: Breakpoint
  showTitle?: boolean
}

type Breakpoint = 'xs' | 'sm' | 'md' | 'lg' | 'xl'

const DefaultCollapse = styled(BootstrapCollapse)`
  padding: ${({theme}) => theme.spacers[0]};
`

const HiddenOverCollapse = styled(BootstrapCollapse)`
  background-color: ${({theme}) => theme.colors.light};

  @media (min-width: ${({theme}) => theme.breakpoints.min.lg}) {
    background-color: transparent;

    &&:not(.show) {
      display: block;
    }
  }
`

const Nav = styled.div`
  display: flex;
  border-bottom: 1px solid ${({theme}) => darken(0.1, theme.colors.light)};
  cursor: pointer;
  padding: ${({theme}) => theme.spacers[3]};
`

const NavTitle = styled.div`
  flex: 1;
`
const RichTexContent = styled.div`
  padding: 1rem;
`
const Title = styled.h4`
  font-weight: normal;
`

/**
 * Render accordion component.
 * @return React component
 */
export const Accordion = ({
  children,
  title,
  ariaControls,
  hiddenOver,
  showTitle,
}: Props) => {
  // Dropdown in sidenav state handling
  const [isOpen, setIsOpen] = useState<boolean>(true)
  useEffect(() => setIsOpen(false), [])

  const onClick = useCallback(() => {
    setIsOpen(!isOpen)
  }, [isOpen, setIsOpen])

  const titleElement = showTitle ? (
    <Customize component={Title} hiddenUnder={hiddenOver}>
      {title}
    </Customize>
  ) : (
    <Noop />
  )

  const Collapse =
    typeof hiddenOver === 'string' ? HiddenOverCollapse : DefaultCollapse

    return (
    <>
      <Customize
        aria-controls={ariaControls}
        aria-expanded={isOpen}
        component={Nav}
        hiddenOver={hiddenOver}
        onClick={onClick}
      >
        <NavTitle>{title}</NavTitle>
        {isOpen ? <FaChevronUp /> : <FaChevronDown />}
      </Customize>
      {titleElement}
      <Collapse isOpen={isOpen}><RichTexContent>{children}</RichTexContent></Collapse>
    </>
  )
}
