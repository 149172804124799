import React from 'react'
import styled from 'styled-components'
import { BsExclamationCircle } from 'react-icons/bs'

interface Props {
  notificationText?: string
}

const Notification = styled.div`
  color: ${({theme}) => theme.colors.red};
  font-weight: 500;
  font-size: initial;
  white-space: inherit;
  line-height: 1.1rem;
  width:88%;
  padding:0.5rem;
`
const NotificationWarning = styled.p`
  color: ${({theme}) => theme.colors.red};
  margin: 0;
  `

const NotificationWarningContainer = styled.div`
  width:10%;
  max-width:1.5rem;
`

const NotificationContainer = styled.div`
  width:100%;
  display:flex;
  margin-left: 15px;
  align-items: center;
`
export const NotificationMessage = ({notificationText}: Props) => {
    const htmlString = notificationText ?? ''

    return (
          <NotificationContainer>
           <NotificationWarningContainer>
             <NotificationWarning><BsExclamationCircle /></NotificationWarning>
           </ NotificationWarningContainer>
            <Notification dangerouslySetInnerHTML={{ __html: htmlString }}></Notification>
          </NotificationContainer>
        )
}
