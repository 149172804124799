import {graphql} from 'gatsby'
import * as IO from 'io-ts'
import {LinkedItems} from '../../util'
import {KenticoContentData} from '../content'
import {KenticoFixedImageData} from '../fixed-image'
import {KenticoPageLinkData} from '../page-link'

/** Loan options. */
export const KenticoLoanOptionsData = IO.intersection(
  [
    KenticoContentData,
    IO.type({
      elements: IO.type({
        amount1: IO.type({
          value: IO.number,
        }),
        amount2: IO.type({
          value: IO.number,
        }),
        amount4: IO.type({
          value: IO.number,
        }),
        approveButtonLink1: LinkedItems(KenticoPageLinkData),
        approveButtonLink2: LinkedItems(KenticoPageLinkData),
        approveButtonLink4: LinkedItems(KenticoPageLinkData),
        approveButtonText1: IO.type({
          value: IO.string,
        }),
        approveButtonText2: IO.type({
          value: IO.string,
        }),
        approveButtonText4: IO.type({
          value: IO.string,
        }),
        description1: IO.type({
          value: IO.string,
        }),
        description2: IO.type({
          value: IO.string,
        }),
        description4: IO.type({
          value: IO.string,
        }),
        footerButtonLink: LinkedItems(KenticoPageLinkData),
        footerButtonText: IO.type({
          value: IO.string,
        }),
        footerTitle: IO.type({
          value: IO.string,
        }),
        headerTitle: IO.type({
          value: IO.string,
        }),
        image1: IO.type({
          value: IO.array(KenticoFixedImageData),
        }),
        image2: IO.type({
          value: IO.array(KenticoFixedImageData),
        }),
        image4: IO.type({
          value: IO.array(KenticoFixedImageData),
        }),
        items: LinkedItems(
          IO.type({
            elements: IO.type({
              amount1: IO.partial({
                value: IO.union([IO.number, IO.null]),
              }),
              amount2: IO.partial({
                value: IO.union([IO.number, IO.null]),
              }),
              amount4: IO.partial({
                value: IO.union([IO.number, IO.null]),
              }),
              state: IO.type({
                value: IO.string,
              }),
            }),
          }),
        ),
        learnMoreButtonLink1: LinkedItems(KenticoPageLinkData),
        learnMoreButtonLink2: LinkedItems(KenticoPageLinkData),
        learnMoreButtonLink4: LinkedItems(KenticoPageLinkData),
        learnMoreButtonText1: IO.type({
          value: IO.string,
        }),
        learnMoreButtonText2: IO.type({
          value: IO.string,
        }),
        learnMoreButtonText4: IO.type({
          value: IO.string,
        }),
        locationNotFound: IO.type({
          value: IO.string,
        }),
        title1: IO.type({
          value: IO.string,
        }),
        title2: IO.type({
          value: IO.string,
        }),
        title4: IO.type({
          value: IO.string,
        }),
      }),
      system: IO.type({
        type: IO.literal('loan_options'),
      }),
    }),
  ],
  'KenticoLoanOptions',
)

/** Loan options data type. */
export interface KenticoLoanOptionsData
  extends IO.TypeOf<typeof KenticoLoanOptionsData> {}

/** Fragment helper. */
export const fragment = graphql`
  fragment KenticoLoanOptions on Node {
    ... on kontent_item_loan_options {
      elements {
        # Header
        headerTitle: header_title {
          value
        }
        locationNotFound: location_not_found {
          value
        }
        items {
          nodes: value {
            ... on kontent_item_loan_options_item {
              elements {
                state {
                  value
                }
                amount1 {
                  value
                }
                amount2 {
                  value
                }
                amount4 {
                  value
                }
              }
            }
          }
        }

        # Footer
        footerTitle: footer_title {
          value
        }
        footerButtonText: footer_button_text {
          value
        }
        footerButtonLink: footer_button_link {
          nodes: value {
            ...KenticoPageLink
          }
        }

        # Installment loan
        image1 {
          value {
            file {
              childImageSharp {
                gatsbyImageData(layout: FIXED, height: 128)
              }
            }
          }
        }
        title1 {
          value
        }
        amount1 {
          value
        }
        description1 {
          value
        }
        approveButtonText1: approve_button_text1 {
          value
        }
        approveButtonLink1: approve_button_link1 {
          nodes: value {
            ...KenticoPageLink
          }
        }
        learnMoreButtonText1: learn_more_button_text1 {
          value
        }
        learnMoreButtonLink1: learn_more_button_link1 {
          nodes: value {
            ...KenticoPageLink
          }
        }

        # Cash advance
        image2 {
          value {
            file {
              childImageSharp {
                gatsbyImageData(layout: FIXED, height: 128)
              }
            }
          }
        }
        title2 {
          value
        }
        amount2 {
          value
        }
        description2 {
          value
        }
        approveButtonText2: approve_button_text2 {
          value
        }
        approveButtonLink2: approve_button_link2 {
          nodes: value {
            ...KenticoPageLink
          }
        }
        learnMoreButtonText2: learn_more_button_text2 {
          value
        }
        learnMoreButtonLink2: learn_more_button_link2 {
          nodes: value {
            ...KenticoPageLink
          }
        }

        # Auto Equity Loan
        image4 {
          value {
            file {
              childImageSharp {
                gatsbyImageData(layout: FIXED, height: 128)
              }
            }
          }
        }
        title4 {
          value
        }
        amount4 {
          value
        }
        description4 {
          value
        }
        approveButtonText4: approve_button_text4 {
          value
        }
        approveButtonLink4: approve_button_link4 {
          nodes: value {
            ...KenticoPageLink
          }
        }
        learnMoreButtonText4: learn_more_button_text4 {
          value
        }
        learnMoreButtonLink4: learn_more_button_link4 {
          nodes: value {
            ...KenticoPageLink
          }
        }
      }
      system {
        id
        codename
        name
        type
      }
    }
  }
`
