import {transparentize} from 'polished'
import React, {ComponentType, ReactNode, useContext} from 'react'
import {FaEdit} from 'react-icons/fa'
import styled from 'styled-components'
import {Container} from '~/bootstrap/components/layout'
import {Noop} from '~/common/components/noop'
import {CommonContext} from '~/common/context'
import {KenticoContext} from '../../context'

type AnchorProps = React.HTMLAttributes<HTMLAnchorElement>

interface Props extends AnchorProps {
  children?: ReactNode
  contentItem?: string
  component?: ComponentType<AnchorProps> | 'a'
}

const PreviewBodyLink = styled.a`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 100;
  font-size: 1.5rem;
  text-align: right;
  background-color: ${({theme}) => theme.colors.light};
  background-image: linear-gradient(
    45deg,
    ${({theme}) => transparentize(1, theme.colors.secondary)} 0%,
    ${({theme}) => transparentize(1, theme.colors.secondary)} 50%,
    ${({theme}) => transparentize(0.3, theme.colors.secondary)} 100%
  );
  color: ${({theme}) => theme.colors.secondary};
  width: 48px;
  height: 48px;
  margin: 0 auto;
  padding-top: 3px;
  padding-right: 9px;

  ${Container}:not(:hover) & {
    opacity: 0.7;
  }
`

/**
 * Link to edit content in Kentico Cloud in development environment only.
 * @return React component
 */
export const KenticoPreviewLink = ({
  children,
  component: Component = 'a',
  contentItem,
  ...props
}: Props) => {
  const {language} = useContext(CommonContext)
  const {contentId} = useContext(KenticoContext)
  if (process.env.NODE_ENV !== 'development' || contentId === undefined) {
    return <Noop />
  }

  let previewUrl = [
    'https://app.kenticocloud.com/goto/edit-item/project',
    encodeURIComponent(String(process.env.KENTICO_PROJECT_ID)),
    'variant-codename',
    encodeURIComponent(language),
    'item',
    encodeURIComponent(contentId),
  ].join('/')
  if (contentItem !== undefined) {
    previewUrl += [
      '/element/related_articles/item',
      encodeURIComponent(contentItem),
      'element/body_copy',
    ].join('/')
  }

  return (
    <Component {...props} href={previewUrl} rel="noopener" target="_blank">
      {children}
    </Component>
  )
}

/**
 * Link to edit body content in Kentico.
 * @param props Component properties
 * @return React component
 */
// eslint-disable-next-line react/no-multi-comp
export const KenticoPreviewBodyLink = (props: Props) => (
  <KenticoPreviewLink {...props} component={PreviewBodyLink}>
    <FaEdit />
  </KenticoPreviewLink>
)
