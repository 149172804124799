/**
 * Properly formats the currency of USD.
 */
export const USD = new Intl.NumberFormat(undefined, {
  currency: 'USD',
  maximumFractionDigits: 0,
  minimumFractionDigits: 0,
  style: 'currency',
})

/**
 * Properly formats the payment.
 *
 */
export const PAYMENT = new Intl.NumberFormat(undefined, {
  currency: 'USD',
  style: 'currency',
})

/**
 * Properly formats the percentage.
 *
 */
export const PERCENTAGE = new Intl.NumberFormat('en-US', {
  maximumFractionDigits: 2,
  minimumFractionDigits: 2,
  style: 'percent',
})
