import {graphql} from 'gatsby'
import * as IO from 'io-ts'

/** Link. */
export const KenticoFileLinkData = IO.type(
  {
    id: IO.string,
    url: IO.string,
  },
  'KenticoFileLink',
)

/** Data type. */
export interface KenticoFileLinkData
  extends IO.TypeOf<typeof KenticoFileLinkData> {}

/** Fragment helper. */
export const fragment = graphql`
  fragment KenticoFileLink on File {
    id
    url: publicURL
  }
`
