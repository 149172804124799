import {UUID} from 'io-ts-types/lib/UUID'
import {darken} from 'polished'
import React from 'react'
import styled from 'styled-components'
import {
  Col,
  Container as BootstrapContainer,
  Row,
} from '~/bootstrap/components/layout'
import {KenticoPreviewBodyLink} from '../preview-link'
import {KenticoSpecialOfferRebateData} from './data'

interface Props {
  data: KenticoSpecialOfferRebateData
}

/** Kentico type data. */
export {KenticoSpecialOfferRebateData}

const Container = styled(BootstrapContainer)`
  padding: ${({theme}) => theme.spacers[5]};
  box-shadow: 5px 10px 18px ${({theme}) => darken(0.2, theme.colors.light)};
  margin: ${({theme}) => theme.spacers[5]} auto;
`

const LeftCol = styled(Col)`
  border-right: 1px solid ${({theme}) => darken(0.1, theme.colors.light)};
`

const RightCol = styled(Col)`
  padding-top: ${({theme}) => theme.spacers[4]};
`

const HeaderCol = styled(Col)`
  color: ${({theme}) => theme.colors.secondary};
`

/**
 * Render Special Offer Rebate from Kentico data.
 * @return React component
 */
export const KenticoSpecialOfferRebate = ({
  data: {
    elements,
    system: {name, id},
  },
}: Props) => {
  let editLink
  if (!UUID.is(name)) {
    editLink = <KenticoPreviewBodyLink contentItem={id} />
  }

  return (
    <Container>
      {editLink}
      <Row>
        <LeftCol lg="7">
          <Row>
            <HeaderCol>
              <h3>{elements.heading.value}</h3>
            </HeaderCol>
          </Row>
          <Row>
            <Col>
              <h2>{elements.leftColumn.value}</h2>
            </Col>
          </Row>
        </LeftCol>
        <RightCol>{elements.rightColumn.value}</RightCol>
      </Row>
    </Container>
  )
}
