type State = 'YES' | 'NO' | 'UNKNOWN'

interface SetStateOptions {
  setShowForm(value: State): void
}

/**
 * Create set show form handler.
 * @param setShowForm from useState in yes more cash
 * @return yes state
 */
export const createSetShowFormHandler = ({
  setShowForm,
}: SetStateOptions) => () => {
  setShowForm('YES')
}
