import {UUID} from 'io-ts-types/lib/UUID'
import React from 'react'
import styled from 'styled-components'
import {
  Container as BootstrapContainer,
  Row, Col
} from '~/bootstrap/components/layout'
import {NavLink} from '~/bootstrap/components/nav'
import {Link} from '~/common/components/link'
import {Noop} from '~/common/components/noop'
import {createUrl, getLinkText, linkedItems, validImage} from '../../util'
import {KenticoFluidImage} from '../fluid-image'
import {KenticoPreviewBodyLink} from '../preview-link'
import {KenticoRichText} from '../rich-text'
import {KenticoErrorBodyData} from './data'

/** Kentico type data. */
export {KenticoErrorBodyData}

interface Props {
  data: KenticoErrorBodyData
}

const Container = styled(BootstrapContainer)`
  padding-top: ${({theme}) => theme.spacers[4]};
  padding-bottom: ${({theme}) => theme.spacers[4]};
`

const ErrorMessage = styled(Row)`
  flex: 1;
  flex-direction: column;
  justify-content: center;
  padding-top: ${({theme}) => theme.spacers[5]};
  padding-right: ${({theme}) => theme.spacers[3]};
  padding-left: ${({theme}) => theme.spacers[3]};
  align-items: center;
`

const Links = styled(Row)`
  padding-right: ${({theme}) => theme.spacers[3]};
  padding-left: ${({theme}) => theme.spacers[3]};
  flex: 1;
  justify-content: center;
  align-items: center;
`

/**
 * Render Error Body.
 * @return React component
 */
export const KenticoErrorBody = ({
  data: {
    elements: {body, image, links},
    system: {id, name},
  },
}: Props) => {
  // Render image
  let errorImage
  if (validImage(image)) {
    const [imageData] = image.value
    errorImage = imageData
  } else {
    return <Noop />
  }

  // Render links
  const linkElements = linkedItems(links).map(link => {
    try {
      const url = createUrl(link)
      const text = getLinkText(link)
      return (
        <NavLink key={link.system.id} tag={Link} to={url}>
          <strong>{text}</strong>
        </NavLink>
      )
    } catch {
      return undefined
    }
  })

  // Render preview link
  let editLink
  if (!UUID.is(name)) {
    editLink = <KenticoPreviewBodyLink contentItem={id} />
  }

  return (
    <Container>
      {editLink}
      <Row>
        <Col />
        <Col md={"auto"}>
          <KenticoFluidImage image={errorImage} />
        </Col>
        <Col />
      </Row>
      <ErrorMessage>
        <KenticoRichText data={body} />
      </ErrorMessage>
      <Links>{linkElements}</Links>
    </Container>
  )
}
