import React from 'react'
import {KenticoBodyItemBase} from '../body-item-base'
import {KenticoFullSizeRow, KenticoFullSizeRowData} from '../full-size-row'
import {KenticoRow, KenticoRowData} from '../row'
import {
  KenticoTwoColumnLayout,
  KenticoTwoColumnLayoutData,
} from '../two-column-layout'
import {KenticoBodyItemData} from './data'

/** Kentico type data. */
export {KenticoBodyItemData}

interface Props {
  data: KenticoBodyItemData | KenticoBodyItemData[]
}

/**
 * Render correct body item base.
 * @return React component
 */
export const KenticoBodyItem = ({data}: Props) => {
  if (Array.isArray(data)) {
    const items = data.map(datum => (
      <KenticoBodyItem key={datum.system.id} data={datum} />
    ))
    return <>{items}</>
  } else if (KenticoFullSizeRowData.is(data)) {
    return <KenticoFullSizeRow data={data} />
  } else if (KenticoRowData.is(data)) {
    return <KenticoRow data={data} />
  } else if (KenticoTwoColumnLayoutData.is(data)) {
    return <KenticoTwoColumnLayout data={data} />
  }

  return <KenticoBodyItemBase data={data} />
}
