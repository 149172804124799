import {format} from 'date-fns'
import {useCallback, useContext, useMemo, useState} from 'react'
import {CommonContext} from '~/common/context'
import {ContactForm} from '../types'
import {ContactContext} from '.'

/**
 * Construct default contact context.
 * @return Contact context
 */
export const useContact = () => {
  const {api} = useContext(CommonContext)
  const [ready, setReady] = useState(true)
  const [error, setError] = useState<Error>()

  const submit = useCallback(
    async (form: ContactForm) => {
      if (!ready) {
        return false
      }
      setReady(false)
      setError(undefined)
      try {
        await api({
          data: {
            comments: form.comments,
            // TODO Remove when endpoint is set to optional
            contactPreference: form.preference ?? '',
            dateOfBirth: format(form.dateOfBirth, 'MM-dd-yyyy'),
            emailAddress: form.email,
            firstName: form.name.first,
            isExistingCustomer: form.existingCustomer,
            lastName: form.name.last,
            phoneNumber: form.phone,
            state: form.state,
          },
          method: 'POST',
          url: 'v1/forms/contact-us-submit',
        })
        setReady(true)
        return true
      } catch (e) {
        setReady(true)
        setError(new Error('Unable to fulfill request'))
        return false
      }
    },
    [api, ready],
  )

  return useMemo<ContactContext>(
    () => ({
      error,
      ready,
      submit,
    }),
    [error, ready, submit],
  )
}
