import {setHours, startOfDay} from 'date-fns'
import * as IO from 'io-ts'
import {Store} from '.'

/** Response. */
export const StoreResponse = IO.intersection([
  IO.type({
    address: IO.string,
    city: IO.string,
    isBilingual: IO.boolean,
    isCashAdvance: IO.boolean,
    isClosed: IO.boolean,
    isInstallment: IO.boolean,
    isTitleLoan: IO.boolean,
    latitude: IO.number,
    longitude: IO.number,
    phone: IO.string,
    state: IO.string,
    storeNumber: IO.string,
    zip: IO.string,
    showRecomended: IO.boolean,
    recommendedDisclaimer: IO.string,
    notificationMessage: IO.string,
    enableDallasStoreChanges: IO.boolean
  }),
  IO.partial({
    fridayClose: IO.union([IO.number, IO.null]),
    fridayOpen: IO.union([IO.number, IO.null]),
    gmbLocationUrl: IO.union([IO.string, IO.null]),
    mainPhoto: IO.union([
      IO.type({
        height: IO.number,
        url: IO.string,
        width: IO.number,
      }),
      IO.null,
    ]),
    mondayClose: IO.union([IO.number, IO.null]),
    mondayOpen: IO.union([IO.number, IO.null]),
    saturdayClose: IO.union([IO.number, IO.null]),
    saturdayOpen: IO.union([IO.number, IO.null]),
    sundayClose: IO.union([IO.number, IO.null]),
    sundayOpen: IO.union([IO.number, IO.null]),
    thursdayClose: IO.union([IO.number, IO.null]),
    thursdayOpen: IO.union([IO.number, IO.null]),
    tuesdayClose: IO.union([IO.number, IO.null]),
    tuesdayOpen: IO.union([IO.number, IO.null]),
    url: IO.union([IO.string, IO.null]),
    wednesdayClose: IO.union([IO.number, IO.null]),
    wednesdayOpen: IO.union([IO.number, IO.null]),
  }),
])

/** Response type. */
export interface StoreResponse extends IO.TypeOf<typeof StoreResponse> {}

const IMAGE_WIDTH_MAIN = 960
const IMAGE_WIDTHS = [
  IMAGE_WIDTH_MAIN / 4,
  IMAGE_WIDTH_MAIN / 2,
  IMAGE_WIDTH_MAIN,
  IMAGE_WIDTH_MAIN * 1.5,
  IMAGE_WIDTH_MAIN * 2,
]
const createImage = (data: StoreResponse['mainPhoto']): Store['image'] => {
  if (!data) {
    return undefined
  }
  const {height, width, url} = data
  const file = {id: url, url}
  if (width <= 0 || height <= 0) {
    return { file } as any;
  }

  const maxWidth = Math.min(width, IMAGE_WIDTH_MAIN)
  const widths = [...IMAGE_WIDTHS.filter(i => width > i), width]
  return {
    file: {
      ...file,
      childImageSharp: {
        // fluid: {
        //   aspectRatio: width / height,
        //   presentationWidth: maxWidth,
        //   sizes: `(max-width: ${maxWidth}px) 100vw, ${maxWidth}px`,
        //   src: `${url}?width=${maxWidth}`,
        //   srcSet: widths.map(w => `${url}?width=${w} ${w}w`).join(','),
        // },
        gatsbyImageData: {
          layout: "FULL_WIDTH",
          backgroundColor: "",
          images: {
            fallback: {
              src:  `${url}?width=${maxWidth}`,
              srcSet: widths.map(w => `${url}?width=${w} ${w}w`).join(','),
              sizes: `(max-width: ${maxWidth}px) 100vw, ${maxWidth}px`,
            },
            sources:[{
              srcSet: `${url}?width=${maxWidth}`,
              type: 'image/webp',
              sizes: `(max-width: ${maxWidth}px) 100vw, ${maxWidth}px`,
            }]
          },
          height: height,
          width: width,
        }
      },
    },
  }
}

const createHours = (open?: number | null, close?: number | null) => {
  if (open != undefined && close != undefined) {
    const today = startOfDay(new Date(0))
    return {
      close: setHours(today, close + 12),
      open: setHours(today, open),
    }
  }

  return undefined
}

/**
 * Convert store response into a store type.
 * @param res Store data from an API call
 * @return Standardized store structure
 */
export const parseStoreResponse = (res: StoreResponse): Store => ({
  address: res.address,
  bilingual: res.isBilingual,
  city: res.city,
  gmbUrl:
    res.gmbLocationUrl != undefined && res.gmbLocationUrl !== ''
      ? res.gmbLocationUrl
      : undefined,
  hours: {
    friday: createHours(res.fridayOpen, res.fridayClose),
    monday: createHours(res.mondayOpen, res.mondayClose),
    saturday: createHours(res.saturdayOpen, res.saturdayClose),
    sunday: createHours(res.sundayOpen, res.sundayClose),
    thursday: createHours(res.thursdayOpen, res.thursdayClose),
    tuesday: createHours(res.tuesdayOpen, res.tuesdayClose),
    wednesday: createHours(res.wednesdayOpen, res.wednesdayClose),
  },
  image: createImage(res.mainPhoto),
  loans: {
    cashAdvance: res.isCashAdvance,
    installment: res.isInstallment,
    title: res.isTitleLoan,
  },
  location: {
    lat: res.latitude,
    lng: res.longitude,
  },
  number: res.storeNumber,
  phone: res.phone,
  state: res.state,
  url: typeof res.url === 'string' ? `/${res.url}` : undefined,
  zip: res.zip,
  recommendedDisclaimer: res.recommendedDisclaimer,
  showRecomended: res.showRecomended,
  notificationMessage: res.notificationMessage,
  enableDallasStoreChanges: res.enableDallasStoreChanges
})
