import {graphql} from 'gatsby'
import * as IO from 'io-ts'
import {Color} from '~/common/types'
import {LinkedItems} from '../../util'
import {KenticoContentData} from '../content'
import {KenticoFixedImageData} from '../fixed-image'
import {KenticoPageLinkData} from '../page-link'
import {KenticoRichTextData} from '../rich-text'

/** Product detail. */
export const KenticoProductDetailData = IO.type(
  {
    elements: IO.type({
      body: KenticoRichTextData,
      buttonLink: LinkedItems(KenticoPageLinkData),
      buttonText: IO.type({
        value: IO.string,
      }),
      featuresBodyColor: IO.type({
        value: IO.array(
          IO.type({
            codename: Color,
          }),
        ),
      }),
      featuresTextColor: IO.type({
        value: IO.array(
          IO.type({
            codename: Color,
          }),
        ),
      }),
      image: IO.type({
        value: IO.array(KenticoFixedImageData),
      }),
      link: LinkedItems(KenticoPageLinkData),
      linkText: IO.type({
        value: IO.string,
      }),
      title: IO.type({
        value: IO.string,
      }),
    }),
    system: IO.type({
      type: IO.literal('product_detail'),
    }),
  },
  'KenticoProductDetail',
)

/** Product details. */
export const KenticoProductDetailsData = IO.intersection(
  [
    KenticoContentData,
    IO.type({
      elements: IO.type({
        details: LinkedItems(KenticoProductDetailData),
      }),
      system: IO.type({
        type: IO.literal('product_details'),
      }),
    }),
  ],
  'KenticoProductDetails',
)

/** Product details data type. */
export interface KenticoProductDetailsData
  extends IO.TypeOf<typeof KenticoProductDetailsData> {}

/** Product detail data type. */
export interface KenticoProductDetailData
  extends IO.TypeOf<typeof KenticoProductDetailData> {}

/** Fragment helper. */
export const fragment = graphql`
  fragment KenticoProductDetails on Node {
    ... on kontent_item_product_details {
      elements {
        details {
          nodes: value {
            ...KenticoProductDetail
          }
        }
      }
      system {
        id
        codename
        name
        type
      }
    }
  }

  fragment KenticoProductDetail on Node {
    ... on kontent_item_product_detail {
      elements {
        body {
          ...KenticoRichText
        }
        buttonLink: button_link {
          nodes: value {
            ...KenticoPageLink
          }
        }
        buttonText: button_text {
          value
        }
        featuresBodyColor: features_body_color {
          value {
            codename
          }
        }
        featuresTextColor: features_text_color {
          value {
            codename
          }
        }
        image {
          value {
            description
            file {
              childImageSharp {
                gatsbyImageData(layout: FIXED, height: 60)
              }
            }
          }
        }
        link {
          nodes: value {
            ...KenticoPageLink
          }
        }
        linkText: link_text {
          value
        }
        title {
          value
        }
      }
      system {
        id
        codename
        name
        type
      }
    }
  }
`
