import React, {ReactNode} from 'react'
import {ContactContext} from '../../context'
import {useContact} from '../../context/default'

interface Props {
  children: ReactNode
}

/**
 * Inject default information into contact context.
 * @return React component
 */
export const ContactProvider = ({children}: Props) => (
  <ContactContext.Provider value={useContact()}>
    {children}
  </ContactContext.Provider>
)
