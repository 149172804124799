import {either} from 'fp-ts/lib/Either'
import * as IO from 'io-ts'

/** Ratio type. */
export const Ratio = IO.keyof({
  n1_1: undefined,
  n1_2: undefined,
  n1_3: undefined,
  n2_1: undefined,
  n3_1: undefined,
})

/** Ratio type information. */
export type Ratio = IO.TypeOf<typeof Ratio>

/** Color type. */
export const Color = IO.keyof({
  dark: undefined,
  light: undefined,
  primary: undefined,
  secondary: undefined,
  tertiary: undefined,
})

/** Color type information. */
export type Color = IO.TypeOf<typeof Color>

/** Align type. */
export const Align = IO.keyof({
  center: undefined,
  left: undefined,
  right: undefined,
})

/** Align type information. */
export type Align = IO.TypeOf<typeof Align>

/** Direction type. */
export const Direction = IO.keyof({
  horizontal: undefined,
  vertical: undefined,
})

/** Direction type information. */
export type Direction = IO.TypeOf<typeof Direction>

/** Converts date from string, adjusting local timezone offset. */
export const DateFromString = new IO.Type(
  'DateFromString',
  (u): u is Date => u instanceof Date,
  (u, c) =>
    either.chain(IO.string.validate(u, c), s => {
      let d = new Date(s)
      if (isNaN(d.getTime())) {
        return IO.failure(u, c)
      }
      d = new Date(d.getTime() + d.getTimezoneOffset() * 60000)
      return IO.success(d)
    }),
  a => new Date(a.getTime() - a.getTimezoneOffset() * 60000).toISOString(),
)
