import React from 'react'
import {UUID} from 'io-ts-types/lib/UUID'
import {Container as BootstrapContainer} from '~/bootstrap/components/layout'
import {WebForm} from '~/web-form/components/form'
import styled from 'styled-components'
import {KenticoPreviewBodyLink} from '../preview-link'
import {KenticoRichText} from '../rich-text'
import {linkedItems} from '../../util'
import {KenticoUtmParamsData} from '../utm-params'
import {KenticoFormData} from './data'

interface Props {
  data: KenticoFormData
  utmData?: KenticoUtmParamsData
}

/** Kentico type data. */
export {KenticoFormData}

const FORM_TYPE_LOOKUP = {
  campaign: 'CAMPAIGN',
  campaign_appointment: 'CAMPAIGN_APPOINTMENT',
} as const

const Container = styled(BootstrapContainer)`
  padding-top: ${({theme}) => theme.spacers[4]};
`

/**
 * Render form component from Kentico data.
 * @return React component
 */
export const KenticoForm = ({data: {elements, system}}: Props) => {
  let editLink
  if (!UUID.is(system.name)) {
    editLink = <KenticoPreviewBodyLink contentItem={system.id} />
  }
  const formType = elements.formType.value[0].codename
  const type = FORM_TYPE_LOOKUP[formType]
  const tcpaRichText = <KenticoRichText data={elements.tcpa} />
  const privacyConsentRichText = (
    <KenticoRichText data={elements.privacyConsent} />
  )

  const redirectLinks = linkedItems(elements.submissionLinks)
  return (
    <Container>
      {editLink}
      <KenticoRichText data={elements.text} />
      <WebForm
        privacyConsent={privacyConsentRichText}
        submissionLinks={redirectLinks}
        tcpa={tcpaRichText}
        type={type}
      />
    </Container>
  )
}
