import React, {useContext} from 'react'
import {FormContext, FormContextValues, useForm} from 'react-hook-form'
import styled from 'styled-components'
import {Button} from '~/bootstrap/components/button'
import {Form, FormGroup, FormItem, FormText} from '~/bootstrap/components/form'
import {Col, Container, Row} from '~/bootstrap/components/layout'
import {DayOptions} from '~/common/components/day-options'
import {Noop} from '~/common/components/noop'
import {MonthOptions} from '~/common/components/month-options'
import {Spinner} from '~/common/components/spinner'
import {StateOptions} from '~/common/components/state-options'
import {YearOptions} from '~/common/components/year-options'
import {MASKS, PATTERNS} from '~/common/util'
import {GooglePlaces} from '~/google/components/places'
import {Recaptcha} from '~/google/components/recaptcha'
import {VehiclesMake} from '~/vehicles/components/make'
import {VehiclesModel} from '~/vehicles/components/model'
import {VehiclesSeries} from '~/vehicles/components/series'
import {VehiclesStyle} from '~/vehicles/components/style'
import {VehiclesYear} from '~/vehicles/components/year'
import {VehiclesContext} from '~/vehicles/context'
import {WebFormContext} from '../../context'
import {useOnGeocode} from '../form/hooks'
import {FormData, useOnChange, useOnSubmit} from './hooks'

type WebFormProps = import('../form').WebFormProps

interface Props extends WebFormProps {
  type: 'TITLE'
}

const DateOfBirthHeader = styled.h4`
  padding-top: ${({theme}) => theme.spacers[5]};
`

const Disclaimer = styled.small`
  > p {
    margin: 0;
  }
`

const StyledForm = styled(FormGroup)`
  && {
    @media (max-width: ${({theme}) => theme.breakpoints.max.xs}) {
      text-align: center;
      padding-right: 1rem;
    }
  }
`

const ShowMeBtn = styled(Button)`
  && {
    @media (max-width: ${({theme}) => theme.breakpoints.max.xs}) {
      margin-left: auto;
      margin-right: auto;
    }
  }
`

const MAX_LENGTH_MESSAGE = "You've exceeded the max length"

const errorValue = (errorMessage: string, errorNumber: number) => ({
  message: errorMessage,
  value: errorNumber,
})

/**
 * Render title loan form.
 * @return React component
 */
export const PrequalTitleForm = ({
  submissionLinks,
  privacyConsent,
  tcpa,
}: Props) => {
  const {title} = useContext(WebFormContext)
  const {selected} = useContext(VehiclesContext)
  const form = useForm<FormData>({mode: 'onBlur', reValidateMode: 'onChange'})
  const {
    handleSubmit,
    formState: {isValid},
  } = form
  const onSubmit = useOnSubmit({
    selected,
    submissionLinks,
    title,
  })

  const onInput = useOnGeocode(form)

  const isAddressOneLoaded = form.getValues().addressOne === undefined ? false : true
  const formDisabled = !title.ready || !isValid || !selected.ready || !isAddressOneLoaded

  const localRecaptcha = (process.env.GOOGLE_RECAPTCHA_DISABLE_FLAG != 'true') ?
   <Recaptcha required name="recaptcha" />
   : <Noop/>
   return (
    <FormContext {...form}>
      <Container>
        <Form
          id="titleForm"
          onInput={onInput}
          onSubmit={handleSubmit(onSubmit)}
        >
          <FormGroup>
            <FormText color="secondary">*Required Fields</FormText>
          </FormGroup>
          <Row>
            <Col md>
              <VehiclesYear />
            </Col>
            <Col md>
              <VehiclesMake />
            </Col>
          </Row>
          <Row>
            <Col md>
              <VehiclesModel />
            </Col>
            <Col md>
              <VehiclesSeries />
            </Col>
          </Row>
          <Row>
            <Col md="6">
              <VehiclesStyle />
            </Col>
          </Row>
          <DateOfBirthHeader>Personal Information</DateOfBirthHeader>
          <Row>
            <Col md sm="12">
              <FormItem
                label="First Name"
                maxLength={errorValue(MAX_LENGTH_MESSAGE, 50)}
                name="firstName"
                placeholder="First Name*"
                required="Please enter your first name"
                type="text"
              />
            </Col>
            <Col md sm="12">
              <FormItem
                label="Last Name"
                maxLength={errorValue(MAX_LENGTH_MESSAGE, 50)}
                name="lastName"
                placeholder="Last Name*"
                required="Please enter your last name"
                type="text"
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <FormItem
                protect
                label="Email Address"
                maxLength={errorValue(MAX_LENGTH_MESSAGE, 254)}
                name="email"
                pattern={PATTERNS.EMAIL}
                placeholder="Email*"
                required="Please enter your email address"
                type="email"
              />
            </Col>
          </Row>
          <DateOfBirthHeader>Date of Birth</DateOfBirthHeader>
          <Row>
            <Col>
              <Row>
                <Col md="4">
                  <FormItem
                    protect
                    required
                    defaultValue=""
                    label="Month"
                    name="dateOfBirth.month"
                    type="select"
                  >
                    <option disabled value="">
                      Month
                    </option>
                    <MonthOptions />
                  </FormItem>
                </Col>
                <Col md="4">
                  <FormItem
                    protect
                    required
                    defaultValue=""
                    label="Day"
                    name="dateOfBirth.day"
                    type="select"
                  >
                    <option disabled value="">
                      Day
                    </option>
                    <DayOptions />
                  </FormItem>
                </Col>
                <Col md="4">
                  <FormItem
                    protect
                    required
                    defaultValue=""
                    label="Year"
                    name="dateOfBirth.year"
                    type="select"
                  >
                    <option disabled value="">
                      Year
                    </option>
                    <YearOptions />
                  </FormItem>
                </Col>
                <Col sm>
                  <FormItem
                    protect
                    label="Phone"
                    mask={MASKS.PHONE}
                    name="phone"
                    pattern={/^\([0-9]{3}\) [0-9]{3}-[0-9]{4}/}
                    placeholder="Phone Number*"
                    required="Please enter your phone number"
                    type="tel"
                  />
                </Col>
                <Col sm>
                  <FormItem
                    protect
                    required
                    inputMaxLength={4}
                    label="Last 4 of Social"
                    mask={MASKS.SSN}
                    name="socialNumber"
                    pattern={/^[0-9]{4}$/}
                    type="social"
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <FormAddress {...form} />
          <FormItem
            label={<Disclaimer>{privacyConsent}</Disclaimer>}
            name="privacyConsent"
            required="You must agree to these terms and conditions"
            type="checkbox"
          />
          <FormItem
            label={<Disclaimer>{tcpa}</Disclaimer>}
            name="tcpa"
            required="You must agree to these terms and conditions"
            type="checkbox"
          />
          { localRecaptcha }
          <StyledForm>
            <ShowMeBtn color="tertiary" disabled={formDisabled}>
              {title.ready ? 'Show Me How Much' : <Spinner />}
            </ShowMeBtn>
          </StyledForm>
        </Form>
      </Container>
    </FormContext>
  )
}

// eslint-disable-next-line react/no-multi-comp
const FormAddress = (form: FormContextValues<FormData>) => {
  const onChange = useOnChange(form)
  return (
    <>
      <Row>
        <Col>
          <GooglePlaces
            label="Address 1"
            name="addressOne"
            placeholder=""
            required="Please enter an address"
            pattern={/^[^\s].+[^\s]$/}
            onChange={onChange}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <FormItem
            label="Address 2"
            maxLength={errorValue(MAX_LENGTH_MESSAGE, 150)}
            name="addressTwo"
            placeholder="APT, Suite, Unit, etc."
            pattern={/^[^\s].+[^\s]$/}
            type="text"
          />
        </Col>
      </Row>
      <Row>
        <Col md sm="12">
          <FormItem
            label="City"
            maxLength={errorValue(MAX_LENGTH_MESSAGE, 50)}
            name="city"
            placeholder="City*"
            required="Please enter the name of the city you live in"
            pattern={/^[^\s].+[^\s]$/}
            type="text"
          />
        </Col>
        <Col md sm="12">
          <FormItem
            defaultValue=""
            label="State"
            name="state"
            required="Please select the state you live in"
            type="select"
          >
            <option disabled value="">
              Select a State
            </option>
            <StateOptions all />
          </FormItem>
        </Col>
      </Row>
      <Row>
        <Col md sm="12">
          <FormItem
            label="Zip Code"
            mask={MASKS.ZIP_CODE}
            name="zipCode"
            pattern={/^[0-9]{5}/}
            placeholder="Zip Code*"
            required="Please enter your zip code"
            type="tel"
          />
        </Col>
      </Row>
    </>
  )
}
