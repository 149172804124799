import React from 'react'
import {KenticoBodyItemBase, KenticoBodyItemBaseData} from '../body-item-base'
import {KenticoRow, KenticoRowData} from '../row'

type Datum = KenticoRowData | KenticoBodyItemBaseData

interface Props {
  data: Datum | Datum[]
}

/**
 * Render correct column.
 * @return React component
 */
export const Column = ({data}: Props) => {
  if (Array.isArray(data)) {
    const items = data.map(datum => (
      <Column key={datum.system.id} data={datum} />
    ))
    return <>{items}</>
  } else if (KenticoRowData.is(data)) {
    return <KenticoRow data={data} />
  }

  return <KenticoBodyItemBase data={data} />
}
