import {UUID} from 'io-ts-types/lib/UUID'
import React from 'react'
import styled from 'styled-components'
import {Customize} from '~/common/components/customize'
import {
  Col,
  Container as BootstrapContainer,
  Row as BootstrapRow,
} from '~/bootstrap/components/layout'
import {linkedItems} from '../../util'
import {KenticoBodyItemBase} from '../body-item-base'
import {KenticoPreviewBodyLink} from '../preview-link'
import {KenticoRowData} from './data'

interface Props {
  data: KenticoRowData
}

const Container = styled(BootstrapContainer)`
  && {
    padding: 0;
  }
`

const Row = styled(BootstrapRow)`
  @media (max-width: ${({theme}) => theme.breakpoints.max.md}) {
    &:nth-child(odd) {
      justify-content: center;
    }
  }
`

/** Kentico type data. */
export {KenticoRowData}

const CONTAINER_FLEX = {
  justify: 'center',
} as const

/**
 * Render row from Kentico data.
 * @param row Row content type data
 * @return React component
 */
export const KenticoRow = ({
  data: {
    elements: {columns},
    system: {id, name},
  },
}: Props) => {
  let alignButtons
  const children = linkedItems(columns).map((column, index, {length}) => {
    // NOTE While using index as key may normally be considered bad practice,
    // in production the order of columns should never change as that is
    // statically set when builiding the page.
    const {type} = column.system
    alignButtons =
      type === 'button_with_image' && length < 4 ? CONTAINER_FLEX : undefined
    const largeCol = type === 'button_with_image' ? 3 : 12 / length
    return (
      // eslint-disable-next-line react/no-array-index-key
      <Col key={index} lg={largeCol} sm="6" xs="12">
        <KenticoBodyItemBase data={column} />
      </Col>
    )
  })

  let editLink
  if (!UUID.is(name)) {
    editLink = <KenticoPreviewBodyLink contentItem={id} />
  }

  return (
    <Container>
      <Customize noGutters component={Row} flex={alignButtons}>
        {children}
        {editLink}
      </Customize>
    </Container>
  )
}
