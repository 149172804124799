import {darken} from 'polished'
import React, {useCallback, useState} from 'react'
import {FaBars, FaTimes, FaPhone} from 'react-icons/fa'
import Sidebar from 'react-sidebar'
import styled from 'styled-components'
import {Accordion} from '~/bootstrap/components/accordion'
import {Button} from '~/bootstrap/components/button'
import {Col, Container} from '~/bootstrap/components/layout'
import {Link} from '~/common/components/link'
import {createUrl, getLinkText, linkedItems} from '../../util'
import {KenticoLink, KenticoLinkData} from '../link'
import {KenticoPageLinkData} from '../page-link'
import {KenticoHeaderDropdownData} from './data'
import {KenticoPhoneLink, KenticoPhoneLinkData} from '../phone-link'
import {NavLink as BootstrapNavLink} from '~/bootstrap/components/nav'

interface Props {
  links: (KenticoPageLinkData | KenticoHeaderDropdownData)[]
  buttonLink: KenticoLinkData[]
  buttonText: string
  phoneNumber: KenticoPhoneLinkData[]
}

const SidenavItem = styled(Col)`
  padding: ${({theme}) => theme.spacers[3]};
  border-bottom: 1px solid ${({theme}) => darken(0.2, theme.colors.light)};

  > a {
    color: ${({theme}) => theme.colors.dark};
  }
`

const SidenavItemTop = styled(SidenavItem)`
  display: flex;
`

const SIDEBAR_STYLES = {
  root: {
    bottom: undefined,
  },
  sidebar: {
    background: 'white',
    height: '100vh',
    position: 'fixed',
    width: '100vw',
  },
}

const SidenavButton = styled(Button)`
  && {
    height: 100%;
    padding: ${({theme}) => theme.spacers[2]};
    color: ${({theme}) => theme.colors.dark};
  }
`

const SidenavClose = styled(Button)`
  && {
    padding: 0;
    color: ${({theme}) => theme.colors.dark};
  }
`

const AccordionLink = styled(Link)`
  display: flex;
  padding: ${({theme}) => theme.spacers[3]};
  color: ${({theme}) => theme.colors.dark};
`

const PhoneLink = styled(KenticoPhoneLink)`
  color: ${({theme}) => theme.colors.dark};
`

const PhoneIcon = styled(FaPhone)`
  margin-right: ${({theme}) => theme.spacers[2]};
  margin-bottom: ${({theme}) => theme.spacers[1]};
  transform: rotate(90deg);
`

const PhoneLinkElem = styled(BootstrapNavLink)`
  flex: 1;
  text-align: right !important;
  color: ${({theme}) => theme.colors.dark};
  padding: 0rem;
`

/**
 * Render Sidenav component.
 * @return React component
 */
export const Sidenav = ({
  buttonLink,
  buttonText,
  links,
  phoneNumber
}: Props) => {
  // Sidenav state handling
  const [open, setOpen] = useState(false)
  const onSetOpen = useCallback(() => setOpen(!open), [open, setOpen])

  const phoneNumberElem = (
    <PhoneLinkElem link={phoneNumber[0]} tag={PhoneLink}>
      <PhoneIcon />
      {phoneNumber[0].elements.url.value}
    </PhoneLinkElem>
  )

  const items = links.map((link, index) => {
    if (KenticoPageLinkData.is(link)) {
      return (
        <SidenavItem key={link.system.id}>
          <Link to={createUrl(link)}>{getLinkText(link)}</Link>
        </SidenavItem>
      )
    }
    const subItems = linkedItems(link.elements.links).map(l => (
      <AccordionLink key={l.system.id} to={createUrl(l)}>
        {getLinkText(l)}
      </AccordionLink>
    ))
    return (
      <Accordion
        // No better key
        // eslint-disable-next-line react/no-array-index-key
        key={index}
        showTitle
        ariaControls={`collapse${index}`}
        hiddenOver="lg"
        title={link.elements.title.value}
      >
        {subItems}
      </Accordion>
    )
  })

  const menu = (
    <Container>
      <SidenavItemTop>
        <SidenavClose
          aria-label="Close Navigation"
          color="link"
          onClick={onSetOpen}
        >
          <FaTimes />
        </SidenavClose>
        {phoneNumberElem}
      </SidenavItemTop>
      {items}
      <SidenavItem>
        <KenticoLink data={buttonLink}>
          <Button block>{buttonText}</Button>
        </KenticoLink>
      </SidenavItem>
    </Container>
  )

  return (
    <>
      <Sidebar
        open={open}
        sidebar={menu}
        styles={SIDEBAR_STYLES}
        onSetOpen={onSetOpen}
      >
        <></>
      </Sidebar>
      <SidenavButton
        aria-label="Open Navigation"
        color="link"
        onClick={onSetOpen}
      >
        <FaBars />
      </SidenavButton>
    </>
  )
}
