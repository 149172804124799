import {graphql} from 'gatsby'
import * as IO from 'io-ts'

/** Page script data. */
export const KenticoPageScriptData = IO.type(
  {
    elements: IO.type({
      script: IO.type({
        value: IO.string,
      }),
    }),
  },
  'KenticoPageScript',
)

/** Data type. */
export interface KenticoPageScriptData
  extends IO.TypeOf<typeof KenticoPageScriptData> {}

/** Fragment helper. */
export const fragment = graphql`
  fragment KenticoPageScript on Node {
    ... on kontent_item_page_script {
      elements {
        script {
          value
        }
      }
    }
  }
`
