// TODO update / figure out testing for this file
// istanbul ignore file
import {noop} from 'lodash'
import {useCallback, useEffect, useMemo} from 'react'
import {FormContextValues} from 'react-hook-form/dist/contextTypes'
import {OnChangeData} from '~/google/components/places'
import {KenticoPageLinkData} from '~/kentico/components/page-link'
import {createUrl, linkedItems} from '~/kentico/util'
import {navigate} from '@reach/router'
import {VehiclesContext} from '~/vehicles/context'
import {WebFormContext} from '../../context'
import {flatten as flatUtil} from 'flat'

type WebFormProps = import('../form').WebFormProps

interface OnSubmitOptions extends Pick<WebFormProps, 'submissionLinks'> {
  title: WebFormContext['title']
  selected: VehiclesContext['selected']
}

type Form = FormContextValues<FormData>

interface RedirectLink {
  code: string
  url: KenticoPageLinkData
}

/** Form data. */
export interface FormData {
  year: string
  make: string
  model: string
  series: string
  bodyStyle: string
  firstName: string
  lastName: string
  email: string
  dateOfBirth: {
    month: string
    day: string
    year: string
  }
  socialNumber: string
  addressOne: string
  addressTwo: string
  city: string
  state: string
  zipCode: string
  phone: string
  privacyConsent: boolean
  tcpa: boolean
  recaptcha: string
  lat: number
  lng: number
}

/**
 * Create onChange handler.
 * @param form Form data
 * @return onChange handler
 */
export const useOnChange = (form: Form) => {
  useEffect(() => {
    form.register({name: 'lat'})
    form.register({name: 'lng'})
  }, [form])
  const {setValue} = form

  return useCallback(
    async (data: OnChangeData) => {
      if (typeof data.address === 'string') {
        // eslint-disable-next-line @typescript-eslint/await-thenable
        await setValue('addressOne', data.address, true)
      }
      if (typeof data.city === 'string') {
        // eslint-disable-next-line @typescript-eslint/await-thenable
        await setValue('city', data.city, true)
      }
      if (typeof data.state === 'string') {
        // eslint-disable-next-line @typescript-eslint/await-thenable
        await setValue('state', data.state, true)
      }
      if (typeof data.zip === 'string') {
        // eslint-disable-next-line @typescript-eslint/await-thenable
        await setValue('zipCode', data.zip, true)
      }
      if (typeof data.location === 'object') {
        // eslint-disable-next-line @typescript-eslint/await-thenable
        await setValue('lat', data.location.lat, true)
        // eslint-disable-next-line @typescript-eslint/await-thenable
        await setValue('lng', data.location.lng, true)
      }
    },
    [setValue],
  )
}

/**
 * Create onSubmit handler.
 * @return onSubmit handler
 */
export const useOnSubmit = ({
  submissionLinks,
  selected,
  title,
}: OnSubmitOptions) => {
  let redirectLinks: RedirectLink[] = []
  submissionLinks.forEach(
    link =>
      (redirectLinks = [
        ...redirectLinks,
        {
          code:
            link.elements.responseStatus.value
              .find(statusCode => statusCode.codename)
              ?.codename.toUpperCase() ?? '',
          url: linkedItems(link.elements.redirectPage)[0],
        },
      ]),
  )

  // Construct redirect links using appropriate react hooks
  const approvedLink = useMemo(() => {
    const url = redirectLinks.find(item => item.code === 'APPROVED')?.url
    return url === undefined ? url : createUrl(url)
  }, [redirectLinks])
  const declinedLink = useMemo(() => {
    const url = redirectLinks.find(item => item.code === 'DECLINED')?.url
    return url === undefined ? url : createUrl(url)
  }, [redirectLinks])
  const errorLink = useMemo(() => {
    const url = redirectLinks.find(item => item.code === 'ERROR')?.url
    return url === undefined ? url : createUrl(url)
  }, [redirectLinks])
  const multipleErrorLink = useMemo(() => {
    const url = redirectLinks.find(item => item.code === 'MULTIPLE_ERROR')?.url
    return url === undefined ? url : createUrl(url)
  }, [redirectLinks])
  const newCustomerLink = useMemo(() => {
    const url = redirectLinks.find(item => item.code === 'NEW_CUSTOMER')?.url
    return url === undefined ? url : createUrl(url)
  }, [redirectLinks])
  const tempDeclinedLink = useMemo(() => {
    const url = redirectLinks.find(item => item.code === 'TEMP_DECLINED')?.url
    return url === undefined ? url : createUrl(url)
  }, [redirectLinks])

  // Declare navigation functions for appropriate responses
  const onApproved = useCallback(
    async () => (approvedLink == undefined ? noop : navigate(approvedLink)),
    [approvedLink],
  )
  const onDeclined = useCallback(
    async () => (declinedLink == undefined ? noop : navigate(declinedLink)),
    [declinedLink],
  )
  const onError = useCallback(
    async () => (errorLink == undefined ? noop : navigate(errorLink)),
    [errorLink],
  )
  const onMultipleError = useCallback(
    async () => (multipleErrorLink === undefined ? noop : navigate(multipleErrorLink)),
    [multipleErrorLink],
  )
  const onNewCustomer = useCallback(
    async () =>
      newCustomerLink == undefined ? noop : navigate(newCustomerLink),
    [newCustomerLink],
  )
  const onTempDeclined = useCallback(
    async () =>
      tempDeclinedLink == undefined ? noop : navigate(tempDeclinedLink),
    [tempDeclinedLink],
  )

  return useCallback(
    async (formData: FormData) => {
      if (selected.style === undefined) {
        return
      }

      // TODO: in the future (before official website launch), verify if this is still necessary
      // For story 9790, applications for the state of Illinois are
      // automatically redirected to this URL due to regulations
      /* eslint-disable @typescript-eslint/no-floating-promises */
      if (formData.state === 'IL') {
        navigate('prequal-temp-decline-thank-you')
        return
      }

      const wnd = window as any;
      if ('appInsights' in wnd) {
          wnd.appInsights.trackEvent('Cashstore:TitleForm', flatUtil.flatten(formData))
      }

      const result = await title.submit({
        captcha: formData.recaptcha,
        address: {
          city: formData.city,
          state: formData.state,
          street1: formData.addressOne,
          street2: formData.addressTwo,
          zip: formData.zipCode,
        },
        dateOfBirth: new Date(
          Number(formData.dateOfBirth.year),
          Number(formData.dateOfBirth.month) - 1,
          Number(formData.dateOfBirth.day),
        ),
        email: formData.email,
        lat: formData.lat,
        lng: formData.lng,
        name: {
          first: formData.firstName,
          last: formData.lastName,
        },
        phone: formData.phone,
        privacyConsent: formData.privacyConsent,
        ssn: formData.socialNumber,
        tcpa: formData.tcpa,
        vehicle: {
          make: selected.make,
          model: selected.model,
          series: selected.series,
          style: selected.style,
          year: selected.year,
        },
      })
      switch (result) {
        case 'APPROVED':
          await onApproved()
          break
        case 'DECLINED':
          // TODO: in the future (before official website launch), verify if this is still necessary
          // For story 9790, applications for the state of Illinois are
          // automatically redirected to this URL due to regulations
          /* eslint-disable @typescript-eslint/no-floating-promises */
          if (formData.state === 'IL') {
            await onTempDeclined()
            break
          }
          await onDeclined()
          break
        case 'TEMP_DECLINED':
          await onTempDeclined()
          break
        case 'ERROR':
          await onError()
          break
        case 'MULTIPLE_ERROR':
          await onMultipleError()
          break
        case 'NEW_CUSTOMER':
          await onNewCustomer()
          break
        default:
          break
      }
    },
    [
      selected.style,
      selected.make,
      selected.model,
      selected.series,
      selected.year,
      title,
      onTempDeclined,
      onApproved,
      onDeclined,
      onError,
      onNewCustomer,
    ],
  )
}
