"use strict";

exports.__esModule = true;
exports.resetComponentClassCache = exports.activateCacheForComponentClass = exports.inComponentClassCache = void 0;
var componentClassCache = Object.create({});

var inComponentClassCache = function inComponentClassCache(className) {
  return componentClassCache[className] || false;
};

exports.inComponentClassCache = inComponentClassCache;

var activateCacheForComponentClass = function activateCacheForComponentClass(className) {
  if (className) {
    componentClassCache[className] = true;
  }
};

exports.activateCacheForComponentClass = activateCacheForComponentClass;

var resetComponentClassCache = function resetComponentClassCache() {
  for (var className in componentClassCache) {
    delete componentClassCache[className];
  }
};

exports.resetComponentClassCache = resetComponentClassCache;