import {constant} from 'lodash'
import {createContext} from 'react'
import {NonTitleAPI as NTAPI, NonTitleData} from './non-title'
import {TitleAPI, TitleData} from './title'
import {CampaignAPI as CAPI, CampaignData} from './campaign'

/** WebForm data. */
export type WebFormData = NonTitleData | TitleData | CampaignData

/** Non title API. */
export type NonTitleAPI = NTAPI

/** Campaign API. */
export type CampaignAPI = CAPI

/** WebForm information. */
export interface WebFormContext {
  data?: WebFormData
  campaign: CampaignAPI
  campaignAppointment: CampaignAPI
  cashAdvance: NonTitleAPI
  installment: NonTitleAPI
  title: TitleAPI
}

/** Default context. */
export const DEFAULT_CONTEXT: WebFormContext = {
  campaign: {
    ready: false,
    submit: constant(Promise.resolve(undefined)),
  },
  campaignAppointment: {
    ready: false,
    submit: constant(Promise.resolve(undefined)),
  },
  cashAdvance: {
    ready: false,
    submit: constant(Promise.resolve(undefined)),
  },
  installment: {
    ready: false,
    submit: constant(Promise.resolve(undefined)),
  },
  title: {
    ready: false,
    submit: constant(Promise.resolve(undefined)),
  },
}

/** Context implementation. */
export const WebFormContext = createContext(DEFAULT_CONTEXT)

// eslint-disable-next-line functional/immutable-data
WebFormContext.displayName = 'WebForm'
