import {FormItemMask} from '~/bootstrap/components/form'

const MASK_PLACEHOLDER = null

/** Mask for phone inputs. */
export const PHONE: FormItemMask = {
  beforeMaskedStateChange: ({currentState, nextState}) => {
    // Handle empty
    if (currentState.value.length <= 1 && nextState.value === '(') {
      return {selection: {end: 1, length: 1, start: 1}, value: '('}
    }

    // Handle clear
    if (nextState.value.length === 2) {
      return {...nextState, selection: {end: 2, length: 2, start: 2}}
    }

    // Handle autofill
    if (/[0-9]{10}/.test(currentState.value)) {
      const area = currentState.value.slice(0, 3)
      const officeCode = currentState.value.slice(3, 6)
      const lineNumber = currentState.value.slice(6, 10)
      return {
        selection: {end: 13, length: 13, start: 13},
        value: `(${area}) ${officeCode}-${lineNumber}`,
      }
    }

    return nextState
  },
  mask: '(999) 999-9999',
  maskPlaceholder: MASK_PLACEHOLDER,
}

/** Mask for access code inputs. */
export const ACCESS_CODE: FormItemMask = {
  mask: 'aa9999-9999999',
  maskPlaceholder: MASK_PLACEHOLDER,
}

/** Mask for zip code inputs. */
export const ZIP_CODE: FormItemMask = {
  mask: '99999',
  maskPlaceholder: MASK_PLACEHOLDER,
}

/** Mask for numerical inputs. */
export const CASH: FormItemMask = {
  mask: '999999999999',
  maskPlaceholder: MASK_PLACEHOLDER,
}

/** Mask for SSN inputs. */
export const SSN: FormItemMask = {
  mask: '9999',
  maskPlaceholder: MASK_PLACEHOLDER,
}
