"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

exports.__esModule = true;
exports.createNoScriptStyles = exports.createStyleImage = exports.createPseudoStyles = exports.createPseudoElementMediaQuery = exports.createPseudoElementWithContent = exports.createPseudoElement = void 0;

var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));

var _StyleUtils = require("./StyleUtils");

var _ImageUtils = require("./ImageUtils");

var _MediaUtils = require("./MediaUtils");

var _SimpleUtils = require("./SimpleUtils");

var createPseudoElement = function createPseudoElement(className, appendix) {
  if (appendix === void 0) {
    appendix = ":before";
  }

  var escapedClassName = (0, _StyleUtils.escapeClassNames)(className);
  var classes = (0, _SimpleUtils.stringToArray)(escapedClassName);
  var pseudoClasses = "";

  if (Array.isArray(classes)) {
    classes = classes.filter(function (c) {
      return c !== '';
    });

    if (classes.length > 0) {
      pseudoClasses = "." + classes.join('.') + appendix;
    }
  }

  return pseudoClasses;
};

exports.createPseudoElement = createPseudoElement;

var createPseudoElementWithContent = function createPseudoElementWithContent(pseudoElementString, imageSource) {
  return "\n    " + pseudoElementString + " {\n      opacity: 1;\n      background-image: " + imageSource + ";\n    }";
};

exports.createPseudoElementWithContent = createPseudoElementWithContent;

var createPseudoElementMediaQuery = function createPseudoElementMediaQuery(pseudoElementString, media, imageSource, imageSourceWebP) {
  return "\n      @media " + media + " {\n        " + createPseudoElementWithContent(pseudoElementString, imageSource) + "\n      }\n      " + (imageSourceWebP && "@media " + media + " {\n          " + createPseudoElementWithContent(pseudoElementString, imageSourceWebP) + "\n        }") + "\n    ";
};

exports.createPseudoElementMediaQuery = createPseudoElementMediaQuery;

var createPseudoStyles = function createPseudoStyles(_ref) {
  var className = _ref.className,
      transitionDelay = _ref.transitionDelay,
      lastImage = _ref.lastImage,
      nextImage = _ref.nextImage,
      afterOpacity = _ref.afterOpacity,
      bgColor = _ref.bgColor,
      fadeIn = _ref.fadeIn,
      backgroundStyles = _ref.backgroundStyles,
      style = _ref.style,
      finalImage = _ref.finalImage,
      originalData = _ref.originalData;
  var pseudoBefore = createPseudoElement(className);
  var pseudoAfter = createPseudoElement(className, ":after");
  var currentBackgroundStyles = (0, _extends2.default)({}, backgroundStyles, style);
  //Customization done here to the style output
  var usableImage = nextImage != undefined ? nextImage : lastImage ;
  return "\n          " + pseudoBefore + ",\n          " + pseudoAfter + " {\n            content: '';\n            display: block;\n            position: absolute;\n            width: 100%;\n            height: 100%;\n            top: 0;\n            left: 0;\n            " + (bgColor && "background-color: " + bgColor + ";") + "\n            " + (0, _StyleUtils.setTransitionStyles)(transitionDelay, fadeIn) + " \n          " + (0, _StyleUtils.kebabifyBackgroundStyles)(currentBackgroundStyles) + "\n  "+ "\n  background-image: " + usableImage + "; \n        }\n          " + pseudoBefore + " {\n            z-index: -100;\n            " + ((!afterOpacity || finalImage) && createStyleImage(nextImage, originalData) || "") + "\n            " + (afterOpacity && lastImage && createStyleImage(lastImage, originalData) || "") + "\n            opacity: " + Number(!afterOpacity) + "; \n          }\n          " + pseudoAfter + " {\n            z-index: -101;\n            " + ((afterOpacity || finalImage) && createStyleImage(nextImage, originalData) || "") + "\n            " + (!afterOpacity && lastImage && createStyleImage(lastImage, originalData) || "") + "\n            " + (finalImage ? "opacity: " + Number(afterOpacity) + ";" : "") + "\n          }\n        ";
};

exports.createPseudoStyles = createPseudoStyles;

var createStyleImage = function createStyleImage(image, originalData) {
  var hasStackedImages = (0, _ImageUtils.hasImageArray)({
    fluid: originalData
  }) && !(0, _MediaUtils.hasArtDirectionArray)({
    fluid: originalData
  });

  if ((0, _SimpleUtils.isBrowser)() || hasStackedImages) {
    return image ? "background-image: " + image + ";" : "";
  }

  return "";
};

exports.createStyleImage = createStyleImage;

var createNoScriptStyles = function createNoScriptStyles(_ref2) {
  var className = _ref2.className,
      image = _ref2.image;

  if (image) {
    var returnArray = Array.isArray(image) && !(0, _MediaUtils.hasArtDirectionArray)({
      fluid: image
    });
    var addUrl = false;
    var allSources = (0, _ImageUtils.getCurrentFromData)({
      data: image,
      propName: "src",
      checkLoaded: false,
      addUrl: addUrl,
      returnArray: returnArray
    });
    var sourcesAsUrl = (0, _ImageUtils.getUrlString)({
      imageString: allSources,
      hasImageUrls: true,
      returnArray: returnArray
    });
    var sourcesAsUrlWithCSS = "";

    if (returnArray) {
      var cssStrings = (0, _ImageUtils.getCurrentFromData)({
        data: image,
        propName: "CSS_STRING",
        addUrl: false,
        returnArray: returnArray
      });
      sourcesAsUrlWithCSS = (0, _SimpleUtils.filteredJoin)((0, _SimpleUtils.combineArray)(sourcesAsUrl, cssStrings));
    }

    var pseudoBefore = createPseudoElement(className);

    if ((0, _MediaUtils.hasArtDirectionArray)({
      fluid: image
    })) {
      return image.map(function (currentMedia) {
        var sourceString = (0, _ImageUtils.getUrlString)({
          imageString: currentMedia.src
        });
        var webPString = (0, _ImageUtils.getUrlString)({
          imageString: currentMedia.srcWebp || ""
        });

        if (currentMedia.media) {
          return createPseudoElementMediaQuery(pseudoBefore, currentMedia.media, sourceString, webPString);
        }

        return createPseudoElementMediaQuery(pseudoBefore, 'screen', sourceString, webPString);
      }).join('');
    }

    return createPseudoElementWithContent(pseudoBefore, sourcesAsUrlWithCSS || sourcesAsUrl);
  }

  return "";
};

exports.createNoScriptStyles = createNoScriptStyles;