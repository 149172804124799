import React, {ReactNode} from 'react'
import styled from 'styled-components'
import {Container as BootstrapContainer} from '~/bootstrap/components/layout'
import {Customize} from '~/common/components/customize'
import {Color} from '~/common/types'
import {KenticoRichText, KenticoRichTextData} from '../rich-text'
import {KenticoNeedMoreCashData} from './data'

/** Kentico type data. */
export {KenticoNeedMoreCashData}

interface Props {
  backgroundColor: Color
  mainText: KenticoRichTextData
  subText: KenticoRichTextData
  image: ReactNode
  editLink?: ReactNode
}

const Container = styled(BootstrapContainer)`
  padding-top: ${({theme}) => theme.spacers[6]};
  padding-bottom: ${({theme}) => theme.spacers[6]};
`

const ImageContainer = styled.div`
  display: flex;
  padding-top: ${({theme}) => theme.spacers[4]};
  align-items: center;
  justify-content: center;
`

const RichTextField = styled.div`
  text-align: center;
  padding: ${({theme}) => theme.spacers[4]} ${({theme}) => theme.spacers[2]};
`

/**
 * Render no more cash component from Kentico data.
 * @return React component
 */
export const KenticoNoMoreCash = ({
  backgroundColor,
  editLink,
  mainText,
  subText,
  image,
}: Props) => (
  <Customize
    background={backgroundColor}
    component={Container}
    textColor="light"
  >
    {editLink}
    <ImageContainer>{image}</ImageContainer>
    <RichTextField>
      <KenticoRichText data={mainText} />
    </RichTextField>
    <RichTextField>
      <KenticoRichText data={subText} />
    </RichTextField>
  </Customize>
)
