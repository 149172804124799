import React, {useCallback, useContext, useMemo} from 'react'
import styled from 'styled-components'
import {FormGroup, Input, Label} from '~/bootstrap/components/form'
import {VehiclesContext} from '../../context'
import {createOnChange} from './change'

const SelectInput = styled(Input)`
  &&,
  &&:disabled {
    background-color: ${({theme}) => theme.colors.light};
  }
`

/**
 * Render vehicles style input.
 * @return React component
 */
export const VehiclesStyle = () => {
  const {selected, ready, styles} = useContext(VehiclesContext)

  const options = useMemo(
    () =>
      styles.map(style => (
        <option key={style} value={style}>
          {style}
        </option>
      )),
    [styles],
  )

  const onChange = useCallback(createOnChange(selected), [selected])

  return (
    <FormGroup>
      <small>
        <Label>Style*</Label>
      </small>
      <SelectInput
        required
        aria-label="Vehicle Style"
        disabled={!ready}
        name="style"
        type="select"
        value={selected.style ?? ''}
        onChange={onChange}
      >
        <option disabled value="">
          Style
        </option>
        {options}
      </SelectInput>
    </FormGroup>
  )
}
