import * as IO from 'io-ts'
import {useCallback, useContext, useMemo, useState} from 'react'
import {CommonContext} from '~/common/context'
import {TitleCalculatorForm} from '../types'

const Response = IO.type({
  result: IO.type({
    apr: IO.number,
    finePrint: IO.string,
    loanAmount: IO.number,
    payment: IO.number,
  }),
})

/** Prequal non title API. */
export interface TitleCalculatorAPI {
  ready: boolean
  error?: Error
  submit(form: TitleCalculatorForm): Promise<TitleCalculatorData | undefined>
}

/** Title Calculator data. */
export interface TitleCalculatorData {
  amount: number
  apr: number
  finePrint: string
  payment: number
}

/**
 * Construct title calculator API.
 * @return Title calculator API
 */
export const useCalculatorsTitle = () => {
  const {api} = useContext(CommonContext)
  const [ready, setReady] = useState(true)
  const [error, setError] = useState<Error>()

  const submit = useCallback(
    async (
      form: TitleCalculatorForm,
    ): Promise<TitleCalculatorData | undefined> => {
      if (!ready) {
        return undefined
      }
      setReady(false)
      setError(undefined)
      let response
      try {
        response = await api({
          data: {
            selectedBodyStyle: form.style,
            selectedMake: form.make,
            selectedModel: form.model,
            selectedSeries: form.series,
            selectedState: form.state,
            selectedYear: form.year,
          },
          method: 'POST',
          type: Response,
          url: 'v1/bb/vehicles/title-loan-submit',
        })
      } catch {
        setError(new Error('Unable to complete request'))
        setReady(true)
        return undefined
      }
      setReady(true)
      return {
        amount: response.result.loanAmount,
        apr: response.result.apr / 100,
        finePrint: response.result.finePrint,
        payment: response.result.payment,
      }
    },
    [api, ready],
  )

  return useMemo<TitleCalculatorAPI>(
    () => ({
      error,
      ready,
      submit,
    }),
    [error, ready, submit],
  )
}
