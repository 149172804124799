import {UUID} from 'io-ts-types/lib/UUID'
import React, {useContext} from 'react'
import styled from 'styled-components'
import {Container as BootstrapContainer} from '~/bootstrap/components/layout'
import {Customize} from '~/common/components/customize'
import {Noop} from '~/common/components/noop'
import {WebFormContext} from '~/web-form/context'
import {KenticoPreviewBodyLink} from '../preview-link'
import {KenticoRichText} from '../rich-text'
import {KenticoFormDeclinedData} from './data'

interface Props {
  data: KenticoFormDeclinedData
}

/** Kentico type data. */
export {KenticoFormDeclinedData}

const DeclinedContainer = styled(BootstrapContainer)`
  height: 100%;
  align-items: center;

  &&& {
    display: flex;
  }
`

const Container = styled(BootstrapContainer)`
  @media (max-width: ${({theme}) => theme.breakpoints.max.md}) {
    text-align: center;
  }
`

const Title = styled.h1`
  color: ${({theme}) => theme.colors.secondary};
  padding-bottom: ${({theme}) => theme.spacers[3]};
`

/**
 * Render form declined from Kentico data.
 * @return React component
 */
export const KenticoFormDeclined = ({
  data: {
    elements: {backgroundColor, message},
    system: {id, name},
  },
}: Props) => {
  const {data} = useContext(WebFormContext)
  if (
    !data ||
    (data.status !== 'DECLINED' && data.status !== 'NEW_CUSTOMER')
  ) {
    return <Noop />
  }

  let editLink
  if (!UUID.is(name)) {
    editLink = <KenticoPreviewBodyLink contentItem={id} />
  }
  return (
    <Customize
      background={backgroundColor.value[0].codename}
      component={DeclinedContainer}
    >
      {editLink}
      <Container>
        <Title>We&apos;re Sorry, {data.name}!</Title>
        <KenticoRichText data={message} />
      </Container>
    </Customize>
  )
}
