import React, {ReactNode} from 'react'
import {WebFormContext} from '../../context'
import {useWebForm} from '../../context/default'

interface Props {
  children: ReactNode
}

/**
 * Inject default information into web form context.
 * @return React component
 */
export const WebFormProvider = ({children}: Props) => (
  <WebFormContext.Provider value={useWebForm()}>
    {children}
  </WebFormContext.Provider>
)
