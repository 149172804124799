import React from 'react'
import {KenticoYoutubeVideoData} from './data'
import styled from 'styled-components'

interface Props {
  youtubeVideo: KenticoYoutubeVideoData
}

/** Kentico type data. */
export {KenticoYoutubeVideoData} from './data'

const VideoContainer = styled.div`
  padding:0;
  margin-bottom: 0.25rem;
  aspect-ratio: 1.77;
`

const TitleContainer = styled.span`
  padding:0;
  margin-bottom: 0.25rem;
`

/**
 * Render youtube video from Kentico data.
 * @param props Component properties
 * @return React component
 */
export const KenticoYoutubeVideo = ({youtubeVideo}: Props) => {
  const id = youtubeVideo.elements.id.value
  const url = `https://www.youtube.com/embed/${id}`

  return (
    <VideoContainer>
      <TitleContainer>{youtubeVideo.elements.title.value}</TitleContainer>
      <iframe
        width='100%'
        height='100%'
        allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
        src={url}
      />
    </VideoContainer>
  )
}
