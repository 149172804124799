import React, {ReactNode} from 'react'
import {KenticoPageLink} from '../page-link'
import {KenticoPhoneLink, KenticoPhoneLinkData} from '../phone-link'
import {KenticoLinkData} from './data'

interface Props {
  children?: ReactNode
  className?: string
  params?: string
  data: KenticoLinkData | KenticoLinkData[]
}

/** Kentico type data. */
export {KenticoLinkData}

/**
 * Render rich text link or phone link from Kentico data.
 * @param props Component properties
 * @return React component
 */
export const KenticoLink = (props: Props) => {
  if (Array.isArray(props.data)) {
    if (props.data.length === 0) {
      throw new Error('No link found')
    }
    return <KenticoLink {...props} data={props.data[0]} />
  }
  const {className, children, data} = props
  if (KenticoPhoneLinkData.is(data)) {
    return (
      <KenticoPhoneLink className={className} link={data}>
        {children}
      </KenticoPhoneLink>
    )
  }

  return (
    <KenticoPageLink className={className} link={data} params={props.params}>
      {children}
    </KenticoPageLink>
  )
}
