"use strict";

exports.__esModule = true;
exports.switchImageSettings = void 0;

var _ImageUtils = require("./ImageUtils");

var _MediaUtils = require("./MediaUtils");

var _SimpleUtils = require("./SimpleUtils");

var switchImageSettings = function switchImageSettings(_ref) {
  var image = _ref.image,
      bgImage = _ref.bgImage,
      imageRef = _ref.imageRef,
      state = _ref.state;
  var currentSources = (0, _ImageUtils.getCurrentFromData)({
    data: imageRef,
    propName: "currentSrc"
  });
  var returnArray = Array.isArray(image) && !(0, _MediaUtils.hasArtDirectionArray)({
    fluid: image
  });
  var lastImage = Array.isArray(bgImage) ? (0, _SimpleUtils.filteredJoin)(bgImage) : bgImage;
  var nextImage;
  var nextImageArray;
  var finalImage = returnArray && state.seenBefore && !!currentSources;

  if (returnArray) {
    if (!currentSources) {
      nextImage = (0, _ImageUtils.getCurrentFromData)({
        data: image,
        propName: "tracedSVG",
        returnArray: returnArray
      });
      nextImage = (0, _SimpleUtils.combineArray)((0, _ImageUtils.getCurrentFromData)({
        data: image,
        propName: "base64",
        returnArray: returnArray
      }), nextImage);
    }

    nextImage = (0, _SimpleUtils.combineArray)((0, _ImageUtils.getCurrentFromData)({
      data: image,
      propName: "CSS_STRING",
      addUrl: false,
      returnArray: returnArray
    }), nextImage);

    if ((state.imgLoaded || !!currentSources) && state.isVisible) {
      if (currentSources) {
        nextImage = (0, _SimpleUtils.combineArray)((0, _ImageUtils.getCurrentFromData)({
          data: imageRef,
          propName: "currentSrc",
          returnArray: returnArray
        }), nextImage);
        finalImage = true;
      } else {
        nextImage = (0, _SimpleUtils.combineArray)((0, _ImageUtils.getCurrentFromData)({
          data: imageRef,
          propName: "src",
          returnArray: returnArray
        }), nextImage);
        finalImage = true;
      }
    }

    nextImage = (0, _SimpleUtils.combineArray)(nextImage, bgImage);
    var dummyArray = (0, _ImageUtils.createDummyImageArray)(image.length);
    nextImage = (0, _SimpleUtils.combineArray)(nextImage, dummyArray);
    nextImageArray = nextImage;
    nextImage = (0, _SimpleUtils.filteredJoin)(nextImage);
  } else {
    nextImage = "";
    nextImage = (0, _ImageUtils.getCurrentFromData)({
      data: image,
      propName: "tracedSVG"
    }) || (0, _ImageUtils.getCurrentFromData)({
      data: image,
      propName: "base64"
    });

    if (state.imgLoaded && state.isVisible) {
      nextImage = currentSources;
      finalImage = true;
    }
  }

  var afterOpacity = state.imageState % 2;

  if (!returnArray && nextImage === "" && state.imgLoaded && state.isVisible && imageRef && !imageRef.currentSrc) {
    nextImage = (0, _ImageUtils.getCurrentFromData)({
      data: imageRef,
      propName: "src",
      checkLoaded: false
    });
    finalImage = true;
  }

  if (!nextImage) nextImage = lastImage;
  var newImageSettings = {
    lastImage: lastImage,
    nextImage: nextImage,
    afterOpacity: afterOpacity,
    finalImage: finalImage
  };
  if (nextImageArray) newImageSettings.nextImageArray = nextImageArray;
  return newImageSettings;
};

exports.switchImageSettings = switchImageSettings;