import {graphql} from 'gatsby'
import * as IO from 'io-ts'
import {LinkedItems} from '../../util'
import {KenticoContentData} from '../content'
import {KenticoFixedImageData} from '../fixed-image'
import {KenticoPageLinkData} from '../page-link'

/** Page Switcher. */
export const KenticoPageSwitcherData = IO.intersection(
  [
    KenticoContentData,
    IO.type({
      elements: IO.type({
        items: LinkedItems(
          IO.type(
            {
              elements: IO.type({
                image: IO.type({
                  value: IO.array(KenticoFixedImageData),
                }),
                link: LinkedItems(KenticoPageLinkData),
                title: IO.type({value: IO.string}),
              }),
            },
            'KenticoPageSwitcherItem',
          ),
        ),
        title: IO.type({value: IO.string}),
      }),
    }),
  ],
  'KenticoPageSwitcher',
)

/** Data Type. */
export interface KenticoPageSwitcherData
  extends IO.TypeOf<typeof KenticoPageSwitcherData> {}

/** Fragment helper. */
export const fragment = graphql`
  fragment KenticoPageSwitcher on Node {
    ... on kontent_item_page_switcher {
      elements {
        title {
          value
        }
        items {
          nodes: value {
            ... on kontent_item_page_switcher_item {
              elements {
                image {
                  value {
                    file {
                      childImageSharp {
                        gatsbyImageData(layout: FIXED, height: 140)
                      }
                    }
                  }
                }
                title {
                  value
                }
                link {
                  nodes: value {
                    ...KenticoPageLink
                  }
                }
              }
            }
          }
        }
      }
      system {
        id
        codename
        name
        type
      }
    }
  }
`
