import {graphql} from 'gatsby'
import * as IO from 'io-ts'

/** Phone Link. */
export const KenticoPhoneLinkData = IO.type(
  {
    elements: IO.type({
      url: IO.type({
        value: IO.string,
      }),
    }),
    system: IO.type({
      id: IO.string,
      type: IO.literal('phone_link'),
    }),
  },
  'KenticoPhoneLink',
)

/** Data type. */
export interface KenticoPhoneLinkData
  extends IO.TypeOf<typeof KenticoPhoneLinkData> {}

/** Fragment helper. */
export const fragment = graphql`
  fragment KenticoPhoneLink on Node {
    ... on kontent_item_phone_link {
      elements {
        url: phone_number {
          value
        }
      }
      system {
        id
        type
      }
    }
  }
`
