import {UUID} from 'io-ts-types/lib/UUID'
import React from 'react'
import styled from 'styled-components'
import {Button} from '~/bootstrap/components/button'
import {
  Card as BootstrapCard,
  CardBody,
  CardTitle as BootstrapCardTitle,
} from '~/bootstrap/components/card'
import {
  Col as BootstrapCol,
  Container as BootstrapContainer,
  Row,
} from '~/bootstrap/components/layout'
import {Customize} from '~/common/components/customize'
import {Link} from '~/common/components/link'
import {createUrl, linkedItems, validImage} from '../../util'
import {KenticoFixedImage} from '../fixed-image'
import {KenticoPreviewBodyLink} from '../preview-link'
import {KenticoRichText} from '../rich-text'
import {KenticoProductDetailData, KenticoProductDetailsData} from './data'

/** Kentico type data. */
export {KenticoProductDetailsData, KenticoProductDetailData}

interface Props {
  data: KenticoProductDetailsData
}

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-top: ${({theme}) => theme.spacers[5]};
  padding-bottom: ${({theme}) => theme.spacers[3]};
`

const Container = styled(BootstrapContainer)`
  padding-top: ${({theme}) => theme.spacers[5]};
  padding-bottom: ${({theme}) => theme.spacers[5]};
`

const Title = styled.h4`
  padding-bottom: ${({theme}) => theme.spacers[4]};
`

const CardTitle = styled(BootstrapCardTitle)`
  font-size: 1.5rem;
  font-weight: bold;
  padding: ${({theme}) => theme.spacers[2]};
`

const Col = styled(BootstrapCol)`
  @media (max-width: ${({theme}) => theme.breakpoints.max.md}) {
    padding-bottom: ${({theme}) => theme.spacers[4]};
  }
`

const Card = styled(BootstrapCard)`
  height: 100%;
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: ${({theme}) => theme.spacers[3]};
`

const LinkText = styled(Link)`
  padding-bottom: ${({theme}) => theme.spacers[3]};
`

/**
 * Render product details from Kentico data.
 * @return React component
 */
export const KenticoProductDetails = ({
  data: {
    elements: {details},
    system: {id, name},
  },
}: Props) => {
  const colLength = 12 / linkedItems(details).length

  // Render preview link
  let editLink
  if (!UUID.is(name)) {
    editLink = <KenticoPreviewBodyLink contentItem={id} />
  }
  const styledDetails = linkedItems(details).map(({elements}, index) => {
    let imageElement
    const {image} = elements
    if (validImage(image)) {
      imageElement = (
        <ImageContainer>
          <KenticoFixedImage image={image.value[0]} />
        </ImageContainer>
      )
    }
    return (
      // No better key
      // eslint-disable-next-line react/no-array-index-key
      <Col key={index} lg={colLength}>
        <Card>
          {imageElement}
          <Customize component={Title} textAlign="center">
            {elements.title.value}
          </Customize>
          <Customize
            background={elements.featuresBodyColor.value[0].codename}
            component={CardTitle}
            textAlign="center"
            textColor={elements.featuresTextColor.value[0].codename}
          >
            Features
          </Customize>
          <CardBody>
            <KenticoRichText data={elements.body} />
          </CardBody>
          <ButtonContainer>
            <Button
              color="secondary"
              size="md"
              tag={Link}
              to={createUrl(elements.buttonLink)}
            >
              {elements.buttonText.value}
            </Button>
          </ButtonContainer>
          <Customize
            component={LinkText}
            textAlign="center"
            textColor="secondary"
            to={createUrl(elements.link)}
          >
            {elements.linkText.value}
          </Customize>
        </Card>
      </Col>
    )
  })
  return (
    <Container>
      <Row>{styledDetails}</Row>
      {editLink}
    </Container>
  )
}
