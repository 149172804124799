import {graphql} from 'gatsby'
import * as IO from 'io-ts'
import {LinkedItems} from '../../util'
import {KenticoContentData} from '../content'
import {KenticoFixedImageData} from '../fixed-image'
import {KenticoLinkData} from '../link'
import {KenticoPageLinkData} from '../page-link'
import {KenticoPhoneLinkData} from '../phone-link'
import {KenticoRichTextData} from '../rich-text'

/** Header dropdown. */
export const KenticoHeaderDropdownData = IO.type(
  {
    elements: IO.type({
      links: LinkedItems(KenticoLinkData),
      title: IO.type({
        value: IO.string,
      }),
    }),
  },
  'KenticoHeaderDropdown',
)

/** Header. */
export const KenticoHeaderData = IO.intersection(
  [
    KenticoContentData,
    IO.type({
      elements: IO.type({
        buttonLink: LinkedItems(KenticoLinkData),
        buttonText: IO.type({
          value: IO.string,
        }),
        ddscButton: IO.type({
          value: IO.string,
        }),
        ddscImage: IO.type({
          value: IO.array(KenticoFixedImageData),
        }),
        ddscLink: LinkedItems(KenticoLinkData),
        ddscText: KenticoRichTextData,
        dropdownLinks: LinkedItems(KenticoPageLinkData),
        links: LinkedItems(KenticoPageLinkData),
        locatorLink: LinkedItems(KenticoPageLinkData),
        logo: IO.type({
          value: IO.array(KenticoFixedImageData),
        }),
        logoLink: LinkedItems(KenticoPageLinkData),
        logoMobile: IO.type({
          value: IO.array(KenticoFixedImageData),
        }),
        mobileButtonLink: LinkedItems(KenticoLinkData),
        mobileButtonText: IO.type({
          value: IO.string,
        }),
        mobileLinkList: LinkedItems(
          IO.union([KenticoPageLinkData, KenticoHeaderDropdownData]),
        ),
        phoneNumber: LinkedItems(KenticoPhoneLinkData),
        signInButtonText: IO.type({
          value: IO.string,
        }),
        signInButtonUrl: IO.type({
          value: IO.string,
        }),
        signInDisplay: IO.type({
          value: IO.array(
            IO.type({
              codename: IO.keyof({
                display: undefined,
                hide: undefined,
              }),
            }),
          ),
        }),
      }),
      system: IO.type({
        type: IO.literal('header'),
      }),
    }),
  ],
  'KenticoHeader',
)

/** Dropdown data type. */
export interface KenticoHeaderDropdownData
  extends IO.TypeOf<typeof KenticoHeaderDropdownData> {}

/** Data type. */
export interface KenticoHeaderData
  extends IO.TypeOf<typeof KenticoHeaderData> {}

/** Fragment helper. */
export const fragment = graphql`
  fragment KenticoHeader on Node {
    ... on kontent_item_header {
      elements {
        buttonLink: button_link {
          nodes: value {
            ...KenticoLink
          }
        }
        buttonText: button_text {
          value
        }
        dropdownLinks: loan_type_dropdown_links {
          nodes: value {
            ...KenticoPageLink
          }
        }
        ddscButton: dropdown_side_component_button_text {
          value
        }
        ddscImage: dropdown_side_component_image {
          value {
            file {
              childImageSharp {
                gatsbyImageData(layout: FIXED, width: 150)
              }
            }
          }
        }
        ddscLink: dropdown_side_component_link {
          nodes: value {
            ...KenticoLink
          }
        }
        ddscText: dropdown_side_component_text {
          ...KenticoRichText
        }
        links {
          nodes: value {
            ...KenticoPageLink
          }
        }
        locatorLink: locator_link {
          nodes: value {
            ...KenticoPageLink
          }
        }
        logo {
          value {
            file {
              childImageSharp {
                gatsbyImageData(layout: FIXED, height: 35)
              }
            }
          }
        }
        logoMobile: logo {
          value {
            file {
              childImageSharp {
                gatsbyImageData(layout: FIXED, height: 20)
              }
            }
          }
        }
        logoLink: logo_link {
          nodes: value {
            ...KenticoPageLink
          }
        }
        phoneNumber: phone_number {
          nodes: value {
            ...KenticoPhoneLink
          }
        }
        mobileButtonLink: mobile_button_link {
          nodes: value {
            ...KenticoLink
          }
        }
        mobileButtonText: mobile_button_text {
          value
        }
        mobileLinkList: mobile_link_list {
          nodes: value {
            ...KenticoPageLink
            ... on kontent_item_header_dropdown {
              elements {
                links {
                  nodes: value {
                    ...KenticoPageLink
                  }
                }
                title {
                  value
                }
              }
            }
          }
        }
        signInDisplay: sign_in_display {
          value {
            codename
          }
        }
        signInButtonText: sign_in_button_text {
          value
        }
        signInButtonUrl: sign_in_button_url {
          value
        }
      }
      system {
        id
        codename
        name
        type
      }
    }
  }
`
