import {getImage, IGatsbyImageData} from 'gatsby-plugin-image'
import {UUID} from 'io-ts-types/lib/UUID'
import React, {useMemo} from 'react'
import styled from 'styled-components'
import {Button as BootstrapButton} from '~/bootstrap/components/button'
import {
  Col,
  ContainerBase,
  Row as BootstrapRow,
} from '~/bootstrap/components/layout'
import {Customize} from '~/common/components/customize'
import {Noop} from '~/common/components/noop'
import {linkedItems, validImage} from '../../util'
import {BlueImage} from '../blue-image'
import {KenticoLink} from '../link'
import {KenticoPreviewBodyLink} from '../preview-link'
import {KenticoRichText} from '../rich-text'
import {KenticoHeroBannerData} from './data'
import {KenticoFixedImage} from '../fixed-image'

type Data = KenticoHeroBannerData

type OptionalElementKey =
  | 'body'
  | 'buttonColor'
  | 'link'
  | 'buttonText'
  | 'textColor'
  | 'textJustify'
  | 'columns'

type HeroBannerElements = Omit<Data['elements'], OptionalElementKey> &
  Partial<Pick<Data['elements'], OptionalElementKey>>

interface HeroBannerData extends Omit<Data, 'elements'> {
  elements: HeroBannerElements
}

interface Props {
  className?: string
  data: HeroBannerData
}

/** Kentico type data. */
export {KenticoHeroBannerData}

// Vertical centerting in IE with min-height
// https://stackoverflow.com/a/33222765

const Container = styled(ContainerBase)`
  display: flex;
  flex-direction: column;
`

const BackgroundImage = styled(BlueImage)`
  flex: 1;
  height: 100%;
`

const HeroWrapper = styled.div`
  padding-top: ${({theme}) => theme.spacers[4]};
  padding-bottom: ${({theme}) => theme.spacers[4]};
`

const Row = styled(BootstrapRow)`
  flex: 1;

  @media (max-width: ${({theme}) => theme.breakpoints.max.sm}) {
    text-align: center;
  }
`

const Button = styled(BootstrapButton)`
  margin-top: ${({theme}) => theme.spacers[3]};

  @media (max-width: ${({theme}) => theme.breakpoints.max.sm}) {
    margin: 0 auto;
  }
`

const CONTAINER_FLEX = {
  align: 'center',
} as const

const ImageContainer = styled.div`
  padding-right: ${({theme}) => theme.spacers[2]};
  width: 60px;
`

const ItemText = styled.div`
  margin-top: ${({theme}) => theme.spacers[3]};
  font-weight: 800;
`

const GridContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${({theme}) => theme.spacers[2]};
`
const GridItem = styled.div`
  display: flex;
  flex: 1 0 45%;
  padding-bottom: ${({theme}) => theme.spacers[2]};
  @media (max-width: ${({theme}) => theme.breakpoints.max.md}) {
    flex-basis: 100%;
  }
`
/**
 * Render hero banner from Kentico data.
 * @return React component
 */
export const KenticoHeroBanner = ({
  className,
  data: {
    elements: {
      image,
      body,
      textJustify,
      link,
      textColor,
      buttonText,
      buttonColor,
      columns,
    },
    system: {id, name},
  },
}: Props) => {
  let imageData
  if (validImage(image)) {
    imageData = image.value[0].file.childImageSharp.gatsbyImageData
  }

  let editLink
  if (!UUID.is(name)) {
    editLink = <KenticoPreviewBodyLink contentItem={id} />
  }

  let buttonTextValue
  if (buttonText) {
    buttonTextValue = buttonText.value
  }

  let buttonLink
  if (link) {
    buttonLink =
      linkedItems(link).length > 0 ? (
        <Button
          color={buttonColor?.value[0].codename}
          data={linkedItems(link)}
          tag={KenticoLink}
        >
          {buttonTextValue}
        </Button>
      ) : (
        <Noop />
      )
  } else {
    buttonLink = <Noop />
  }

  const richText = body ? <KenticoRichText data={body} /> : <Noop />

  const listWithImagesItems = columns?.value.map(column => {
    const image = column.elements.image.value[0]
    const text = column?.elements?.text.value
    let imageElement
    if (validImage(column.elements.image)) {
      imageElement = <KenticoFixedImage image={image} />
    }

    return (
      <GridItem key={column.system.codename}>
        <ImageContainer>{imageElement}</ImageContainer>
        <ItemText>{text}</ItemText>
      </GridItem>
    )
  })

  const md = useMemo(() => {
    let offset = 0
    let size = 7
    switch (textJustify?.value[0].codename) {
      case 'center':
        offset = 2
        size = 8
        break
      case 'right':
        offset = 5
        break
      default:
        break
    }
    return {offset, size}
  }, [textJustify])

  let myGatsbyImageData = imageData as IGatsbyImageData
  let pluginImage = getImage(myGatsbyImageData)
  return (
    <BackgroundImage image={pluginImage}>
      <Container>
        {editLink}
        <Customize
          className={className}
          component={HeroWrapper}
          flex={CONTAINER_FLEX}
          textColor={textColor?.value[0].codename}
        >
          <Row align={textJustify?.value[0].codename}>
            <Col md={md}>
              {richText}
              <GridContainer>{listWithImagesItems}</GridContainer>
              {buttonLink}
            </Col>
          </Row>
        </Customize>
      </Container>
    </BackgroundImage>
  )
}
