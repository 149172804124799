import {UUID} from 'io-ts-types/lib/UUID'
import React from 'react'
import styled from 'styled-components'
import {Container as BootstrapContainer} from '~/bootstrap/components/layout'
import {Customize} from '~/common/components/customize'
import {linkedItems} from '../../util'
import {KenticoPreviewBodyLink} from '../preview-link'
import {KenticoRichText} from '../rich-text'
import {KenticoBulletedListData} from './data'

interface Props {
  data: KenticoBulletedListData
}

/** Kentico type data. */
export {KenticoBulletedListData}

const Container = styled(BootstrapContainer)`
  padding-top: ${({theme}) => theme.spacers[3]};
  padding-bottom: ${({theme}) => theme.spacers[3]};
`

const BULLET_SIZE = 28
const OFFSET = 12

const List = styled.ol`
  list-style: none;
  padding-inline-start: ${BULLET_SIZE + OFFSET}px;
`

const ListItem = styled.li`
  position: relative;
  counter-increment: custom;

  &:not(:last-child) {
    display: inline-block;
    width: 100%;
    min-height: ${BULLET_SIZE + OFFSET}px;

    &:before {
      content: '';
      border: 1px solid ${({theme}) => theme.colors.light};
      transform: translateX(-50%);
      position: absolute;
      top: 0;
      bottom: 0;
      left: -${BULLET_SIZE / 2 + OFFSET}px;
    }
  }

  &:after {
    content: counter(custom, decimal);
    width: ${BULLET_SIZE}px;
    height: ${BULLET_SIZE}px;
    padding-top: 1px;
    border-radius: 50%;
    color: ${({theme}) => theme.colors.tertiary};
    background-color: ${({theme}) => theme.colors.light};
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: -${BULLET_SIZE + OFFSET}px;
  }
`

/**
 * Render bulleted list from Kentico data.
 * @return React component
 */
export const KenticoBulletedList = ({
  data: {
    elements: {header, listBody},
    system: {id, name},
  },
}: Props) => {
  const items = linkedItems(listBody).map((item, index) => (
    // No better key to use so far
    // eslint-disable-next-line react/no-array-index-key
    <ListItem key={index}>
      <KenticoRichText data={item.elements.body} />
    </ListItem>
  ))
  let editLink
  if (!UUID.is(name)) {
    editLink = <KenticoPreviewBodyLink contentItem={id} />
  }
  return (
    <Customize background="tertiary" component={Container} textColor="light">
      {editLink}
      <KenticoRichText data={header} />
      <List>{items}</List>
    </Customize>
  )
}
