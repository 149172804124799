import {graphql} from 'gatsby'
import * as IO from 'io-ts'
import {LinkedItems} from '../../util'
import {KenticoContentData} from '../content'
import {KenticoFixedImageData} from '../fixed-image'

/** Checklist data. */
export const KenticoChecklistData = IO.intersection(
  [
    KenticoContentData,
    IO.type({
      elements: IO.type({
        image: IO.type({
          value: IO.array(KenticoFixedImageData),
        }),
        items: LinkedItems(
          IO.type({
            elements: IO.type({
              text: IO.type({
                value: IO.string,
              }),
            }),
            system: IO.type({
              codename: IO.string,
            }),
          }),
        ),
        subtitle: IO.type({
          value: IO.string,
        }),
        title: IO.type({
          value: IO.string,
        }),
      }),
      system: IO.type({
        type: IO.literal('checklist'),
      }),
    }),
  ],
  'KenticoChecklist',
)

/** Data Type. */
export interface KenticoChecklistData
  extends IO.TypeOf<typeof KenticoChecklistData> {}

/** Fragment Helper. */
export const fragment = graphql`
  fragment KenticoChecklist on Node {
    ... on kontent_item_checklist {
      elements {
        image {
          value {
            description
            file {
              childImageSharp {
                gatsbyImageData(layout: FIXED, height: 55)
              }
            }
          }
        }
        subtitle {
          value
        }
        title {
          value
        }
        items {
          nodes: value {
            ... on kontent_item_checklist_item {
              elements {
                text {
                  value
                }
              }
              system {
                codename
              }
            }
          }
        }
      }
      system {
        id
        codename
        name
        type
      }
    }
  }
`
