import {graphql} from 'gatsby'
import * as IO from 'io-ts'
import {Ratio} from '~/common/types'
import {LinkedItems} from '../../util'
import {KenticoContentData} from '../content'
import {KenticoFormDeclinedData} from '../form-declined'
import {KenticoFullSizeImageData} from '../full-size-image'
import {KenticoHeroBannerData} from '../hero-banner'
import {KenticoLinkListData} from '../link-list'
import {KenticoLinksColumnData} from '../links-column'
import {KenticoNeedMoreCashData} from '../need-more-cash'
import {KenticoSecuritySealData} from '../security-seal'
import {KenticoStoreLocatorData} from '../store-locator'
import {KenticoTextWithButtonLinkData} from '../text-with-button-link'

/** Full size row. */
export const KenticoFullSizeRowData = IO.intersection(
  [
    KenticoContentData,
    IO.type({
      elements: IO.type({
        columns: LinkedItems(
          IO.union([
            KenticoFullSizeImageData,
            KenticoFormDeclinedData,
            KenticoLinkListData,
            KenticoLinksColumnData,
            KenticoNeedMoreCashData,
            KenticoSecuritySealData,
            KenticoStoreLocatorData,
            KenticoTextWithButtonLinkData,
            KenticoHeroBannerData,
          ]),
        ),
        size: IO.type({
          value: IO.array(
            IO.type({
              codename: Ratio,
            }),
          ),
        }),
      }),
      system: IO.type({
        type: IO.literal('full_size_row'),
      }),
    }),
  ],
  'KenticoFullSizeRow',
)

/** Row data type. */
export interface KenticoFullSizeRowData
  extends IO.TypeOf<typeof KenticoFullSizeRowData> {}

/** Fragment helper. */
export const fragment = graphql`
  fragment KenticoFullSizeRow on Node {
    ... on kontent_item_full_size_row {
      system {
        codename
        id
        name
        type
      }
      elements {
        size {
          value {
            codename
          }
        }
        columns {
          nodes: value {
            ...KenticoFullSizeImage
            ...KenticoFormDeclined
            ...KenticoLinkList
            ...KenticoLinksColumn
            ...KenticoNeedMoreCash
            ...KenticoSecuritySeal
            ...KenticoStoreLocator
            ...KenticoTextWithButtonLink
            ...KenticoHeroBanner
          }
        }
      }
    }
  }
`
