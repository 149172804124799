import {darken} from 'polished'
import React, {useContext} from 'react'
import {FaMapMarkerAlt} from 'react-icons/fa'
import {MdAccessTime, MdArrowDropDown, MdPhone} from 'react-icons/md'
import {UncontrolledCollapse} from 'reactstrap'
import styled from 'styled-components'
import {Button} from '~/bootstrap/components/button'
import {Col, Row} from '~/bootstrap/components/layout'
import {Link} from '~/common/components/link'
import {formatPhoneNumber} from '~/common/util'
import {StoresHours} from '~/stores/components/hours'
import {StoresContext} from '~/stores/context'
import {KenticoFluidImage} from '../fluid-image'
import {HeaderDropdown as BaseHeaderDropdown} from './dropdown'

interface Props {
  locatorLink: string
}

const HeaderDropdown = styled(BaseHeaderDropdown)`
  > .dropdown-menu {
    width: 92vw;
    max-width: 500px;
  }
`

const LocationsRow = styled(Row)`
  border-bottom: 1px solid ${({theme}) => darken(0.2, theme.colors.light)};
  padding: ${({theme}) => theme.spacers[3]} 0;
`

const MapMarker = styled(FaMapMarkerAlt)`
  margin: 0 5px 5px 0;
`

const AddressLine = styled.span`
  display: block;
`

const StoreRow = styled(Row)`
  @media (max-width: ${({theme}) => theme.breakpoints.max.sm}) {
    flex-direction: column;
  }
`

const Image = styled(KenticoFluidImage)`
  @media (max-width: ${({theme}) => theme.breakpoints.max.sm}) {
    width: 50%;
    display: inline-block;
  }
`

const StoreInfo = styled.span`
  padding-left: ${({theme}) => theme.spacers[1]};
`

const StoreLink = styled(Link)`
  color: ${({theme}) => theme.colors.secondary};

  &:hover {
    text-decoration: underline;
  }
`

const StoreDetail = styled.p`
  margin: 0;
`

const StoreHours = styled(StoreDetail)`
  cursor: pointer;
  display: inline-block;

  &:first-of-type {
    margin: 0;
  }

  &:last-of-type {
    cursor: pointer;
    margin-bottom: ${({theme}) => theme.spacers[2]};
  }

  > svg {
    margin-right: ${({theme}) => theme.spacers[2]};
  }
`

const LocationsLinkCol = styled(Col)`
  padding-top: ${({theme}) => theme.spacers[3]};
`

/**
 * Render locations dropdown.
 * @return React component
 */
export const Locations = ({locatorLink}: Props) => {
  const {location} = useContext(StoresContext)
  if (!location.ready) {
    return (
      <Link to={locatorLink}>
        <MapMarker />
        Locations
      </Link>
    )
  }
  // FIXME Centralize this code for use in all four places
  const locationStores = location.stores.map(store => {
    const addressLine = `${store.city}, ${store.state} ${store.zip}`
    let image
    if (store.image) {
      image = (
        <Image
          alt={`The Cash Store -  #${store.number}`}
          image={store.image}
        />
      )
    }

    let storeDetails
    if (store.url !== undefined) {
      storeDetails = (
        <p>
          <StoreLink to={store.url}>Store Details</StoreLink>
        </p>
      )
    }

    let storeLocationLink = (
      <>
        <StoreDetail>
          {store.address}
          <AddressLine>{addressLine}</AddressLine>
        </StoreDetail>
      </>
    )
    if (store.gmbUrl !== undefined) {
      storeLocationLink = (
        <a href={store.gmbUrl} rel="noopener noreferrer" target="_blank">
          {storeLocationLink}
        </a>
      )
    }

    return (
      <LocationsRow key={store.number}>
        <Col>
          <StoreRow>
            <Col>{image}</Col>
            <Col>
              {storeLocationLink}
              <StoreDetail>
                <MdPhone />
                <StoreInfo>
                  <a href={`tel:${store.phone}`}>
                    {formatPhoneNumber(store.phone)}
                  </a>
                </StoreInfo>
              </StoreDetail>
              <StoreHours id={`dropDown${store.number}`}>
                <MdAccessTime />
                Store Hours
                <MdArrowDropDown />
              </StoreHours>
              <UncontrolledCollapse toggler={`#dropDown${store.number}`}>
                <StoresHours store={store} />
              </UncontrolledCollapse>
              {storeDetails}
            </Col>
          </StoreRow>
        </Col>
      </LocationsRow>
    )
  })

  return (
    <HeaderDropdown
      right
      title={
        <>
          <MapMarker />
          {location.city}, {location.state}
        </>
      }
    >
      {locationStores}
      <Row>
        <LocationsLinkCol>
          <Button block tag={Link} to={locatorLink}>
            More Locations
          </Button>
        </LocationsLinkCol>
      </Row>
    </HeaderDropdown>
  )
}
