import {UUID} from 'io-ts-types/lib/UUID'
import React from 'react'
import styled from 'styled-components'
import {Container as BootstrapContainer} from '~/bootstrap/components/layout'
import {Customize} from '~/common/components/customize'
import {KenticoPreviewBodyLink} from '../preview-link'
import {KenticoRichText} from '../rich-text'
import {KenticoTextData} from './data'

interface Props {
  className?: string
  data: KenticoTextData
}

/** Kentico type data. */
export {KenticoTextData}

const Container = styled(BootstrapContainer)`
  padding-top: ${({theme}) => theme.spacers[4]};
  padding-bottom: ${({theme}) => theme.spacers[4]};

  img {
    border-radius: 5px;
  }
`

/**
 * Render text from Kentico data.
 * @return React component
 */
export const KenticoText = ({
  className,
  data: {
    elements: {align, body, options},
    system: {id, name},
  },
}: Props) => {
  let editLink
  if (!UUID.is(name)) {
    editLink = <KenticoPreviewBodyLink contentItem={id} />
  }
  const isSmall = options.value.some(
    ({codename}) => (codename as string) === 'small',
  )
  let children = <KenticoRichText data={body} />
  if (isSmall) {
    children = <small>{children}</small>
  }
  const alignment = align.value.length > 0 ? align.value[0].codename : 'left'
  return (
    <Customize
      className={className}
      component={Container}
      textAlign={alignment}
    >
      {editLink}
      {children}
    </Customize>
  )
}
