import {graphql} from 'gatsby'
import * as IO from 'io-ts'
import {KenticoContentData} from '../content'

/** Kentico Schema Financial Service. */
export const KenticoSchemaFinancialServiceData = IO.intersection(
  [
    KenticoContentData,
    IO.type({
      elements: IO.type({
        addressCountry: IO.type({
          value: IO.string,
        }),
        addressLocality: IO.type({
          value: IO.string,
        }),
        addressRegion: IO.type({
          value: IO.string,
        }),
        addressType: IO.type({
          value: IO.string,
        }),
        aggregateRatingType: IO.type({
          value: IO.string,
        }),
        areaServedId: IO.type({
          value: IO.string,
        }),
        areaServedName: IO.type({
          value: IO.string,
        }),
        areaServedType: IO.type({
          value: IO.string,
        }),
        context: IO.type({
          value: IO.string,
        }),
        description: IO.type({
          value: IO.string,
        }),
        geoType: IO.type({
          value: IO.string,
        }),
        hasPos: IO.type({
          value: IO.string,
        }),
        id: IO.type({
          value: IO.string,
        }),
        image: IO.type({
          value: IO.array(
            IO.type({
              file: IO.type({
                url: IO.string,
              }),
            }),
          ),
        }),
        latitude: IO.type({
          value: IO.string,
        }),
        logo: IO.type({
          value: IO.array(
            IO.type({
              file: IO.type({
                url: IO.string,
              }),
            }),
          ),
        }),
        longitude: IO.type({
          value: IO.string,
        }),
        name: IO.type({
          value: IO.string,
        }),
        openingHoursSpecification: IO.type({
          nodes: IO.array(
            IO.type({
              elements: IO.type({
                closes: IO.type({
                  value: IO.string,
                }),
                day: IO.type({
                  value: IO.string,
                }),
                opens: IO.type({
                  value: IO.string,
                }),
                type: IO.type({
                  value: IO.string,
                }),
              }),
            }),
          ),
        }),
        breadcrumb:IO.type({
          nodes:IO.array(
            IO.type({
              elements: IO.type({
                context: IO.type({
                  value: IO.string,
                }),
                type: IO.type({
                  value: IO.string,
                }),
                itemListElement: IO.type({
                  nodes: IO.array(
                    IO.type({
                      elements: IO.type({
                        type: IO.type({
                          value: IO.string,
                        }),
                        position: IO.type({
                          value: IO.string,
                        }),
                        item: IO.type({
                          nodes: IO.array(
                            IO.type({
                              elements: IO.type({
                                type: IO.type({
                                  value: IO.string,
                                }),
                                id: IO.type({
                                  value: IO.string,
                                }),
                                name: IO.type({
                                  value: IO.string,
                                }),
                              }),
                            }),
                          ),
                        })
                      }),
                    }),
                  ),
                }),
              }),
            }),
          ),
        }),
        postalCode: IO.type({
          value: IO.string,
        }),
        priceRange: IO.type({
          value: IO.string,
        }),
        ratingValue: IO.type({
          value: IO.string,
        }),
        reviewCount: IO.type({
          value: IO.string,
        }),
        streetAddress: IO.type({
          value: IO.string,
        }),
        telephone: IO.type({
          value: IO.string,
        }),
        type: IO.type({
          value: IO.string,
        }),
        url: IO.type({
          value: IO.string,
        }),
      }),
      system: IO.type({
        type: IO.literal('schema_financial_service'),
      }),
    }),
  ],
  'KenticoSchemaFinancialService',
)

/** Data Type. */
export interface KenticoSchemaFinancialServiceData
  extends IO.TypeOf<typeof KenticoSchemaFinancialServiceData> {}

/** Fragment Helper. */
export const fragment = graphql`
  fragment KenticoSchemaFinancialService on Node {
    ... on kontent_item_schema_financial_service {
      elements {
        context {
          value
        }
        id {
          value
        }
        type {
          value
        }
        name {
          value
        }
        description {
          value
        }
        image {
          value {
            file {
              url: publicURL
            }
          }
        }
        logo {
          value {
            file {
              url: publicURL
            }
          }
        }
        telephone {
          value
        }
        url {
          value
        }
        priceRange: price_range {
          value
        }
        hasPos: has_pos {
          value
        }
        areaServedType: area_served_type {
          value
        }
        areaServedName: area_served_name {
          value
        }
        areaServedId: area_served_id {
          value
        }
        aggregateRatingType: aggregate_rating_type {
          value
        }
        ratingValue: rating_value {
          value
        }
        reviewCount: review_count {
          value
        }
        addressType: address_type {
          value
        }
        addressCountry: address_country {
          value
        }
        addressLocality: address_locality {
          value
        }
        addressRegion: address_region {
          value
        }
        postalCode: postal_code {
          value
        }
        streetAddress: street_address {
          value
        }
        geoType: geo_type {
          value
        }
        latitude {
          value
        }
        longitude {
          value
        }
        openingHoursSpecification: opening_hours_specification {
          nodes: value {
            ... on kontent_item_schema_opening_hours_specifications {
              elements {
                type {
                  value
                }
                closes {
                  value
                }
                day: day_of_week {
                  value
                }
                opens {
                  value
                }
              }
            }
          }
        }
        breadcrumb: breadcrumb {
          nodes: value {
            ... on kontent_item_schema_breadcrumb {
              elements {
                context {
                  value
                }
                type {
                  value
                }
                itemListElement: item_list_element {
                  nodes: value {
                    ... on kontent_item_schema_breadcrumb_list_element {
                      elements {
                        type {
                          value
                        }
                        position {
                          value
                        }
                        item: item {
                          nodes: value {
                            ... on kontent_item_schema_breadcrumb_item {
                              elements {
                                type {
                                  value
                                }
                                id {
                                  value
                                }
                                name {
                                  value
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      system {
        id
        codename
        name
        type
      }
    }
  }
`
