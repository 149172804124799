import {UUID} from 'io-ts-types/lib/UUID'
import React from 'react'
import styled from 'styled-components'
import {Button as BootstrapButton} from '~/bootstrap/components/button'
import {
  Col as BootstrapCol,
  Container as BootstrapContainer,
  Row as BootstrapRow,
} from '~/bootstrap/components/layout'
import {Customize} from '~/common/components/customize'
import {linkedItems} from '../../util'
import {KenticoLink} from '../link'
import {KenticoPreviewBodyLink} from '../preview-link'
import {KenticoRichText} from '../rich-text'
import {KenticoTextWithButtonLinkData} from './data'

/** Kentico type data. */
export {KenticoTextWithButtonLinkData}

interface Props {
  data: KenticoTextWithButtonLinkData
}

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;
`

const Container = styled(BootstrapContainer)`
  padding-top: ${({theme}) => theme.spacers[4]};
  padding-bottom: ${({theme}) => theme.spacers[4]};
`

const Row = styled(BootstrapRow)`
  align-items: center;
`

const Col = styled(BootstrapCol)`
  padding-top: ${({theme}) => theme.spacers[2]};
  padding-bottom: ${({theme}) => theme.spacers[2]};

  @media (max-width: ${({theme}) => theme.breakpoints.max.sm}) {
    text-align: center;
  }
`

const ButtonColVertical = styled(Col)`
  display: flex;
  justify-content: center;
`

const ButtonColHorizontal = styled(ButtonColVertical)`
  display: flex;
  justify-content: flex-end;

  @media (max-width: ${({theme}) => theme.breakpoints.max.sm}) {
    justify-content: center;
  }
`

const Button = styled(BootstrapButton)`
  && {
    display: inline-flex;
    width: auto;
  }
`

const Content = styled(Col)`
  > p:last-child {
    margin-bottom: 0;
  }
`

/**
 * Render text with button link from Kentico data.
 * @return React component
 */
export const KenticoTextWithButtonLink = ({
  data: {
    elements: {
      backgroundColor,
      buttonColor,
      buttonText,
      content,
      direction,
      link,
      textColor,
    },
    system: {name, id},
  },
}: Props) => {
  const contentDirection =
    direction.value.length > 0 ? direction.value[0].codename : undefined
  const isVertical = contentDirection === 'vertical'
  const ButtonCol = isVertical ? ButtonColVertical : ButtonColHorizontal

  let editLink
  if (!UUID.is(name)) {
    editLink = <KenticoPreviewBodyLink contentItem={id} />
  }

  return (
    <Customize
      background={backgroundColor.value[0].codename}
      component={Wrapper}
      textAlign={isVertical ? 'center' : undefined}
      textColor={textColor.value[0].codename}
    >
      {editLink}
      <Container>
        <Row noGutters={!isVertical}>
          <Content md={isVertical ? undefined : 8} sm="12">
            <KenticoRichText data={content} />
          </Content>
          <ButtonCol md={isVertical ? undefined : 4} sm="12">
            <Button
              block={!isVertical}
              color={buttonColor.value[0].codename}
              data={linkedItems(link)}
              tag={KenticoLink}
            >
              {buttonText.value}
            </Button>
          </ButtonCol>
        </Row>
      </Container>
    </Customize>
  )
}
