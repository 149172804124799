import React from 'react'
import {Helmet} from 'react-helmet'
import {linkedItems} from '~/kentico/util'
import {KenticoSchemaLoanOrCreditData} from './data'

interface Props {
  data: KenticoSchemaLoanOrCreditData
}

/** Kentico type data. */
export {KenticoSchemaLoanOrCreditData}

/**
 * Render Kentico Schema Loan or Credit script.
 * @return React component
 */
export const KenticoSchemaLoanOrCredit = ({data}: Props) => {
  const schema = {
    '@context': data.elements.context.value,
    '@id': data.elements.id.value,
    '@type': data.elements.type.value,
    amount: linkedItems(data.elements.amount).map(item => ({
      '@type': item.elements.type.value,
      currency: item.elements.currency.value,
      maxValue: item.elements.maxValue.value,
      minValue: item.elements.minValue.value,
      name: item.elements.name.value,
    })),
    areaServed: linkedItems(data.elements.areaServed).map(item => ({
      '@id': item.elements.id.value,
      '@type': item.elements.type.value,
      name: item.elements.name.value,
    })),
    description: data.elements.description.value,
    image: data.elements.image.value[0].file.url,
    logo: data.elements.logo.value[0].file.url,
    name: data.elements.name.value,
    offers: {
      '@type': data.elements.offersType.value,
      offeredBy: data.elements.offeredBy.value,
    },
  }
  return (
    <Helmet>
      <script type="application/ld+json">{JSON.stringify(schema)}</script>
    </Helmet>
  )
}
