import React from 'react'
import styled from 'styled-components'

const Disclaimer = styled.div`
  border: solid 0.1rem;
  border-color: ${({theme}) => theme.colors.secondary};
  border-radius: 1rem;
  padding: 1rem 0.3rem 1rem 0.3rem;
  font-weight: 500;
  font-size: initial;
  white-space: inherit;
  line-height: 1.1rem;
  margin: 0 1.3rem 0.3rem 1.3rem;
`

export const recommendedDisclaimerText = `*There may be stores closer to you, but they
may have lower loan amounts and less flexible
terms. Contact your store for more information.`;

export const RecommendedDisclaimer = () => {
    return (
      <Disclaimer>{recommendedDisclaimerText}</Disclaimer>
        )
}
