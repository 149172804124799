import React from 'react'
import {Helmet} from 'react-helmet'
import {linkedItems} from '~/kentico/util'
import {KenticoSchemaFinancialServiceData} from './data'

interface Props {
  data: KenticoSchemaFinancialServiceData
}

/** Kentico type data. */
export {KenticoSchemaFinancialServiceData}

/**
 * Render Kentico Schema FinancialService script.
 * @return React component
 */
export const KenticoSchemaFinancialService = ({data}: Props) => {
  let aggregateRating
  if (
    data.elements.aggregateRatingType.value !== '' &&
    data.elements.ratingValue.value !== '' &&
    data.elements.reviewCount.value !== ''
  ) {
    aggregateRating = {
      '@type': data.elements.aggregateRatingType.value,
      ratingValue: data.elements.ratingValue.value,
      reviewCount: data.elements.reviewCount.value,
    }
  } else if (
    data.elements.aggregateRatingType.value !== '' &&
    data.elements.ratingValue.value !== ''
  ) {
    aggregateRating = {
      '@type': data.elements.aggregateRatingType.value,
      ratingValue: data.elements.ratingValue.value,
    }
  } else if (
    data.elements.aggregateRatingType.value !== '' &&
    data.elements.reviewCount.value !== ''
  ) {
    aggregateRating = {
      '@type': data.elements.aggregateRatingType.value,
      reviewCount: data.elements.reviewCount.value,
    }
  }

  const schema = {
    '@context': data.elements.context.value,
    '@id': data.elements.id.value,
    '@type': data.elements.type.value,
    address: {
      '@type': data.elements.addressType.value,
      addressCountry: data.elements.addressCountry.value,
      addressLocality: data.elements.addressLocality.value,
      addressRegion: data.elements.addressRegion.value,
      postalCode: data.elements.postalCode.value,
      streetAddress: data.elements.streetAddress.value,
    },
    aggregateRating,
    areaServed: {
      '@id': data.elements.areaServedId.value,
      '@type': data.elements.areaServedType.value,
      name: data.elements.areaServedName.value,
    },
    description: data.elements.description.value,
    geo: {
      '@type': data.elements.geoType.value,
      latitude: data.elements.latitude.value,
      longitude: data.elements.longitude.value,
    },
    hasPOS: data.elements.hasPos.value,
    image: data.elements.image.value[0].file.url,
    logo: data.elements.logo.value[0].file.url,
    name: data.elements.name.value,
    openingHoursSpecification: linkedItems(
      data.elements.openingHoursSpecification,
    ).map(item => ({
      '@type': item.elements.type.value,
      closes: item.elements.closes.value,
      dayOfWeek: item.elements.day.value,
      opens: item.elements.opens.value,
    })),
    priceRange: data.elements.priceRange.value,
    telephone: data.elements.telephone.value,
    url: data.elements.url.value,
  }

  if(data.elements.breadcrumb.nodes[0]){
    const schemaBreadcrumb = {
          '@context': data.elements.breadcrumb.nodes[0].elements.context.value,
          '@type': data.elements.breadcrumb.nodes[0].elements.type.value,
          itemListElement: linkedItems(
            data.elements.breadcrumb.nodes[0].elements.itemListElement,
          ).map(lkItem => ({
            '@type': lkItem.elements.type.value,
            position: lkItem.elements.position.value,
            item:  linkedItems(
              lkItem.elements.item,
            ).map((brCrItem) => {
              if(brCrItem.elements.id.value){
                return {
                  '@type': brCrItem.elements.type.value,
                  '@id': brCrItem.elements.id.value,
                  name: brCrItem.elements.name.value,
                }
              }
              return {
                '@type': brCrItem.elements.type.value,
                name: brCrItem.elements.name.value,
              }
            }),
          }))
      }
      return (
        <Helmet>
          <script type="application/ld+json">{JSON.stringify(schema)}</script>
          <script type="application/ld+json">{JSON.stringify(schemaBreadcrumb)}</script>
        </Helmet>
      )
  }
  return (
    <Helmet>
      <script type="application/ld+json">{JSON.stringify(schema)}</script>
    </Helmet>
  )
}
