"use strict";

exports.__esModule = true;
exports.imageReferenceCompleted = exports.hasPictureRef = exports.hasActivatedPictureRefs = exports.activateMultiplePictureRefs = exports.activatePictureRef = exports.createMultiplePictureRefs = exports.createPictureRef = void 0;

var _HelperUtils = require("./HelperUtils");

var _ImageUtils = require("./ImageUtils");

var _MediaUtils = require("./MediaUtils");

var _SimpleUtils = require("./SimpleUtils");

var _ImageCache = require("./ImageCache");

var createPictureRef = function createPictureRef(props, onLoad, index, isLoop) {
  if (isLoop === void 0) {
    isLoop = false;
  }

  var convertedProps = (0, _HelperUtils.convertProps)(props);

  if ((0, _SimpleUtils.isBrowser)() && (typeof convertedProps.fluid !== "undefined" || typeof convertedProps.fixed !== "undefined")) {
    var isImageStack = (0, _ImageUtils.hasImageArray)(convertedProps) && !(0, _MediaUtils.hasArtDirectionArray)(convertedProps);

    if (isImageStack && !isLoop) {
      return createMultiplePictureRefs(props, onLoad);
    }

    var img = new Image();

    img.onload = function () {
      return onLoad();
    };

    if (!img.complete && typeof convertedProps.onLoad === "function") {
      img.addEventListener('load', convertedProps.onLoad);
    }

    if (typeof convertedProps.onError === "function") {
      img.addEventListener('error', convertedProps.onError);
    }

    if (convertedProps.crossOrigin) {
      img.crossOrigin = convertedProps.crossOrigin;
    }

    if ((convertedProps.critical || convertedProps.isVisible) && !isLoop) {
      return activatePictureRef(img, convertedProps, index, isLoop);
    }

    return img;
  }

  return null;
};

exports.createPictureRef = createPictureRef;

var createMultiplePictureRefs = function createMultiplePictureRefs(props, onLoad) {
  var convertedProps = (0, _HelperUtils.convertProps)(props);
  var imageStack = convertedProps.fluid || convertedProps.fixed;
  var imageRefs = imageStack.map(function (imageData, index) {
    return createPictureRef(convertedProps, onLoad, index, true);
  });

  if (convertedProps.critical || convertedProps.isVisible) {
    return activatePictureRef(imageRefs, convertedProps);
  }

  return imageRefs;
};

exports.createMultiplePictureRefs = createMultiplePictureRefs;

var activatePictureRef = function activatePictureRef(imageRef, props, selfRef, index, isLoop) {
  if (selfRef === void 0) {
    selfRef = null;
  }

  if (index === void 0) {
    index = 0;
  }

  if (isLoop === void 0) {
    isLoop = false;
  }

  var convertedProps = (0, _HelperUtils.convertProps)(props);

  if ((0, _SimpleUtils.isBrowser)() && (typeof convertedProps.fluid !== "undefined" || typeof convertedProps.fixed !== "undefined")) {
    var isImageStack = (0, _ImageUtils.hasImageArray)(convertedProps) && !(0, _MediaUtils.hasArtDirectionArray)(convertedProps);

    if (isImageStack && !isLoop) {
      return activateMultiplePictureRefs(imageRef, props, selfRef);
    }

    var bodyClone = document.createElement('body');
    var imageData = isImageStack ? (0, _ImageUtils.getSelectedImage)(convertedProps, index) : (0, _ImageUtils.getCurrentSrcData)(convertedProps);

    if (!imageData) {
      return null;
    }

    if ((0, _SimpleUtils.isString)(imageData)) {
      return imageData;
    }

    if (selfRef) {
      imageRef.width = selfRef.offsetWidth;
      imageRef.height = selfRef.offsetHeight;
    }

    if ((0, _ImageUtils.hasPictureElement)()) {
      var pic = document.createElement('picture');

      if (selfRef) {
        pic.width = imageRef.width;
        pic.height = imageRef.height;
      }

      if ((0, _MediaUtils.hasArtDirectionArray)(convertedProps)) {
        var sources = (0, _MediaUtils.createArtDirectionSources)(convertedProps).reverse();
        sources.forEach(function (currentSource) {
          return pic.appendChild(currentSource);
        });
      }

      var sourcesAvif = (0, _MediaUtils.createSourceElementForSrcSet)(imageData, 'avif');
      sourcesAvif && pic.appendChild(sourcesAvif);
      var sourcesWebp = (0, _MediaUtils.createSourceElementForSrcSet)(imageData, 'webp');
      sourcesWebp && pic.appendChild(sourcesWebp);
      pic.appendChild(imageRef);
      bodyClone.appendChild(pic);
    }

    imageRef.sizes = imageData.sizes || "";
    imageRef.srcset = imageData.srcSet || "";
    imageRef.src = imageData.src || "";
    return imageRef;
  }

  return null;
};

exports.activatePictureRef = activatePictureRef;

var activateMultiplePictureRefs = function activateMultiplePictureRefs(imageRefs, props, selfRef) {
  return imageRefs.map(function (imageRef, index) {
    return activatePictureRef(imageRef, props, selfRef, index, true);
  });
};

exports.activateMultiplePictureRefs = activateMultiplePictureRefs;

var hasActivatedPictureRefs = function hasActivatedPictureRefs(imageRefs) {
  return Array.isArray(imageRefs) ? imageRefs.every(function (imageRef) {
    return hasPictureRef(imageRef);
  }) : hasPictureRef(imageRefs);
};

exports.hasActivatedPictureRefs = hasActivatedPictureRefs;

var hasPictureRef = function hasPictureRef(imageRef) {
  return (0, _SimpleUtils.isString)(imageRef) || !!imageRef && !!imageRef.currentSrc;
};

exports.hasPictureRef = hasPictureRef;

var imageReferenceCompleted = function imageReferenceCompleted(imageRef, props) {
  return imageRef ? Array.isArray(imageRef) ? imageRef.every(function (singleImageRef) {
    return (0, _ImageUtils.imageLoaded)(singleImageRef);
  }) || (0, _ImageCache.inImageCache)(props) : (0, _ImageUtils.imageLoaded)(imageRef) || (0, _ImageCache.inImageCache)(props) : (0, _SimpleUtils.isString)(imageRef);
};

exports.imageReferenceCompleted = imageReferenceCompleted;