import {UUID} from 'io-ts-types/lib/UUID'
import {toString} from 'lodash'
import React from 'react'
import styled from 'styled-components'
import {
  Col,
  Container as BootstrapContainer,
  Row,
} from '~/bootstrap/components/layout'
import {validImage} from '../../util'
import {KenticoFluidImage} from '../fluid-image'
import {KenticoPreviewBodyLink} from '../preview-link'
import {KenticoRichText} from '../rich-text'
import {KenticoTextWithImageData} from './data'

interface Props {
  data: KenticoTextWithImageData
}

/** Kentico type data. */
export {KenticoTextWithImageData}

const Container = styled(BootstrapContainer)`
  padding: ${({theme}) => theme.spacers[5]} 0;
`

const ImageCol = styled(Col)`
  text-align: center;

  > * {
    border-radius: ${({theme}) => theme.spacers[1]};
  }

  @media (max-width: ${({theme}) => theme.breakpoints.max.md}) {
    padding-bottom: ${({theme}) => theme.spacers[4]};
  }
`

const TextCol = styled(Col)`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

/**
 * Render text with image from Kentico data.
 * @return React component
 */
export const KenticoTextWithImage = ({
  data: {
    elements: {image, info},
    system: {id, name},
  },
}: Props) => {
  let imageElement
  if (validImage(image)) {
    imageElement = (
      <KenticoFluidImage
        alt={toString(image.value[0].description)}
        image={image.value[0]}
      />
    )
  }
  let editLink
  if (!UUID.is(name)) {
    editLink = <KenticoPreviewBodyLink contentItem={id} />
  }
  return (
    <Container>
      {editLink}
      <Row>
        <ImageCol md>{imageElement}</ImageCol>
        <TextCol md>
          <KenticoRichText data={info} />
        </TextCol>
      </Row>
    </Container>
  )
}
