import {graphql} from 'gatsby'
import * as IO from 'io-ts'
import {KenticoContentData} from '../content'
import {KenticoRichTextData} from '../rich-text'

/** Referral Form. */
export const KenticoReferralFormData = IO.intersection(
  [
    KenticoContentData,
    IO.type({
      elements: IO.type({
        header: KenticoRichTextData,
      }),
      system: IO.type({
        type: IO.literal('referral_form'),
      }),
    }),
  ],
  'KenticoReferralForm',
)

/** Data Type. */
export interface KenticoReferralFormData
  extends IO.TypeOf<typeof KenticoReferralFormData> {}

/** Fragment helper. */
export const fragment = graphql`
  fragment KenticoReferralForm on Node {
    ... on kontent_item_referral_form {
      elements {
        header {
          ...KenticoRichText
        }
      }
      system {
        id
        codename
        name
        type
      }
    }
  }
`
