import {KenticoBodyItemBaseData} from './data'
import {RENDERERS} from './renderers'

/** Kentico type data. */
export {KenticoBodyItemBaseData}

interface Props {
  data: KenticoBodyItemBaseData
}

/**
 * Render correct page body item.
 * @return React component
 */
export const KenticoBodyItemBase = ({data}: Props) => {
  const {type} = data.system
  const render = RENDERERS[type]
  if (!render) {
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    throw new Error(`Unsupported body item: ${type}`)
  }
  return render(data)
}
