import React, {ReactNode, useCallback, useState} from 'react'
import styled from 'styled-components'
import {
  Dropdown,
  DropdownMenu as BootstrapDropdownMenu,
  DropdownToggle as BootstrapDropdownToggle,
} from '~/bootstrap/components/dropdown'

interface Props {
  className?: string
  title: ReactNode | ReactNode[]
  children: ReactNode | ReactNode[]
  right?: boolean
}

const DropdownMenu = styled(BootstrapDropdownMenu)`
  box-shadow: 10px 40px 80px 0px ${({theme}) => theme.colors.dark};

  && {
    padding: ${({theme}) => theme.spacers[3]};
    border: none;
  }
`

const DropdownToggle = styled(BootstrapDropdownToggle)`
  && {
    padding: ${({theme}) => theme.spacers[2]} ${({theme}) => theme.spacers[3]};
    color: ${({theme}) => theme.colors.dark};

    @media (max-width: ${({theme}) => theme.breakpoints.max.sm}) {
      font-size: 0.9rem;
      padding: ${({theme}) => theme.spacers[2]} 0;
    }
  }
`

/**
 * Render header dropdown.
 * @return React component
 */
export const HeaderDropdown = ({className, children, right, title}: Props) => {
  const [open, setOpen] = useState(false)
  const toggle = useCallback(() => {
    setOpen(!open)
  }, [open, setOpen])
  return (
    <Dropdown className={className} isOpen={open} toggle={toggle}>
      <DropdownToggle caret color="link">
        {title}
      </DropdownToggle>
      <DropdownMenu flip={false} right={right}>
        {children}
      </DropdownMenu>
    </Dropdown>
  )
}
