import React, {ReactNode} from 'react'
import {CalculatorsContext} from '../../context'
import {useCalculators} from '../../context/default'

interface Props {
  children: ReactNode
}

/**
 * Inject default information into calculators context.
 * @return React component
 */
export const CalculatorsProvider = ({children}: Props) => (
  <CalculatorsContext.Provider value={useCalculators()}>
    {children}
  </CalculatorsContext.Provider>
)
