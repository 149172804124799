import React from 'react'
import styled from 'styled-components'
import {Card as BootstrapCard, CardBody} from '~/bootstrap/components/card'
import {Col as BootstrapCol, ColProps} from '~/bootstrap/components/layout'
import {Customize} from '~/common/components/customize'
import {validImage} from '../../util'
import {KenticoFixedImage} from '../fixed-image'
import {KenticoRichText} from '../rich-text'
import {KenticoCardData} from './data'

/** Kentico type data. */
export {KenticoCardData}

type Size = 'xs' | 'sm' | 'md' | 'lg' | 'xl'

interface Props extends Pick<ColProps, Size> {
  data: KenticoCardData
}

const Card = styled(BootstrapCard)`
  padding-top: ${({theme}) => theme.spacers[3]};
  padding-bottom: ${({theme}) => theme.spacers[2]};
  width: 100%;

  && {
    border-radius: 10px;
  }
`

const Col = styled(BootstrapCol)`
  display: flex;
  padding-top: ${({theme}) => theme.spacers[3]};
  padding-bottom: ${({theme}) => theme.spacers[2]};
`

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-top: ${({theme}) => theme.spacers[3]};
`

/**
 * Render Kentico Card.
 * @return React component
 */
export const KenticoCard = ({
  data: {
    elements: {body, image},
  },
  ...props
}: Props) => {
  let imageElement
  if (validImage(image)) {
    imageElement = (
      <ImageContainer>
        <KenticoFixedImage image={image.value[0]} />
      </ImageContainer>
    )
  }
  return (
    <Col {...props}>
      <Card>
        {imageElement}
        <Customize component={CardBody} textAlign="center">
          <KenticoRichText data={body} />
        </Customize>
      </Card>
    </Col>
  )
}
