import React from 'react'
import SpinnerBase from 'react-spinkit'
import styled, {keyframes} from 'styled-components'

interface Props {
  className?: string
}

interface FadeProps {
  theme: {
    colors: {
      primary: string
      secondary: string
      tertiary: string
    }
  }
}

const fadeKeyframes = ({theme}: FadeProps) => keyframes`
  0% { color: ${theme.colors.primary} }
  33.3% { color: ${theme.colors.secondary} }
  66.7% { color: ${theme.colors.tertiary} }
  100% { color: ${theme.colors.primary} }
`

const StyledSpinner = styled(SpinnerBase)`
  width: 24px;
  height: 24px;

  && {
    animation: ${fadeKeyframes} 2.25s linear infinite;

    > * {
      width: 100%;
      height: 100%;
      margin: 0;
    }
  }
`

/**
 * Render spinner.
 * @param props Component properties
 * @return React component
 */
export const Spinner = (props: Props) => (
  <StyledSpinner {...props} name="ball-clip-rotate" />
)
